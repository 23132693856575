import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import Logo from 'app/components/Typos/Logo';

interface Props {
	children: ReactNode;
}
const AuthWrapper = ({ children }: Props) => {
	const [t, i18n] = useTranslation();

	return (
		<div className={styles.Auth_Wrapper}>
			<div className={styles.slideWrapper}>
				<h1>{t('signup_banner_text')}</h1>
			</div>
			<div className={styles.compWrapper}>
				<div className={styles.LogoWrapper}>
					<Logo variant="dark" />
				</div>
				{children}
				<p className={styles.trademark}>{t('auth_copyright')}</p>
			</div>
		</div>
	);
};

export default AuthWrapper;
