/**
 *
 * FileUploader
 *
 */
import React, { memo } from 'react';
import { FileDrop } from 'react-file-drop';
import { Typography as MuiTypography } from '@material-ui/core';
import styles from './style.module.scss';

interface Props {}

export const FileUploader = memo((props: Props) => {
	const handleChange = e => {
		let files = e.target.files;
		console.log(files);
	};
	return (
		<div className={styles.wrapper}>
			<FileDrop
				onFrameDragEnter={event => console.log('onFrameDragEnter', event)}
				onFrameDragLeave={event => console.log('onFrameDragLeave', event)}
				onFrameDrop={event => console.log('onFrameDrop', event)}
				onDragOver={event => console.log('onDragOver', event)}
				onDragLeave={event => console.log('onDragLeave', event)}
				onDrop={(files, event) => console.log('onDrop!', files, event)}
			>
				<MuiTypography variant="body2">
					Drop your CSV file here or click{' '}
					<input
						type="file"
						id="fileElem"
						multiple
						onChange={handleChange}
						className={styles.visuallyHidden}
					/>
					<label htmlFor="fileElem" className={styles.filelabel}>
						here
					</label>{' '}
					to upload
				</MuiTypography>
			</FileDrop>
		</div>
	);
});
