import React from 'react';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NameReportTableCell from 'app/components/NameReportTableCell';
import Pagination, {
	Props as PaginationProps,
} from 'app/components/Pagination';
import { NoDataFound } from 'app/components/NoDataFound';
import { Trigger } from 'API';
import styles from './style.module.scss';

interface Props extends PaginationProps {
	triggers: Trigger[];
}

const TriggerTable = (props: Props) => {
	const { triggers, ...paginationProps } = props;
	const history = useHistory();
	const { path } = useRouteMatch();

	if (triggers.length === 0)
		return (
			<NoDataFound
				heading={{
					key: 'triggers_no_triggers_msg',
					text: 'No triggers found',
				}}
				description={{
					key: 'trigges_no_triggers_sub_msg',
					text: 'No triggers found',
				}}
			/>
		);

	return (
		<Paper className={styles.wrapperPaper}>
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Action Count</TableCell>
							<TableCell>Last Updated</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{triggers.map(({ id, name, description, updatedAt, actions }) => (
							<TableRow key={id}>
								<NameReportTableCell
									id={id}
									name={name}
									description={description}
									handleViewClick={() => history.push(`${path}/${id}`)}
								/>
								<TableCell>
									<span
										className={styles.clickable}
										onClick={() => history.push(`${path}/assign/${id}`)}
									>
										{actions?.items?.length ?? 0}
									</span>
								</TableCell>
								<TableCell>{moment(updatedAt).format('MM/DD/YYYY')}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Pagination {...paginationProps} />
		</Paper>
	);
};

export default TriggerTable;
