import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { selectTriggerByID } from '../selectors';
import { SelectedList } from 'app/components/SelectedList';
import { selectActionsList } from 'app/containers/ActionsContainer/selectors';
import { actions as triggerActions } from '../slice';
import styles from './style.module.scss';

interface Props {
	cancel: () => void;
}

export const AssignActions = ({ cancel }: Props) => {
	const dispatch = useDispatch();
	const {
		params: { id },
	} = useRouteMatch();
	const trigger = useSelector(selectTriggerByID(id));
	const actions = useSelector(selectActionsList);
	const selectedActions = trigger?.actions?.items?.map(i => i?.actionId) ?? [];
	const initalValues = actions.reduce((accum, action) => {
		accum[action.id] = selectedActions.indexOf(action.id) !== -1;
		return accum;
	}, {});
	const [values, setValues] = useState(initalValues);

	const submit = () => {
		dispatch(
			triggerActions.assignActions({
				group: trigger?.group,
				triggerId: id,
				actionIds: Object.keys(values).filter(id => values[id]),
			})
		);
		cancel();
	};

	return (
		<div className={styles.wrapper}>
			<SelectedList
				values={values}
				list={actions}
				setValues={setValues}
				handleSubmit={submit}
				cancel={cancel}
				objectName="actions"
			/>
		</div>
	);
};

export default AssignActions;
