import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectNotificationsDomain = (state: RootState) =>
	state.notificationsContainer || initialState;

export const selectError = createSelector(
	[selectNotificationsDomain],
	state => state.error
);

export const selectLoading = createSelector(
	[selectNotificationsDomain],
	state => state.fetchingData
);

export const selectNotifications = createSelector(
	[selectNotificationsDomain],
	state => state.notifications
);
