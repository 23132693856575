import React, { ReactNode } from 'react';
import styles from './style.module.scss';
import { Link } from 'react-router-dom';

interface Props {
	children: ReactNode;
	link: string;
}

const LinkComponent = ({ children, link }: Props) => {
	return (
		<Link to={link} className={styles.link}>
			{children}
		</Link>
	);
};

export default LinkComponent;
