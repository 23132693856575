import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { listAudiences } from 'app/containers/AudiencesContainer/selectors';
import { TriggerLeaf } from 'API';
import styles from '../../style.module.scss';

interface Props extends TriggerLeaf {
	id: string;
	triggerId: string;
	editable: boolean;
	handleUpdate: Function;
}

export const Audience = (props: Props) => {
	const { id, triggerId, editable, handleUpdate } = props;
	const audiences = useSelector(listAudiences);
	const menuItems = audiences.map(({ id, name }) => (
		<MenuItem key={id} value={id}>
			{name}
		</MenuItem>
	));
	const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
		handleUpdate(id, { triggerId: event.target.value as string });
	};

	return (
		<div className={styles.nodeExpression}>
			<div>On entering audience:</div>
			<Select
				id="audience-trigger-selector"
				value={triggerId || ''}
				onChange={handleChange}
				disabled={!editable}
				variant="outlined"
			>
				{menuItems}
			</Select>
		</div>
	);
};

export default Audience;
