import React from 'react';

export function MarketoIcon() {
	return (
		<svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
			<title>94A69D42-7034-488B-B4A9-5181DF8D12AA</title>
			<desc>Created with sketchtool.</desc>
			<g
				id="Design"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Settings---Integrations---Data-sources"
					transform="translate(-688.000000, -286.000000)"
				>
					<g id="Marketo" transform="translate(688.000000, 286.000000)">
						<path
							d="M10.2555408,-4.14336123e-16 L53.7444592,4.14336123e-16 C57.3105342,-2.4074122e-16 58.6036791,0.371302445 59.9073828,1.06853082 C61.2110865,1.76575919 62.2342408,2.78891348 62.9314692,4.09261719 C63.6286976,5.39632089 64,6.68946584 64,10.2555408 L64,53.7444592 C64,57.3105342 63.6286976,58.6036791 62.9314692,59.9073828 C62.2342408,61.2110865 61.2110865,62.2342408 59.9073828,62.9314692 C58.6036791,63.6286976 57.3105342,64 53.7444592,64 L10.2555408,64 C6.68946584,64 5.39632089,63.6286976 4.09261719,62.9314692 C2.78891348,62.2342408 1.76575919,61.2110865 1.06853082,59.9073828 C0.371302445,58.6036791 1.60494146e-16,57.3105342 -2.76224082e-16,53.7444592 L2.76224082e-16,10.2555408 C-1.60494146e-16,6.68946584 0.371302445,5.39632089 1.06853082,4.09261719 C1.76575919,2.78891348 2.78891348,1.76575919 4.09261719,1.06853082 C5.39632089,0.371302445 6.68946584,2.4074122e-16 10.2555408,-4.14336123e-16 Z"
							id="Rectangle"
							fill="#563E94"
						></path>
						<g
							id="marketo-an-adobe-company-vector-logo"
							transform="translate(15.000000, 12.000000)"
							fill="#FFFFFF"
							fillRule="nonzero"
						>
							<g id="Group">
								<polygon
									id="Path"
									points="23.7387387 0.215384615 23.7387387 39.8769231 33.8162162 31.7846154 33.8162162 5.72307692"
								></polygon>
								<polygon
									id="Path"
									points="19.0522523 31.2307692 10.8738739 34.8 10.8738739 3.63076923 19.0522523 6.06153846"
								></polygon>
								<polygon
									id="Path"
									points="0 30.3076923 6.18738739 28.6769231 6.18738739 8.76923077 0 7.78461538"
								></polygon>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
