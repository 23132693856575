import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import {
	getSfConnectedUser,
	selectUrlLoader,
} from 'app/containers/IntegrationsContainer/selectors';
import { TextField, Typography } from '@material-ui/core';
import styles from './style.module.scss';
import { actions } from 'app/containers/IntegrationsContainer/slice';
import CustomButton from 'app/components/Typos/ButtonComponent';

interface Props {
	fetchingIdentity: () => void;
	disconnectSf: () => void;
	isConnected: boolean;
}

export const AuthorizationForm = (props: Props) => {
	const { fetchingIdentity, disconnectSf, isConnected } = props;
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const identity = useSelector(getSfConnectedUser);
	const urlLoader = useSelector(selectUrlLoader);

	useEffect(() => {
		isConnected && fetchingIdentity();
	}, [isConnected, fetchingIdentity]);

	return (
		<Formik enableReinitialize initialValues={{}} onSubmit={() => {}}>
			{() => (
				<Form className={styles.formWrapper}>
					<div className={styles.formContent}>
						<Typography
							variant="body2"
							data-translation-key="integrations_salesforce_authorization_description"
						>
							{t('integrations_salesforce_authorization_description')}
						</Typography>
						{identity && (
							<>
								<TextField
									id="sf-user-name-input"
									label="Name"
									defaultValue={identity.display_name}
									InputProps={{
										readOnly: true,
									}}
									fullWidth
									variant="outlined"
								/>
								<TextField
									id="sf-user-email-input"
									label="Email"
									defaultValue={identity.username}
									InputProps={{
										readOnly: true,
									}}
									fullWidth
									variant="outlined"
								/>
							</>
						)}
						<div className={styles.connectSec}>
							{!isConnected ? (
								<CustomButton
									variant="contained"
									type="button"
									onClick={() => dispatch(actions.generateSalesforceUrl())}
									loader={urlLoader}
									disabled={urlLoader}
								>
									{t('integrations_salesforce_connect_sf')}
								</CustomButton>
							) : (
								<>
									<span className={styles.connected}>{'Connected'}</span>
									<span style={{ color: '#FF7D5E' }} onClick={disconnectSf}>
										{'Disconnect'}
									</span>
								</>
							)}
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
