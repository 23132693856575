import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import _ from 'lodash';
import { Providers } from 'API';
import { listIntegrations } from 'app/containers/IntegrationsContainer/selectors';
import { IAction } from 'app/containers/ActionsContainer/types';
import styles from './style.module.scss';

function getIntegrationProvider(integrationProvider: Providers | null) {
	switch (integrationProvider) {
		case Providers.googleTagManager:
			return Providers.googleTagManager;
		default:
			return Providers.universalAnalytics;
	}
}

interface Props {
	step: any;
	stepCount: any;
	next: any;
	setDestinationProvider: (provider: Providers) => void;
	currentAction?: IAction;
	editable?: boolean;
}
export const Overview = (props: Props) => {
	const { currentAction, editable } = props;
	const history = useHistory();
	const [t, i18n] = useTranslation();
	const integrations = useSelector(listIntegrations) || [];
	const destinations = integrations.filter(i => !i.source);

	return (
		<Formik
			enableReinitialize
			initialValues={{
				name: currentAction?.name || '',
				description: currentAction?.description || '',
				destinationId: destinations.length
					? currentAction?.destinationId || ''
					: '',
			}}
			onSubmit={async values => {
				const destinationProvider: Providers | null =
					destinations.find(d => d.id === values.destinationId)?.provider ||
					null;
				props.setDestinationProvider(
					getIntegrationProvider(destinationProvider)
				);
				props.next(values);
			}}
			validationSchema={Yup.object().shape({
				name: Yup.string().required('Required'),
				description: Yup.string().required('Required'),
				destinationId: Yup.string().required('Required'),
			})}
		>
			{({ isValid }) => (
				<Form className={styles.overviewForm}>
					<Box mb={3}>
						<InputLabel htmlFor="name">
							{t('actions_create_action_overview_name')}
						</InputLabel>
						<Field
							type="text"
							name="name"
							placeholder={t('actions_create_action_overview_name_placeholder')}
							component={TextField}
							InputLabelProps={{ shrink: false }}
							fullWidth
							variant="outlined"
						/>
						<InputLabel htmlFor="description">
							{t('actions_create_action_overview_description')}
						</InputLabel>
						<Field
							type="text"
							name="description"
							placeholder={t(
								'actions_create_action_overview_description_placeholder'
							)}
							component={TextField}
							InputLabelProps={{ shrink: false }}
							inputProps={{
								style: { padding: '0', margin: '0' },
							}}
							fullWidth
							variant="outlined"
							multiline
							rows={4}
						/>
						<InputLabel htmlFor="destination">
							{t('actions_create_action_overview_destination')}
						</InputLabel>
						<Field
							component={TextField}
							select={true}
							name="destinationId"
							placeholder={t(
								'actions_create_action_overview_destination_placeholder'
							)}
							fullWidth
							variant="outlined"
							// disabled={!destinations.length}
						>
							{destinations.map(d => (
								<MenuItem key={d.id} value={d.id}>
									{d.name}
								</MenuItem>
							))}
							{!destinations.length && (
								//to goto destination tab of integration
								<MenuItem value={''}>
									{'No destinations available.'}
									<Link to={`/integrations/destination`}>
										{'Setup destinations here'}
									</Link>
								</MenuItem>
							)}
						</Field>
					</Box>
					<div className={`${styles.bottomSec}`}>
						<div className={styles.navigationWrapper}>
							<Button
								type="button"
								variant="contained"
								color="secondary"
								fullWidth
								onClick={() => history.goBack()}
							>
								{t('globals_cancel')}
							</Button>
							<Button
								type="submit"
								variant="contained"
								disabled={!editable && !isValid}
								fullWidth
							>
								{t('globals_next_step')}
							</Button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
