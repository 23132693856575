import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCognitoUser } from '../selectors';
import { AmplifyAuthStatus } from 'app/containers/AuthContainer/types';

interface Props {
	component: any;
	path?: string;
	exact?: boolean;
}

export const PrivateRoute: React.StatelessComponent<Props> = props => {
	const { component: Component, ...rest } = props;
	const cognitoUser: any = useSelector(selectCognitoUser);
	return (
		<Route
			{...rest}
			render={matchProps =>
				!cognitoUser &&
				!(cognitoUser && cognitoUser.userConfirmed === false) ? (
					<Redirect to={AmplifyAuthStatus.login} />
				) : (
					<Component {...matchProps} />
				)
			}
		/>
	);
};
