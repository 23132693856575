import React from 'react';
import { Providers } from 'API';
import { GoogleTagManagerIcon } from 'app/containers/IntegrationsContainer/Destinations/GoogleTagManager/GoogleTagManagerIcon';
import { UniversalAnalyticsIcon } from 'app/containers/IntegrationsContainer/Destinations/UniversalAnalytics/UniversalAnalyticsIcon';

export const getFormattedSourceAndIcon = type => {
	switch (type) {
		case Providers.googleTagManager:
			return { source: 'Google Tag Manager', Icon: <GoogleTagManagerIcon /> };
		case Providers.universalAnalytics:
			return {
				source: 'Universal Analytics',
				Icon: <UniversalAnalyticsIcon />,
			};
		default:
			return { source: '', Icon: '' };
	}
};
