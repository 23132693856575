import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Typography as MuiTypography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { selectNotifications } from 'app/containers/NotificationsContainer/selectors';
import styles from './style.module.scss';
import { Notifications } from './Notifications';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { BoxWrapper } from './BoxWrapper';
import { selectFeatureFlags } from 'app/containers/AuthContainer/selectors';
import { listAudiences } from 'app/containers/AudiencesContainer/selectors';
import { selectGoals } from 'app/containers/GoalContainer/selectors';
import { selectEventsList } from 'app/containers/EventContainer/selectors';
import { selectActionsList } from 'app/containers/ActionsContainer/selectors';
import { listIntegrations } from 'app/containers/IntegrationsContainer/selectors';
import {
	fetchDashboardStats,
	fetchDashboardChartData,
} from './fetchDashboardGraphs';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import moment from 'moment';
import RichTextConverter from 'app/components/RichTextConverter';
import { AttributionGraphComp } from 'app/components/AttributionGraph';

interface DashboardStats {
	organization_id?: string;
	visitors?: string;
	audience_members?: string;
	events_received?: string;
	goals_triggered?: string;
	actions_activated?: string;
	leads_imported?: string;
	visitors_last_7days?: string;
	audience_members_last_7days?: string;
	events_received_last_7days?: string;
	goals_triggered_last_7days?: string;
	actions_activated_last_7days?: string;
	leads_imported_last_7days?: string;
}

const Steps = ({
	title,
	subTitle,
	buttonText,
	stepNo,
	buttonUrl,
	translationKey,
}) => {
	const history = useHistory();
	const handleStepClick = () => {
		history.push(buttonUrl);
	};
	return (
		<div className={styles.stepsWrapper}>
			<div>
				<MuiTypography variant={'h3'}>
					<strong>{`${stepNo} `} </strong>
					{title}
				</MuiTypography>
				<MuiTypography variant={'body2'}>
					<RichTextConverter
						html={subTitle}
						translationKey={translationKey.subtitle}
					></RichTextConverter>
				</MuiTypography>
			</div>
			<Button onClick={handleStepClick} variant="contained">
				{buttonText}
			</Button>
		</div>
	);
};

const start_time = moment().subtract(6, 'd').format('YYYY-MM-DD');
const end_time = moment().format('YYYY-MM-DD');

export const DashBoardComp = () => {
	const [dashboardStats, setStats] = useState<DashboardStats>();
	const [dashboardChart, setChart] = useState([]);
	const [loading, setLoading] = useState(false);
	const [t, i18n] = useTranslation();
	const match = useRouteMatch();
	let baseUrl = match.url.split('dashboard')[0];
	const notifications = useSelector(selectNotifications);
	//steps link
	let installSnippetURL = `${baseUrl}orgSettings/funnelenvy-script`;
	let configureURL = `${baseUrl}integrations`;
	let setupAudienceURL = `${baseUrl}audiences/create`;
	let setupActionURL = `${baseUrl}actions/create-action`;
	let setupGoalURL = `${baseUrl}goals/addCustomGoal`;
	let setupEventURL = `${baseUrl}goals/addCustomGoal`;

	const { marketoMilestone, websiteAttribution } = useSelector(
		selectFeatureFlags
	);
	const audienceList = useSelector(listAudiences);
	const actionsList = useSelector(selectActionsList);
	const integrationsList = useSelector(listIntegrations);
	const goals = useSelector(selectGoals) ?? [];
	const events = useSelector(selectEventsList) ?? [];
	const currentOrg = useSelector(selectedOrganization);

	const sourcedIntegrations = integrationsList.filter(
		item => item.source === true
	);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				if (currentOrg?.id) {
					const org = currentOrg?.id;
					const stats = await fetchDashboardStats(org);
					setStats(stats?.rows[0]);
					const chart = await fetchDashboardChartData(
						org,
						start_time,
						end_time
					);
					setChart(chart?.rows);
				}
			} catch (err) {}
			setLoading(false);
		};
		fetchData();
	}, [currentOrg]);

	return (
		<>
			<div className={styles.titleWrapper}>
				<MuiTypography variant="h3" data-translation-key="dashboard_title">
					{t('dashboard_title')}
				</MuiTypography>
				<MuiTypography
					variant="body2"
					data-translation-key="dashboard_description"
				>
					{t('dashboard_description')}
					<RichTextConverter
						html={t('globals_go_to_knowledge_base')}
						translationKey={'globals_go_to_knowledge_base'}
					></RichTextConverter>
				</MuiTypography>
			</div>
			<div className={styles.Wrapper}>
				{!!audienceList.length ||
				!!sourcedIntegrations.length ||
				!!actionsList.length ? (
					<div className={styles.mainContWrapper}>
						<BoxWrapper
							title={t('dashboard_visitors')}
							count={dashboardStats?.visitors}
							scale={dashboardStats?.visitors_last_7days}
							chartData={dashboardChart}
							configureChartXY={{ x: 'date', y: 'visitors' }}
							showButton={!audienceList.length}
							buttonText={t('dashboard_install_snippet')}
							buttonLink={installSnippetURL}
							noGraphMsg={t('dashboard_visitors_no_graph_msg')}
							chartColor="#FF9A44"
							dateRange={[start_time, end_time]}
						/>
						{/* Events Recieved */}
						<BoxWrapper
							title={t('dashboard_events')}
							count={dashboardStats?.events_received}
							scale={dashboardStats?.events_received_last_7days}
							chartData={dashboardChart}
							configureChartXY={{ x: 'date', y: 'events_received' }}
							showButton={!goals.length && !events.length}
							buttonText={t('dashboard_step_event')}
							buttonLink={setupEventURL}
							noGraphMsg={t('dashboard_events_no_graph_msg')}
							chartColor="#BF00FE"
							dateRange={[start_time, end_time]}
						/>
						{/* Goals Triggered */}
						<BoxWrapper
							title={t('dashboard_goals')}
							count={dashboardStats?.goals_triggered}
							scale={dashboardStats?.goals_triggered_last_7days}
							chartData={dashboardChart}
							configureChartXY={{ x: 'date', y: 'goals_triggered' }}
							showButton={!goals.length}
							buttonText={t('dashboard_step_goal')}
							buttonLink={setupGoalURL}
							noGraphMsg={t('dashboard_goals_no_graph_msg')}
							chartColor="#00FE81"
							dateRange={[start_time, end_time]}
						/>
						<BoxWrapper
							title={t('dashboard_actions_activated')}
							count={dashboardStats?.actions_activated}
							scale={dashboardStats?.actions_activated_last_7days}
							showButton={!marketoMilestone && !actionsList.length}
							buttonText={t('dashboard_setup')}
							buttonLink={setupActionURL}
							chartData={dashboardChart}
							configureChartXY={{ x: 'date', y: 'actions_activated' }}
							noGraphMsg={t('dashboard_actions_activated_no_graph_msg')}
							chartColor="#FF7D5E"
							dateRange={[start_time, end_time]}
						/>
						<BoxWrapper
							title={t('dashboard_leads_imported')}
							count={dashboardStats?.leads_imported}
							scale={dashboardStats?.leads_imported_last_7days}
							chartData={dashboardChart}
							configureChartXY={{ x: 'date', y: 'leads_imported' }}
							showButton={!sourcedIntegrations.length}
							noGraphMsg={t('dashboard_leads_imported_no_graph_msg')}
							buttonText={t('dashboard_configure')}
							buttonLink={configureURL}
							chartColor="#048CFF"
							dateRange={[start_time, end_time]}
						/>
						<BoxWrapper
							title={t('dashboard_audience_members')}
							count={dashboardStats?.audience_members}
							scale={dashboardStats?.audience_members_last_7days}
							configureChartXY={{ x: 'date', y: 'audience_members' }}
							chartData={dashboardChart}
							showButton={!audienceList.length}
							buttonText={t('dashboard_setup_audience')}
							buttonLink={setupAudienceURL}
							noGraphMsg={t('dashboard_audience_members_no_graph_msg')}
							chartColor="#79CD04"
							dateRange={[start_time, end_time]}
						/>
						{currentOrg?.activateWebsiteAttribution && websiteAttribution && (
							<>
								<MuiTypography
									variant="h3"
									data-translation-key={'dashboard_website_attribution_label'}
								>
									{t('dashboard_website_attribution_label')}
								</MuiTypography>
								<AttributionGraphComp />
							</>
						)}
					</div>
				) : (
					<div className={styles.mainContWrapper}>
						<Steps
							stepNo="Step 1."
							title={t('dashboard_add_snippet_title')}
							subTitle={t('dashboard_add_snippet_sub_title')}
							buttonText={t('dashboard_add_snippet')}
							buttonUrl={installSnippetURL}
							translationKey={{
								title: 'dashboard_add_snippet_title',
								subtitle: 'dashboard_add_snippet_sub_title',
							}}
						/>
						<Steps
							stepNo="Step 2."
							title={t('dashboard_configure_title')}
							subTitle={t('dashboard_configure_sub_title')}
							buttonText={t('dashboard_configure')}
							buttonUrl={configureURL}
							translationKey={{
								title: 'dashboard_configure_title',
								subtitle: 'dashboard_configure_sub_title',
							}}
						/>
						{!marketoMilestone && (
							<>
								<Steps
									stepNo="Step 3."
									title={t('dashboard_create_title')}
									subTitle={t('dashboard_create_sub_title')}
									buttonText={t('dashboard_create')}
									buttonUrl={setupAudienceURL}
									translationKey={{
										title: 'dashboard_create_title',
										subtitle: 'dashboard_create_sub_title',
									}}
								/>
								<Steps
									stepNo="Step 4."
									title={t('dashboard_setup_title')}
									subTitle={t('dashboard_setup_sub_title')}
									buttonText={t('dashboard_setup')}
									buttonUrl={setupActionURL}
									translationKey={{
										title: 'dashboard_setup_title',
										subtitle: 'dashboard_setup_sub_title',
									}}
								/>
							</>
						)}
					</div>
				)}

				{notifications.length > 0 && (
					<div className={styles.notificationWrapper}>
						<Box className={styles.boxWrap}>
							<div className={styles.notiBoxTitleWrapper}>
								<MuiTypography variant="h3">
									{t('dashboard_notifications')}
								</MuiTypography>
							</div>
							<div className={styles.notifListsWrapper}>
								{notifications.map(notification => {
									return (
										<Notifications
											id={notification.id}
											subjectId={notification.subjectId}
											subjectType={notification.subjectType}
											message={notification.message}
											timing={notification.updatedAt}
											type={notification.status}
											key={notification.id}
										/>
									);
								})}
							</div>
						</Box>
					</div>
				)}
			</div>
		</>
	);
};
