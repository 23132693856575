import React from 'react';
// import { useRouteMatch } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import styles from './style.module.scss';

interface Props {
	open: boolean;
	name: string | null;
	handleClose: () => void;
	handleArchive: () => void;
}

export const ArchiveComp = ({
	open,
	name,
	handleClose,
	handleArchive,
}: Props) => (
	<Dialog
		maxWidth={'md'}
		open={open}
		onClose={handleClose}
		className={styles.archiveWrapper}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
		data-testid={'archive-comp'}
	>
		<DialogTitle id="alert-dialog-title" className={styles.archiveTitle}>
			Confirm Archive
		</DialogTitle>
		<Divider />
		<DialogContent className={styles.archiveContent}>
			<DialogContentText id="alert-dialog-description">
				{`Are you sure? You want to Archive "${name}".`}
			</DialogContentText>
		</DialogContent>
		<Divider />
		<DialogActions className={styles.archiveButtons}>
			<Button
				style={{ background: '#868dac' }}
				onClick={handleClose}
				disableRipple={true}
			>
				NO
			</Button>
			<Button
				style={{ background: '#f54545' }}
				disableRipple={true}
				onClick={handleArchive}
				autoFocus
			>
				YES
			</Button>
		</DialogActions>
	</Dialog>
);
