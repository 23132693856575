import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { Providers } from 'API';
import { integrationsContainerSaga } from '../../saga';
import { reducer, sliceKey, actions } from '../../slice';
import { Divider, Typography } from '@material-ui/core';
import commonIntStyles from 'app/containers/IntegrationsContainer/style.module.scss';
import { selectIntegrationByProvider } from 'app/containers/IntegrationsContainer/selectors';
import { Header } from '../../components/Header';
import { NotActivated } from '../../components/NotActivated';
import { ButtonSection } from '../../components/ButtonSection';
import { DemandBaseIcon } from './DemandBaseIcon';
import RichTextConverter from 'app/components/RichTextConverter';
import { IntegrationProps as Props } from '../../types';

const integrationName = 'Demandbase';

export function Demandbase({
	group,
	provider,
	organizationId,
	goToIntegration,
}: Props) {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: integrationsContainerSaga });
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const demandbase = useSelector(
		selectIntegrationByProvider(Providers.demandbase)
	);

	const initialIntegrationObj = {
		integrationId: demandbase?.id,
		integration: demandbase?.id
			? null
			: {
					id: demandbase?.id,
					name: integrationName,
					group,
					organizationId,
					source: true,
					provider,
			  },
		group,
		provider,
		attributes: undefined,
		lists: undefined,
		configuration: undefined,
		credentials: undefined,
	};

	//handle Integration Active state
	const handleActiveState = active => {
		dispatch(
			actions.modifyIntegration(
				demandbase?.id
					? {
							//if integration is created only update active
							integrationId: demandbase?.id,
							provider,
							configuration: {
								...demandbase?.configuration,
								active,
							},
					  }
					: {
							// if not, create integration and update active
							...initialIntegrationObj,
							configuration: { active },
					  }
			)
		);
	};
	return (
		<div className={commonIntStyles.wrapper}>
			<Header
				label="integrations_demandbase_title"
				changehandler={handleActiveState}
				value={demandbase?.configuration?.active ?? false}
			/>
			<Divider />
			{demandbase?.configuration?.active ? (
				<div
					className={`${commonIntStyles.padding} ${commonIntStyles.activeIntContentWrapper}`}
				>
					<Typography
						variant="body2"
						data-translation-key="integrations_demandbase_description"
					>
						{t('integrations_demandbase_description')}
					</Typography>
					<Typography
						variant="body2"
						data-translation-key="integrations_demandbase_more"
					>
						{t('integrations_demandbase_more')}
						<RichTextConverter
							html={t('globals_knowledge_base')}
							translationKey={'globals_knowledge_base'}
						/>
					</Typography>
				</div>
			) : (
				<>
					<NotActivated name="Demandbase Source" Icon={<DemandBaseIcon />} />
				</>
			)}

			<div className={commonIntStyles.bottomSec}>
				<Divider />
				<ButtonSection
					disabled={!demandbase?.configuration?.active}
					onSave={goToIntegration}
					onCancel={goToIntegration}
				/>
			</div>
		</div>
	);
}
