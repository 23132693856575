import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { actions } from '../slice';
import { selectFetchingData } from '../selectors';
import AuthWrapper from '../AuthWrapper';
import { TextField } from 'formik-material-ui';
import styles from './style.module.scss';
import CustomButton from 'app/components/Typos/ButtonComponent';
import TextTitle from 'app/components/Typos/TextTitle';
import LinkComponent from 'app/components/Typos/LinkComponent';
import { AmplifyAuthStatus } from 'app/containers/AuthContainer/types';
import { history } from 'utils/history';
import queryString from 'query-string';
import { passwordRequirements } from 'app/containers/AuthContainer/passwordRequirements';

interface Props {
	submitForm: (UserInfo) => {};
	isLoading?: boolean;
	success: boolean;
	error?: string;
}

type UserInfo = {
	user: any;
	code: 'string';
	newPassword: 'string';
};

export const ForgotPasswordSubmitContainer = (props: Props) => {
	const queryParam = queryString.parse(history.location.search);
	const [t, i18n] = useTranslation();
	const dispatch = useDispatch();
	const isFetchingData: boolean = useSelector(selectFetchingData);

	const submitForm = (userInfo): boolean => {
		dispatch(actions.forgotPasswordSubmit(userInfo));
		return true;
	};

	return (
		<AuthWrapper>
			<TextTitle>{t('forgot_password_submit_title')}</TextTitle>
			<Formik
				initialValues={{
					username: queryParam.user || '',
					code: '',
					newPassword: '',
				}}
				validationSchema={Yup.object().shape({
					username: Yup.string()
						.email(t('globals_email_invalid'))
						.required(t('globals_email_required')),
					code: Yup.string().required(
						t('forgot_password_submit_code_required')
					),
					newPassword: passwordRequirements(t),
				})}
				onSubmit={(values, { setSubmitting }) => {
					submitForm(values);
					setSubmitting(false);
				}}
			>
				{({ dirty, isValid }) => {
					return (
						<Form>
							<Field
								type="text"
								name="username"
								placeholder={t('globals_email_placeholder')}
								label={t('globals_email')}
								component={TextField}
								fullWidth
								variant="outlined"
							/>

							<Field
								type="password"
								name="code"
								placeholder={t('forgot_password_submit_code_placeholder')}
								label={t('forgot_password_submit_code')}
								component={TextField}
								fullWidth
								variant="outlined"
							/>

							<Field
								type="password"
								name="newPassword"
								placeholder={t(
									'forgot_password_submit_new_password_placeholder'
								)}
								label={t('forgot_password_submit_new_password')}
								component={TextField}
								fullWidth
								variant="outlined"
							/>

							<div className={`mb-3 ${styles.forgotBtnWrapper}`}>
								<LinkComponent link={AmplifyAuthStatus.login}>
									{t('auth_common_back_to_login')}
								</LinkComponent>
								<CustomButton
									type="submit"
									disabled={!(dirty && isValid) || isFetchingData}
									loader={isFetchingData}
								>
									{t('globals_submit')}
								</CustomButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</AuthWrapper>
	);
};
