import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography as MuiTypography } from '@material-ui/core';
import styles from './style.module.scss';

interface Props {
	label?: string;
}
export const NoMatchFound = ({ label }: Props) => {
	const [t, i18n] = useTranslation();

	return (
		<MuiTypography
			variant="body2"
			className={styles.notMatched}
			data-translation-key={'integration_common_no_match_found'}
		>
			{label ? label : t('integration_common_no_match_found')}
		</MuiTypography>
	);
};
