import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { actions } from './slice';
import { Route, Switch } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { ModalContainer } from 'app/containers/ModalContainer';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import {
	selectActionsList,
	selectFetchingData,
	selectPage,
	selectNextToken,
	selectIndexRange,
} from './selectors';
import { Loader } from 'app/components/Loader';
import { ActionReport } from './ActionReport';
import { AssignTriggers } from './AssignTriggers';
import ActionTable from './Table';
import { CreateAction } from './CreateAction';
import SearchBar from 'app/components/SearchBar';
import styles from './style.module.scss';

export function ActionsContainer() {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const history = useHistory();
	const match = useRouteMatch();
	const { path } = match;
	const loading = useSelector(selectFetchingData);
	const org = useSelector(selectedOrganization);
	const actionsLength = useSelector(selectActionsList).length;
	const actionsList = useSelector(selectPage);
	const nextToken = useSelector(selectNextToken);
	const indexRange = useSelector(selectIndexRange);
	const [search, setSearch] = useState('');

	const buildFilters = useCallback(() => {
		const filters: any = {
			isArchived: {
				ne: true,
			},
		};
		if (search) {
			filters.or = [
				{ name: { contains: search } },
				{ description: { contains: search } },
			];
		}
		return filters;
	}, [search]);

	useEffect(() => {
		dispatch(
			actions.fetchActions({
				organizationId: org.id,
				filter: buildFilters(),
			})
		);
	}, [buildFilters, dispatch, org.id]);

	const handlePrevious = () => dispatch(actions.previous({}));
	const handleNext = () => dispatch(actions.next({}));
	const handleCreate = () => history.push(`${match.url}/create-action`);

	return (
		<div>
			<SearchBar
				title={t('actions_title')}
				description={t('actions_description')}
				search={search}
				handleSearch={setSearch}
				handleCreate={handleCreate}
				createButtonText={t('actions_create_action')}
			/>
			{loading ? (
				<Loader style={{ minHeight: '400px' }} />
			) : (
				<ActionTable
					actions={actionsList}
					hasNext={!!nextToken || actionsLength - 1 > indexRange[1]}
					hasPrevious={indexRange[0] !== 0}
					handleNext={handleNext}
					handlePrevious={handlePrevious}
				/>
			)}
			<Switch>
				<Route path={`${path}/create-action`}>
					<ModalContainer
						onCloseModel={() => history.push(`${path}`)}
						modalInnerWrapStyle={{
							maxWidth: '730px',
						}}
						closeBtnStyle={{ top: '16px', right: '20px' }}
					>
						<CreateAction />
					</ModalContainer>
				</Route>
				<Route path={`${path}/report/:id`}>
					<ModalContainer onCloseModel={() => history.push(`${path}`)}>
						<ActionReport />
					</ModalContainer>
				</Route>
				<Route path={`${path}/assign/:id`}>
					<Dialog open maxWidth="lg" onClose={() => history.push(path)}>
						<AssignTriggers cancel={() => history.push(path)} />
					</Dialog>
				</Route>
				<Route path={`${path}/:id`}>
					<ModalContainer
						onCloseModel={() => history.push(`${path}`)}
						modalInnerWrapStyle={{
							maxWidth: '730px',
						}}
						closeBtnStyle={{ top: '16px', right: '20px' }}
					>
						<CreateAction />
					</ModalContainer>
				</Route>
			</Switch>
		</div>
	);
}
