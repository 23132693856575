import React from 'react';

import { RetoolApp } from '../RetoolApp';

import styles from './style.module.scss';

export function HPEUploader() {
	return (
		<RetoolApp
			url="https://funnelenvy.retool.com/embedded/public/3e563e71-0061-4fcf-8b55-4b98c6d71bc9"
			styles={styles}
		/>
	);
}
