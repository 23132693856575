import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { Providers } from 'API';
import { integrationsContainerSaga } from '../../saga';
import { reducer, sliceKey, actions } from '../../slice';
import { selectIntegrationByProvider } from 'app/containers/IntegrationsContainer/selectors';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import { Switch, TextField } from 'formik-material-ui';
import styles from './style.module.scss';
import { ButtonSection } from './ButtonSection';
import LinkComponent from 'app/components/Typos/LinkComponent';
import { Typography as MuiTypography } from '@material-ui/core';
import PasswordReveal from 'app/components/PasswordReveal';
import { FileUploader } from 'app/components/FileUploader';
import { IntegrationProps as Props } from '../../types';

enum ImportType {
	s3 = 's3',
	csv = 'csv',
}

export function AccountLookup({
	group,
	provider,
	organizationId,
	goToIntegration,
}: Props) {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: integrationsContainerSaga });
	const [t, i18n] = useTranslation();

	return (
		<div className={styles.wrapper}>
			<h3 className={styles.header}>Account Lookup Integration</h3>
			<Divider />
			<Formik
				enableReinitialize
				initialValues={{
					active: false,
					accountName: '',
					importType: ImportType.csv,
					awsSecretKey: '',
					s3ImportPath: '',
					awsAccessKey: '',
				}}
				onSubmit={async values => {
					try {
						goToIntegration();
					} catch (err) {
						console.log('error authenticating account match', err);
					}
				}}
				validationSchema={Yup.object().shape({
					active: Yup.boolean().required('Required'),
					accountName: Yup.string().required('Required'),
					awsSecretKey: Yup.string().when('importType', {
						is: ImportType.s3,
						then: Yup.string().required('Required'),
						otherwise: Yup.string(),
					}),
					awsAccessKey: Yup.string().when('importType', {
						is: ImportType.s3,
						then: Yup.string().required('Required'),
						otherwise: Yup.string(),
					}),
					s3ImportPath: Yup.string().when('importType', {
						is: ImportType.s3,
						then: Yup.string().required('Required'),
						otherwise: Yup.string(),
					}),
				})}
			>
				{({ isValid, values, setFieldValue }) => {
					return (
						<Form className={styles.formWrapper}>
							<div className={styles.formContent}>
								<InputLabel htmlFor="active">
									<Field
										type="checkbox"
										id="active"
										name="active"
										component={Switch}
									/>
									Active Account Lookup Integration
								</InputLabel>
							</div>
							<Divider />
							<div className={styles.fieldWrapper}>
								<MuiTypography variant="h6">{'Name'}</MuiTypography>
								<Field
									type="text"
									name="accountName"
									placeholder={'Apple Inc.'}
									component={TextField}
									InputLabelProps={{ shrink: false }}
									fullWidth
									variant="outlined"
								/>

								<MuiTypography variant="h6">{'Data Import'}</MuiTypography>
								<div className={styles.labelWrap}>
									<InputLabel className={styles.label}>
										<Field
											type="radio"
											name="importType"
											value={ImportType.csv}
										/>
										<span>Upload CSV</span>
									</InputLabel>

									<InputLabel className={styles.label}>
										<Field
											type="radio"
											name="importType"
											value={ImportType.s3}
										/>
										<span>Import via AWS S3</span>
									</InputLabel>
								</div>
							</div>
							{values.importType === ImportType.s3 ? (
								<div className={styles.fieldWrapper}>
									<MuiTypography variant="h6">{'AWS Access Key'}</MuiTypography>
									<Field
										type="text"
										name="awsAccessKey"
										placeholder={'wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY'}
										component={TextField}
										InputLabelProps={{ shrink: false }}
										fullWidth
										variant="outlined"
									/>
									<div style={{ marginBottom: '20px' }}>
										<MuiTypography variant="h6">
											{'AWS Secret Key'}
										</MuiTypography>
										<PasswordReveal
											id="awsSecretKey"
											name="awsSecretKey"
											placeholder={t(
												'integrations_marketo_authorization_client_secret_placeholder'
											)}
											fullWidth
											value={values.awsSecretKey}
											onChange={event =>
												setFieldValue('awsSecretKey', event.target.value)
											}
										/>
									</div>

									<MuiTypography variant="h6">{'S3 Import Path'}</MuiTypography>
									<Field
										type="text"
										name="s3ImportPath"
										placeholder={'s3://import-bucket/FSxLustre20181105T222312Z'}
										component={TextField}
										InputLabelProps={{ shrink: false }}
										fullWidth
										variant="outlined"
									/>
								</div>
							) : (
								<div className={styles.fieldWrapper}>
									<FileUploader />
								</div>
							)}
							<div className={styles.bottomSec}>
								<MuiTypography variant="body2">
									{
										'If you need help or more information about Account Lookup, please see the article in our'
									}
									<LinkComponent link="#">{' Knowledge Base'}</LinkComponent>
								</MuiTypography>
								<ButtonSection
									nextDisabled={!isValid}
									onCancel={goToIntegration}
								/>
							</div>{' '}
						</Form>
					);
				}}
			</Formik>
		</div>
	);
}
