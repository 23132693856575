import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router-dom';

import styles from './style.module.scss';

type Props = {
	nextDisabled?: boolean;
	onCancel: Function;
};

export function ButtonSection({ nextDisabled = false, onCancel }: Props) {
	const history = useHistory();

	return (
		<DialogActions className={styles.buttons}>
			<Button
				fullWidth
				variant="contained"
				onClick={() => onCancel()}
				style={{ backgroundColor: '#E5E8F5' }}
			>
				Cancel
			</Button>
			<Button
				type="submit"
				variant="contained"
				disabled={nextDisabled}
				fullWidth
			>
				{'Save'}
			</Button>
		</DialogActions>
	);
}
