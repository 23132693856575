import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Typography as MuiTypography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { SideBar } from 'app/components/SideBar';
import { Conditions } from 'app/components/Conditions';
import { ParentObject } from 'app/components/Conditions/types';
import { Loader } from 'app/components/Loader';
import { selectedAudience, selectFetchingData } from '../selectors';
import { actions } from '../slice';
import styles from './style.module.scss';
import conditions from './conditions.json';

export function EditAudience() {
	const dispatch = useDispatch();
	const loading = useSelector(selectFetchingData);
	const audience = useSelector(selectedAudience);
	const {
		params: { id },
	} = useRouteMatch();

	useEffect(() => {
		dispatch(
			actions.getAudience({
				id: id,
			})
		);
	}, [dispatch, id]);

	if (loading) return <Loader />;
	if (audience) {
		return (
			<div className={styles.wrapper}>
				<MuiTypography
					variant="h3"
					className={styles.header}
					style={{ padding: '0px 24px' }}
				>
					{audience.name}
				</MuiTypography>
				<MuiTypography variant="body2">{audience.description}</MuiTypography>
				<div className={styles.editWrapper}>
					<Box className={styles.leftSide}>
						<SideBar conditions={conditions} />
					</Box>
					<Box className={styles.rightSide}>
						<Conditions
							parentObject={ParentObject.Audience}
							conditions={audience.conditions}
							editable
						/>
					</Box>
				</div>
			</div>
		);
	}
	return null;
}
