import { Auth, API } from 'aws-amplify';

export async function getStripeCustomerPortal(
	baseUrl: string
): Promise<{ url: string } | undefined> {
	try {
		const user = await Auth.currentAuthenticatedUser();
		const data = await API.post(
			'stripeIntegration',
			'/stripe/create-customer-portal-session',
			{
				headers: {
					Authorization: user.signInUserSession.accessToken.jwtToken,
				},
				body: {
					url: baseUrl,
				},
			}
		);

		return data;
	} catch (err) {
		console.error(`Error fetching stripe customer portal: ${err.message}`);
	}
}
