import React, { useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { Providers } from 'API';
import { selectIntegrationByProvider } from 'app/containers/IntegrationsContainer/selectors';
import { actions } from 'app/containers/IntegrationsContainer/slice';

interface Props {
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export const ValueDropDown = (props: Props) => {
	const dispatch = useDispatch();
	const { disabled, value, setValue } = props;
	const marketo = useSelector(selectIntegrationByProvider(Providers.marketo));
	useEffect(() => {
		marketo?.id && dispatch(actions.fetchIntegration({ id: marketo?.id }));
	}, [dispatch, marketo]);

	const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
		setValue(event.target.value as string);
	};

	const menuItems: JSX.Element[] = (marketo?.lists?.items || []).map(
		({ externalId, name }: any) => (
			<MenuItem key={externalId} value={externalId}>
				{name}
			</MenuItem>
		)
	);
	return (
		<Select
			id="marketo-external-id-selector"
			disabled={disabled}
			value={value}
			onChange={handleChange}
			variant="outlined"
		>
			{menuItems}
		</Select>
	);
};

export default ValueDropDown;
