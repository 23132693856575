import React, { memo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey, actions } from './slice';
import {
	selectPage,
	selectNextToken,
	selectSearchGoalsLoader,
	selectGoals,
	selectIndexRange,
} from './selectors';
import { goalContainerSaga } from './saga';
import { Route, Switch } from 'react-router-dom';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { ModalContainer } from 'app/containers/ModalContainer';
import styles from './style.module.scss';
import SearchBar from 'app/components/SearchBar';
import AddCustomGoal from './AddCustomGoal';
import { ViewGoal } from './ViewGoal';
import { GoalReport } from './GoalReport';
import GoalTable from './Table';
import {
	CreateActionType,
	CreateAction,
} from 'app/containers/ActionsContainer/CreateAction';
import { TextField, MenuItem } from '@material-ui/core';
import { listIntegrations } from 'app/containers/IntegrationsContainer/selectors';
import { IIntegration } from 'app/containers/IntegrationsContainer/types';
import filter from './filter.png';
import { Loader } from 'app/components/Loader';

const goalWidth = '512px';

export const GoalContainer = memo(() => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: goalContainerSaga });
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const history = useHistory();
	const match = useRouteMatch();
	const { path } = match;
	const org = useSelector(selectedOrganization);
	const goals = useSelector(selectPage);
	const goalsLength = useSelector(selectGoals)?.length ?? 0;
	const integrationsList = useSelector(listIntegrations);
	const loading = useSelector(selectSearchGoalsLoader);
	const nextToken = useSelector(selectNextToken);
	const indexRange = useSelector(selectIndexRange);

	const [search, setSearch] = useState('');
	const [source, setSource] = useState('all');
	const [sourcesList, setSourcesList] = useState([] as IIntegration[]);

	useEffect(() => {
		const sourcedIntegrations = integrationsList?.filter(item => item.source);
		setSourcesList(sourcedIntegrations);
	}, [integrationsList]);

	const buildFilters = useCallback(() => {
		const filters: any = {
			isArchived: {
				ne: true,
			},
		};
		if (source !== 'all') {
			filters.type = { eq: source };
		}
		if (search) {
			filters.or = [
				{ name: { contains: search } },
				{ description: { contains: search } },
			];
		}
		return filters;
	}, [search, source]);

	useEffect(() => {
		dispatch(
			actions.fetchGoals({
				organizationId: org.id,
				filter: buildFilters(),
			})
		);
	}, [buildFilters, dispatch, org.id]);

	const handlePrevious = () => dispatch(actions.previous({}));
	const handleNext = () => dispatch(actions.next({}));

	const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setSource(event.target.value);

	const handleAddGoal = () => history.push(`${match.url}/addCustomGoal`);
	const onModalClose = () => history.push(`${match.url}`);

	return (
		<>
			<SearchBar
				title={t('goals_title')}
				description={t('goals_description')}
				search={search}
				handleSearch={setSearch}
				handleCreate={handleAddGoal}
				createButtonText={t('goals_add_goal')}
			>
				<div className={styles.filter}>
					<img src={filter} alt="" />
					<TextField
						id="filter-by-source"
						select
						value={source}
						onChange={handleFilterChange}
						className={styles.filterDropdown}
					>
						<MenuItem value={'all'}>{'All Sources'}</MenuItem>
						{sourcesList?.map(source => (
							<MenuItem key={source?.provider} value={source?.provider}>
								{source?.name}
							</MenuItem>
						))}
					</TextField>
				</div>
			</SearchBar>
			{loading ? (
				<Loader style={{ minHeight: '400px' }} />
			) : (
				<GoalTable
					goals={goals ?? []}
					hasNext={!!nextToken || goalsLength - 1 > indexRange[1]}
					hasPrevious={indexRange[0] !== 0}
					handleNext={handleNext}
					handlePrevious={handlePrevious}
				/>
			)}
			<Switch>
				<Route
					path={`${path}/addCustomGoal`}
					render={() => {
						return (
							<ModalContainer
								onCloseModel={onModalClose}
								modalInnerWrapStyle={{
									maxWidth: goalWidth,
								}}
								closeBtnStyle={{ top: '16px', right: '20px' }}
							>
								<AddCustomGoal />
							</ModalContainer>
						);
					}}
				/>
				<Route
					path={`${match.url}/edit-goal/:id?`}
					render={() => {
						return (
							<ModalContainer
								onCloseModel={onModalClose}
								modalInnerWrapStyle={{
									maxWidth: goalWidth,
								}}
								closeBtnStyle={{ top: '16px', right: '20px' }}
							>
								<AddCustomGoal update />
							</ModalContainer>
						);
					}}
				/>
				<Route
					path={`${match.url}/create-action/:id`}
					render={() => {
						return (
							<ModalContainer
								onCloseModel={onModalClose}
								closeBtnStyle={{ top: '12px', right: '24px' }}
								modalInnerWrapStyle={{
									maxWidth: '730px',
									maxHeight: '500px',
								}}
							>
								<CreateAction typeOf={CreateActionType.Goal} />
							</ModalContainer>
						);
					}}
				/>
				<Route
					path={`${match.url}/report/:id`}
					render={() => {
						return (
							<ModalContainer onCloseModel={onModalClose}>
								<GoalReport />
							</ModalContainer>
						);
					}}
				/>
				<Route
					path={`${match.url}/:id`}
					render={() => {
						return (
							<ModalContainer
								onCloseModel={onModalClose}
								modalInnerWrapStyle={{
									maxWidth: goalWidth,
								}}
							>
								<ViewGoal />
							</ModalContainer>
						);
					}}
				/>
			</Switch>
		</>
	);
});
