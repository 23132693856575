import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Divider, Typography } from '@material-ui/core';
import { selectIntegrationByProvider } from 'app/containers/IntegrationsContainer/selectors';
import { GoogleOptimizeIcon } from './GoogleOptimizeIcon';
import commonIntStyles from 'app/containers/IntegrationsContainer/style.module.scss';
import { Header } from '../../components/Header';
import { NotActivated } from '../../components/NotActivated';
import { ButtonSection } from '../../components/ButtonSection';
import RichTextConverter from 'app/components/RichTextConverter';
import { actions } from '../../slice';
import { Providers } from 'API';
import { IntegrationProps as Props } from '../../types';

export const GoogleOptimize = ({
	group,
	provider,
	organizationId,
	baseUrl,
	goToIntegration,
}: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const googleOptimize: any = useSelector(
		selectIntegrationByProvider(Providers.googleOptimize)
	);
	const [t, i18n] = useTranslation();
	const initialIntegrationObj = {
		integrationId: googleOptimize?.id,
		integration: googleOptimize?.id
			? null
			: {
					id: googleOptimize?.id,
					name: 'Google Optimize',
					group,
					organizationId,
					source: true,
					provider,
			  },
		group,
		provider,
		configuration: undefined,
	};
	const handleActiveState = (active: boolean) => {
		dispatch(
			actions.modifyIntegration(
				googleOptimize?.id
					? {
							integrationId: googleOptimize?.id,
							provider,
							configuration: {
								...googleOptimize?.configuration,
								active,
							},
					  }
					: {
							...initialIntegrationObj,
							configuration: { active },
					  }
			)
		);
	};

	return (
		<div className={commonIntStyles.wrapper}>
			<Header
				label="integrations_google_optimize_title"
				changehandler={handleActiveState}
				value={googleOptimize?.configuration?.active ?? false}
			/>
			<Divider />
			{googleOptimize?.configuration?.active ? (
				<div
					className={`${commonIntStyles.padding} ${commonIntStyles.activeIntContentWrapper}`}
				>
					<Typography
						variant="body2"
						data-translation-key="integrations_google_optimize_description"
					>
						{t('integrations_google_optimize_description')}
					</Typography>
					<Typography
						variant="body2"
						data-translation-key="integrations_google_optimize_more"
					>
						{t('integrations_google_optimize_more')}
						<RichTextConverter
							html={t('globals_knowledge_base')}
							translationKey={'globals_knowledge_base'}
						/>
					</Typography>
				</div>
			) : (
				<NotActivated name="Google Optimize" Icon={<GoogleOptimizeIcon />} />
			)}
			<div className={commonIntStyles.bottomSec}>
				<Divider />
				<ButtonSection
					disabled={!googleOptimize?.configuration?.active}
					onSave={goToIntegration}
					onCancel={goToIntegration}
				/>
			</div>
		</div>
	);
};

export default GoogleOptimize;
