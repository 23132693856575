import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { actions } from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import * as APIt from 'API';
import { errorMessageHandler } from 'utils/errorMessage';
import { audiencesByOrganization, getAudience } from 'graphql/queries';
import {
	createAudience,
	updateAudience,
	editAudienceCondition,
} from 'graphql/mutations';
import { IAudience, ModifyAudience, ArchiveAudience } from './types';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { selectIndexRange } from './selectors';
import _ from 'lodash';

const errorMessage = (err: Error) =>
	errorMessageHandler(err, 'Error occurred fetching Audiences.');

export function* handleError(err: Error) {
	const errMessage = errorMessage(err);
	yield call(toast.error, errMessage);
	yield put(actions.setAudienceError(errMessage));
}

export function* handleModifyAudiences(
	action: PayloadAction<ModifyAudience[]>
): SagaIterator {
	try {
		const currentOrg = yield select(selectedOrganization);
		const {
			data,
		}: GraphQLResult<APIt.AudiencesByOrganizationQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(audiencesByOrganization, {
				filter: { organizationId: { eq: currentOrg?.id } },
			})
		);
		//filter audiences that are already created
		const audiencesToCreate = _.filter(
			action.payload,
			v =>
				!_.find(data?.audiencesByOrganization?.items, {
					name: v?.audience?.name,
				})
		);
		const audiences = audiencesToCreate
			.filter(i => i !== null)
			.map(audience =>
				call(handleModifyAudience, { payload: audience, type: '' })
			);
		yield all(audiences);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleModifyAudience(
	action: PayloadAction<ModifyAudience>
): SagaIterator {
	try {
		const { audience, audienceId, conditions } = action.payload;
		let aId = audienceId;
		if (audience) {
			const { data }: GraphQLResult<any> = yield call(
				[API, 'graphql'],
				graphqlOperation(audience.id ? updateAudience : createAudience, {
					input: action.payload.audience,
				})
			);
			yield put(
				actions.modifyAudienceSuccess(
					audience.id ? data?.updateAudience : data?.createAudience
				)
			);
			aId = audience.id ? data?.updateAudience?.id : data?.createAudience?.id;

			// aId && history.push(`/audiences/${aId}`);
			toast.success(
				`${audience.id ? 'Updated' : 'Created'} Audience ${audience.name}`
			);
		}

		if (conditions) {
			yield put(
				actions.addConditional({
					input: conditions,
					audienceId: aId,
				})
			);
		}
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleCreateConditional(
	action: PayloadAction<{
		input: any;
		audienceId: string | undefined;
	}>
): SagaIterator {
	try {
		const { input, audienceId } = action.payload;
		yield call(
			[API, 'graphql'],
			graphqlOperation(editAudienceCondition, { input, audienceId })
		);
		const { data }: GraphQLResult<any> = yield call(
			[API, 'graphql'],
			graphqlOperation(getAudience, { id: audienceId })
		);
		yield put(
			actions.addConditionalSuccess({
				audienceId,
				conditionals: data?.conditions || {},
			})
		);
		yield call(toast.success, 'Conditionals saved');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleFetchAudiences(
	action: PayloadAction<APIt.AudiencesByOrganizationQueryVariables>
): SagaIterator {
	try {
		const {
			data,
		}: GraphQLResult<APIt.AudiencesByOrganizationQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(audiencesByOrganization, action.payload)
		);
		yield put(
			actions.setIndexRange([
				0,
				parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10') - 1,
			])
		);
		yield put(
			actions.fetchAudiencesSuccess(data?.audiencesByOrganization?.items)
		);
		yield put(actions.setNextToken(data?.audiencesByOrganization?.nextToken));
		yield put(
			actions.setIndexRange([
				0,
				parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10') - 1,
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleNext() {
	try {
		const indexRange = yield select(selectIndexRange);
		yield put(
			actions.setIndexRange([
				indexRange[0] + parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
				indexRange[1] + parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handlePrevious() {
	try {
		const indexRange = yield select(selectIndexRange);
		yield put(
			actions.setIndexRange([
				indexRange[0] - parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
				indexRange[1] - parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleArchiveAudience(
	action: PayloadAction<ArchiveAudience>
): SagaIterator {
	try {
		const { data }: GraphQLResult<any> = yield call(
			[API, 'graphql'],
			graphqlOperation(updateAudience, {
				input: action.payload,
			})
		);
		yield put(actions.archiveAudienceSuccess(data?.updateAudience));
		yield call(toast.success, 'Audience Archived');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleGetAudience(
	action: PayloadAction<APIt.GetAudienceQueryVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.GetAudienceQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(getAudience, action.payload)
		);
		yield put(actions.getAudienceSuccess(data?.getAudience as IAudience));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* audiencesContainerSaga() {
	yield takeLatest(actions.modifyAudience.type, handleModifyAudience);
	yield takeLatest(actions.modifyAudiences.type, handleModifyAudiences);
	yield takeLatest(actions.addConditional.type, handleCreateConditional);
	yield takeLatest(actions.fetchAudiences.type, handleFetchAudiences);
	yield takeLatest(actions.getAudience.type, handleGetAudience);
	yield takeLatest(actions.archiveAudience.type, handleArchiveAudience);
	yield takeLatest(actions.next.type, handleNext);
	yield takeLatest(actions.previous.type, handlePrevious);
}
