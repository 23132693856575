import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FunnelenvyScript } from './FunnelenvyScript';
import { StripeRedirect } from './StripeRedirect';
import { WebsiteAttribution } from './WebsiteAttribution';
// import { PrivacySettings } from './PrivacySettings';
import styles from './style.module.scss';
import { Typography as MuiTypography } from '@material-ui/core';
import { CustomTabs, Data } from 'app/components/CustomTabs';
import { useRouteMatch } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useSelector } from 'react-redux';
import { reducer, sliceKey } from 'app/containers/OrganizationsContainer/slice';
import { organizationContainerSaga } from 'app/containers/OrganizationsContainer/saga';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { selectFeatureFlags } from 'app/containers/AuthContainer/selectors';
import { getStripeCustomerPortal } from './fetchStripeCustomerPortal';

interface Params {
	page: string;
}
const feScript = 'funnelenvy-script';
const subscriptionAndBilling = 'subscribtion-and-billing';
const attribution = 'attribution';
// const privacySettings = 'privacy-settings';

const OrganizationPreferences = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: organizationContainerSaga });
	const [t, i18n] = useTranslation();
	const org = useSelector(selectedOrganization);
	const { websiteAttribution } = useSelector(selectFeatureFlags);
	const [stripePortal, setStripePortal] = useState<string | undefined>();

	useEffect(() => {
		const fetchData = async () => {
			const loc = window.location;
			const url = `${loc.protocol}//${loc.host}`;
			const portal = await getStripeCustomerPortal(url);
			setStripePortal(portal?.url);
		};
		fetchData();
	}, []);

	const match = useRouteMatch();
	const { params } = match;
	const { page } = params as Params;

	const tabs: Data[] = [
		{
			label: t('org_preferences_common_fe_script'),
			translationKey: 'org_preferences_common_fe_script',
			content: <FunnelenvyScript org={org} />,
			tabUrlName: feScript,
		},
		// {
		// 	label: t('org_preferences_common_privacy_settings'),
		// 	translationKey: 'org_preferences_common_privacy_settings',
		// 	content: <PrivacySettings org={org} />,
		// 	tabUrlName: privacySettings,
		// 	disabled: false,
		// },
	];
	if (websiteAttribution) {
		tabs.push({
			label: t('org_preferences_website_attribution_label'),
			translationKey: 'org_preferences_website_attribution_label',
			content: <WebsiteAttribution org={org} />,
			tabUrlName: attribution,
		});
	}
	if (!org.invoiced) {
		tabs.push({
			label: t('org_preferences_common_subscription_and_billing'),
			translationKey: 'org_preferences_common_subscription_and_billing',
			content: <StripeRedirect portalUrl={stripePortal} />,
			tabUrlName: subscriptionAndBilling,
			disabled: !stripePortal,
		});
	}
	return (
		<>
			<div className={styles.wrapper}>
				<MuiTypography variant="h3">
					{t('org_preferences_common_title')}
				</MuiTypography>
				<MuiTypography variant="body2">
					{t('org_preferences_common_description')}
				</MuiTypography>
				<CustomTabs tabs={tabs} page={page} />
			</div>
		</>
	);
};

export default OrganizationPreferences;
