/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import 'sanitize.css/sanitize.css';
import awsconfig from './aws-exports';
import './global-style.scss';

import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import createMyTheme from './theme/main';
// Import root app
import App from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

// Initialize languages
import './locales/i18n';
const theme = createMyTheme({});

// I found out Auth module didn't load configs of aws-exports,
// so my solution for this issue is:
// https://github.com/aws-amplify/amplify-js/issues/4315#issuecomment-629736223

Amplify.configure(awsconfig);

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

interface Props {
	Component: typeof App;
}
const ConnectedApp = ({ Component }: Props) => (
	<Provider store={store}>
		<HelmetProvider>
			<StylesProvider injectFirst>
				<ThemeProvider theme={theme}>
					<Component />
				</ThemeProvider>
			</StylesProvider>
		</HelmetProvider>
	</Provider>
);
const render = (Component: typeof App) => {
	ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
	// Hot reloadable translation json files and app
	// modules.hot.accept does not accept dynamic dependencies,
	// have to be constants at compile-time
	module.hot.accept(['./app', './locales/i18n'], () => {
		ReactDOM.unmountComponentAtNode(MOUNT_NODE);
		const App = require('./app').App;
		render(App);
	});
}

render(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
