import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styles from './style.module.scss';

interface Props {
	html: string;
	translationKey?: string;
}

const RichTextConverter = (props: Props) => {
	const { html, translationKey } = props;
	const convertedHtml = ReactHtmlParser(html);
	return (
		<span
			className={styles.richTextWrapper}
			data-translation-key={translationKey}
		>
			{convertedHtml}
		</span>
	);
};

export default RichTextConverter;
