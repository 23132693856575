import React from 'react';
import styled from 'styled-components/macro';
import { P } from './P';
import { Helmet } from 'react-helmet-async';
import { Footer } from 'app/components/Footer';
import { NavLink } from 'react-router-dom';
import LogoPNG from 'app/components/Header/logo_white.png';

interface Props {}
export function NotAuthorizedPage(props: Props) {
	return (
		<>
			<Helmet>
				<title>404 Page Not Found</title>
				<meta name="description" content="User Not Authorized" />
			</Helmet>
			<Wrapper>
				<Header>
					<NavLink to="/">
						<Img src={LogoPNG} alt="Nav Brand" />
					</NavLink>
				</Header>
				<Content>
					<Title>
						4
						<span role="img" aria-label="Crying Face">
							😢
						</span>
						4
					</Title>
					<P>Page Not Found.</P>
				</Content>
				<Footer />
			</Wrapper>
		</>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
`;

const Header = styled.div`
	height: 48px;
	background: #41519a;
	border-bottom: 1px solid rgba(255, 255, 255, 0.08);
	display: flex;
	align-items: center;
	padding-left: 24px;
`;

const Img = styled.img`
	width: 100px;
`;

const Content = styled.div`
	align-self: center;
	margin: auto;
`;

const Title = styled.div`
	text-align: center;
	margin-top: -8vh;
	font-weight: bold;
	color: ${p => p.theme.text};
	font-size: 3.375rem;

	span {
		font-size: 3.125rem;
	}
`;
