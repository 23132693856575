import _ from 'lodash';

export const getCognitoAttribute = (item, field) => {
	const cognitoUser = _.filter(
		item?.Attributes || item?.UserAttributes,
		(value, key) => {
			return value['Name'] === field;
		}
	);

	if (field === 'custom:organization') {
		return _.get(cognitoUser[0], 'Value')?.toLowerCase().replace(/ /gi, '-');
	}
	return _.get(cognitoUser[0], 'Value');
};
