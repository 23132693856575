import React, { ReactNode } from 'react';
import styles from './style.module.scss';

interface Props {
	children: ReactNode;
	pos?: 'left' | 'center' | 'right';
}

const TextTitle = ({ children, pos }: Props) => {
	return (
		<h1 className={styles.textTitle} style={{ textAlign: pos }}>
			{children}
		</h1>
	);
};

export default TextTitle;
