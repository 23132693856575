import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styles from './style.module.scss';

interface Props {
	onCancel: Function;
	onAgree: Function;
	content: string;
}

export default function AlertDialog(props: Props) {
	const { onCancel, onAgree, content } = props;
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		handleClickOpen();
	}, []);

	return (
		<div>
			<Dialog
				open={open}
				onClose={() => {
					handleClose();
					onCancel();
				}}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className={styles.closeBtn}>
					<IconButton aria-label="close" onClick={() => onCancel()}>
						<CloseIcon />
					</IconButton>
				</div>

				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{content}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							handleClose();
							onCancel();
						}}
						color="secondary"
						variant="contained"
						fullWidth
					>
						No
					</Button>
					<Button onClick={() => onAgree()} variant="contained" fullWidth>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
