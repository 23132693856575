import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Typography as MuiTypography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { actions } from '../slice';
import { selectLoading, selectTriggerByID } from '../selectors';
import { SideBar } from 'app/components/SideBar';
import { Conditions } from 'app/components/Conditions';
import { ParentObject } from 'app/components/Conditions/types';
import { Loader } from 'app/components/Loader';
import conditions from '../conditions.json';
import styles from '../style.module.scss';

export const EditTrigger = () => {
	const dispatch = useDispatch();
	const {
		params: { id },
	} = useRouteMatch<any>();
	const loading = useSelector(selectLoading);
	const trigger = useSelector(selectTriggerByID(id));

	useEffect(() => {
		dispatch(actions.getTrigger({ id: id }));
	}, [dispatch, id]);

	if (loading) return <Loader />;
	return (
		<DndProvider backend={HTML5Backend}>
			<div className={styles.wrapper}>
				<MuiTypography variant="h3">{trigger?.name}</MuiTypography>
				<MuiTypography variant="body2">{trigger?.description}</MuiTypography>
				<div className={styles.editWrapper}>
					<Box className={styles.leftSide}>
						<SideBar conditions={conditions} />
					</Box>
					<Box className={styles.rightSide}>
						<Conditions
							parentObject={ParentObject.Trigger}
							conditions={trigger?.conditions || null}
							editable
						/>
					</Box>
				</div>
			</div>
		</DndProvider>
	);
};
