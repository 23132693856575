import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { actions } from '../slice';
import { selectedGoal } from '../selectors';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Divider, Button } from '@material-ui/core';
import { CustomTabs } from 'app/components/CustomTabs';
import { GoalConfiguration } from './GoalConfiguration';
import { EventConfiguration } from './EventConfiguration';
import styles from './style.module.scss';
import { MatchTypes } from 'API';
import { isEmpty } from 'lodash';
interface Props {
	update?: Boolean;
	onClose?: Function;
}

const goalFields = ['name', 'source'];
const eventFields = ['eventName', 'filterAttributes'];

const verifyFieldsForErrorAndSubmit = (values, changeTab) => {
	if (goalFields?.filter(item => values[item]?.length === 0)?.[0]) {
		changeTab(0);
		return false;
	} else if (
		eventFields?.filter(item => {
			if (item === 'filterAttributes') {
				return (
					values[item]?.filter(
						(obj, ind) =>
							values[item]?.[ind]?.['name']?.length === 0 ||
							values[item]?.[ind]?.['value']?.length === 0
					)?.length > 0
				);
			}
			return values[item]?.length === 0;
		})?.[0]
	) {
		changeTab(1);
		return false;
	} else {
		return true;
	}
};
const AddCustomGoal = (props: Props) => {
	const { update } = props;

	const history = useHistory();
	const [t, i18n] = useTranslation();
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const org = useSelector(selectedOrganization);
	const goal = useSelector(selectedGoal);
	const [tabValue, setTabValue] = useState(0);
	const { params } = match;
	const { id } = params as any;

	useEffect(() => {
		if (update) {
			dispatch(
				actions.getGoal({
					id: id,
				})
			);
		} else {
			dispatch(actions.clearSelectedGoal({}));
		}
	}, [dispatch, id, update]);

	const changeDefaultTabValue = useCallback(value => setTabValue(value), []);

	const handleSubmit = async values => {
		const {
			name,
			description,
			source,
			defaultGoalValue,
			eventName,
			filterAttributes,
		} = values;
		dispatch(
			actions.modifyEventAndGoal({
				group: org?.group,
				organizationId: org?.id,
				goalData: {
					id: goal?.id,
					name,
					description,
					defaultGoalValue,
					type: source,
					typeState: source,
				},
				eventData: {
					id: goal?.event?.id,
					name: eventName,
					source,
					attributes: filterAttributes,
				},
			})
		);
	};
	console.log(goal);

	return (
		<div className={styles.createCustomGoalWrapper}>
			<div>
				<h3 className={styles.header}>
					{update ? 'Edit Goal' : 'Add Custom Goal'}
				</h3>
			</div>
			<Divider />
			<div className={styles.formWrapper}>
				<Formik
					enableReinitialize
					initialValues={{
						name: goal?.name || '',
						description: goal?.description || undefined,
						source: goal?.type || '',
						defaultGoalValue: goal?.defaultGoalValue ?? undefined,
						eventName: goal?.event?.name || '',
						filterAttributes:
							goal?.event?.attributes?.map(item => ({
								name: item?.name || '',
								matchType: item?.matchType || MatchTypes.Contains,
								value: item?.value || '',
							})) || [],
					}}
					onSubmit={() => {}}
					validationSchema={Yup.object().shape({
						name: Yup.string().required('Required'),
						eventName: Yup.string().required('Required'),
						defaultGoalValue: Yup.number().typeError(
							'Goal value must be a number'
						),
						source: Yup.string().required('Required'),
						filterAttributes: Yup.array().of(
							Yup.object().shape({
								name: Yup.string().required('Required'),
								value: Yup.string().required('Required'),
								matchType: Yup.string().required('Required'),
							})
						),
					})}
				>
					{({ errors, touched, values, setTouched }) => (
						<Form className={styles.overviewForm}>
							<CustomTabs
								tabs={[
									{
										translationKey: 'goals_add_custom_goal_config_label',
										content: <GoalConfiguration values={values} />,
										label: t('goals_add_custom_goal_config_label'),
									},
									{
										translationKey: 'goals_add_custom_goal_event_Config_label',
										content: <EventConfiguration values={values} />,
										label: t('goals_add_custom_goal_event_Config_label'),
									},
								]}
								defaultTabValue={tabValue}
								changeDefaultTab={changeDefaultTabValue}
								theme="transparent"
								centeredTab={true}
								style={{ marginTop: '0px' }}
								panelStyles={{ padding: '24px' }}
							/>
							<div className={`${styles.bottomSec}`}>
								<div className={styles.buttonsWrapper}>
									<Button
										fullWidth
										variant="contained"
										onClick={() => history.goBack()}
										className={styles.cancel}
									>
										{t('globals_cancel')}
									</Button>
									<Button
										type="button"
										onClick={() => {
											setTouched(
												{
													name: true,
													source: true,
													eventName: true,
													filterAttributes: values?.filterAttributes?.map(
														item => ({ name: true, value: true })
													),
												},
												true
											);
											const passed = verifyFieldsForErrorAndSubmit(
												values,
												changeDefaultTabValue
											);
											//submit form
											if (passed && isEmpty(errors)) {
												handleSubmit(values);
											}
										}}
										variant="contained"
										disabled={!touched || !errors}
										fullWidth
									>
										{t('globals_save')}
									</Button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default AddCustomGoal;
