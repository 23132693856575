import React, { ChangeEvent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { SourceType } from 'API';

interface Props {
	disabled: boolean;
	source: string;
	setSource: (source: string) => void;
}

export const SourceDropDown = ({ disabled, source, setSource }: Props) => {
	const menuItems: JSX.Element[] = [];
	for (let source in SourceType) {
		menuItems.push(
			<MenuItem key={source} value={source}>
				{source.charAt(0) + source.slice(1).toLowerCase()}
			</MenuItem>
		);
	}
	const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
		setSource(event.target.value as string);
	};

	return (
		<Select
			id="source-type-selector"
			value={source}
			onChange={handleChange}
			disabled={disabled}
			variant="outlined"
		>
			{menuItems}
		</Select>
	);
};

export default SourceDropDown;
