import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';

export const initialState: ContainerState = {
	loading: false,
	error: undefined,
};

const actionTriggerContainerSlice = createSlice({
	name: 'actionTriggerContainer',
	initialState,
	reducers: {
		setError(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		createActionTrigger(_state, _action) {},
		deleteActionTrigger(_state, _action) {},
	},
});

export const { actions, reducer, name: sliceKey } = actionTriggerContainerSlice;
