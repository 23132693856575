import { Auth } from '@aws-amplify/auth';
import React, { useState, useEffect } from 'react';
import Retool from 'react-retool';

export interface Props {
	url: string;
	styles: any;
}

export function RetoolApp(props: Props) {
	const [token, setToken] = useState(undefined as string | undefined);
	const clientId = '15638113-0224-4a87-9c96-cc08f509866b';
	const orgId = '';
	// Todo: Switch retool environment based on local environment
	const environment = 'production';
	console.debug(`[RetoolApp] Before effect`, token);

	useEffect(() => {
		console.debug(`[RetoolApp] Inside effect`);
		async function injectToken() {
			console.debug(`[RetoolApp] Inside injectToken`);
			const session = await Auth.currentSession();
			const token = session.getAccessToken().getJwtToken();
			console.debug(`[RetoolApp] token:`, token);
			setToken(token);
		}
		console.debug(`[RetoolApp] Calling injectToken`);
		injectToken();
	});

	return (
		<div className={props.styles.wrapper}>
			{token && (
				<Retool
					url={props.url + `?environment=${environment}`}
					data={{ environment, token, clientId, orgId }}
				/>
			)}
		</div>
	);
}
