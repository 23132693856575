import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-material-ui';
import {
	Divider,
	InputLabel,
	MenuItem,
	Button,
	Typography,
} from '@material-ui/core';
import { Field, FieldArray } from 'formik';
import styles from './style.module.scss';
import { MatchTypes } from 'API';
import CloseIcon from '@material-ui/icons/Close';
import RichTextConverter from 'app/components/RichTextConverter';

const matchTypesArr = [
	MatchTypes.Contains,
	MatchTypes.Regex,
	MatchTypes.Equals,
];

const pushObj = {
	name: '',
	matchType: MatchTypes.Contains,
	value: '',
};
export const EventConfiguration = ({ values }) => {
	const [t, i18n] = useTranslation();
	const { filterAttributes } = values;

	return (
		<div>
			<InputLabel htmlFor="eventName">
				{t('goals_add_custom_goal_event_name_label')}
			</InputLabel>
			<Field
				type="text"
				name="eventName"
				placeholder={t('goals_add_custom_goal_event_name_placeholder')}
				component={TextField}
				InputLabelProps={{ shrink: false }}
				fullWidth
				variant="outlined"
			/>
			<InputLabel htmlFor="filterAttributes">
				{t('goals_add_custom_goal_filter_by_attribute')}
			</InputLabel>
			<FieldArray name="filterAttributes">
				{({ remove, push }) => {
					return (
						<>
							{filterAttributes?.map((attribute, index) => (
								<React.Fragment key={index}>
									<div className={styles.filterWrapper}>
										<div className={styles.filterRow}>
											<div>
												<Field
													type="text"
													name={`filterAttributes.${index}.name`}
													placeholder={'Attribute Name'}
													component={TextField}
													InputLabelProps={{ shrink: false }}
													fullWidth
													variant="outlined"
												/>
												<Field
													type="text"
													component={TextField}
													select={true}
													name={`filterAttributes.${index}.matchType`}
													placeholder={'Choose Goal Type'}
													fullWidth
													variant="outlined"
												>
													{matchTypesArr.map(type => (
														<MenuItem key={type} value={type}>
															{type}
														</MenuItem>
													))}
												</Field>
											</div>
											<Field
												type="text"
												name={`filterAttributes.${index}.value`}
												placeholder={'Attribute Value'}
												component={TextField}
												InputLabelProps={{ shrink: false }}
												fullWidth
												variant="outlined"
											/>
										</div>
										<span
											className={styles.removeBtn}
											onClick={() => remove(index)}
										>
											<CloseIcon />
										</span>
									</div>
									<Divider className={styles.divider} />
								</React.Fragment>
							))}
							<Button
								type="button"
								variant="contained"
								className={styles.addFilter}
								onClick={() => push(pushObj)}
							>
								Add Filter
							</Button>
						</>
					);
				}}
			</FieldArray>
			<Typography variant="body2">
				{t('goals_add_custom_goal_more_information')}{' '}
				<RichTextConverter
					html={t('globals_knowledge_base')}
					translationKey={'globals_knowledge_base'}
				/>
			</Typography>
		</div>
	);
};
