import React from 'react';
import Plotly from 'plotly.js-basic-dist';

import createPlotlyComponent from 'react-plotly.js/factory';
const Plot = createPlotlyComponent(Plotly);

interface Props {
	data: Data[];
	layout: Object;
	style?: Object;
}

export interface Data {
	x: any[];
	y: any[];
	type: string;
	mode?: string;
	marker?: Object;
}

export const generateScatterPlot = (
	rawData: any[],
	xKey: string,
	yKey: string
) => {
	const xValues = rawData.map(data => data[xKey]);
	const yValues = rawData.map(data => data[yKey]);
	return [
		{
			x: xValues,
			y: yValues,
			type: 'scatter',
			fill: 'tonexty',
			marker: { color: '#FF9A44' },
			line: { shape: 'spline', smoothing: 1.3 },
		},
	];
};

export function AreaChart(props: Props) {
	const { data, layout, style } = props;
	return (
		<>
			<Plot
				data={data}
				style={{
					height: '500px',
					width: '100%',
					...style,
				}}
				layout={layout}
				config={{
					responsive: true,
					displaylogo: false,
					modeBarButtonsToRemove: ['toImage', 'lasso2d'],
				}}
			/>
		</>
	);
}

export default AreaChart;
