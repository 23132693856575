import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './style.module.scss';

interface Props {
	style?: React.CSSProperties;
}
export const Loader = ({ style }: Props) => {
	return (
		<div className={styles.loaderWrapper} style={style}>
			<CircularProgress />
		</div>
	);
};
