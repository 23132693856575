import { API, graphqlOperation } from 'aws-amplify';
import { PayloadAction } from '@reduxjs/toolkit';
import { GraphQLResult } from '@aws-amplify/api';
import {
	all,
	call,
	put,
	takeEvery,
	takeLatest,
	select,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { toast } from 'react-toastify';
import * as APIt from 'API';
import { IAction } from './types';
import { actionsByOrganization, getAction } from 'graphql/queries';
import { handleCreateActionTrigger } from 'app/containers/ActionTriggerContainer/saga';
import { actions as actionTriggerActions } from 'app/containers/ActionTriggerContainer/slice';
import { actions as triggerActions } from 'app/containers/TriggersContainer/slice';
import {
	editUniversalAnalyticsDestination,
	createAction,
	updateAction,
} from 'graphql/mutations';
import { errorMessageHandler } from 'utils/errorMessage';
import { selectActionByID, selectIndexRange } from './selectors';
import { selectTriggerByID } from 'app/containers/TriggersContainer/selectors';
import { actions } from './slice';

export interface ModifyAction {
	destination?: APIt.IntegrationDestination;
	actionId?: string;
	action: APIt.UpdateActionInput;
	configuration: any;
}

const errorMessage = (err: Error) =>
	errorMessageHandler(err, 'Error occurred fetching actions.');

export function* handleError(err: Error) {
	const errMessage = errorMessage(err);
	yield call(toast.error, errorMessage);
	yield put(actions.setActionError(errMessage));
}

export function* handleFetchActions(
	action: PayloadAction<APIt.ActionsByOrganizationQueryVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.ActionsByOrganizationQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(actionsByOrganization, action.payload)
		);
		yield put(
			actions.fetchActionsSuccess(
				data?.actionsByOrganization?.items as IAction[]
			)
		);
		yield put(actions.setNextToken(data?.actionsByOrganization?.nextToken));
		yield put(
			actions.setIndexRange([
				0,
				parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10') - 1,
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleFetchAction(
	action: PayloadAction<APIt.GetActionQueryVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.GetActionQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(getAction, action.payload)
		);
		yield put(actions.fetchActionSuccess(data?.getAction as IAction));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleNext() {
	try {
		const indexRange = yield select(selectIndexRange);
		yield put(
			actions.setIndexRange([
				indexRange[0] + parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
				indexRange[1] + parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handlePrevious() {
	try {
		const indexRange = yield select(selectIndexRange);
		yield put(
			actions.setIndexRange([
				indexRange[0] - parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
				indexRange[1] - parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleModifyActions(
	action: PayloadAction<ModifyAction[]>
): SagaIterator {
	try {
		const actionsObj = action.payload
			.filter(a => a !== null)
			.map(a => put(actions.modifyAction(a)));
		yield all(actionsObj);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleModifyAction(
	action: PayloadAction<ModifyAction>
): SagaIterator {
	try {
		const {
			configuration,
			destination,
			actionId,
			action: actionObj,
		} = action.payload;
		let actId = actionId;
		const { data }: GraphQLResult<any> = yield call(
			[API, 'graphql'],
			graphqlOperation(actionObj.id ? updateAction : createAction, {
				input: action.payload.action,
			})
		);

		const actionData = actionObj.id ? data?.updateAction : data?.createAction;

		yield put(actions.modifyActionSuccess(actionData));

		actId = actionData.id;
		toast.success(
			`${actionObj.id ? 'Updated' : 'Created'} Action ${actionObj.name}`
		);

		//to update actionsCount on Audience
		//can also add goal condition here

		if (configuration) {
			yield put(
				actions.editActionConfiguration({
					actionId: actId,
					destination,
					input: configuration,
				})
			);
		}
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleEditActionConfiguration(
	action: PayloadAction<{
		input: any;
		actionId: string | undefined;
		destination: APIt.IntegrationDestination;
	}>
): SagaIterator {
	try {
		const { input, destination, actionId } = action.payload;
		const { createdAt, updatedAt, ...configuration } = input;
		const { operation, returnValueName } = getConfigurationOperation(
			destination
		);
		const { data }: GraphQLResult<any> = yield call(
			[API, 'graphql'],
			graphqlOperation(operation, {
				actionId,
				input: configuration,
			})
		);
		yield put(
			actions.editActionConfigurationSuccess({
				actionId,
				configuration: data?.[returnValueName],
			})
		);
		yield call(toast.success, 'Configuration saved');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleArchiveAction(
	action: PayloadAction<{ id: string; isArchived: boolean }>
) {
	try {
		const { data }: GraphQLResult<any> = yield call(
			[API, 'graphql'],
			graphqlOperation(updateAction, { input: action.payload })
		);
		yield put(actions.archiveActionSuccess(data?.updateAction as IAction));
	} catch (err) {
		yield call(handleError, err);
	}
}

function getConfigurationOperation(destination: APIt.IntegrationDestination) {
	switch (destination) {
		case APIt.IntegrationDestination.universalAnalytics:
			return {
				operation: editUniversalAnalyticsDestination,
				returnValueName: 'editUniversalAnalyticsDestination',
			};
		default:
			throw new Error(
				`No configuration operation found for destination ${destination}.`
			);
	}
}

export function* handleAssignTriggers(
	action: PayloadAction<{
		group: string;
		actionId: string;
		triggerIds: string[];
	}>
) {
	try {
		const { group, actionId, triggerIds } = action.payload;
		const a = yield select(selectActionByID(actionId));
		const existingTriggerIds = a.triggers.items.map(t => t.triggerId);
		const triggersToDelete = triggerIds.length
			? existingTriggerIds.filter(
					(triggerId: string) => triggerIds.indexOf(triggerId) === -1
			  )
			: existingTriggerIds;
		const createActionTriggers = triggerIds
			// filter triggers that already have the assignment
			.filter(
				(triggerId: string) => existingTriggerIds.indexOf(triggerId) === -1
			)
			.map((triggerId: string) => {
				return put(
					actions.assignTrigger({
						group,
						action: a,
						triggerId,
					})
				);
			});
		const deleteActionTriggers = triggersToDelete.map((triggerId: string) =>
			put(actions.removeTrigger({ group, action: a, triggerId }))
		);
		yield all(createActionTriggers);
		yield all(deleteActionTriggers);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleAssignTrigger(
	action: PayloadAction<{ group: string; action: IAction; triggerId: string }>
) {
	try {
		const { group, action: a, triggerId } = action.payload;
		const t = yield select(selectTriggerByID(triggerId));
		const actionTrigger = yield* handleCreateActionTrigger({
			...action,
			payload: {
				group,
				actionId: a?.id,
				triggerId,
			},
		});
		yield put(actions.assignTriggerSuccess(actionTrigger));
		yield put(triggerActions.assignActionSuccess(actionTrigger));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleRemoveTrigger(
	action: PayloadAction<{ group: string; action: IAction; triggerId: string }>
) {
	try {
		const { action: a, triggerId } = action.payload;
		const t = yield select(selectTriggerByID(triggerId));
		const actionTriggerId =
			a?.triggers?.items.find(t => t?.triggerId === triggerId)?.id ?? null;
		if (actionTriggerId) {
			yield put(
				actionTriggerActions.deleteActionTrigger({ id: actionTriggerId })
			);
			yield put(
				actions.removeTriggerSuccess({
					action: a,
					trigger: t,
				})
			);
			yield put(
				triggerActions.removeActionSuccess({
					action: a,
					trigger: t,
				})
			);
		}
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* actionContainerSaga() {
	yield takeLatest(actions.fetchActions.type, handleFetchActions);
	yield takeLatest(actions.fetchAction.type, handleFetchAction);
	yield takeLatest(actions.modifyActions.type, handleModifyActions);
	yield takeEvery(actions.modifyAction.type, handleModifyAction);
	yield takeEvery(
		actions.editActionConfiguration.type,
		handleEditActionConfiguration
	);
	yield takeLatest(actions.archiveAction.type, handleArchiveAction);
	yield takeLatest(actions.next.type, handleNext);
	yield takeLatest(actions.previous.type, handlePrevious);
	yield takeLatest(actions.assignTriggers.type, handleAssignTriggers);
	yield takeEvery(actions.assignTrigger.type, handleAssignTrigger);
	yield takeEvery(actions.removeTrigger.type, handleRemoveTrigger);
}
