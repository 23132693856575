import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from 'utils/history';
import queryString from 'query-string';
import { loadStripe } from '@stripe/stripe-js';
import { useQuery } from 'utils/history';
import styles from './style.module.scss';
import { Formik, Form, Field } from 'formik';
import TextTitle from 'app/components/Typos/TextTitle';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import TextComp from 'app/components/Typos/TextComponent';
import LinkComponent from 'app/components/Typos/LinkComponent';
import CustomButton from 'app/components/Typos/ButtonComponent';
import { AmplifyAuthStatus } from 'app/containers/AuthContainer/types';
import AuthWrapper from '../AuthWrapper';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { getStripeSession } from './fetchStripeSession';

// phone input
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const stripeKey = process.env.REACT_APP_STRIPE_KEY || '';
const stripeTestKey = process.env.REACT_APP_STRIPE_TEST_KEY || '';

export const SignUpContainer = () => {
	const [t, i18n] = useTranslation();
	const { email } = queryString?.parse(history?.location?.search);
	const { checkout_code: code } =
		queryString?.parse(history?.location?.search) || null;
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState({
		website: '',
		organization: '',
		name: '',
		username: email || '',
		phone_number: '',
		tos: false,
	});
	const query = useQuery();

	useEffect(() => {
		const userData = localStorage.getItem('user');
		const user = userData && JSON.parse(userData);
		if (user) {
			setValues({
				website: user.attributes.website,
				organization: user.attributes['custom:organization'],
				name: user.attributes.name,
				username: user.username,
				phone_number: user.attributes.phone_number,
				tos: false,
			});
		}
		localStorage.removeItem('user');
	}, []);

	const handleSubmitForm = async (userInfo, setFieldError) => {
		setLoading(true);
		localStorage.setItem('user', JSON.stringify(userInfo));
		const {
			email,
			'custom:organization': organization,
			name,
		} = userInfo.attributes;
		const loc = window.location;
		const url = `${loc.protocol}//${loc.host}`;
		const key = email.endsWith('@funnelenvy.com') ? stripeTestKey : stripeKey;
		try {
			const stripePromise = loadStripe(key);
			const stripe = await stripePromise;
			const data = await getStripeSession(email, organization, url, code);
			if (!data?.emailConflict && !data?.organizationConflict) {
				await stripe?.redirectToCheckout({
					sessionId: data?.id || '',
				});
			}
			if (data?.emailConflict)
				setFieldError('username', 'Email is already in use.');
			if (data?.organizationConflict)
				setFieldError('organization', 'Organization name is already taken.');
		} catch (err) {}
		setLoading(false);
	};

	return (
		<AuthWrapper>
			<div className={styles.SignupWrapper}>
				<TextTitle>{t('signup_greeting')}</TextTitle>
				<TextComp>{t('signup_title')}</TextComp>
				<Formik
					enableReinitialize
					initialValues={values}
					validationSchema={Yup.object().shape({
						name: Yup.string().required(t('signup_name_required')),
						website: Yup.string().required(t('signup_website_required')),
						organization: Yup.string().required(t('globals_required')),
						username: Yup.string()
							.email(t('globals_email_invalid'))
							.required(t('globals_email_required')),
					})}
					onSubmit={(values, { setSubmitting, setFieldError }) => {
						const {
							username,
							phone_number,
							website,
							organization,
							name,
						} = values;
						handleSubmitForm(
							{
								username,
								attributes: {
									phone_number,
									email: username,
									website: website,
									name,
									'custom:organization': organization,
									'custom:signup_type': query.get('signup_type') ?? '',
								},
							},
							setFieldError
						);
						setSubmitting(false);
					}}
				>
					{({ isValid, dirty, setFieldValue, values }) => (
						<Form>
							<Field
								name="username"
								type="text"
								label={t('globals_email')}
								placeholder={t('globals_email_placeholder')}
								component={TextField}
								fullWidth
								variant="outlined"
							/>
							<Field
								name="name"
								type="text"
								label={t('signup_name')}
								placeholder={t('signup_name_placeholder')}
								component={TextField}
								fullWidth
								variant="outlined"
							/>
							<Field
								name="organization"
								type="text"
								label={t('auth_common_organization')}
								placeholder={t('auth_common_organization_placeholder')}
								component={TextField}
								fullWidth
								variant="outlined"
							/>
							<Field
								name="website"
								type="text"
								label={t('signup_website')}
								placeholder={t('signup_website_placeholder')}
								component={TextField}
								fullWidth
								variant="outlined"
							/>
							<PhoneInput
								inputProps={{
									name: 'phone_number',
									label: t('globals_phone'),
								}}
								inputClass={`MuiInputBase-input MuiOutlinedInput-input`}
								containerClass={`MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth`}
								preferredCountries={['us']}
								country="us"
								enableSearch={true}
								enableAreaCodeStretch
								onChange={val => {
									const phoneNo = `+${val}`;
									setFieldValue('phone_number', phoneNo);
								}}
							/>
							<div className={styles.tosWrapper}>
								<MuiCheckbox
									name={'tos'}
									checked={values.tos}
									onChange={e =>
										setFieldValue('tos', Boolean(e.target.checked))
									}
								/>
								<span className={styles.tos}>
									I agree to the{' '}
									<a href="https://www.funnelenvy.com/terms/" target="_blank">
										Terms of Service
									</a>
								</span>
							</div>

							<div className={`form-group ${styles.bottomSec}`}>
								<TextComp>
									{t('signup_already_account')}{' '}
									<LinkComponent link={AmplifyAuthStatus.login}>
										{t('globals_login')}
									</LinkComponent>
								</TextComp>
								<CustomButton
									type="submit"
									disabled={!(dirty && isValid) || loading || !values.tos}
									loader={loading}
								>
									{t('globals_signup')}
								</CustomButton>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</AuthWrapper>
	);
};
