import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { DialogContent } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { actions } from '../../../slice';
import { Switch, TextField } from 'formik-material-ui';
import styles from '../style.module.scss';

interface Props {
	config: any;
}

const defaultValue = 'funnelEnvy_bvid__c';

export function AdvancedSettings(props: Props) {
	const { config } = props;
	const dispatch = useDispatch();
	const handleToggle = (e, values) => {
		const { hiddenField } = values;
		dispatch(
			actions.setMarketoConfiguration(
				e.target.checked
					? {
							...config,
							hiddenField,
					  }
					: {
							...config,
							hiddenField: null,
					  }
			)
		);
	};

	const handleHiddenFieldChange = e => {
		dispatch(
			actions.setMarketoConfiguration({
				...config,
				hiddenField: e.target.value,
			})
		);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				activateHiddenField: !!config?.hiddenField,
				hiddenField: config?.hiddenField || defaultValue,
			}}
			onSubmit={() => console.log('submit')}
			validationSchema={Yup.object().shape({
				hiddenField: Yup.string().required('Required'),
			})}
		>
			{({ values, handleChange }) => (
				<Form className={styles.formWrapper}>
					<DialogContent>
						<InputLabel
							htmlFor="activateHiddenField"
							className={styles.labelReverse}
						>
							<Field
								type="checkbox"
								id="activateHiddenField"
								name="activateHiddenField"
								component={Switch}
								checked={values.activateHiddenField}
								inputProps={{
									onChange: e => {
										handleChange(e);
										handleToggle(e, values);
									},
								}}
							/>
							Add hidden field with FunnelEnvy visitor ID
						</InputLabel>
						{values.activateHiddenField && (
							<>
								<InputLabel htmlFor="hiddenField">Hidden Field</InputLabel>
								<Field
									type="text"
									id="hiddenField"
									name="hiddenField"
									placeholder={defaultValue}
									InputLabelProps={{ shrink: false }}
									component={TextField}
									variant="outlined"
									fullWidth
									InputProps={{
										onChange: e => {
											handleChange(e);
											handleHiddenFieldChange(e);
										},
									}}
								/>
							</>
						)}
					</DialogContent>
				</Form>
			)}
		</Formik>
	);
}
