import React from 'react';
import { Typography as MuiTypography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import style from './style.module.scss';
import { ModalContainer } from 'app/containers/ModalContainer';
import { MarketoReport } from './DataSources/Marketo/MarketoReport';
import { ReportIcon } from 'app/components/ReportIcon';
import { listIntegrations } from './selectors';
import { Providers } from 'API';

interface Props {
	name: string;
	icon: React.ReactNode;
	onClickHandler: () => void;
	compo: any;
	provider: string;
	baseUrl: string;
}

export function Integration(props: Props) {
	const { baseUrl } = props;
	const history = useHistory();
	const { name, provider, icon, onClickHandler, compo } = props;
	const integrationsList = useSelector(listIntegrations);
	const isActiveIntegration =
		integrationsList?.filter(item => item.provider === provider)[0]
			?.configuration?.active ?? false;
	const onCloseModal = () => history.push(baseUrl);

	return (
		<>
			<div className={style.intWrapper}>
				{provider === Providers.marketo && isActiveIntegration && (
					<span
						className={style.reportIconWrapper}
						onClick={() => history.push(`${baseUrl}/${provider}/report`)}
					>
						<ReportIcon />
					</span>
				)}
				<div className={style.integration} onClick={onClickHandler}>
					<div className={style.icon}>{icon}</div>
					<MuiTypography variant="body2">{name}</MuiTypography>
					<span
						className={`${style.dot} ${
							isActiveIntegration ? style.active : style.inActive
						}`}
					></span>
				</div>
			</div>

			<Switch>
				{history.location.pathname.includes('/report') === true && (
					<Route
						exact
						path={`${baseUrl}/${provider}/report`}
						render={() => (
							<ModalContainer onCloseModel={onCloseModal}>
								<MarketoReport />
							</ModalContainer>
						)}
					/>
				)}

				{history.location.pathname.includes('/report') === false &&
					provider === Providers.salesforce && (
						<>
							<Route exact path={`${baseUrl}/${provider}`}>
								<Redirect to={`${baseUrl}/${provider}/authorization`} />
							</Route>
							<Route path={`${baseUrl}/${provider}/:tab?`}>
								<Dialog open maxWidth="lg" onClose={onCloseModal}>
									{React.cloneElement(compo, { goToIntegration: onCloseModal })}
								</Dialog>
							</Route>
						</>
					)}

				{history.location.pathname.includes('/report') === false &&
					provider === Providers.marketo && (
						<>
							<Route exact path={`${baseUrl}/${provider}`}>
								<Redirect to={`${baseUrl}/${provider}/authorization`} />
							</Route>
							<Route path={`${baseUrl}/${provider}/:tab?`}>
								<Dialog open maxWidth="lg" onClose={onCloseModal}>
									{React.cloneElement(compo, { goToIntegration: onCloseModal })}
								</Dialog>
							</Route>
						</>
					)}

				{history.location.pathname.includes('/report') === false &&
					provider === Providers.hubspot && (
						<>
							<Route exact path={`${baseUrl}/${provider}`}>
								<Redirect to={`${baseUrl}/${provider}/authorization`} />
							</Route>
							<Route path={`${baseUrl}/${provider}/:tab?`}>
								<Dialog open maxWidth="lg" onClose={onCloseModal}>
									{React.cloneElement(compo, { goToIntegration: onCloseModal })}
								</Dialog>
							</Route>
						</>
					)}

				<Route path={`${baseUrl}/${provider}`} exact>
					<Dialog open maxWidth="lg" onClose={onCloseModal}>
						{React.cloneElement(compo, { goToIntegration: onCloseModal })}
					</Dialog>
				</Route>
			</Switch>
		</>
	);
}
