import React from 'react';

export function AllSetImage() {
	return (
		<svg width="450px" height="342px" viewBox="0 0 450 342" version="1.1">
			<title>162DF8DF-55C4-4ED7-8078-6B564D2F6C2C</title>
			<defs>
				<polygon
					id="path-1"
					points="0.000676712463 0.120134116 449.892471 0.120134116 449.892471 341.537439 0.000676712463 341.537439"
				></polygon>
				<polygon
					id="path-3"
					points="0.0495657953 0.176510855 372.595933 0.176510855 372.595933 126.406735 0.0495657953 126.406735"
				></polygon>
			</defs>
			<g
				id="Stage-based-onboarding"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Onboarding---You'r-all-set"
					transform="translate(-495.000000, -144.000000)"
				>
					<g id="Group-117" transform="translate(495.000000, 144.000000)">
						<g id="Group-3" transform="translate(0.000000, 0.079752)">
							<mask id="mask-2" fill="white">
								<use xlinkHref="#path-1"></use>
							</mask>
							<g id="Clip-2"></g>
							<path
								d="M446.315623,178.367718 C441.021693,194.102565 429.534231,210.69315 409.785073,226.643926 C402.630731,232.422149 396.642561,238.615879 391.462078,245.017164 C383.804502,254.476535 377.904457,264.38471 372.595973,274.068383 C348.085428,318.77051 336.168104,358.646451 221.926123,327.086837 C176.525769,314.546451 166.976278,331.059278 117.766774,339.419668 C116.32167,339.662712 114.842871,339.901967 113.328782,340.13265 C99.6766549,342.209189 87.5326362,341.91331 76.7581568,339.708968 C63.1229774,336.917253 51.6795791,331.069845 42.1446433,323.099641 C23.2322818,307.287235 11.8245724,283.1272 5.70640673,257.905866 C3.45999557,248.652056 1.92676562,239.255689 0.997656181,230.077444 C0.469100576,224.863066 0.160859548,219.948954 0.0496056712,215.307195 C-0.346761187,198.953872 1.6940895,186.002366 5.11704032,175.295078 L5.11903412,175.292884 C5.54271599,173.967408 5.98733274,172.675428 6.45089056,171.416943 C6.95432432,170.047404 7.48068675,168.719734 8.02599025,167.42556 L8.0323704,167.413 C21.2480549,136.12813 46.1737085,125.38296 46.6163314,95.4528755 C47.7888835,16.1896721 107.567107,2.42848471 155.002131,11.7283518 C255.505654,31.4336464 252.464515,-8.95190961 324.628206,2.05743908 C456.754346,22.215525 365.533943,74.6194905 420.855233,106.554537 C442.943115,119.30467 456.821537,147.147847 446.315623,178.367718"
								id="Fill-1"
								fill="#EBF3FA"
								mask="url(#mask-2)"
							></path>
						</g>
						<path
							d="M446.315623,178.44747 C441.021693,194.182317 429.534231,210.772902 409.785073,226.723677 C343.246881,280.46568 377.657027,370.186549 221.926123,327.166588 C175.193912,314.256952 166.445529,332.133137 113.328782,340.212401 C35.5693,352.037612 6.75075764,286.933957 0.997656181,230.157195 C-1.62418697,204.289672 1.17390784,185.81156 6.45089056,171.496695 L8.02599025,167.505312 L8.0323704,167.492751 C8.0323704,167.492751 25.3780062,165.7414 31.6702304,180.57206 C31.6702304,180.57206 37.3332122,171.423323 48.0299335,174.603031 C58.7268542,177.784732 56.4196323,186.593726 56.4196323,186.593726 C56.4196323,186.593726 73.050093,175.013952 87.8807532,190.159433 C102.711613,205.304914 93.7534825,226.86424 93.7534825,226.86424 C93.7534825,226.86424 107.596416,225.912003 111.791365,236.4556 C111.791365,236.4556 131.678892,227.816278 144.177408,239.295565 C156.675924,250.774652 169.822424,261.427311 169.822424,261.427311 C169.822424,261.427311 183.977785,265.03708 182.912499,282.655268 C182.912499,282.655268 193.372158,274.685264 207.953195,278.880013 C207.953195,278.880013 210.46179,260.728883 235.374883,256.800306 C260.287975,252.871927 271.477763,259.583646 271.477763,259.583646 C271.477763,259.583646 271.865556,242.999641 291.036114,241.224962 C291.036114,241.224962 290.490611,215.536083 319.015467,212.809566 C347.540323,210.084843 353.509353,225.041511 353.509353,225.041511 C353.509353,225.041511 357.188507,213.115614 376.484475,213.906354 C376.484475,213.906354 380.25973,191.870908 405.428826,193.007771 C405.428826,193.007771 411.301555,181.979681 423.886004,185.754936 C423.886004,185.754936 432.78093,170.806642 446.315623,178.44747"
							id="Fill-4"
							fill="#CADAFC"
						></path>
						<path
							d="M391.462058,245.096936 C348.448478,298.224251 360.913299,365.561557 221.926303,327.166608 C175.193892,314.256972 166.44551,332.133157 113.328762,340.212421 C35.56928,352.037632 6.7507377,286.933977 0.997636243,230.157016 C-1.39332521,206.565412 0.722891006,189.121283 5.11702038,175.374849 L5.11901418,175.372656 C5.11901418,175.372656 19.5323726,182.837233 18.9033296,194.792439 C18.9033296,194.792439 32.7460634,190.287455 43.7554121,199.933445 C43.7554121,199.933445 59.8006934,188.085106 81.194136,199.933445 C102.585784,211.77959 89.6867147,236.45562 89.6867147,236.45562 C89.6867147,236.45562 101.641921,236.948487 104.473312,248.901699 C104.473312,248.901699 119.792851,239.641708 129.908779,252.452652 C139.871385,265.068403 130.842076,285.098686 114.043937,289.901943 C117.699763,288.989581 133.468106,285.985926 141.985607,299.551921 C141.985607,299.551921 156.06959,285.081938 170.697082,299.551921 C170.697082,299.551921 186.853418,294.346316 193.535629,308.663175 C193.535629,308.663175 203.225882,291.464482 223.157472,297.756506 C243.089063,304.04893 243.760374,318.311159 243.760374,318.311159 C243.760374,318.311159 248.989107,307.824185 262.190237,310.760649 C262.190237,310.760649 257.252997,286.011048 283.309134,279.718824 C283.309134,279.718824 285.87216,259.583666 308.316533,261.261646 C308.316533,261.261646 316.917973,251.978527 326.985452,254.522612 C326.985452,254.522612 356.907162,223.529236 391.462058,245.096936"
							id="Fill-6"
							fill="#EEF4FF"
						></path>
						<path
							d="M303.878859,65.4694572 C303.878859,65.4694572 298.577153,60.0702548 288.733378,64.1110833 C278.889404,68.1519118 281.757082,79.0673527 274.036701,76.517685 C266.31652,73.9680173 258.816453,74.2407687 258.150326,80.8854962 C257.162599,90.7362494 252.489138,87.0114377 249.437432,95.8638968 C246.686391,103.84387 249.841774,107.38605 255.656086,104.963188 C263.939914,101.511327 257.582093,110.256918 269.308611,111.874885 C281.035328,113.492851 295.213019,103.447703 297.353959,96.5423859 C299.495297,89.6370691 302.812776,85.8861387 305.343702,83.0525543 C307.874428,80.2193686 311.148443,71.0325498 303.878859,65.4694572"
							id="Fill-8"
							fill="#163560"
						></path>
						<path
							d="M244.020166,138.289586 L243.769945,140.546365 C243.700361,141.175009 244.006608,141.785908 244.552111,142.106112 L248.023312,144.14457 C248.689639,144.535753 249.478983,144.661163 250.233835,144.495678 L253.537158,143.77173 L244.020166,138.289586 Z"
							id="Fill-10"
							fill="#163560"
						></path>
						<path
							d="M275.532687,149.601653 L264.745846,143.387984 C263.756923,142.818356 263.41399,141.543323 263.983618,140.5544 L285.198815,103.72498 C287.306657,100.065964 294.149169,95.1091848 297.808385,97.217027 L298.868288,97.8275277 C302.536475,99.9407532 301.684925,108.358764 299.571699,112.027151 L278.366471,148.839424 C277.796644,149.828347 276.521611,150.17128 275.532687,149.601653"
							id="Fill-12"
							fill="#D86036"
						></path>
						<polygon
							id="Fill-14"
							fill="#D86036"
							points="275.812676 91.3870647 301.388507 91.7054741 268.162277 144.333343 254.252951 140.349338"
						></polygon>
						<path
							d="M253.537237,143.77175 L242.750197,137.558082 C241.761473,136.988454 241.41854,135.713221 241.988168,134.724497 L263.203166,97.8950775 C265.311207,94.2360611 272.15352,89.2792822 275.812736,91.3871245 L276.872638,91.9976252 C280.541026,94.1108507 279.689475,102.528861 277.576249,106.197249 L256.370822,143.009521 C255.801194,143.998445 254.526161,144.341378 253.537237,143.77175"
							id="Fill-16"
							fill="#F77D48"
						></path>
						<path
							d="M248.022175,144.144311 L247.879619,144.358245 L246.457444,146.491409 L117.766814,339.49934 C116.32171,339.742583 114.842911,339.981639 113.328622,340.212322 C99.6766947,342.288861 87.5326761,341.993181 76.7581967,339.78864 C63.1228179,336.996925 51.6794196,331.149517 42.1446832,323.179313 L90.8778689,279.582548 L174.139034,205.095086 L190.244927,190.688108 L244.551174,142.105654 L246.453456,143.221582 L246.453456,143.223576 L246.516261,143.259265 L248.022175,144.144311 Z"
							id="Fill-18"
							fill="#F8FBFF"
						></path>
						<path
							d="M279.858669,199.583653 C279.858669,199.583653 274.038974,200.422244 268.171429,205.251819 C265.023423,207.843157 260.962257,210.499891 259.953197,209.304012 C259.467308,208.727805 259.132151,204.953547 263.303773,202.181172 C267.305922,199.521447 266.370233,193.904123 270.278872,193.220649 C272.949564,192.753502 272.872603,195.448319 279.253352,193.007512 L279.858669,199.583653 Z"
							id="Fill-20"
							fill="#233862"
						></path>
						<path
							d="M320.93673,183.698514 C319.812029,193.662913 275.915596,201.875961 275.915596,201.875961 C275.915596,201.875961 274.651329,195.976515 278.19311,193.061185 C278.19311,193.061185 288.233872,185.686927 292.88241,182.849754 C298.416992,179.475053 302.461808,178.909214 302.461808,178.909214 C302.392224,178.519227 300.800576,177.939231 298.409615,177.180193 L298.404829,177.180392 C290.993487,174.829307 275.935335,170.772727 274.93405,165.396653 C274.842933,164.907175 274.812428,164.382607 274.83416,163.830724 C275.116083,156.395456 284.921179,144.096918 284.921179,144.096918 L294.480239,140.783427 C294.480239,140.783427 295.897231,151.323835 299.148316,153.868717 C302.399003,156.411407 322.06163,173.734313 320.93673,183.698514"
							id="Fill-22"
							fill="#F99746"
						></path>
						<path
							d="M298.409555,177.180113 L298.404969,177.180312 C290.993626,174.829227 275.935275,170.772647 274.934189,165.396573 C274.843073,164.907096 274.812368,164.382528 274.834101,163.830645 C275.743272,161.743538 277.478872,159.755722 279.708536,159.076037 C284.152111,157.719856 283.495155,167.648567 288.427012,170.644646 C293.358868,173.640326 298.409555,177.180113 298.409555,177.180113"
							id="Fill-24"
							fill="#ED7D2B"
						></path>
						<path
							d="M304.937286,94.0216482 C304.937286,94.0216482 330.671624,86.7602393 334.428536,85.1107709 C338.185248,83.461502 350.367946,67.5312627 350.367946,67.5312627 L353.908332,69.6010235 C353.908332,69.6010235 350.092005,84.4805317 339.082058,92.1480771 C334.783631,95.141564 310.597875,104.630443 310.597875,104.630443 L304.937286,94.0216482 Z"
							id="Fill-26"
							fill="#FFB27D"
						></path>
						<path
							d="M219.208877,221.114528 C219.208877,221.114528 215.431828,225.107306 213.900591,228.88615 C212.369355,232.664993 210.126533,236.965016 208.665279,236.405955 C207.961269,236.136593 205.843458,232.994369 208.155266,228.551192 C210.372966,224.288254 206.838562,219.822747 209.929745,217.335086 C212.041774,215.635574 213.276932,218.03152 217.682825,212.810363 L219.208877,221.114528 Z"
							id="Fill-28"
							fill="#233862"
						></path>
						<path
							d="M295.928793,144.515137 C295.928793,144.515137 268.361159,179.512457 256.28792,190.334987 C249.877065,196.081309 219.464083,221.503219 219.464083,221.503219 C219.464083,221.503219 217.376577,219.438443 216.736369,217.644623 C215.477685,214.117995 216.535594,211.960906 216.535594,211.960906 C216.535594,211.960906 229.507237,186.646063 241.811956,179.360529 C250.338828,174.311837 255.535261,142.852311 273.839914,132.089993 C292.144566,121.327875 295.928793,144.515137 295.928793,144.515137"
							id="Fill-30"
							fill="#F99746"
						></path>
						<path
							d="M310.555946,122.391885 C301.945534,140.025625 299.689154,148.356706 296.651405,150.16807 C293.613456,151.977043 264.336938,147.671837 261.884767,143.259763 C261.236185,142.0906 261.445933,140.764526 262.187226,139.315833 L262.18942,139.31344 C264.245423,135.290357 270.385919,130.326599 273.567023,125.201943 C275.633593,121.874296 277.146885,118.97691 278.25105,116.451566 C283.653443,104.094809 279.255924,100.637366 281.881954,99.0100288 C286.480248,96.1628866 307.930514,89.8971799 314.691679,92.2061963 C318.002579,93.3376761 313.769349,100.158057 314.076194,105.777375 C314.459402,112.832825 313.261329,116.850924 310.555946,122.391885"
							id="Fill-32"
							fill="#739AF0"
						></path>
						<path
							d="M280.595835,99.9139566 C280.595835,99.9139566 261.040474,113.327625 256.770957,120.343 C252.501639,127.358573 246.256868,143.735822 246.256868,143.735822 C246.256868,143.735822 246.48117,144.788347 250.184847,144.164887 C250.184847,144.164887 262.416792,125.796035 264.343797,123.950377 C266.270802,122.104519 283.309991,112.748028 283.309991,112.748028 L280.595835,99.9139566 Z"
							id="Fill-34"
							fill="#FFB27D"
						></path>
						<path
							d="M320.42251,98.6906225 C319.531482,101.2373 317.637973,104.238762 314.13547,107.301433 L304.812275,92.3826473 C304.812275,92.3826473 314.586666,90.7933917 319.999027,89.6788591 C319.999027,89.6788591 322.281526,93.3771533 320.42251,98.6906225"
							id="Fill-36"
							fill="#739AF0"
						></path>
						<path
							d="M295.628746,94.1247475 C295.628746,94.1247475 286.321701,95.4589965 279.222986,99.4872641 C276.279942,101.157268 268.69494,107.813759 268.69494,107.813759 C268.69494,107.813759 270.811954,114.73702 279.516274,116.81994 C288.220394,118.90286 286.363172,102.198629 286.363172,102.198629 L295.628746,94.1247475 Z"
							id="Fill-38"
							fill="#739AF0"
						></path>
						<path
							d="M350.252565,67.687078 C350.252565,67.687078 351.649818,59.3777293 353.416123,59.4808086 C355.182827,59.5836885 356.516478,62.3618454 356.753141,63.9315618 C356.990204,65.5010789 353.792951,69.7566393 353.792951,69.7566393 L350.252565,67.687078 Z"
							id="Fill-40"
							fill="#FFB27D"
						></path>
						<path
							d="M250.622864,144.881956 C250.622864,144.881956 251.29916,148.692302 250.69823,150.228124 C250.097499,151.763946 248.368877,152.977969 247.176985,152.881868 C245.984894,152.785567 248.002616,149.987672 247.234406,148.406591 C246.465997,146.82551 245.112209,143.470148 247.350844,142.666648 C249.58928,141.863148 250.622864,144.881956 250.622864,144.881956"
							id="Fill-42"
							fill="#FFB27D"
						></path>
						<path
							d="M250.187319,143.600124 C250.187319,143.600124 251.062995,146.045516 251.564236,146.877129 C252.065476,147.708742 250.133088,146.471989 249.656172,145.690022 C249.179256,144.908055 250.187319,143.600124 250.187319,143.600124"
							id="Fill-44"
							fill="#FFB27D"
						></path>
						<path
							d="M280.759386,119.71611 C280.567384,123.846859 276.303848,131.815069 271.48165,133.35747 C266.735217,134.875348 262.327331,139.178161 262.18936,139.313341 C264.245363,135.290257 270.386059,130.3265 273.567162,125.202043 C275.633533,121.874395 277.146825,118.97681 278.25099,116.451467 C279.393835,116.324661 280.890977,116.867173 280.759386,119.71611"
							id="Fill-46"
							fill="#6385DB"
						></path>
						<path
							d="M288.656298,109.599743 C286.107229,117.300385 279.054171,120.354883 276.110928,121.318883 C275.509998,121.514276 275.081531,121.623934 274.896905,121.667997 L274.810374,121.688533 L274.029803,118.168086 C274.055922,118.164298 275.019723,117.936606 276.386272,117.356012 L276.388664,117.355614 C279.143693,116.184058 283.54181,113.580359 285.234544,108.463877 C287.573866,101.396464 281.589883,96.4508507 280.825461,95.8572973 C282.282727,95.4503633 283.742386,95.1465086 285.095775,94.9247984 C287.915403,98.043097 290.719081,103.371719 288.656298,109.599743"
							id="Fill-48"
							fill="#D86036"
						></path>
						<path
							d="M302.038883,93.9966261 C302.038883,93.9966261 311.928316,95.2760456 314.13545,107.301433 C314.13545,107.301433 316.314271,106.519665 316.759287,104.677198 C316.759287,104.677198 314.049716,94.0020093 303.659242,92.1132853 C303.057116,92.0038259 300.592982,91.3550443 300.592982,91.3550443 L300.497479,91.9147031 C300.497479,91.9147031 300.545928,93.3396699 300.618502,93.5105383 C300.691077,93.6812074 302.038883,93.9966261 302.038883,93.9966261"
							id="Fill-50"
							fill="#D86036"
						></path>
						<path
							d="M291.064047,94.9445569 C291.859373,92.9086907 292.533276,89.8878888 292.631969,86.0803345 L293.181659,86.205545 L302.424504,88.3209637 C302.424504,88.3209637 302.126431,91.2406801 302.038903,93.9967058 C302.038305,94.0196345 302.037508,94.0425631 302.03671,94.0652924 C301.741828,102.54531 290.403702,96.6344993 291.064047,94.9445569"
							id="Fill-52"
							fill="#FFB27D"
						></path>
						<path
							d="M302.424404,88.3209238 L302.182556,91.2342601 C296.984329,90.9341936 294.230895,88.8694174 293.181759,86.2055051 L302.424404,88.3209238 Z"
							id="Fill-54"
							fill="#E8945B"
						></path>
						<path
							d="M304.629464,88.2536531 C304.629464,88.2536531 292.688813,91.2716637 290.923903,83.8066881 C289.158994,76.3417125 286.429087,71.7037417 293.978201,69.4613181 C301.527315,67.2186952 303.687395,69.7157266 304.882875,72.0640208 C306.077957,74.4125144 307.690142,87.2762938 304.629464,88.2536531"
							id="Fill-56"
							fill="#FFB27D"
						></path>
						<path
							d="M299.39477,67.9938237 C299.39477,67.9938237 298.173569,77.5261675 293.44169,80.006451 C288.709413,82.4867346 286.206998,81.6511342 286.206998,81.6511342 C286.206998,81.6511342 289.348425,77.6729109 288.889852,72.1267656 C288.889852,72.1267656 295.937924,64.4069827 299.39477,67.9938237"
							id="Fill-58"
							fill="#163560"
						></path>
						<path
							d="M298.748739,68.8963957 C298.748739,68.8963957 301.396502,69.2335467 302.815687,70.7729575 C304.203569,72.2782743 305.579089,74.3807333 306.049027,77.2466172 C306.049027,77.2466172 307.120693,70.710751 303.549404,67.9961963 C299.762386,65.1179508 298.748739,68.8963957 298.748739,68.8963957"
							id="Fill-60"
							fill="#163560"
						></path>
						<path
							d="M301.244874,84.3721688 C301.244874,84.3721688 301.659185,85.2031834 303.816074,84.5526074 C303.816074,84.5526074 305.62724,84.0065064 305.343722,83.0526739 C305.343722,83.0526739 303.39977,82.7839101 301.244874,84.3721688"
							id="Fill-62"
							fill="#FFFFFF"
						></path>
						<path
							d="M117.80035,164.780809 L117.40179,166.481717 L115.623323,174.065922 L115.623323,174.068115 L110.392397,196.367738 L100.784089,237.340667 L90.8779087,279.582647 L76.7582366,339.788739 C63.1228578,336.997025 51.6794595,331.149617 42.1445237,323.179413 C23.2321622,307.366808 11.8244528,283.206972 5.70628711,257.985638 L110.455202,160.896493 L110.480323,160.909054 L113.762911,162.645651 L114.165658,162.859586 L117.238299,164.482935 L117.290536,164.51025 L117.80035,164.780809 Z"
							id="Fill-64"
							fill="#F8FBFF"
						></path>
						<path
							d="M160.713124,241.092395 C160.713124,241.092395 153.579118,243.105332 147.04724,250.11333 C143.542942,253.873432 138.908161,257.866808 137.449101,256.539936 C136.746088,255.900525 135.701737,251.239227 140.455348,247.083157 C145.01576,243.095961 142.915095,236.23072 147.686451,234.728593 C150.946309,233.702386 151.296819,237.082669 158.866269,232.974251 L160.713124,241.092395 Z"
							id="Fill-66"
							fill="#233862"
						></path>
						<path
							d="M198.293288,209.412654 C197.880771,220.864825 158.203013,245.523509 158.203013,245.523509 C158.203013,245.523509 153.912561,243.0835 154.053124,233.796992 C154.053124,233.796992 166.952791,215.582658 174.914621,209.354634 C176.186066,208.360926 176.184271,206.435716 172.799003,203.996305 L172.796411,203.996704 C171.821843,203.294089 170.568343,202.548409 168.982875,201.772025 C168.982875,201.772025 153.89362,194.437842 144.625055,188.294754 C142.868121,187.12938 141.320735,186.007071 140.12665,184.986646 C139.694595,184.620385 157.29105,164.327718 157.29105,164.327718 L168.173394,160.555454 C168.173394,160.555454 172.231369,167.409132 175.367811,171.965157 C178.031923,175.835516 198.732322,197.179513 198.293288,209.412654"
							id="Fill-68"
							fill="#2A4E96"
						></path>
						<path
							d="M172.796431,203.996684 C171.821863,203.29407 170.568363,202.548389 168.982895,201.772005 C168.982895,201.772005 153.89344,194.437822 144.625075,188.294734 C145.89014,185.456564 148.19796,181.644823 151.719005,180.723888 C157.495434,179.213985 157.198957,189.412257 160.369293,193.321894 C163.515904,197.201225 172.654473,203.889617 172.796431,203.996684"
							id="Fill-70"
							fill="#233862"
						></path>
						<path
							d="M110.72558,157.715968 L110.440667,160.285374 C110.361314,161.000948 110.710029,161.696385 111.331097,162.06105 L115.283002,164.38163 C116.041642,164.827045 116.940246,164.969801 117.799373,164.781586 L121.560272,163.95735 L110.72558,157.715968 Z"
							id="Fill-72"
							fill="#163560"
						></path>
						<path
							d="M146.601467,170.594462 L134.321072,163.52047 C133.195175,162.871887 132.80459,161.420204 133.453172,160.294307 L157.60603,118.365552 C160.005565,114.199911 167.795529,108.556868 171.961369,110.956402 L173.168015,111.651639 C177.344422,114.057355 176.374838,123.64074 173.968923,127.817346 L149.82743,169.726562 C149.178848,170.852459 147.727164,171.242844 146.601467,170.594462"
							id="Fill-74"
							fill="#D86036"
						></path>
						<polygon
							id="Fill-76"
							fill="#D86036"
							points="146.920195 104.319291 173.933952 105.093482 138.210292 164.596562 122.375157 160.061072"
						></polygon>
						<path
							d="M121.560332,163.957291 L109.279938,156.883299 C108.154041,156.234716 107.763456,154.783033 108.412038,153.657136 L132.564696,111.728381 C134.964431,107.562541 142.754395,101.919696 146.920235,104.319231 L148.126881,105.014269 C152.303088,107.420184 151.333704,117.003569 148.927988,121.180175 L124.786296,163.089391 C124.137913,164.215288 122.68603,164.605673 121.560332,163.957291"
							id="Fill-78"
							fill="#F77D48"
						></path>
						<path
							d="M180.077499,107.31856 C180.077499,107.31856 209.375151,99.0516792 213.652244,97.1739211 C217.929138,95.296163 231.798589,77.1601861 231.798589,77.1601861 L235.829249,79.5164555 C235.829249,79.5164555 231.484366,96.4563536 218.949962,105.185596 C214.056586,108.593593 186.52165,119.396385 186.52165,119.396385 L180.077499,107.31856 Z"
							id="Fill-80"
							fill="#FFB27D"
						></path>
						<path
							d="M83.2026074,251.693015 C83.2026074,251.693015 78.4410213,256.726555 76.5108263,261.490335 C74.580432,266.253914 71.752829,271.675049 69.9107599,270.970241 C69.0233208,270.630299 66.3536265,266.669222 69.2677603,261.068048 C72.0636619,255.693968 67.6079242,250.06488 71.505,246.928638 C74.1675166,244.785904 75.7244728,247.806706 81.2787926,241.224584 L83.2026074,251.693015 Z"
							id="Fill-82"
							fill="#233862"
						></path>
						<path
							d="M169.821506,164.803677 C169.821506,164.803677 138.436947,204.646721 124.69191,216.96759 C117.393416,223.509836 82.7693354,252.451595 82.7693354,252.451595 C82.7693354,252.451595 80.3925299,250.101108 79.6637971,248.058861 C78.2308551,244.043753 79.4353079,241.588392 79.4353079,241.588392 C79.4353079,241.588392 96.6078821,210.640873 108.083181,199.093199 C117.480944,189.636021 123.835175,162.910368 144.674342,150.658086 C165.51331,138.405804 169.821506,164.803677 169.821506,164.803677"
							id="Fill-84"
							fill="#2A4E96"
						></path>
						<path
							d="M185.736932,145.023316 C178.112054,166.022585 175.593689,172.387384 171.610879,172.978345 C140.369874,177.614123 130.961345,171.365961 128.16963,166.342988 C127.431327,165.012129 129.23671,161.483706 131.408155,158.884393 L131.410547,158.881402 C133.751066,154.301451 140.741916,148.650432 144.363649,142.816183 C146.71613,139.027969 148.43897,135.729231 149.696059,132.854176 C155.846524,118.786542 150.8399,114.850188 153.829599,112.997751 C159.064712,109.756236 183.485137,102.623028 191.18239,105.25165 C194.951464,106.539843 190.132257,114.304685 190.48177,120.702182 C190.918013,128.734393 188.540809,137.300942 185.736932,145.023316"
							id="Fill-86"
							fill="#F99746"
						></path>
						<path
							d="M152.365654,114.02679 C152.365654,114.02679 130.102317,129.29788 125.241839,137.284632 C120.381161,145.271385 113.271679,163.916378 113.271679,163.916378 C113.271679,163.916378 113.527085,165.114451 117.743766,164.404858 C117.743766,164.404858 131.669442,143.492718 133.863217,141.391256 C136.056992,139.289794 155.455441,128.637734 155.455441,128.637734 L152.365654,114.02679 Z"
							id="Fill-88"
							fill="#FFB27D"
						></path>
						<path
							d="M197.706732,112.634043 C196.692488,115.533423 194.536595,118.950392 190.5492,122.437144 L179.935022,105.452785 C179.935022,105.452785 191.063002,103.643414 197.224632,102.374561 C197.224632,102.374561 199.823148,106.584863 197.706732,112.634043"
							id="Fill-90"
							fill="#F99746"
						></path>
						<path
							d="M169.479989,107.435955 C169.479989,107.435955 158.884154,108.955029 150.802497,113.540961 C147.45212,115.442246 138.816786,123.02047 138.816786,123.02047 C138.816786,123.02047 141.226887,130.902348 151.136458,133.273571 C161.045829,135.644794 158.931606,116.627758 158.931606,116.627758 L169.479989,107.435955 Z"
							id="Fill-92"
							fill="#F99746"
						></path>
						<path
							d="M231.666998,77.3376141 C231.666998,77.3376141 233.257849,67.8778445 235.268793,67.9950798 C237.279936,68.112315 238.798412,71.2750753 239.067973,73.0619163 C239.337734,74.849156 235.697658,79.6938835 235.697658,79.6938835 L231.666998,77.3376141 Z"
							id="Fill-94"
							fill="#FFB27D"
						></path>
						<path
							d="M118.242435,165.221218 C118.242435,165.221218 119.012439,169.559123 118.328367,171.307683 C117.644296,173.056243 115.676418,174.438343 114.31944,174.328883 C112.962461,174.219225 115.259515,171.033934 114.384836,169.233934 C113.510157,167.433934 111.968952,163.613819 114.517224,162.699265 C117.065895,161.78451 118.242435,165.221218 118.242435,165.221218"
							id="Fill-96"
							fill="#FFB27D"
						></path>
						<path
							d="M117.746697,163.761899 C117.746697,163.761899 118.743595,166.545837 119.314021,167.492692 C119.884845,168.439346 117.68469,167.031327 117.141978,166.141097 C116.599067,165.250866 117.746697,163.761899 117.746697,163.761899"
							id="Fill-98"
							fill="#FFB27D"
						></path>
						<path
							d="M152.551695,136.570913 C152.333175,141.273482 147.479475,150.34506 141.989555,152.100997 C136.585767,153.829021 131.567579,158.727581 131.410667,158.881502 C133.751185,154.301551 140.742036,148.650332 144.363569,142.816283 C146.71605,139.027869 148.43889,135.729132 149.695979,132.854076 C150.997131,132.709725 152.701628,133.327404 152.551695,136.570913"
							id="Fill-100"
							fill="#ED7D2B"
						></path>
						<path
							d="M161.542184,125.053704 C158.640013,133.820629 150.610195,137.298011 147.259818,138.395596 C146.575747,138.617904 146.087864,138.742716 145.877519,138.79296 L145.779025,138.816486 L144.890191,134.808356 C144.920097,134.80397 146.017483,134.544976 147.573044,133.884032 L147.575835,133.883434 C150.712477,132.549783 155.7193,129.585605 157.646504,123.760527 C160.309818,115.714559 153.497014,110.084076 152.626921,109.408378 C154.285959,108.94502 155.947789,108.599296 157.488396,108.34708 C160.698808,111.896837 163.890479,117.963363 161.542184,125.053704"
							id="Fill-102"
							fill="#D86036"
						></path>
						<path
							d="M176.777805,107.290128 C176.777805,107.290128 188.036378,108.746597 190.54916,122.437204 C190.54916,122.437204 193.029643,121.547173 193.536267,119.449699 C193.536267,119.449699 190.451664,107.29611 178.622266,105.145999 C177.936998,105.021387 175.131526,104.282884 175.131526,104.282884 L175.022665,104.919903 C175.022665,104.919903 175.077893,106.542056 175.160835,106.73665 C175.243378,106.931245 176.777805,107.290128 176.777805,107.290128"
							id="Fill-104"
							fill="#D86036"
						></path>
						<path
							d="M164.283276,108.369291 C165.188859,106.051502 165.956072,102.612401 166.068323,98.2776872 L166.694176,98.4202437 L177.216639,100.828551 C177.216639,100.828551 176.877494,104.15261 176.777805,107.290049 C176.777007,107.316367 176.77601,107.342286 176.775213,107.368206 C176.439457,117.02237 163.531615,110.293305 164.283276,108.369291"
							id="Fill-106"
							fill="#FFB27D"
						></path>
						<path
							d="M177.216659,100.828591 L176.941117,104.145272 C171.023327,103.803536 167.88848,101.452849 166.694196,98.4202836 L177.216659,100.828591 Z"
							id="Fill-108"
							fill="#E8945B"
						></path>
						<path
							d="M181.7101,99.9363469 C181.7101,99.9363469 168.92368,105.503228 165.590649,97.6199535 C162.257619,89.7368786 158.362537,85.1461608 166.385377,81.2584559 C174.408217,77.370751 177.276493,79.7124657 179.039608,82.0721245 C180.803121,84.4317833 184.950219,98.2850842 181.7101,99.9363469"
							id="Fill-110"
							fill="#FFB27D"
						></path>
						<path
							d="M156.640833,82.6858152 C156.48432,82.5777514 156.176677,82.5364798 155.546637,82.660494 C155.546637,82.660494 155.964338,82.2238525 156.624683,82.0386287 C156.535361,81.1412206 156.607138,79.494743 158.000403,78.7452747 C158.000403,78.7452747 157.309154,79.3743177 157.437754,80.3197763 C158.71179,78.1130416 162.07712,75.8881635 172.505476,74.1011232 C186.248919,71.7460501 183.997324,81.499506 182.513939,82.9705295 C181.030354,84.4413536 175.92444,82.736056 172.739947,83.8186907 C169.555255,84.9011232 170.128671,89.7542246 169.189792,90.6783496 C168.250913,91.6024745 168.395264,90.3531613 166.732437,90.0572818 C165.06961,89.7614023 165.5549,92.2692003 166.393691,93.76714 C167.232282,95.2650798 165.720385,97.9050665 165.720385,97.9050665 L163.08319,96.1997718 C160.445995,94.4946766 156.096526,85.5415308 156.527187,83.2885401 C156.565268,83.0895591 156.601755,82.8885844 156.640833,82.6858152"
							id="Fill-112"
							fill="#2C3A64"
						></path>
						<g id="Group-116" transform="translate(0.000000, 215.210456)">
							<mask id="mask-4" fill="white">
								<use xlink-href="#path-3"></use>
							</mask>
							<g id="Clip-115"></g>
							<path
								d="M372.595933,58.9376983 C348.085388,103.639825 336.168064,143.515767 221.926283,111.956152 C176.525729,99.4157665 166.976238,115.928593 117.766735,124.288784 C116.32163,124.532027 114.843031,124.771083 113.328742,125.001965 C99.676615,127.078505 87.5325964,126.782625 76.758117,124.578283 C63.1229375,121.786568 51.6795392,115.93916 42.1446035,107.968957 C23.2322419,92.1563514 11.8245326,67.9965153 5.70636686,42.7751817 C3.45995569,33.5211719 1.92672574,24.1250044 0.997616305,14.9465596 C0.4690607,9.73238148 0.160819672,4.81826983 0.0495657953,0.176510855 C0.0495657953,0.176510855 22.871564,4.85375942 26.4328844,23.5649468 C26.4328844,23.5649468 31.6701905,21.4967811 36.70393,26.0145259 C41.7376695,30.5302769 37.5429198,39.5597851 30.4117058,40.9189566 C30.4117058,40.9189566 48.8688835,43.1149247 46.3521134,63.2498826 C46.3521134,63.2498826 57.0193265,59.2648804 61.7533983,69.7518542 C61.7533983,69.7518542 77.3937395,54.6506358 98.7873815,64.2988192 C120.181023,73.9466039 119.132087,91.3552437 119.132087,91.3552437 C119.132087,91.3552437 129.224887,83.3850399 140.118994,90.096759 C151.012902,96.8084781 151.22245,103.520197 151.22245,103.520197 C151.22245,103.520197 156.466136,97.6474679 163.807098,101.003228 C163.807098,101.003228 175.552556,79.6097851 204.286961,88.6287262 C204.286961,88.6287262 211.25249,83.083179 219.208139,88.1169185 C227.165583,93.150658 227.165583,99.128261 227.165583,99.128261 C227.165583,99.128261 245.413013,88.1169185 261.770722,104.791243 C261.770722,104.791243 272.467643,99.7575033 281.276637,104.791243 C281.276637,104.791243 281.501139,88.1169185 297.904905,83.3978002 C297.904905,83.3978002 296.06144,66.7254696 316.82564,64.5231214 C337.588046,62.3209725 344.509513,74.9032277 344.509513,74.9032277 C344.509513,74.9032277 353.929008,58.9965153 372.595933,58.9376983"
								id="Fill-114"
								fill="#F8FBFF"
								mask="url(#mask-4)"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
