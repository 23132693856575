import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector } from 'react-redux';
import {
	selectOrganizations,
	selectedOrganization,
} from 'app/containers/OrganizationsContainer/selectors';
import OrgSelector from '../orgSelector';
import { removeSessionItem } from '../utility';
import styles from './styles.module.scss';

interface Props {
	handleLogout: () => void;
	userData: any;
	baseUrl: string;
}

const AccountMenu = (props: Props) => {
	const { userData, baseUrl } = props;
	const { email } = userData;
	const [t, i18n] = useTranslation();
	const orgs = useSelector(selectOrganizations);
	const selectedOrg = useSelector(selectedOrganization);
	useEffect(() => {
		if (orgs?.length === 1) {
			removeSessionItem('selectedOrg');
		}
	}, [orgs]);
	return (
		<>
			<div className={styles.accountMenu} style={{ position: 'relative' }}>
				{orgs.length > 1 ? (
					<OrgSelector />
				) : (
					<span className={styles.email}>{selectedOrg?.group || email}</span>
				)}

				<span className={styles.btn}>
					<MoreVertIcon style={{ fontSize: '16px' }} />
					<div className={styles.dropListWrpr}>
						<ul className={styles.dropList}>
							<li>
								<Link
									to={`${baseUrl}user`}
									data-translation-key="header_profile"
								>
									{t('header_profile')}
								</Link>
							</li>
							<li>
								<Link
									to={`${baseUrl}orgSettings`}
									data-translation-key="header_organization"
								>
									{t('header_organization')}
								</Link>
							</li>
							<li>
								<Link
									to={`${baseUrl}members`}
									data-translation-key="header_members"
								>
									{t('header_members')}
								</Link>
							</li>
							<li>
								<span
									onClick={() => {
										removeSessionItem('selectedOrg');
										props.handleLogout();
									}}
									data-translation-key="header_logout"
								>
									{t('header_logout')}
								</span>
							</li>
						</ul>
					</div>
				</span>
			</div>
		</>
	);
};

export default AccountMenu;
