import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './style.module.scss';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

interface Prop {
	id: Boolean;
	onEdit: (id) => void;
}

export const CustomMenu = (props: Prop) => {
	const { id, onEdit } = props;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<>
			<span className={styles.option} onClick={handleClick}>
				<MoreHorizIcon />
			</span>
			<Menu
				id={`menu-${id}`}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem onClick={() => onEdit(id)}>Edit Action</MenuItem>
				<MenuItem onClick={handleClose}>Remove Action</MenuItem>
			</Menu>
		</>
	);
};
