/* --- STATE --- */
import { CognitoUser } from '@aws-amplify/auth';

export interface FeatureFlags {
	actions: boolean;
	feApps: boolean;
	feAppsS3Uploader: boolean;
	feAppsHpeS3Uploader: boolean;
	triggers: boolean;
	marketoMilestone: boolean;
	marketoAdvancedSettings: boolean;
	testOrg: boolean;
	goalsUi: boolean;
	universalAnalyticsSourceIntegration: boolean;
	customIntegration: boolean;
	salesforceIntegration: boolean;
	demandbaseIntegration: boolean;
	clearbitIntegration: boolean;
	hubspotIntegration: boolean;
	sixSenseIntegration: boolean;
	accountMatchIntegration: boolean;
	googleOptimizeIntegration: boolean;
	audienceCondition: boolean;
	websiteAttribution: boolean;
}

export interface User {
	['custom:organization']: string;
	name: string;
	family_name: string;
	email: string;
	email_verified: boolean;
	zoneinfo: string;
	phone_number: number;
	phone_number_verified: boolean;
	sub: string;
	website: string;
}

export interface AuthContainerState {
	readonly error?: string;
	readonly userError?: string;
	readonly cognitoUser?: User;
	readonly userPoolId?: string;
	readonly loggedIn: boolean;
	readonly tempUser: {
		username: string;
		password: string;
	};
	readonly checkingAuth: boolean;
	readonly fetchingData: boolean;
	readonly featureFlags: FeatureFlags;
}

export type ContainerState = AuthContainerState;

export enum AuthAction {
	LOGIN = 'AUTH/LOGIN',
	LOGOUT = 'AUTH/LOGOUT',
	LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS',
	FETCH_CURRENT_USER = 'AUTH/FETCH_CURRENT_USER',
	SET_NEW_PASSWORD = 'AUTH/SET_NEW_PASSWORD',
	FORGOT_PASSWORD = 'AUTH/FORGOT_PASSWORD',
	RESET_PASSWORD = 'AUTH/RESET_PASSWORD',
	SET_AUTH_ERROR = 'AUTH/SET_AUTH_ERROR',
	SET_CURRENT_USER = 'AUTH/SET_CURRENT_USER',
	SET_COGNITO_USER = 'AUTH/SET_COGNITO_USER',
	SET_AUTH_STATUS = 'AUTH/SET_AUTH_STATUS',
	CLEAN_AUTH_STATE = 'AUTH/CLEAN_AUTH_STATE',
	SET_USER_ERROR = 'AUTH/SET_USER_ERROR',
}

export interface UserData {
	username: string;
	attributes: Map<string, string>;
}

export interface AuthState {
	readonly error?: string;
	readonly userError?: string;
	readonly currentUser?: UserData;
	readonly cognitoUser?: CognitoUser;
	readonly loggedIn: boolean;
	readonly checkingAuth: boolean;
	readonly authStatus: AmplifyAuthStatus;
}

export enum AmplifyAuthStatus {
	login = '/login',
	signUp = '/sign-up',
	checkout = '/checkout',
	completeSignUp = '/complete-sign-up',
	confirmSignIn = '/confirm-sign-in',
	confirmSignUp = '/confirm-sign-up',
	forgotPassword = '/forgot-password',
	verifyContact = '/verify-contact',
	signedIn = '/signed-in',
	changePassword = '/change-password',
	forgotPasswordSubmit = '/forgot-password-submit',
	mfaRequired = '/mfa-new-password',
	dashboard = '/dashboard',
	newPasswordRequired = '/new-password-required',
}

export interface State {
	authState: AuthState;
}
