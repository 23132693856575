import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { actions } from './slice';
import { selectEventsList } from './selectors';
import * as APIt from 'API';
import { toast } from 'react-toastify';
import { IEvent } from './types';
import { errorMessageHandler } from 'utils/errorMessage';
import { listEvents } from 'graphql/queries';
import { createEvent, updateEvent } from 'graphql/mutations';

const errorMessage = (err: Error) =>
	errorMessageHandler(err, 'Error occurred fetching goals.');

export function* handleError(err: Error) {
	const errMessage = errorMessage(err);
	yield call(toast.error, errMessage);
}

export function* handleFetchEvents(
	action: PayloadAction<APIt.ListEventsQueryVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.ListEventsQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(listEvents, action.payload)
		);
		yield put(actions.fetchEventsSuccess(data?.listEvents?.items as IEvent[]));
		return data?.listEvents?.items;
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleModifyEvent(
	action: PayloadAction<APIt.UpdateEventInput>
): SagaIterator {
	try {
		const {
			group,
			organizationId,
			name,
			source,
			id,
			attributes,
		} = action.payload;
		let eventsList = yield select(selectEventsList);
		if (eventsList?.length === 0) {
			const { data } = yield call(handleFetchEvents, {
				payload: {
					filter: {
						organizationId: {
							eq: organizationId,
						},
					},
				},
				type: '',
			});
			eventsList = data?.listEvents?.items;
		}
		//Do not create any event with same name
		const noDuplicateName = eventsList?.filter(item => item?.name === name);

		if (noDuplicateName?.length > 0) {
			throw new Error('Event name is already taken');
		}

		const data = yield call(
			id ? handleUpdateEvent : handleCreateEvent,
			action.payload
		);
		yield put(actions.modifyEventSuccess(data));
		return data;
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleUpdateEvent(event: APIt.UpdateEventInput): SagaIterator {
	const { data }: GraphQLResult<any> = yield call(
		[API, 'graphql'],
		graphqlOperation(updateEvent, { input: event })
	);
	toast.success(`Updated Event ${event.name}`);
	return data.updateEvent;
}

export function* handleCreateEvent(event: APIt.CreateEventInput): SagaIterator {
	try {
		const { data }: GraphQLResult<any> = yield call(
			[API, 'graphql'],
			graphqlOperation(createEvent, { input: event })
		);
		toast.success(`Created Event ${event.name}`);
		return data.createEvent;
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* eventContainerSaga() {
	yield takeLatest(actions.modifyEvent.type, handleModifyEvent);
	yield takeLatest(actions.fetchEvents.type, handleFetchEvents);
}
