/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './reducers';

export function configureAppStore() {
	const reduxSagaMonitorOptions = {};
	const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
	const { run: runSaga } = sagaMiddleware;

	// Create the store with saga middleware
	const middlewares = [sagaMiddleware];

	const enhancers = [
		createInjectorsEnhancer({
			createReducer,
			runSaga,
		}),
	];

	const store = configureStore({
		reducer: createReducer(),
		middleware: [
			...getDefaultMiddleware({
				serializableCheck: false,
			}),
			...middlewares,
		],
		devTools: process.env.NODE_ENV !== 'production',
		enhancers,
	});

	// Make reducers hot reloadable, see http://mxs.is/googmo
	/* istanbul ignore next */
	if (module.hot) {
		module.hot.accept('./reducers', () => {
			forceReducerReload(store);
		});
	}

	return store;
}
