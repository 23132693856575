import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Integration } from '../Integration';
import { GoogleTagManager } from './GoogleTagManager';
import { GoogleTagManagerIcon } from './GoogleTagManager/GoogleTagManagerIcon';
import { UniversalAnalytics } from './UniversalAnalytics';
import { UniversalAnalyticsIcon } from './UniversalAnalytics/UniversalAnalyticsIcon';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import integrationsList from '../integrationsList.json';
import styles from './style.module.scss';
import { convertOrganizationToGroup } from 'utils/groupNameConvert';
import _ from 'lodash';

export const Destinations = () => {
	const history = useHistory();
	const match = useRouteMatch();
	const org = useSelector(selectedOrganization);

	//Return provider Component
	const retComp = (val, data) => {
		let comp = {
			googleTagManager: <GoogleTagManager {...data} />,
			universalAnalytics: <UniversalAnalytics {...data} />,
		};
		return comp[val] || <GoogleTagManager {...data} />;
	};

	//Return Icon Component
	const retIcon = val => {
		let iconComp = {
			googleTagManager: <GoogleTagManagerIcon />,
			universalAnalytics: <UniversalAnalyticsIcon />,
		};
		return iconComp[val] || <GoogleTagManagerIcon />;
	};
	//filter destination integrations
	const addList = _.filter(integrationsList, item => !item.source);

	return (
		<>
			<div className={styles.optionsWrapper}>
				{addList.map(integration => {
					const { name, provider } = integration;
					let baseUrl;
					if (match.url.includes(provider)) {
						baseUrl = match.url.split(`/${provider}`)?.[0]; // integrations
					} else if (match.url.includes('integrations/')) {
						baseUrl = match.url.split('integrations/')[0] + 'integrations'; //integrations
					} else {
						baseUrl = match.url.split('integrations')[0] + 'integrations'; //integrations
					}
					return (
						<Integration
							key={provider}
							provider={provider}
							name={name}
							icon={retIcon(provider)}
							compo={retComp(provider, {
								...integration,
								group: convertOrganizationToGroup(org?.group),
								organizationId: org?.id,
								baseUrl: baseUrl,
							})}
							onClickHandler={() => history.push(`${baseUrl}/${provider}`)}
							baseUrl={baseUrl}
						/>
					);
				})}
			</div>
		</>
	);
};
