import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.eventContainer || initialState;

export const selectLoading = createSelector(
	[selectDomain],
	eventContainerState => eventContainerState.loading
);

export const selectEventsList = createSelector(
	[selectDomain],
	eventContainerState => eventContainerState.events
);
