/**
 *
 * Header
 *
 */
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styles from './style.module.scss';
import AccountMenu from './AccountMenu';
import LogoPNG from './logo_white.png';
import {
	selectCognitoUser,
	selectFeatureFlags,
} from 'app/containers/AuthContainer/selectors';

interface Props {
	handleLogout?: any;
	history?: any;
	baseUrl: string;
}

export const Header = memo((props: Props) => {
	const { handleLogout, baseUrl } = props;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [t, i18n] = useTranslation();
	const userData = useSelector(selectCognitoUser);
	const { actions, feApps, triggers, goalsUi } = useSelector(
		selectFeatureFlags
	);
	return (
		<div className={styles.header}>
			<div className={styles.brandLogo}>
				<NavLink to={`${baseUrl}`}>
					<img src={LogoPNG} alt="Nav Brand" />
				</NavLink>
			</div>
			<div className={styles.navBar}>
				<NavLink
					activeClassName={styles.active}
					to={`${baseUrl}dashboard`}
					data-translation-key="header_dashboard"
				>
					{t('header_dashboard')}
				</NavLink>
				<NavLink
					activeClassName={styles.active}
					to={`${baseUrl}audiences`}
					data-translation-key="header_audiences"
				>
					{t('header_audiences')}
				</NavLink>
				{actions && (
					<NavLink
						activeClassName={styles.active}
						to={`${baseUrl}actions`}
						data-translation-key="header_actions"
					>
						Actions
					</NavLink>
				)}
				{triggers && (
					<NavLink
						activeClassName={styles.active}
						to={`${baseUrl}triggers`}
						data-translation-key="header_triggers"
					>
						Triggers
					</NavLink>
				)}
				<NavLink
					activeClassName={styles.active}
					to={`${baseUrl}integrations`}
					data-translation-key="header_integrations"
				>
					{t('header_integrations')}
				</NavLink>
				{goalsUi && (
					<NavLink
						activeClassName={styles.active}
						to={`${baseUrl}goals`}
						data-translation-key="header_goals"
					>
						{t('header_goals')}
					</NavLink>
				)}
				{feApps && (
					<NavLink
						activeClassName={styles.active}
						to={`${baseUrl}apps`}
						data-translation-key="header_apps"
					>
						{t('header_apps')}
					</NavLink>
				)}
			</div>
			{userData && (
				<AccountMenu
					handleLogout={handleLogout}
					userData={userData}
					baseUrl={baseUrl}
				/>
			)}
		</div>
	);
});
