import { v4 as uuidv4 } from 'uuid';

import { ConditionType } from 'API';
import { Condition } from './types';

export const getConditionsFromString = (conditions: string | null) => {
	try {
		if (conditions === null) return null;
		return addIdsToConditions(JSON.parse(conditions ?? ''));
	} catch (err) {
		return null;
	}
};

export const addIdsToConditions = (conditions: Condition) => {
	const conditionWithId = { ...conditions, id: uuidv4() };
	if (conditionWithId?.operands?.length > 0) {
		return {
			...conditionWithId,
			operands: conditions.operands.map(c => addIdsToConditions(c)),
		};
	}
	return conditionWithId;
};

export const findNodeAndRemove = (conditions: Condition, nodeId: string) => {
	if (conditions.id === nodeId) {
		return null;
	}
	return conditions.operands
		? {
				...conditions,
				operands: (conditions.operands || [])
					.map(o => findNodeAndRemove(o, nodeId))
					.filter(n => n !== null),
		  }
		: conditions;
};

export const findTargetAndAddNode = (
	conditions: Condition,
	target: Condition,
	node: Condition
) => {
	const { id: targetId } = target;
	if (conditions.id === targetId) {
		// exit condition
		return {
			...conditions,
			// @ts-ignore
			operands: [].concat(conditions?.operands || [], node),
		};
	}
	//add conditional wrapper on leaf nodes
	if (conditions.id === node.id) {
		return {
			...target,
			operands:
				target.operands?.length > 0
					? target.operands.map(o => findTargetAndAddNode(o, target, node))
					: [{ ...node }],
		};
	}
	return conditions.operands
		? {
				...conditions,
				operands: (conditions.operands || []).map(o =>
					findTargetAndAddNode(o, target, node)
				),
		  }
		: conditions;
};

export const findNodeAndUpdate = (
	id: string,
	node: Condition,
	conditions: Condition
) => {
	if (conditions.id === id) {
		return { ...conditions, ...node };
	}
	return conditions.operands
		? {
				...conditions,
				operands: (conditions.operands || []).map(c =>
					findNodeAndUpdate(id, node, c)
				),
		  }
		: conditions;
};

type ExpandedConditions = Condition & {
	editable: boolean;
	parentObject: string;
	__typename: string;
};

export const formatConditionsForSave = (conditions: ExpandedConditions) => {
	// strip __typename and id from conditions before save
	if (
		conditions.typeOf === ConditionType.AND ||
		conditions.typeOf === ConditionType.OR ||
		conditions.typeOf === ConditionType.NOT
	) {
		const { id, parentObject, editable, __typename, ...rest } = conditions;
		return {
			...rest,
			operands: (conditions.operands || []).map(c =>
				formatConditionsForSave(c as ExpandedConditions)
			),
		};
	}
	const {
		id,
		parentObject,
		editable,
		__typename,
		name,
		typeOf,
		...node
	} = conditions;
	return {
		name,
		typeOf,
		node,
	};
};
