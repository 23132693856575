import React, { memo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { isEmpty } from 'lodash';
import { ModalContainer } from 'app/containers/ModalContainer';
import { Loader } from 'app/components/Loader';
import TriggerTable from './Table';
import { AssignActions } from './AssignActions';
import { CreateTrigger } from './CreateTrigger';
import { ViewTrigger } from './ViewTrigger';
import { EditTrigger } from './EditTrigger';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import SearchBar from 'app/components/SearchBar';
import {
	selectTriggers,
	selectLoading,
	selectPage,
	selectNextToken,
	selectIndexRange,
} from './selectors';
import { actions } from './slice';

export const TriggersContainer = memo(() => {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const history = useHistory();
	const match = useRouteMatch();
	const triggersLength = useSelector(selectTriggers).length;
	const triggers = useSelector(selectPage);
	const nextToken = useSelector(selectNextToken);
	const indexRange = useSelector(selectIndexRange);
	const loading = useSelector(selectLoading);
	const organization = useSelector(selectedOrganization);
	const [search, setSearch] = useState('');

	const buildFilters = useCallback(() => {
		const filters: any = {
			isArchived: {
				ne: true,
			},
		};
		if (search) {
			filters.or = [
				{ name: { contains: search } },
				{ description: { contains: search } },
			];
		}
		return filters;
	}, [search]);

	useEffect(() => {
		const filter = buildFilters();
		dispatch(
			actions.fetchTriggers({
				organizationId: organization.id,
				filter: isEmpty(filter) ? null : filter,
			})
		);
	}, [buildFilters, dispatch, organization.id]);

	const handleOpenCreate = () => history.push(`${match.url}/create`);
	const handleModalClose = () => history.push(`${match.url}`);
	const handleNext = () => dispatch(actions.next({}));
	const handlePrevious = () => dispatch(actions.previous({}));

	return (
		<>
			<SearchBar
				title={t('triggers_title')}
				description={t('triggers_description')}
				search={search}
				handleSearch={setSearch}
				handleCreate={handleOpenCreate}
				createButtonText={t('add_trigger')}
			/>
			{loading ? (
				<Loader style={{ minHeight: '400px' }} />
			) : (
				<TriggerTable
					triggers={triggers}
					hasNext={!!nextToken || triggersLength - 1 > indexRange[1]}
					hasPrevious={indexRange[0] !== 0}
					handleNext={handleNext}
					handlePrevious={handlePrevious}
				/>
			)}
			<Switch>
				<Route
					path={`${match.url}/create`}
					render={() => (
						<ModalContainer
							onCloseModel={handleModalClose}
							modalInnerWrapStyle={{
								maxWidth: '512px',
							}}
							closeBtnStyle={{ top: '16px', right: '20px' }}
						>
							<CreateTrigger />
						</ModalContainer>
					)}
				/>
				<Route
					path={`${match.url}/edit/:id`}
					render={() => (
						<ModalContainer onCloseModel={handleModalClose}>
							<EditTrigger />
						</ModalContainer>
					)}
				/>
				<Route
					path={`${match.url}/assign/:id`}
					render={() => (
						<Dialog open maxWidth="lg" onClose={handleModalClose}>
							<AssignActions cancel={handleModalClose} />
						</Dialog>
					)}
				/>
				<Route
					path={`${match.url}/:id`}
					render={() => {
						return (
							<ModalContainer
								onCloseModel={handleModalClose}
								modalInnerWrapStyle={{
									maxWidth: '730px',
								}}
							>
								<ViewTrigger />
							</ModalContainer>
						);
					}}
				/>
			</Switch>
		</>
	);
});
