import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography as MuiTypography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import AreaChart from 'app/components/AreaChart';
import { layout } from 'app/components/AreaChart/fakeData';
import { getAscendingFormattedChartData } from 'utils/chartData';
import styles from './style.module.scss';

interface Props {
	title: string;
	count?: string | Number;
	scale?: string | Number;
	period?: string;
	buttonText?: string;
	buttonLink?: string;
	noGraphMsg: string;
	showButton: boolean;
	chartData?: any[];
	configureChartXY: { x: string; y: string };
	dateRange: [string, string];
	boxTitleStyles?: React.CSSProperties;
	chartColor?: string;
}
export const BoxWrapper = ({
	title,
	count = 0,
	scale = 0,
	period = 'over last 7 days',
	buttonText,
	buttonLink,
	showButton,
	chartData,
	noGraphMsg,
	configureChartXY = { x: 'date', y: 'visitors' },
	dateRange,
	chartColor = '#FF9A44',
}: Props) => {
	const history = useHistory();

	const handleClick = () => {
		if (buttonLink) {
			history.push(buttonLink);
		}
	};
	return (
		<Box className={styles.boxWrap} mr={1.6}>
			<div
				className={styles.boxTitleWrapper}
				style={{ borderLeft: `2px solid ${chartColor}` }}
			>
				<div>
					<MuiTypography variant="body2">{title}</MuiTypography>
					<span className={styles.count}>{count}</span>
					<span className={styles.scale}>
						{Number(scale) >= 0 ? (
							`+${scale}`
						) : (
							<span className={styles.neg}>{`-${scale}`}</span>
						)}{' '}
						<span className={styles.period}>{period}</span>
					</span>
				</div>
			</div>
			<Divider />
			{!!chartData?.length ? (
				<div className={styles.boxGraphWrapper}>
					<AreaChart
						data={getAscendingFormattedChartData(
							chartData,
							7,
							configureChartXY.x,
							configureChartXY.y,
							chartColor
						)}
						layout={layout(dateRange)}
						style={{ height: '300px' }}
					/>
				</div>
			) : (
				<div className={styles.noGraph}>
					<MuiTypography variant="body2">{noGraphMsg}</MuiTypography>
					{showButton && (
						<Button variant="contained" onClick={handleClick}>
							{buttonText}
						</Button>
					)}
				</div>
			)}
		</Box>
	);
};
