import _ from 'lodash';

export const numberWithCommas = x => {
	return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export const difference = (numb1, num2) => {
	const n = Number(numb1) - Number(num2);
	return numberWithCommas(n);
};

export const GFG_sortFunction = (a, b) => {
	var dateA = new Date(a.convertedDate).getTime();
	var dateB = new Date(b.convertedDate).getTime();
	return dateA < dateB ? 1 : -1;
};

export const getAscendingFormattedChartData = (
	data,
	lastUpto,
	x,
	y,
	color = '#FF9A44',
	template = '<b>%{text}</b>' + '<br><b>Y</b>: %{y}<br>' + '<extra></extra>'
) => {
	//change date to plotly accepted date format
	const convertedInput = _.map(data, item => {
		return {
			...item,
			convertedDate: new Date(item.date),
		};
	});
	// ascend the data with date
	const re = convertedInput.slice().sort(GFG_sortFunction);
	//get last 7 days data if it has much previous data too
	const lastDaysData = re.slice(0, lastUpto);
	//return chart data
	return [
		{
			x: lastDaysData.map(item => item[x]),
			y: lastDaysData.map(item => item[y]),
			type: 'scatter',
			fill: 'tonexty',
			text: lastDaysData.map(item => item[x]),
			marker: { color },
			line: { shape: 'spline', smoothing: 1.3 },
			hovertemplate: template,
		},
	];
};

export const layout = {
	xaxis: {
		tickformat: '%d %b',
		hovermode: 'x undefined',
	},
	hovermode: 'closest',
	hoverlabel: { bgcolor: '#FFF' },
	showlegend: false,
	legend: {
		x: 1,
		y: 1,
		orientation: 'h',
	},
	margin: {
		l: 30,
		r: 10,
		t: 40,
		b: 30,
		pad: 5,
	},
};
