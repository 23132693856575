import React from 'react';

import GoogleTagManager from './GoogleTagManager';
import UniversalAnalytics from './UniversalAnalytics';
import { IntegrationDestination } from 'API';

interface Props {
	step: any;
	stepCount: any;
	next: any;
	destinationProvider: IntegrationDestination;
	config: any;
}

const Configuration = (props: Props) => {
	const { next, destinationProvider, config } = props;
	switch (destinationProvider) {
		case IntegrationDestination.googleTagManager:
			return <GoogleTagManager next={next} config={config ?? {}} />;
		case IntegrationDestination.universalAnalytics:
			return <UniversalAnalytics next={next} config={config} />;
		default:
			return null;
	}
};

export default Configuration;
