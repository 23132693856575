export type AudienceStats = {
	audience_id: string;
	visitors: number;
	conversions: number;
	individuals: number;
	accounts: number;
	actions: number;
};

export const fetchAudienceStats = async (
	organizationId: string,
	startTime: string,
	endTime: string
) => {
	try {
		const params = new URLSearchParams();
		params.append('organization_id', organizationId);
		params.append('start_time', startTime);
		params.append('end_time', endTime);
		const data = await fetch(
			`${
				process.env.REACT_APP_REPORT_ENDPOINT
			}/v2_audiences/stats-by-audience?${params.toString()}`,
			{
				cache: 'no-cache',
			}
		);
		const json = await data.json();
		return json;
	} catch (err) {
		console.error(err);
		return { rows: [] };
	}
};
