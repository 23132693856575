import React from 'react';
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import styles from '../style.module.scss';
import audienceImg from 'app/containers/AudiencesContainer/firstAudience.png';

interface Props {
	onAddClick?: any;
}

export const NoSelectedList = (props: Props) => {
	const { onAddClick } = props;

	return (
		<div className={styles.noDataImported}>
			<span className={styles.noImgWrapper}>
				<img src={audienceImg} alt="audience img" />
			</span>
			<Typography variant="h5">No lists have been added yet</Typography>
			<Typography variant="body2">Please add some lists to import.</Typography>
			{onAddClick && (
				<span className={styles.addIcon} onClick={() => onAddClick()}>
					<AddIcon />
				</span>
			)}
		</div>
	);
};
