import React from 'react';
import LogoDark from './assets/Images/black-logo.png';
import LogoLight from './assets/Images/logo_white.png';

interface Props {
	variant: 'light' | 'dark';
}

const Logo = ({ variant }: Props) => {
	if (variant === 'light') {
		return <img src={LogoLight} alt="funnelenvy logo" width="100%" />;
	} else {
		return <img src={LogoDark} alt="funnelenvy logo" width="100%" />;
	}
};

export default Logo;
