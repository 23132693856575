export const fetchAudienceReport = async (
	organizationId: string,
	audienceId: string,
	startDate: string,
	endDate: string
) => {
	try {
		const params = new URLSearchParams();
		params.append('organization_id', organizationId);
		params.append('audience_id', audienceId);
		params.append('start_time', startDate);
		params.append('end_time', endDate);
		const data = await fetch(
			`${
				process.env.REACT_APP_REPORT_ENDPOINT
			}/v2_audiences/audience-stats-by-date?${params.toString()}`,
			{
				cache: 'no-cache',
			}
		);
		const json = await data.json();
		return json;
	} catch (err) {
		return { rows: [] };
	}
};

export const fetchAudienceReportStats = async (
	organizationId: string,
	audienceId: string
) => {
	try {
		const params = new URLSearchParams();
		params.append('organization_id', organizationId);
		params.append('audience_id', audienceId);
		const data = await fetch(
			`${
				process.env.REACT_APP_REPORT_ENDPOINT
			}/v2_audiences/audience-stats?${params.toString()}`,
			{
				cache: 'no-cache',
			}
		);
		const json = await data.json();
		return json;
	} catch (err) {
		return { rows: [] };
	}
};
