import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';

interface Props {
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
	placeholder?: string;
}

export const ValueInput = ({
	disabled,
	value,
	setValue,
	placeholder = '',
}: Props) => {
	const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
		setValue(event.target.value as string);
	};

	return (
		<TextField
			style={{ marginBottom: '0px' }}
			disabled={disabled}
			variant="outlined"
			value={value}
			onChange={handleChange}
			placeholder={placeholder}
		/>
	);
};
