import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { ConditionType, Operators, Providers } from 'API';
import { integrationsContainerSaga } from '../../saga';
import { reducer, sliceKey, actions } from '../../slice';
import { AuthorizationForm } from './AuthorizationForm';
import { DataImportForm } from './DataImportForm';
import { AdvancedSettings } from './AdvancedSettings';
import styles from './style.module.scss';
import commonIntStyles from 'app/containers/IntegrationsContainer/style.module.scss';
import { selectFeatureFlags } from 'app/containers/AuthContainer/selectors';
import {
	selectIntegrationByProvider,
	selectListOpen,
	selectFetchingData,
	selectMarketoListsToBe,
	selectAudienceToBeLists,
	selectMarketoLeads,
	getCheckedMarketoCreds,
	selectConfigurations,
	selectAllDeselected,
} from 'app/containers/IntegrationsContainer/selectors';
import { actions as audienceActions } from 'app/containers/AudiencesContainer/slice';
import { Loader } from 'app/components/Loader';
import { Header } from '../../components/Header';
import { Divider, AppBar, Tabs, Tab } from '@material-ui/core';
import { MarketoIcon } from './MarketoIcon';
import { NotActivated } from '../../components/NotActivated';
import { ButtonSection } from '../../components/ButtonSection';
import { AddList } from './DataImportForm/addList';
import { IntegrationProps as Props } from '../../types';

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
	theme?: 'transparent';
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`marketo-tabpanel-${index}`}
			aria-labelledby={`marketo-tab-${index}`}
			{...other}
			className={styles.tabPanel}
		>
			{value === index && <>{children}</>}
		</div>
	);
};

function a11yProps(index: any, translationKey: string) {
	return {
		id: `marketo-tab-${index}`,
		'aria-controls': `marketo-tabpanel-${index}`,
		'data-translation-key': translationKey,
	};
}
const advanced = 'advanced';
const routesToTabValues = {
	authorization: 0,
	import: 1,
};

const tabValuesToRoutes = {
	0: 'authorization',
	1: 'import',
};

export function Marketo({
	group,
	provider,
	organizationId,
	baseUrl,
	goToIntegration,
}: Props) {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: integrationsContainerSaga });
	const history = useHistory();
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const { params } = match;
	const { tab } = params as any;
	const [t, i18n] = useTranslation();
	const leads = useSelector(selectMarketoLeads);
	const { marketoAdvancedSettings } = useSelector(selectFeatureFlags);
	const isAllDeselected = useSelector(selectAllDeselected);
	const loading = useSelector(selectFetchingData);
	const marketo = useSelector(selectIntegrationByProvider(Providers.marketo));
	const currentSelectedList = useSelector(selectMarketoListsToBe);
	const audienceToBeCreated = useSelector(selectAudienceToBeLists);
	const savedCreds = useSelector(getCheckedMarketoCreds);
	const config = useSelector(selectConfigurations);
	const openListPage = useSelector(selectListOpen);
	//set tab values
	const [value, setValue] = React.useState<number | false>(0);

	//fetch Marketo Data
	useEffect(() => {
		marketo?.id && dispatch(actions.fetchIntegration({ id: marketo?.id }));
	}, [dispatch, marketo]);

	//default tabs
	const tabs = {
		authorization: {
			title: 'integrations_marketo_tab_authorization_label',
			content: <AuthorizationForm credentials={marketo?.credentials} />,
		},
		import: {
			title: 'integrations_marketo_tab_data_import_label',
			content: (
				<DataImportForm
					credentials={{ ...marketo?.credentials, ...savedCreds }}
					selectedList={currentSelectedList}
					leads={leads}
					lists={marketo?.lists?.items}
				/>
			),
		},
	};
	//create Audiences from marketo selected list
	const createAudiences = list => {
		dispatch(
			audienceActions.modifyAudiences(
				list.map(item => {
					const { name, audienceType, externalId } = item;
					return {
						audience: {
							name,
							audienceType,
							group,
							organizationId,
						},
						conditions: {
							typeOf: ConditionType.MARKETO,
							node: {
								operator: Operators.equalTo,
								value: externalId,
							},
						},
					};
				})
			)
		);
	};
	//add advanced tab content
	if (marketoAdvancedSettings) {
		tabs[advanced] = {
			title: 'integrations_marketo_tab_advanced_label',
			content: (
				<AdvancedSettings config={{ ...marketo?.configuration, ...config }} />
			),
		};
		routesToTabValues[advanced] = 2;
		tabValuesToRoutes[2] = advanced;
	}

	//setting tabs from url
	useEffect(() => {
		if (!tabs[tab]) {
			setValue(false);
		} else {
			setValue(routesToTabValues[tab]);
		}
	}, [tab, marketoAdvancedSettings, tabs]);

	//handle tab change
	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
		history.push(`${baseUrl}/${provider}/${tabValuesToRoutes[newValue]}`);
	};
	//handle Integration Active state
	const handleActiveState = active => {
		if (marketo?.id) {
			//if marketo is created only update active
			dispatch(
				actions.modifyIntegration({
					integrationId: marketo?.id,
					provider,
					configuration: { ...marketo?.configuration, active },
				})
			);
		} else {
			//if not, create integration and update active
			dispatch(
				actions.modifyIntegration({
					integrationId: marketo?.id,
					integration: {
						id: marketo?.id,
						name: 'Marketo',
						group,
						organizationId,
						source: true,
						provider,
					},
					group,
					provider,
					credentials: undefined,
					attributes: undefined,
					lists: undefined,
					configuration: { active },
				})
			);
		}
	};

	//clearing marketo states on unmount
	useEffect(() => {
		return () => {
			dispatch(actions.clearMarketoState());
		};
	}, [dispatch]);

	const saveMarketoIntegration = () => {
		dispatch(
			actions.modifyIntegration({
				integrationId: marketo?.id,
				integration: marketo?.id
					? null
					: {
							id: marketo?.id,
							name: 'Marketo',
							group,
							organizationId,
							source: true,
							provider: Providers.marketo,
					  },
				group,
				provider,
				credentials: savedCreds
					? { ...marketo?.credentials, ...savedCreds }
					: undefined,
				configuration: config
					? { ...marketo?.configuration, ...config }
					: undefined,
				attributes: undefined,
				lists:
					isAllDeselected === true
						? []
						: currentSelectedList.length === 0
						? undefined
						: currentSelectedList,
			})
		);
		//create Audience from the list
		createAudiences(audienceToBeCreated);
		//close integration
		goToIntegration();
	};

	if (loading) {
		return (
			<div className={commonIntStyles.wrapper}>
				<Loader />
			</div>
		);
	}

	//open add marketo list page
	if (marketo?.configuration?.active && openListPage) {
		return (
			<div className={commonIntStyles.wrapper}>
				<AddList lists={marketo?.lists?.items} />
			</div>
		);
	}

	return (
		<div className={commonIntStyles.wrapper}>
			<Header
				label="integrations_marketo_title"
				changehandler={handleActiveState}
				value={marketo?.configuration?.active ?? false}
			/>
			<Divider />
			{marketo?.configuration?.active ? (
				<>
					<AppBar position="static" className={styles.transparentTabBar}>
						<Tabs
							value={value}
							onChange={handleTabChange}
							aria-label="marketo-tabs"
							className={styles.transparentTabs}
						>
							{Object.entries(tabs).map((tab, i) => {
								return (
									<Tab
										key={i}
										label={t(tab[1].title)}
										{...a11yProps(i, t(tab[1].title))}
									/>
								);
							})}
						</Tabs>
					</AppBar>
					{Object.entries(tabs).map((tab, i) => {
						return (
							<TabPanel value={value} index={i} key={i}>
								{tab[1].content}
							</TabPanel>
						);
					})}
				</>
			) : (
				<>
					<NotActivated name="Marketo Source" Icon={<MarketoIcon />} />
				</>
			)}

			<div className={styles.bottomSec}>
				<Divider />
				<ButtonSection
					disabled={!marketo?.configuration?.active}
					onSave={saveMarketoIntegration}
					onCancel={goToIntegration}
				/>
			</div>
		</div>
	);
}

export { fetchLeadsFromMarketo } from './DataImportForm/fetchLeadsFromMarketo';
export type { Lead } from './DataImportForm/fetchLeadsFromMarketo';
export { CheckedIcon } from './DataImportForm/checkedIcon';
export { NoSelectedList } from './DataImportForm/NoSelectedList';
export { NoMatchFound } from './NoMatchFound';
export { checkConnection } from './AuthorizationForm/checkConnection';
