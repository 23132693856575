import { API } from 'aws-amplify';
import awsExports from 'aws-exports';

export async function getStripeSession(
	email: string,
	organization: string,
	url: string,
	code?: string
): Promise<
	| {
			id: string;
			emailConflict: boolean;
			organizationConflict: boolean;
	  }
	| undefined
> {
	try {
		const args = {
			body: {
				userPoolId: awsExports.aws_user_pools_id,
				email,
				organization,
				url,
				code,
			},
		};
		const data = await API.post(
			'stripeIntegration',
			'/stripe/create-checkout-session',
			args
		);
		return data;
	} catch (err) {}
}
