import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FieldArray, Field } from 'formik';
import { Typography, Checkbox } from '@material-ui/core';
import { Loader } from 'app/components/Loader';
import styles from './style.module.scss';
import { actions } from '../../slice';
import {
	selectFetchingSfOpportunities,
	selectAllDeselected,
} from '../../selectors';
import cx from 'classnames';
import { CheckedIcon } from '../Marketo/DataImportForm/checkedIcon';
import { MessageTag } from '../../components/MessageTag';

interface Props {
	credentials: any;
	fetchOpportunities: () => void;
	lists: any[];
	selectedOpportunities: any[];
	leads: any[];
}

export function DataImportForm({
	credentials,
	fetchOpportunities,
	lists,
	selectedOpportunities,
	leads,
}: Props) {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const isFetchingOpp = useSelector(selectFetchingSfOpportunities);
	const isAllDeselected = useSelector(selectAllDeselected);
	const [initialValues, setInitialValues] = useState({});

	//Don't fetch opportunities again
	useEffect(() => {
		if (leads?.length > 0) {
			selectedOpportunities?.length === 0 &&
				!isAllDeselected &&
				dispatch(actions.setSelectedOpportunities(leads));
		} else if (credentials?.access_token?.length > 0) {
			fetchOpportunities();
		}
	}, [
		dispatch,
		fetchOpportunities,
		leads,
		selectedOpportunities,
		credentials,
		isAllDeselected,
	]);

	//update leads based on saved lists
	useEffect(() => {
		if (lists?.length > 0 && leads?.length > 0) {
			const filteredLeads = leads?.filter(lead => {
				return lists.find(list => lead.id === list.externalId);
			});
			dispatch(actions.setSelectedOpportunities(filteredLeads));
		}
	}, [lists, leads, dispatch]);

	//set initial values
	useEffect(() => {
		//merge both sf list and to be sf list
		let mergedList = [] as any[];
		if (selectedOpportunities.length > 0) {
			mergedList = [...selectedOpportunities];
		}
		//if all the saved list are deselected
		if (isAllDeselected) {
			mergedList = [];
		}
		setInitialValues(
			mergedList?.reduce((accum, list) => {
				accum[list.id] = true;
				return accum;
			}, {})
		);
	}, [selectedOpportunities, leads, isAllDeselected]);

	//toggle leads selection
	const handleToggleselection = (values, id) => {
		//code to find out if all leads are deselected or not
		const valuesCount = Object.keys(values).length;
		if (valuesCount === 1 && values[id]) {
			dispatch(actions.setAllDeselected(true));
		} else {
			dispatch(actions.setAllDeselected(false));
		}
		//code to add or remove list
		if (id in values) {
			//to remove clicked lead
			dispatch(
				actions.setSelectedOpportunities(
					selectedOpportunities.filter(item => item.id !== id)
				)
			);
		} else {
			//to add clicked lead
			const addLead = leads.find(item => item.id === id);
			dispatch(
				actions.setSelectedOpportunities([...selectedOpportunities, addLead])
			);
		}
	};

	if (isFetchingOpp) return <Loader />;
	//if credentials not saved or removed
	if (credentials?.access_token?.length === 0) {
		return (
			<div className={styles.tagWrapper}>
				<MessageTag
					type="error"
					translationKey="integrations_salesforce_authorization_msg"
					message={t('integrations_salesforce_authorization_msg')}
				/>
			</div>
		);
	}
	//if no leads were found
	if (!leads.length)
		return (
			<div className={styles.noLeadsMsg}>
				<Typography>No leads Found</Typography>
				<Typography>Check If you are connected with Salesforce.</Typography>
			</div>
		);

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			onSubmit={() => {}}
		>
			{({ setValues, values }) => (
				<Form className={styles.formWrapper}>
					<div className={styles.formContent}>
						<Typography
							variant="body2"
							data-translation-key={
								'integrations_salesforce_import_description'
							}
						>
							{t('integrations_salesforce_import_description')}
						</Typography>
						<FieldArray
							name="selectedLeads"
							render={() => (
								<div className={styles.listWrapper}>
									{leads.map(({ apiname, id }) => (
										<div key={id} className={styles.labelWrap}>
											<span className={styles.label}>{apiname}</span>
											<Field type="checkbox" name={id}>
												{() => (
													<div className={styles.checkWrapper}>
														<Checkbox
															checked={values[id] ?? false}
															onChange={e => {
																setValues({
																	...values,
																	[id]: e.target.checked,
																});
															}}
															disableRipple
															checkedIcon={<CheckedIcon />}
															className={cx({
																[styles.checkBox]: true,
																[styles.showSelected]: values[id] === true,
															})}
														/>
														<span className={styles.onHoverText}>
															{values[id] ? (
																<span
																	style={{ color: '#FF7D5E' }}
																	onClick={() =>
																		handleToggleselection(values, id)
																	}
																>
																	Deselect
																</span>
															) : (
																<span
																	style={{ color: '#048CFF' }}
																	onClick={() =>
																		handleToggleselection(values, id)
																	}
																>
																	Add
																</span>
															)}
														</span>
													</div>
												)}
											</Field>
										</div>
									))}
								</div>
							)}
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
}
