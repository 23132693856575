import React from 'react';
import { Providers } from 'API';
import { MarketoIcon } from 'app/containers/IntegrationsContainer/DataSources/Marketo/MarketoIcon';
import { SalesforceIcon } from 'app/containers/IntegrationsContainer/DataSources/Salesforce/SalesforceIcon';
import { UniversalAnalyticsIcon } from 'app/containers/IntegrationsContainer/DataSources/UniversalAnalyticsSource/UniversalAnalyticsIcon';
import { ClearbitRevealIcon } from 'app/containers/IntegrationsContainer/DataSources/ClearbitReveal/ClearbitRevealIcon';
import { DemandBaseIcon } from 'app/containers/IntegrationsContainer/DataSources/Demandbase/DemandBaseIcon';
import { SixSenseIcon } from 'app/containers/IntegrationsContainer/DataSources/SixSense/SixSenseIcon';
import { AccountMatchIcon } from 'app/containers/IntegrationsContainer/DataSources/AccountMatch/AccountMatchIcon';
import { GoogleOptimizeIcon } from 'app/containers/IntegrationsContainer/DataSources/GoogleOptimize/GoogleOptimizeIcon';
import { HubSpotIcon } from 'app/containers/IntegrationsContainer/DataSources/HubSpot/HubSpotIcon';

export const getFormattedSourceAndIcon = type => {
	switch (type) {
		case Providers.marketo:
			return { source: 'Marketo', Icon: <MarketoIcon /> };
		case Providers.universalAnalyticsSource:
			return { source: 'Google Analytics', Icon: <UniversalAnalyticsIcon /> };
		case Providers.salesforce:
			return { source: 'Salesforce', Icon: <SalesforceIcon /> };
		case Providers.clearbit:
			return { source: 'Clearbit', Icon: <ClearbitRevealIcon /> };
		case Providers.demandbase:
			return { source: 'Demandbase', Icon: <DemandBaseIcon /> };
		case Providers.sixSense:
			return { source: 'SixSense', Icon: <SixSenseIcon /> };
		case Providers.accountMatch:
			return { source: 'Account Match', Icon: <AccountMatchIcon /> };
		case Providers.googleOptimize:
			return { source: 'Google Optimize', Icon: <GoogleOptimizeIcon /> };
		case Providers.hubspot:
			return { source: 'HubSpot', Icon: <HubSpotIcon /> };
		default:
			return { source: '', Icon: '' };
	}
};
