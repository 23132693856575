import { createSelector } from '@reduxjs/toolkit';

import { AudienceType } from 'API';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state.audiencesContainer || initialState;

export const selectFetchingData = createSelector(
	[selectDomain],
	audiencesContainerState => audiencesContainerState.fetchingData
);

export const selectModalFetchingData = createSelector(
	[selectDomain],
	audiencesContainerState => audiencesContainerState.modalFetchingData
);

export const selectAudience = (id: string) =>
	createSelector([selectDomain], audiencesContainerState =>
		audiencesContainerState.audiences.find(a => a.id === id)
	);

export const listAudiences = createSelector(
	[selectDomain],
	audiencesContainerState => audiencesContainerState.audiences
);

export const selectedAudience = createSelector(
	[selectDomain],
	audiencesContainerState => audiencesContainerState.selectedAudience
);

export const selectPage = createSelector(
	[selectDomain],
	audiencesContainerState =>
		audiencesContainerState.audiences?.slice(
			audiencesContainerState.indexRange[0],
			audiencesContainerState.indexRange[1] + 1
		)
);

export const selectAudienceByType = (t: AudienceType | null) =>
	createSelector([selectDomain], audiencesContainerState =>
		audiencesContainerState.audiences.find(a => a?.audienceType === t)
	);
export const selectAudiencesByType = (t: AudienceType | null) =>
	createSelector([selectDomain], audiencesContainerState =>
		audiencesContainerState.audiences.filter(a => a?.audienceType === t)
	);

export const selectNextToken = createSelector(
	[selectDomain],
	audiencesContainerState => audiencesContainerState.nextToken
);

export const selectIndexRange = createSelector(
	[selectDomain],
	audiencesContainerState => audiencesContainerState.indexRange
);
