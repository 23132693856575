import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectActionDomain = (state: RootState) =>
	state.actionContainer || initialState;

export const selectError = createSelector(
	[selectActionDomain],
	state => state.error
);
export const selectFetchingData = createSelector(
	[selectActionDomain],
	state => state.fetchingData
);

export const selectActionsList = createSelector(
	[selectActionDomain],
	state => state.actions
);

export const selectPage = createSelector([selectActionDomain], state =>
	state.actions?.slice(state.indexRange[0], state.indexRange[1] + 1)
);

export const selectActionByID = (id: string) =>
	createSelector([selectActionDomain], state =>
		state.actions.find(a => a.id === id)
	);

export const selectActionByDestinationID = (id: string) =>
	createSelector([selectActionDomain], state =>
		state.actions.find(a => a.destinationId === id)
	);

export const selectedAction = createSelector(
	[selectActionDomain],
	state => state.selectedAction
);

export const selectNextToken = createSelector(
	[selectActionDomain],
	state => state.nextToken
);

export const selectIndexRange = createSelector(
	[selectActionDomain],
	state => state.indexRange
);
