import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Divider, Button, InputLabel } from '@material-ui/core';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { actions } from '../slice';
import styles from './style.module.scss';

export const CreateTrigger = () => {
	const history = useHistory();
	const [t, i18n] = useTranslation();
	const dispatch = useDispatch();
	const org = useSelector(selectedOrganization);

	return (
		<div className={styles.createCustomGoalWrapper}>
			<div>
				<h3 className={styles.header}>{t('triggers_create_new_trigger')}</h3>
			</div>
			<Divider />
			<div className={styles.formWrapper}>
				<Formik
					enableReinitialize
					initialValues={{
						name: '',
						description: '',
					}}
					onSubmit={(values, { setSubmitting }) => {
						const reqData = {
							group: org?.group,
							organizationId: org?.id,
						};
						dispatch(
							actions.modifyTrigger({
								...reqData,
								...values,
							})
						);
						setSubmitting(false);
						history.push('/triggers');
					}}
					validationSchema={Yup.object().shape({
						name: Yup.string().required('Required'),
						description: Yup.string().required('Required'),
					})}
				>
					{({ errors, touched }) => (
						<Form className={styles.form}>
							<div style={{ padding: '16px' }}>
								<InputLabel htmlFor="name">{t('globals_name')}</InputLabel>
								<Field
									type="text"
									name="name"
									placeholder={t('triggers_edit_trigger_name_placeholder')}
									component={TextField}
									InputLabelProps={{ shrink: false }}
									fullWidth
									variant="outlined"
								/>

								<InputLabel htmlFor="description">
									{t('globals_description')}
								</InputLabel>
								<Field
									type="text"
									name="description"
									placeholder={t(
										'triggers_edit_trigger_description_placeholder'
									)}
									component={TextField}
									InputLabelProps={{ shrink: false }}
									fullWidth
									variant="outlined"
								/>
							</div>
							<div className={`${styles.bottomSec}`}>
								<div className={styles.buttonsWrapper}>
									<Button
										fullWidth
										variant="contained"
										onClick={() => history.goBack()}
										className={styles.cancel}
									>
										{t('globals_cancel')}
									</Button>
									<Button
										type="submit"
										variant="contained"
										disabled={!touched || !errors}
										fullWidth
									>
										{t('globals_save')}
									</Button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};
