import palette from '../palette';

export default {
	MuiCssBaseline: {
		'@global': {
			body: {
				fontFamily: 'Inter, sans-serif',
				backgroundColor: '#F5F7FB',
			},
			button: {
				'&:focus': {
					outline: 'none',
				},
			},
			a: {
				color: palette.text.link,
			},
		},
	},
	MuiButton: {
		root: {
			backgroundColor: palette.buttonPrimary.main,
			color: '#ffffff',
			padding: '16px',
			'&:hover': {},
		},
		contained: {
			backgroundColor: palette.buttonPrimary.main,
			borderRadius: '4px',
			color: '#FFFFFF',
			'&:hover': {
				boxShadow: 'none',
				backgroundColor: palette.buttonPrimary.main,
			},
			'&:focus': {
				outline: 'none',
			},
			'&:active': {
				boxShadow: 'none',
			},
			'&$disabled': {
				color: '#FFFFFF',
				backgroundColor: '#E5E8F5',
			},
			boxShadow: 'none',
		},
		containedSecondary: {
			backgroundColor: palette.buttonSecondary.main,
			borderRadius: '4px',
			color: '#FFFFFF',
			'&:hover': {
				boxShadow: 'none',
				backgroundColor: palette.buttonSecondary.main,
			},
			'&:focus': {
				outline: 'none',
			},
			'&:active': {
				boxShadow: 'none',
			},
			'&$disabled': {
				color: '#FFFFFF',
				backgroundColor: '#E5E8F5',
			},
			boxShadow: 'none',
		},

		outlined: {
			background: 'transparent',
			color: palette.text.link,
			boxShadow: 'none',
			'&:hover': {
				boxShadow: 'none',
				background: 'transparent',
			},
			'&:focus': {
				outline: 'none',
			},
			'&:active': {
				boxShadow: 'none',
			},
			'&$disabled': {
				color: '#FFFFFF',
				backgroundColor: '#E5E8F5',
			},
		},
	},
	MuiTextField: {
		root: {
			marginBottom: '20px',
		},
	},
	MuiInputBase: {
		root: {
			marginBottom: '0px',
		},
	},
	MuiFormHelperText: {
		contained: {
			marginTop: '10px',
			marginLeft: '0px',
		},
	},
	MuiCheckbox: {
		root: {
			'&$checked': {
				color: palette.success.main,
			},
		},
		colorPrimary: {
			'&$checked': {
				color: palette.success.main,
			},
		},
		colorSecondary: {
			'&$checked': {
				color: palette.success.main,
			},
		},
	},
	MuiTableCell: {
		root: {
			borderBottom: '1px solid rgba(229,232,245,0.5)',
		},
		head: {
			padding: '16px',
			fontSize: '10px',
			letterSpacing: '0',
			color: palette.text.secondary,
			fontWeight: 400,
			lineHeight: '16px',
			backgroundColor: 'rgba(245,247,251,0.5)',
			borderTop: `1px solid ${palette.divider}`,
		},
		body: {
			color: palette.text.primary,
			fontSize: '14px',
			letterSpacing: '0',
			lineHeight: '19px',
			padding: '16px',
		},
	},
	MuiTableRow: {
		root: {
			'&:nth-of-type(even)': {
				backgroundColor: '#FAFBFD',
			},
		},
	},
};
