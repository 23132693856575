/**
 *
 * Dashboard
 *
 */
import React from 'react';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useSelector } from 'react-redux';
import {
	sliceKey as organizationSliceKey,
	actions as organizationActions,
	reducer as organizationReducer,
} from 'app/containers/OrganizationsContainer/slice';

import { organizationContainerSaga } from '../OrganizationsContainer/saga';
import {
	selectPermissionState,
	selectFetchingData,
} from '../OrganizationsContainer/selectors';
import { AccessDenied } from './AccessDenied';
import { Main } from './main';
import _ from 'lodash';

import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
export function Dashboard({ history, match }) {
	useInjectReducer({ key: organizationSliceKey, reducer: organizationReducer });
	useInjectSaga({ key: organizationSliceKey, saga: organizationContainerSaga });

	const isPermissionDenied = useSelector(selectPermissionState);
	const isfetchingData = useSelector(selectFetchingData);
	const selectedOrg = useSelector(selectedOrganization);

	if (!isfetchingData && isPermissionDenied) return <AccessDenied />;
	return !isfetchingData && !isPermissionDenied && !_.isEmpty(selectedOrg) ? (
		<Main history={history} match={match} />
	) : null;
}
