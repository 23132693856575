import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { Auth } from 'aws-amplify';
import {
	all,
	select,
	call,
	put,
	takeEvery,
	takeLatest,
	takeLeading,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
	getIntegration,
	integrationsByOrganization,
	listIntegrationLists,
} from 'graphql/queries';
import { selectIntegration, selectIntegrationByProvider } from './selectors';
import {
	createIntegrationField,
	updateIntegrationField,
	createIntegrationList,
	updateIntegrationList,
	createIntegration,
	updateIntegration,
	deleteIntegration,
	deleteIntegrationList,
	// credentials
	editMarketoCredentials,
	editHubSpotCredentials,
	editSalesforceCredentials,
	editSixSenseCredentials,
	// configuration
	editCustomConfiguration,
	editMarketoConfiguration,
	editSalesforceConfiguration,
	editGoogleTagManagerConfiguration,
	editUniversalAnalyticsConfiguration,
	editUniversalAnalyticsSourceConfiguration,
	editClearbitConfiguration,
	editDemandbaseConfiguration,
	editSixSenseConfiguration,
	editAccountMatchConfiguration,
	editGoogleOptimizeConfiguration,
	editHubSpotConfiguration,
} from 'graphql/mutations';
import * as APIt from 'API';
import {
	IIntegration,
	IIntegrationList,
	IIntegrationField,
	ModifyIntegration,
	ReportPayload,
	ReportStats,
} from './types';
import { errorMessageHandler } from 'utils/errorMessage';
import { actions } from './slice';
import { fetchResponse } from './utilities';

const redirect = (title: string, location: string) => {
	const signinWin = window.open(
		location,
		title,
		'width=780,height=610,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=' +
			200 +
			',top=' +
			200
	);
	signinWin?.focus();
};

const errorMessage = (err: Error) =>
	errorMessageHandler(err, 'Error occurred fetching integrations.');

export function* handleError(err: Error) {
	const errMessage = errorMessage(err);
	yield call(toast.error, errMessage);
	yield put(actions.setIntegrationError(errMessage));
}

export function* handleFetchIntegrations(
	action: PayloadAction<APIt.IntegrationsByOrganizationQueryVariables>
): SagaIterator {
	try {
		const {
			data,
		}: GraphQLResult<APIt.IntegrationsByOrganizationQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(integrationsByOrganization, action.payload)
		);
		yield put(
			actions.fetchingIntegrationsSuccess(
				data?.integrationsByOrganization?.items as IIntegration[]
			)
		);
		yield put(
			actions.setNextToken(data?.integrationsByOrganization?.nextToken || null)
		);
	} catch (err) {
		yield call(handleError, err);
	}
}
export function* handleFetchIntegrationLists(
	action: PayloadAction<APIt.ListIntegrationListsQueryVariables>
): SagaIterator {
	try {
		const { filter } = action.payload;
		const { data }: GraphQLResult<APIt.ListIntegrationListsQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(listIntegrationLists, action.payload)
		);
		yield put(
			actions.fetchIntegrationListsSuccess({
				integrationId: filter?.integrationId?.eq || '',
				lists: data?.listIntegrationLists?.items as IIntegrationList[],
			})
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleFetchIntegration(
	action: PayloadAction<APIt.GetIntegrationQueryVariables>
): SagaIterator {
	try {
		const integration = yield select(selectIntegration(action?.payload?.id));
		if (
			integration &&
			!integration?.attributes?.items &&
			!integration?.lists?.items
		) {
			const { data }: GraphQLResult<APIt.GetIntegrationQuery> = yield call(
				[API, 'graphql'],
				graphqlOperation(getIntegration, { id: action?.payload?.id })
			);
			yield put(
				actions.fetchIntegrationSuccess(data?.getIntegration as IIntegration)
			);
		} else {
			yield put(actions.setLoadingState(false));
		}
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleFetchMarketoReport(
	action: PayloadAction<ReportPayload>
): SagaIterator {
	try {
		const { start_time, end_time, organization_id } = action.payload;
		const url = `${process.env.REACT_APP_REPORT_ENDPOINT}/imports/updated-leads-overall?start_time=${start_time}&end_time=${end_time}&organization_id=${organization_id}`;
		const request = yield call(fetchResponse, url);
		yield put(actions.fetchMarketoReportDataSuccess(request?.rows));
	} catch (err) {
		yield call(handleError, err);
	}
}
export function* handleFetchMarketoReportStats(
	action: PayloadAction<ReportStats>
): SagaIterator {
	try {
		const { organization_id } = action.payload;
		const url = `${process.env.REACT_APP_REPORT_ENDPOINT}/imports/total-stats?organization_id=${organization_id}`;
		const request = yield call(fetchResponse, url);
		yield put(actions.fetchMarketoReportStatsSuccess(request?.rows));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleModifyIntegrations(
	action: PayloadAction<ModifyIntegration[]>
): SagaIterator {
	try {
		const integrations = action.payload
			.filter(i => i !== null)
			.map(integration =>
				call(handleModifyIntegration, { payload: integration, type: '' })
			);
		yield all(integrations);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleCreateCredential(
	action: PayloadAction<{
		input: any;
		integrationId: string | undefined;
		provider: APIt.Providers;
	}>
): SagaIterator {
	try {
		const { input, provider, integrationId } = action.payload;
		const { createdAt, updatedAt, ...credentials } = input;
		const { operation, returnValueName } = getCredentialOperation(provider);
		const { data }: GraphQLResult<any> = yield call(
			[API, 'graphql'],
			graphqlOperation(operation, {
				integrationId,
				input: credentials,
			})
		);
		yield put(
			actions.addCredentialSuccess({
				integrationId,
				credentials: data?.[returnValueName],
			})
		);
		yield call(toast.success, 'Credentials saved');
	} catch (err) {
		yield call(handleError, err);
	}
}

function getCredentialOperation(provider: APIt.Providers) {
	switch (provider) {
		case APIt.Providers.marketo:
			return {
				operation: editMarketoCredentials,
				returnValueName: 'editMarketoCredentials',
			};
		case APIt.Providers.hubspot:
			return {
				operation: editHubSpotCredentials,
				returnValueName: 'editHubSpotCredentials',
			};
		case APIt.Providers.salesforce:
			return {
				operation: editSalesforceCredentials,
				returnValueName: 'editSalesforceCredentials',
			};
		case APIt.Providers.sixSense:
			return {
				operation: editSixSenseCredentials,
				returnValueName: 'editSixSenseCredentials',
			};
		default:
			throw new Error(
				`No credential operation found for provider ${provider}.`
			);
	}
}

export function* handleCreateConfiguration(
	action: PayloadAction<{
		input: any;
		integrationId: string;
		provider: APIt.Providers;
	}>
): SagaIterator {
	try {
		const { input, provider, integrationId } = action.payload;
		const { createdAt, updatedAt, ...configuration } = input;
		const { operation, returnValueName } = getConfigurationOperation(provider);
		const { data }: GraphQLResult<any> = yield call(
			[API, 'graphql'],
			graphqlOperation(operation, {
				integrationId,
				input: configuration,
			})
		);
		yield put(
			actions.addConfigurationSuccess({
				integrationId,
				configuration: data?.[returnValueName],
			})
		);
		yield call(toast.success, 'Configuration saved');
	} catch (err) {
		yield call(handleError, err);
	}
}

function getConfigurationOperation(provider: APIt.Providers) {
	switch (provider) {
		case APIt.Providers.custom:
			return {
				operation: editCustomConfiguration,
				returnValueName: 'editCustomConfiguration',
			};
		case APIt.Providers.marketo:
			return {
				operation: editMarketoConfiguration,
				returnValueName: 'editMarketoConfiguration',
			};
		case APIt.Providers.salesforce:
			return {
				operation: editSalesforceConfiguration,
				returnValueName: 'editSalesforceConfiguration',
			};
		case APIt.Providers.googleTagManager:
			return {
				operation: editGoogleTagManagerConfiguration,
				returnValueName: 'editGoogleTagManagerConfiguration',
			};
		case APIt.Providers.universalAnalytics:
			return {
				operation: editUniversalAnalyticsConfiguration,
				returnValueName: 'editUniversalAnalyticsConfiguration',
			};
		case APIt.Providers.universalAnalyticsSource:
			return {
				operation: editUniversalAnalyticsSourceConfiguration,
				returnValueName: 'editUniversalAnalyticsSourceConfiguration',
			};
		case APIt.Providers.clearbit:
			return {
				operation: editClearbitConfiguration,
				returnValueName: 'editClearbitConfiguration',
			};
		case APIt.Providers.demandbase:
			return {
				operation: editDemandbaseConfiguration,
				returnValueName: 'editDemandbaseConfiguration',
			};
		case APIt.Providers.sixSense:
			return {
				operation: editSixSenseConfiguration,
				returnValueName: 'editSixSenseConfiguration',
			};
		case APIt.Providers.accountMatch:
			return {
				operation: editAccountMatchConfiguration,
				returnValueName: 'editAccountMatchConfiguration',
			};
		case APIt.Providers.googleOptimize:
			return {
				operation: editGoogleOptimizeConfiguration,
				returnValueName: 'editGoogleOptimizeConfiguration',
			};
		case APIt.Providers.hubspot:
			return {
				operation: editHubSpotConfiguration,
				returnValueName: 'editHubSpotConfiguration',
			};
		default:
			throw new Error(
				`No configuration operation found for provider ${provider}.`
			);
	}
}

export function* handleCreateFields(
	action: PayloadAction<{
		integrationId: string;
		group: string;
		input: IIntegrationField[];
	}>
): SagaIterator {
	try {
		const updates = action.payload.input.map(field => {
			return call(
				[API, 'graphql'],
				graphqlOperation(
					field.id ? updateIntegrationField : createIntegrationField,
					{
						input: {
							...field,
							group: action.payload.group,
							integrationId: action.payload.integrationId,
						},
					}
				)
			);
		});
		const attributes = yield all(updates);
		yield put(
			actions.addIntegrationFieldsSuccess({
				integrationId: action.payload.integrationId,
				attributes: attributes.map(
					a =>
						a?.data?.updateIntegrationField || a?.data?.createIntegrationField
				),
			})
		);
		yield call(toast.success, 'Attributes saved');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleCreateLists(
	action: PayloadAction<{
		integrationId: string;
		group: string;
		input: IIntegrationList[];
	}>
): SagaIterator {
	try {
		const { input, group, integrationId } = action.payload;
		const integration = yield select(selectIntegration(integrationId));
		const toSaveLists = input.map(l => l.id);
		const removeIntegrations = integration.lists.items
			.map(l => l.id)
			.filter(l => toSaveLists.indexOf(l) === -1)
			.map(l =>
				call(
					[API, 'graphql'],
					graphqlOperation(deleteIntegrationList, { input: { id: l } })
				)
			);
		yield all(removeIntegrations);
		const updates = input.map(list => {
			const { createdAt, updatedAt, ...listValues } = list;
			return call(
				[API, 'graphql'],
				graphqlOperation(
					createdAt && updatedAt
						? updateIntegrationList
						: createIntegrationList,
					{
						input: {
							...listValues,
							group,
							integrationId,
						},
					}
				)
			);
		});
		const lists = yield all(updates);
		yield put(
			actions.addIntegrationListsSuccess({
				integrationId,
				lists: lists.map(
					(l: any) =>
						l?.data?.updateIntegrationList || l?.data?.createIntegrationList
				),
			})
		);
		yield call(toast.success, 'Leads saved');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleModifyIntegration(
	action: PayloadAction<ModifyIntegration>
): SagaIterator {
	try {
		const {
			group,
			provider,
			integration,
			integrationId,
			configuration,
			credentials,
			attributes,
			lists,
		} = action.payload;
		let intId = integrationId;
		if (integration) {
			if (!integration.id && integrationId) integration.id = integrationId;
			const data = yield call(
				integration?.id ? handleUpdateIntegration : handleCreateIntegration,
				integration
			);
			yield put(actions.modifyIntegrationSuccess(data));
			intId = data.id;
		}

		if (credentials) {
			yield put(
				actions.addCredential({
					input: credentials,
					integrationId: intId,
					provider,
				})
			);
		}
		if (configuration) {
			yield put(
				actions.addConfiguration({
					input: configuration,
					integrationId: intId,
					provider,
				})
			);
		}
		if (attributes && attributes.length > 0) {
			yield put(
				actions.addIntegrationFields({
					integrationId: intId,
					group,
					input: attributes,
				})
			);
		}
		if (lists) {
			yield put(
				actions.addIntegrationLists({
					integrationId: intId,
					group,
					input: lists,
				})
			);
		}
	} catch (err) {
		yield call(handleError, err);
	}
}

function* handleUpdateIntegration(
	integration: APIt.UpdateIntegrationInput
): SagaIterator {
	try {
		const { data }: GraphQLResult<any> = yield call(
			[API, 'graphql'],
			graphqlOperation(updateIntegration, { input: integration })
		);
		toast.success(`Updated integration ${integration.name}`);
		return data.updateIntegration;
	} catch (err) {
		yield call(handleError, err);
	}
}

function* handleCreateIntegration(
	i: APIt.CreateIntegrationInput
): SagaIterator {
	try {
		// check if integration provider already exists
		const integration = yield select(selectIntegrationByProvider(i.provider));
		// redirect back to update if found
		if (integration) {
			return yield call(handleUpdateIntegration, { ...i, id: integration.id });
		}
		// create integration if not found
		const { data }: GraphQLResult<any> = yield call(
			[API, 'graphql'],
			graphqlOperation(createIntegration, { input: i })
		);
		toast.success(`Created integration ${i.name}`);
		return data.createIntegration;
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleRemoveIntegration(
	action: PayloadAction<string>
): SagaIterator {
	try {
		yield call(
			[API, 'graphql'],
			graphqlOperation(deleteIntegration, { input: { id: action.payload } })
		);
		yield put(actions.removeIntegrationSuccess(action.payload));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleGenerateSalesforceAuthUrl(
	_action: PayloadAction<any>
): SagaIterator {
	try {
		const data = yield call(
			[API, 'get'],
			'oauthIntegrations',
			'/oauth/salesforce/auth',
			() => null
		);
		yield call(redirect, 'Salesforce Login', data?.url);
		yield put(actions.setUrlLoader());
	} catch (err) {
		yield call(handleError, err);
		yield put(actions.setUrlLoader());
	}
}

export function* handleGenerateHubSpotAuthUrl(_action: PayloadAction<any>) {
	try {
		const data = yield call(
			[API, 'get'],
			'oauthIntegrations',
			'/oauth/hubspot/connect',
			null
		);
		yield call(redirect, 'HubSpot Login', data?.url);
		yield put(actions.setUrlLoader());
	} catch (err) {
		yield call(handleError, err);
		yield put(actions.setUrlLoader());
	}
}

export function* handleSalesOAuth(action: PayloadAction<any>): SagaIterator {
	try {
		const currentUser = yield call([Auth, 'currentAuthenticatedUser']);
		const args = {
			body: {
				...action.payload.body,
				authorization: currentUser?.signInUserSession?.accessToken?.jwtToken,
			},
		};
		const auth = yield call(
			[API, 'post'],
			'oauthIntegrations',
			`/oauth/salesforce/auth`,
			args
		);
		if (auth?.salesforceData) {
			yield put(
				actions.modifyIntegrationSuccess({
					...auth?.salesforceData,
					credentials: {
						...auth?.credentials?.data?.editSalesforceCredentials,
					},
				})
			);
		}
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleHubSpotOAuth(action: PayloadAction<any>) {
	try {
		const hubSpotIntegration = yield select(
			selectIntegrationByProvider(APIt.Providers.hubspot)
		);
		const auth = yield call(
			[API, 'get'],
			'oauthIntegrations',
			'/oauth/hubspot/callback',
			{
				queryStringParameters: action.payload,
			}
		);
		if (auth?.credentials) {
			yield put(
				actions.modifyIntegration({
					...hubSpotIntegration,
					integrationId: hubSpotIntegration.id,
					attributes: null,
					lists: null,
					configuration: null,
					credentials: {
						...auth.credentials,
						expiresIn: auth.credentials.expiresIn.toString(),
					},
				})
			);
		}
		yield put(actions.stopLoading({}));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleOpportunity(action: PayloadAction<any>): SagaIterator {
	try {
		const data = yield call(
			[API, 'post'],
			'oauthIntegrations',
			'/oauth/salesforce/query',
			{
				body: action.payload,
			}
		);
		yield put(actions.getOpportunitySuccess(data.result.records));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleHubSpotRefresh(
	action: PayloadAction<{ refreshToken: string }>
) {
	try {
		const { credentials } = yield call(
			[API, 'get'],
			'oauthIntegrations',
			'/oauth/hubspot/refresh',
			{
				queryStringParameters: action.payload,
			}
		);
		const hubSpotIntegration = yield select(
			selectIntegrationByProvider(APIt.Providers.hubspot)
		);
		yield put(
			actions.modifyIntegration({
				...hubSpotIntegration,
				integrationId: hubSpotIntegration.id,
				attributes: null,
				lists: null,
				condition: null,
				credentials: {
					...credentials,
					expiresIn: credentials.expiresIn.toString(),
				},
			})
		);
		return credentials;
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleHubSpotIdentity(
	action: PayloadAction<{ accessToken: string }>
) {
	try {
		const { data } = yield call(
			[API, 'get'],
			'oauthIntegrations',
			'/oauth/hubspot/query/token',
			{
				queryStringParameters: action.payload,
			}
		);
		yield put(actions.fetchHubSpotIdentitySuccess(data));
	} catch (err) {
		const hubspot = yield select(
			selectIntegrationByProvider(APIt.Providers.hubspot)
		);
		yield put(
			actions.refreshHubSpotToken({
				refreshToken: hubspot?.credentials?.refreshToken,
			})
		);
	}
}

export function* handleSalesforceIdentity(
	action: PayloadAction<any>
): SagaIterator {
	try {
		const { identity } = yield call(
			[API, 'post'],
			'oauthIntegrations',
			'/oauth/salesforce/getIdentity',
			{
				body: action.payload,
			}
		);
		yield put(actions.fetchSalesforceIdentitySuccess(identity));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* integrationsContainerSaga() {
	yield takeLatest(actions.fetchingIntegrations.type, handleFetchIntegrations);
	yield takeLatest(actions.fetchIntegration.type, handleFetchIntegration);
	yield takeLatest(actions.modifyIntegrations.type, handleModifyIntegrations);
	yield takeEvery(actions.modifyIntegration.type, handleModifyIntegration);
	yield takeEvery(actions.addCredential.type, handleCreateCredential);
	yield takeEvery(actions.addConfiguration.type, handleCreateConfiguration);
	yield takeEvery(actions.addIntegrationFields.type, handleCreateFields);
	yield takeEvery(actions.addIntegrationLists.type, handleCreateLists);
	yield takeLatest(actions.removeIntegration.type, handleRemoveIntegration);
	yield takeLatest(
		actions.generateSalesforceUrl.type,
		handleGenerateSalesforceAuthUrl
	);
	yield takeLatest(
		actions.generateHubSpotUrl.type,
		handleGenerateHubSpotAuthUrl
	);
	yield takeLeading(actions.getSalesOauth.type, handleSalesOAuth);
	yield takeLeading(actions.getHubSpotOauth.type, handleHubSpotOAuth);
	yield takeLatest(actions.getOpportunity.type, handleOpportunity);
	yield takeLatest(
		actions.fetchSalesforceIdentity.type,
		handleSalesforceIdentity
	);
	yield takeLatest(actions.refreshHubSpotToken.type, handleHubSpotRefresh);
	yield takeLatest(actions.fetchHubSpotIdentity.type, handleHubSpotIdentity);
	yield takeLatest(actions.fetchMarketoReport, handleFetchMarketoReport);
	yield takeLatest(
		actions.fetchMarketoReportStats,
		handleFetchMarketoReportStats
	);
	yield takeLatest(actions.fetchIntegrationLists, handleFetchIntegrationLists);
}
