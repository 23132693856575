import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.members || initialState;

export const selectMembers = createSelector(
	[selectDomain],
	membersState => membersState.members
);

export const selectLoadingState = createSelector(
	[selectDomain],
	membersState => membersState.loading
);

export const selectUserStatusMsg = createSelector(
	[selectDomain],
	membersState => membersState.userStatusMessage
);

export const selectMemberLoadingState = createSelector(
	[selectDomain],
	membersState => membersState.memberLoading
);

export const selectDisableUserName = createSelector(
	[selectDomain],
	membersState => membersState.disableUserName
);
export const selectDisableAddMember = createSelector(
	[selectDomain],
	membersState => membersState.disableAddMember
);

export const selectUserName = createSelector(
	[selectDomain],
	membersState => membersState.userFetchedName
);

export const selectUserEmail = createSelector(
	[selectDomain],
	membersState => membersState.userPassedEmail
);

export const selectMemberType = createSelector(
	[selectDomain],
	membersState => membersState.memberType
);
