import React from 'react';
import Box from '@material-ui/core/Box';
import styles from './style.module.scss';

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
	theme?: 'transparent';
	style?: React.CSSProperties;
}

export const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, theme, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			className={
				theme === 'transparent' ? styles.transparentTabPanel : styles.tabPanel
			}
			{...other}
		>
			{value === index && (
				<Box style={{ width: '100%', height: '100%' }}>{children}</Box>
			)}
		</div>
	);
};
