import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import StackedChart from 'app/components/StackedChart';
import { getAscendingFormattedChartData, layout } from 'utils/chartData';
import styles from './style.module.scss';

const chartData1 = {
	x: [1, 2, 3, 4],
	y: [0, 2, 3, 5],
	fill: 'tozeroy',
	type: 'scatter',
	mode: 'none',
};

const chartData2 = {
	x: [1, 2, 3, 4],
	y: [3, 5, 1, 7],
	fill: 'tonexty',
	type: 'scatter',
	mode: 'none',
};

export const AttributionGraphComp = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<div className={styles.title}>
					<div>
						<Typography variant="body2">Event Attribution</Typography>
						<span className={styles.count}>26M</span>
					</div>
					<a href="#">View Report</a>
				</div>
				<div className={styles.title}>
					<div>
						<Typography variant="body2">Campaign Attribution</Typography>
						<span className={styles.count}>4.5M</span>
					</div>
					<a href="#">View Report</a>
				</div>
			</div>
			<div className={styles.boxGraphWrapper}>
				<StackedChart
					data={[chartData1, chartData2]}
					layout={{
						hovermode: 'closest',
						hoverlabel: { bgcolor: '#FFF' },
						showlegend: false,
						legend: {
							x: 1,
							y: 1,
							orientation: 'h',
						},
						margin: {
							l: 30,
							r: 10,
							t: 40,
							b: 30,
							pad: 5,
						},
					}}
					style={{ height: '300px' }}
				/>
			</div>
			<Divider />
		</div>
	);
};
