import React, { useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
	Switch,
	Route,
	useRouteMatch,
	useHistory,
	Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'app/components/Loader';
import { ModalContainer } from '../ModalContainer';
import {
	sliceKey as authSliceKey,
	reducer as authReducer,
} from 'app/containers/AuthContainer/slice';
import {
	sliceKey as organizationSliceKey,
	actions as organizationActions,
	reducer as organizationReducer,
} from 'app/containers/OrganizationsContainer/slice';
import {
	sliceKey as notificationKey,
	actions as notificationActions,
	reducer as notificationReducer,
} from 'app/containers/NotificationsContainer/slice';
import {
	sliceKey as integrationSliceKey,
	actions as integrationActions,
	reducer as integrationReducer,
} from 'app/containers/IntegrationsContainer/slice';
import {
	sliceKey as goalSliceKey,
	actions as goalActions,
	reducer as goalReducer,
} from 'app/containers/GoalContainer/slice';
import {
	sliceKey as actionSliceKey,
	actions as actionActions,
	reducer as actionReducer,
} from 'app/containers/ActionsContainer/slice';
import {
	sliceKey as audienceSliceKey,
	actions as audienceActions,
	reducer as audienceReducer,
} from 'app/containers/AudiencesContainer/slice';
import {
	sliceKey as triggerSliceKey,
	actions as triggerActions,
	reducer as triggerReducer,
} from 'app/containers/TriggersContainer/slice';
import {
	sliceKey as actionTriggerSliceKey,
	reducer as actionTriggerReducer,
} from 'app/containers/ActionTriggerContainer/slice';

import { sliceKey, reducer } from './slice';
import AccountPreferences from '../../components/AccountPreferences';
import OrganizationPreferences from '../../components/OrganizationPreferences';
import { AppsContainer } from '../AppsContainer';
import { GoalContainer } from '../GoalContainer';
import { AudiencesContainer } from '../AudiencesContainer';
import { JourneysContainer } from '../JourneysContainer';
import { IntegrationsContainer } from '../IntegrationsContainer';
import { ActionsContainer } from '../ActionsContainer';
import { TriggersContainer } from '../TriggersContainer';
import { integrationsContainerSaga } from '../IntegrationsContainer/saga';
import { organizationContainerSaga } from '../OrganizationsContainer/saga';
import { notificationsContainerSaga } from '../NotificationsContainer/saga';
import { goalContainerSaga } from '../GoalContainer/saga';
import { actionContainerSaga } from '../ActionsContainer/saga';
import { audiencesContainerSaga } from '../AudiencesContainer/saga';
import { triggersContainerSaga } from '../TriggersContainer/saga';
import { actionTriggerContainerSaga } from '../ActionTriggerContainer/saga';
import { dashboardSaga } from './saga';
import { authContainerSaga } from '../AuthContainer/saga';
import { DashBoardComp } from '../../components/Dashboard';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { Members } from 'app/containers/Members';
import { GoToDashboard } from 'app/components/GoToDashboard';
import {
	selectFetchingData,
	selectedOrganization,
} from '../OrganizationsContainer/selectors';
import {
	selectCognitoUser,
	selectFeatureFlags,
} from '../AuthContainer/selectors';

export const Routes = () => {
	useInjectReducer({ key: authSliceKey, reducer: authReducer });
	useInjectReducer({ key: organizationSliceKey, reducer: organizationReducer });
	useInjectReducer({ key: integrationSliceKey, reducer: integrationReducer });
	useInjectReducer({ key: notificationKey, reducer: notificationReducer });
	useInjectReducer({ key: actionSliceKey, reducer: actionReducer });
	useInjectReducer({ key: goalSliceKey, reducer: goalReducer });
	useInjectReducer({ key: audienceSliceKey, reducer: audienceReducer });
	useInjectReducer({ key: triggerSliceKey, reducer: triggerReducer });
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectReducer({
		key: actionTriggerSliceKey,
		reducer: actionTriggerReducer,
	});
	useInjectSaga({ key: authSliceKey, saga: authContainerSaga });
	useInjectSaga({ key: organizationSliceKey, saga: organizationContainerSaga });
	useInjectSaga({ key: integrationSliceKey, saga: integrationsContainerSaga });
	useInjectSaga({ key: notificationKey, saga: notificationsContainerSaga });
	useInjectSaga({ key: actionSliceKey, saga: actionContainerSaga });
	useInjectSaga({ key: goalSliceKey, saga: goalContainerSaga });
	useInjectSaga({ key: audienceSliceKey, saga: audiencesContainerSaga });
	useInjectSaga({ key: triggerSliceKey, saga: triggersContainerSaga });
	useInjectSaga({
		key: actionTriggerSliceKey,
		saga: actionTriggerContainerSaga,
	});
	useInjectSaga({ key: sliceKey, saga: dashboardSaga });
	const dispatch = useDispatch();
	const { path } = useRouteMatch();
	const history = useHistory();
	const currentOrg = useSelector(selectedOrganization);
	const { actions, feApps, triggers, marketoMilestone, goalsUi } = useSelector(
		selectFeatureFlags
	);
	const cognitoUser: any = useSelector(selectCognitoUser);
	const fetchingOrganizations = useSelector(selectFetchingData);

	useEffect(() => {
		const filters = {
			organizationId: currentOrg.id,
			filter: {
				isArchived: {
					ne: true,
				},
			},
		};
		dispatch(
			integrationActions.fetchingIntegrations({ organizationId: currentOrg.id })
		);
		dispatch(
			notificationActions.fetchNotifications({ organizationId: currentOrg.id })
		);
		dispatch(audienceActions.fetchAudiences(filters));
		dispatch(triggerActions.fetchTriggers(filters));
		dispatch(goalActions.fetchGoals(filters));
		dispatch(actionActions.fetchActions(filters));
	}, [cognitoUser, dispatch, currentOrg]);

	if (fetchingOrganizations) return <Loader />;

	return (
		<Switch>
			<Route exact path={`${path}`}>
				<Redirect to={`${path}dashboard`} />
			</Route>
			<Route exact path={`${path}dashboard`} component={DashBoardComp} />
			<Route
				path={`${path}GoToDashboard`}
				render={() => {
					return (
						<ModalContainer
							onCloseModel={() => {
								history.push(`${path}`);
							}}
						>
							<GoToDashboard goToDashboard={() => history.push(`${path}`)} />
						</ModalContainer>
					);
				}}
			/>
			<Route exact path={`${path}user`}>
				<Redirect to={`${path}user/basic-info`} />
			</Route>
			<Route exact path={`${path}user/:page?`} component={AccountPreferences} />
			<Route exact path={`${path}orgSettings`}>
				<Redirect to={`${path}orgSettings/funnelenvy-script`} />
			</Route>
			<Route
				exact
				path={`${path}orgSettings/:page?`}
				component={OrganizationPreferences}
			/>
			<Route path={`${path}audiences`} component={AudiencesContainer} />

			<Route path={`${path}members`} component={Members} />
			<Route
				exact
				path={`${path}integrations`}
				component={IntegrationsContainer}
			/>

			<Route
				path={`${path}integrations/:page?`}
				component={IntegrationsContainer}
			/>
			{triggers && (
				<Route path={`${path}triggers`} component={TriggersContainer} />
			)}
			{actions && (
				<Route path={`${path}actions`} component={ActionsContainer} />
			)}
			{goalsUi && <Route path={`${path}goals`} component={GoalContainer} />}
			{!marketoMilestone && (
				<Route path={`${path}journeys`} component={JourneysContainer} />
			)}
			{feApps && <Route path={`${path}apps`} component={AppsContainer} />}
			<Route component={NotFoundPage} />
		</Switch>
	);
};
