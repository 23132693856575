import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NameReportTableCell from 'app/components/NameReportTableCell';
import { NoDataFound } from 'app/components/NoDataFound';
import { ArchiveComp } from 'app/components/ArchiveComp';
import Pagination, {
	Props as PaginationProps,
} from 'app/components/Pagination';
import { getFormattedSourceAndIcon } from './utils';
import { actions } from '../slice';
import { Goal } from 'API';
import styles from './style.module.scss';

interface Props extends PaginationProps {
	goals: Goal[];
}

const GoalTable = (props: Props) => {
	const { goals, ...paginationProps } = props;
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const history = useHistory();
	const { path } = useRouteMatch();
	const [toArchive, setToArchive] = useState<{
		id?: string;
		name?: string;
	} | null>(null);

	const handleArchive = () => {
		dispatch(actions.archiveGoal({ ...toArchive, isArchived: true }));
		setToArchive(null);
	};

	if (goals.length === 0) {
		return (
			<NoDataFound
				heading={{
					key: 'goals_no_goals_msg',
					text: t('goals_no_data_found_heading'),
				}}
				description={{
					key: 'goals_no_goals_sub_msg',
					text: t('goals_no_data_found_description'),
				}}
			/>
		);
	}
	return (
		<Paper className={styles.wrapperPaper}>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Conversions (Last 30 Days)</TableCell>
							<TableCell>Last Updated</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{goals.map(
							({
								id,
								name,
								description,
								type: goalType,
								conversionsLast30d,
								updatedAt,
							}) => (
								<TableRow key={id}>
									<NameReportTableCell
										id={id}
										name={name}
										icon={getFormattedSourceAndIcon(goalType)?.Icon}
										description={description}
										handleViewClick={() => history.push(`${path}/${id}`)}
										handleReportClick={() =>
											history.push(`${path}/report/${id}`)
										}
										handleArchiveClick={() =>
											setToArchive({ id, name: name ?? '' })
										}
									/>
									<TableCell>{conversionsLast30d ?? 0}</TableCell>
									<TableCell>
										{moment(updatedAt).format('MM/DD/YYYY')}
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<ArchiveComp
				open={!isEmpty(toArchive)}
				name={toArchive?.name ?? null}
				handleClose={() => setToArchive(null)}
				handleArchive={handleArchive}
			/>
			<Pagination {...paginationProps} />
		</Paper>
	);
};

export default GoalTable;
