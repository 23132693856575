import React, { memo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { actions, reducer, sliceKey } from './slice';
import {
	listAudiences,
	selectFetchingData,
	selectPage,
	selectNextToken,
	selectIndexRange,
} from './selectors';
import { AudienceStats, fetchAudienceStats } from './fetchStats';
import { audiencesContainerSaga } from './saga';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { ModalContainer } from 'app/containers/ModalContainer';
import { CreateAudience } from './CreateAudience';
import { EditAudience } from './EditAudience';
import { ViewAudience } from './ViewAudience';
import { AudienceReport } from './AudienceReport';
import AudienceTable from './Table';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Loader } from 'app/components/Loader';
import SearchBar from 'app/components/SearchBar';
import {
	CreateActionType,
	CreateAction,
} from 'app/containers/ActionsContainer/CreateAction';
import { last7Days } from 'utils/fetchResponse';

const { startTime, endTime } = last7Days();

export const AudiencesContainer = memo(() => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: audiencesContainerSaga });
	const [t, i18next] = useTranslation();
	const match = useRouteMatch();
	const history = useHistory();
	const dispatch = useDispatch();
	const loading = useSelector(selectFetchingData);
	const audiencesLength = useSelector(listAudiences).length;
	const audiences = useSelector(selectPage);
	const org = useSelector(selectedOrganization);
	const nextToken = useSelector(selectNextToken);
	const indexRange = useSelector(selectIndexRange);

	const [search, setSearch] = useState('');
	const [audienceStats, setStats] = useState<AudienceStats[]>([]);

	const buildFilters = useCallback(() => {
		const filters: any = {
			isArchived: {
				ne: true,
			},
		};
		if (search) {
			filters.or = [
				{ name: { contains: search } },
				{ description: { contains: search } },
			];
		}
		return filters;
	}, [search]);

	useEffect(() => {
		const filters = buildFilters();
		dispatch(
			actions.fetchAudiences({
				organizationId: org.id,
				filter: filters,
			})
		);
	}, [buildFilters, dispatch, org.id]);

	useEffect(() => {
		if (org && org.id) {
			const fetchData = async () => {
				const data = await fetchAudienceStats(org?.id, startTime, endTime);
				setStats(data?.rows ?? []);
			};
			fetchData();
		}
	}, [dispatch, org]);

	const handlePrevious = () => dispatch(actions.previous({}));
	const handleNext = () => dispatch(actions.next({}));
	const handleCreate = () => history.push(`${match.url}/create`);
	const onModalClose = () => history.push(`${match.url}`);

	const extendedAudiences = audiences.map(a => {
		const stats = audienceStats.find(s => s?.audience_id === a.id);
		return {
			...a,
			visitors: stats?.visitors ?? 0,
			individuals: stats?.individuals ?? 0,
		};
	});

	return (
		<DndProvider backend={HTML5Backend}>
			<SearchBar
				title={t('audiences_title')}
				description={t('audiences_description')}
				search={search}
				handleSearch={setSearch}
				handleCreate={handleCreate}
				createButtonText={t('audiences_add_audience')}
			/>
			{loading ? (
				<Loader style={{ minHeight: '400px' }} />
			) : (
				<AudienceTable
					audiences={extendedAudiences}
					hasNext={!!nextToken || audiencesLength - 1 > indexRange[1]}
					hasPrevious={indexRange[0] !== 0}
					handleNext={handleNext}
					handlePrevious={handlePrevious}
				/>
			)}
			<Switch>
				<Route
					path={`${match.url}/create`}
					render={() => {
						return (
							<ModalContainer onCloseModel={() => history.goBack()}>
								<CreateAudience />
							</ModalContainer>
						);
					}}
				/>
				<Route
					path={`${match.url}/edit/:id?`}
					render={() => {
						return (
							<ModalContainer onCloseModel={onModalClose}>
								<EditAudience />
							</ModalContainer>
						);
					}}
				/>
				<Route
					path={`${match.url}/create-action/:id`}
					render={() => {
						return (
							<ModalContainer
								onCloseModel={onModalClose}
								closeBtnStyle={{ top: '12px', right: '24px' }}
								modalInnerWrapStyle={{
									maxWidth: '730px',
									maxHeight: '500px',
								}}
							>
								<CreateAction typeOf={CreateActionType.Audience} />
							</ModalContainer>
						);
					}}
				/>
				<Route
					path={`${match.url}/edit-action/:id`}
					render={() => {
						return (
							<ModalContainer
								onCloseModel={onModalClose}
								closeBtnStyle={{ top: '12px', right: '24px' }}
								modalInnerWrapStyle={{
									maxWidth: '730px',
									maxHeight: '500px',
								}}
							>
								<CreateAction typeOf={CreateActionType.Audience} editable />
							</ModalContainer>
						);
					}}
				/>
				<Route
					path={`${match.url}/report/:id`}
					render={() => {
						return (
							<ModalContainer onCloseModel={onModalClose}>
								<AudienceReport />
							</ModalContainer>
						);
					}}
				/>
				<Route
					path={`${match.url}/:id`}
					render={() => {
						return (
							<ModalContainer
								onCloseModel={onModalClose}
								modalInnerWrapStyle={{
									maxWidth: '730px',
								}}
							>
								<ViewAudience />
							</ModalContainer>
						);
					}}
				/>
			</Switch>
		</DndProvider>
	);
});
