import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import {
	InputAdornment,
	TextField,
	DialogContent,
	Typography,
	Divider,
	Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { AddListFields } from './AddListFields';
import {
	selectMarketoLeads,
	selectMarketoListsToBe,
	selectAllDeselected,
} from 'app/containers/IntegrationsContainer/selectors';
import { actions } from 'app/containers/IntegrationsContainer/slice';
import marketoStyles from '../style.module.scss';
import commonIntStyles from 'app/containers/IntegrationsContainer/style.module.scss';
import styles from './style.module.scss';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
	lists: any;
}

export const AddList = (props: Props) => {
	const { lists = [] } = props;
	const [search, setSearch] = useState('');
	const [t, i18n] = useTranslation();
	const leads = useSelector(selectMarketoLeads);
	const currentSelectedList = useSelector(selectMarketoListsToBe);
	const history = useHistory();
	const dispatch = useDispatch();
	const isAllDeselected = useSelector(selectAllDeselected);

	let mergedList;
	if (currentSelectedList.length > 0) {
		mergedList = [...currentSelectedList];
	} else {
		mergedList = [...lists];
	}

	//if all the saved list are deselected
	if (isAllDeselected) {
		mergedList = [];
	}

	const initialValues = mergedList.reduce((accum, list) => {
		accum[list.externalId] = true;
		return accum;
	}, {});

	const onKeyDown = (keyEvent: any) => {
		if (keyEvent.key.toLowerCase() === 'enter') {
			keyEvent.preventDefault();
		}
	};

	const searchableLeads = leads.map(lead => ({
		...lead,
		nameLowerCase: lead.name.toLowerCase(),
	}));

	const closeAddListModal = () => dispatch(actions.setListOpen(false));

	const submit = values => {
		//make allListsDesecleted state true if not value is true
		if (Object.keys(values).filter(item => values[item]).length === 0) {
			dispatch(actions.setAllDeselected(true));
		} else {
			dispatch(actions.setAllDeselected(false));
		}
		dispatch(actions.setMarketoListsToBe({ leads, lists, values }));
		dispatch(actions.setAudienceToBeLists({ leads, values }));
		closeAddListModal();
	};

	return (
		<>
			<div className={styles.addlistHeader}>
				<Typography variant="h3">Add lists</Typography>
				<CloseIcon onClick={closeAddListModal} />
			</div>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={submit}
			>
				{({ setValues, values, dirty }) => (
					<Form className={marketoStyles.formWrapper} onKeyDown={onKeyDown}>
						<DialogContent className={marketoStyles.addListContent}>
							<TextField
								value={search}
								onChange={e => setSearch(e.target.value)}
								fullWidth
								placeholder={t(
									'integrations_marketo_data_import_search_placeholder'
								)}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon className={styles.searchIcon} />
										</InputAdornment>
									),
								}}
								className={styles.searchInput}
							/>
							<Divider />
							<AddListFields
								search={search}
								values={values}
								setValues={setValues}
								leads={searchableLeads}
							/>
							<div
								className={commonIntStyles.bottomSec}
								style={{ padding: 24 }}
							>
								<Button
									type="submit"
									variant="contained"
									fullWidth
									disabled={!dirty}
								>
									Done
								</Button>
							</div>
						</DialogContent>
					</Form>
				)}
			</Formik>
		</>
	);
};
