import moment from 'moment';
export const UpdatedAddList = (addList, intList) => {
	const providers = intList.map(i => i.provider);
	return addList.filter(a => providers.indexOf(a.provider) === -1);
};

export const salesforeceRedirect = location => {
	const signinWin = window.open(
		location,
		'SalesforceLogin',
		'width=780,height=410,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=' +
			200 +
			',top=' +
			200
	);
	signinWin?.focus();
};

export function fetchResponse(url) {
	return fetch(url).then(response => response.json());
}

export function isLastSevenDays(start_time, end_time) {
	return (
		moment(end_time).diff(moment(start_time), 'days') === 7 &&
		end_time.isSame(moment(), 'day')
	);
}
