import React, { ReactNode } from 'react';
import styles from './style.module.scss';

interface Props {
	children: ReactNode;
	pos?: 'left' | 'center' | 'right';
}

const TextComp = ({ children, pos = 'left' }: Props) => {
	return (
		<p className={styles.text} style={{ textAlign: pos }}>
			{children}
		</p>
	);
};

export default TextComp;
