import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Providers } from 'API';
import { actions as audienceActions } from 'app/containers/AudiencesContainer/slice';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { actions } from 'app/containers/ActionsContainer/slice';
import {
	selectActionByID,
	selectFetchingData,
} from 'app/containers/ActionsContainer/selectors';
import Configuration from './Configuration';
import { Overview } from './Overview';
import styles from './style.module.scss';
import { convertOrganizationToGroup } from 'utils/groupNameConvert';
import { Loader } from 'app/components/Loader';
import _ from 'lodash';
import cx from 'classnames';

export enum CreateActionType {
	Audience = 'AUDIENCE',
	Goal = 'GOAL',
}

const steps = ['OVERVIEW', 'CONFIGURATION'];
interface Props {
	editable?: Boolean;
	typeOf?: CreateActionType;
}

export function CreateAction(props: Props) {
	const { editable = false } = props;
	const history = useHistory();
	const [t, i18n] = useTranslation();
	const match = useRouteMatch();
	const dispatch = useDispatch();
	const organization = useSelector(selectedOrganization);
	const loadingData = useSelector(selectFetchingData);
	const { params } = match;
	//id is currently used in create Action from audience
	const { id } = params as any;
	const currentAction = useSelector(selectActionByID(id));
	const [step, setStep] = useState(0);
	const [
		destinationProvider,
		setDestinationProvider,
	] = useState<Providers | null>();
	const [action, setAction] = useState({
		isArchived: false,
		isActive: true,
		destination: undefined,
		group: convertOrganizationToGroup(organization?.group),
		organizationId: organization?.id,
		configuration: undefined,
	});

	//for edit action fetch current action data
	useEffect(() => {
		if (id && editable) {
			dispatch(
				actions.fetchAction({
					id,
				})
			);
		}
	}, [dispatch, editable, id]);

	const create = useCallback(() => {
		const { configuration, ...actionObj } = action;
		dispatch(
			actions.modifyAction({
				action: {
					...actionObj,
					id: currentAction?.id ?? null,
				},
				destination: destinationProvider,
				configuration,
				actionId: currentAction?.id ?? null,
			})
		);
	}, [action, dispatch, currentAction, destinationProvider]);

	useEffect(() => {
		if (!id) {
			dispatch(
				audienceActions.fetchAudiences({
					organizationId: organization?.id,
				})
			);
		}
	}, [dispatch, id, organization]);

	useEffect(() => {
		const { configuration } = action;
		if (configuration !== undefined) {
			create();
			if (props.typeOf === 'GOAL') history.push('/goals');
			else if (props.typeOf === 'AUDIENCE') history.push('/audiences');
			else history.push('/actions');
		}
	}, [dispatch, create, action, history, props.typeOf]);

	const advanceStep = () => {
		if (step < steps.length - 1) {
			setStep(step + 1);
		}
	};

	const mergeAction = (values: any) => {
		setAction({ ...action, ...values });
	};
	const setCreateActionData = (values: any) => {
		mergeAction(values);
		advanceStep();
	};

	//clickable steps when in edit mode
	const handleSteps = index => {
		if (editable && !_.isEmpty(currentAction)) {
			steps[index] === 'CONFIGURATION' &&
				setDestinationProvider(currentAction?.destination?.provider);
			setStep(index);
		}
	};
	return (
		<>
			<div className={styles.left}>
				<h3 className={styles.header}>
					{editable ? 'Edit Action' : t('actions_create_action_title')}
				</h3>
				<ol className={styles.list}>
					{steps.map((name, index) => (
						<li
							key={name}
							className={cx({
								[styles.active]: step === index,
								[styles.linkStyle]: editable,
							})}
							onClick={() => handleSteps(index)}
						>
							{index + 1}. {name}
						</li>
					))}
				</ol>
			</div>
			<div className={styles.right}>
				{loadingData ? (
					<Loader />
				) : (
					<div className={styles.content}>
						<SelectForm
							step={step}
							stepCount={steps.length}
							currentAction={currentAction || null}
							setAction={setCreateActionData}
							destinationProvider={destinationProvider}
							setDestinationProvider={setDestinationProvider}
							editable={editable}
						/>
					</div>
				)}
			</div>
		</>
	);
}

const SelectForm = ({
	step,
	stepCount,
	setAction,
	currentAction,
	destinationProvider,
	setDestinationProvider,
	editable,
}) => {
	switch (step) {
		// case 1:
		// 	return <Attributes step={step} stepCount={stepCount} next={setAction} />;
		case 1:
			return (
				<Configuration
					destinationProvider={destinationProvider}
					step={step}
					stepCount={stepCount}
					next={setAction}
					config={currentAction?.configuration}
				/>
			);
		default:
			return (
				<Overview
					step={step}
					stepCount={stepCount}
					next={setAction}
					setDestinationProvider={setDestinationProvider}
					currentAction={currentAction}
					editable={editable}
				/>
			);
	}
};
