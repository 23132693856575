import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';

// The initial state of the JourneysContainer container
export const initialState: ContainerState = {};

const journeysContainerSlice = createSlice({
	name: 'journeysContainer',
	initialState,
	reducers: {
		someAction() {},
	},
});

export const { actions, reducer, name: sliceKey } = journeysContainerSlice;
