import React from 'react';
import { useTranslation } from 'react-i18next';
import MuiTypography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import RichTextConverter from 'app/components/RichTextConverter';
import styles from './style.module.scss';

interface Props {
	title: string;
	description: string;
	search: string;
	handleSearch: (val: string) => void;
	handleCreate: () => void;
	createButtonText: string;
	children?: JSX.Element;
}

const SearchBar = ({
	children,
	title,
	description,
	search,
	handleSearch,
	handleCreate,
	createButtonText,
}: Props) => {
	const [t, i18n] = useTranslation();

	return (
		<>
			<div className={styles.titleWrapper}>
				<MuiTypography variant="h3">{title}</MuiTypography>
				<MuiTypography variant="body2">
					{description}
					<RichTextConverter
						html={t('globals_go_to_knowledge_base')}
						translationKey={'globals_go_to_knowledge_base'}
					></RichTextConverter>
				</MuiTypography>
			</div>
			<div className={styles.searchWrapper}>
				<div className={styles.search}>
					<span className={styles.searchIcon}>
						<SearchIcon />
					</span>
					<InputBase
						value={search}
						placeholder="Search by name, description, value …"
						inputProps={{ 'aria-label': 'search' }}
						className={styles.searchInput}
						onChange={e => handleSearch(e.target.value)}
					/>
				</div>
				{children}
				<Button
					type="button"
					onClick={() => handleCreate()}
					variant="contained"
				>
					{createButtonText}
				</Button>
			</div>
		</>
	);
};

export default SearchBar;
