import { ConditionType, Operators, SourceType } from 'API';

export enum ParentObject {
	Audience = 'AUDIENCE',
	Trigger = 'TRIGGER',
}

export type Condition = {
	id?: string;
	name: string;
	typeOf: ConditionType;
	operands: Condition[];
	operator: Operators;
	value: string;
	source: SourceType;
	attribute: string;
	triggerId: string;
};
