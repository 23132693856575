import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Typography as MuiTypography, Divider } from '@material-ui/core';
import { actions } from '../slice';
import { selectedAudience } from 'app/containers/AudiencesContainer/selectors';
import styles from './style.module.scss';
import { Loader } from 'app/components/Loader';
import { DatePickerComp } from 'app/components/DatePickerComp';
import _ from 'lodash';
import { CustomTabs } from 'app/components/CustomTabs';
import AreaChart from 'app/components/AreaChart';
import { getAscendingFormattedChartData, difference } from 'utils/chartData';
import {
	fetchAudienceReport,
	fetchAudienceReportStats,
} from './fetchAudienceReports';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { Sup } from 'app/components/Sup';
import { layout } from 'app/components/AreaChart/fakeData';

export function AudienceReport(props) {
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const [t, i18n] = useTranslation();
	const { params } = match;
	const { id } = params as any;
	const selected = useSelector(selectedAudience);
	// const apiDataLoading = useSelector(selectFetchingApiData);
	const currentOrg = useSelector(selectedOrganization);

	const [loading, setLoading] = useState(false);
	const [stats, setStats] = useState({
		visitors_last_30days: 0,
		visitors_30_60days: 0,
		actions_last_30days: 0,
		actions_30_60days: 0,
	});
	const [chartData, setChartData] = useState([]);
	const [start_time, setStartTime] = useState(moment().subtract(30, 'd'));
	const [end_time, setEndTime] = useState(moment().subtract(1, 'd'));
	const [isLast30Days, setIsLast30Days] = useState(true);

	//datepicker change values
	const setNewDateChange = (s, e) => {
		setStartTime(moment(s));
		setEndTime(moment(e));
		//change is last 30 days on date change
		setIsLast30Days(false);
	};

	//fetch individual Audience data
	useEffect(() => {
		dispatch(
			actions.getAudience({
				id: id,
			})
		);
	}, [dispatch, id]);

	//fetch Audience report stats
	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const data = await fetchAudienceReportStats(currentOrg?.id, id);
				const chart = await fetchAudienceReport(
					currentOrg?.id,
					id,
					start_time.format('YYYY-MM-DD'),
					end_time.format('YYYY-MM-DD')
				);
				setStats(data.rows ?? []);
				setChartData(chart.rows ?? []);
			} catch (err) {}
			setLoading(false);
		};
		fetchData();
	}, [currentOrg, dispatch, id, start_time, end_time]);

	const actions30DaysProgress = difference(
		stats.actions_last_30days ?? 0,
		stats.actions_30_60days ?? 0
	);
	const visitors30DaysProgress = difference(
		stats.visitors_last_30days ?? 0,
		stats.visitors_30_60days ?? 0
	);

	if (loading) {
		return <Loader />;
	}

	if (!_.isEmpty(selected)) {
		return (
			<div className={styles.reportWrapper}>
				<div className={styles.wrapper}>
					<MuiTypography
						variant="h3"
						className={styles.header}
						style={{ padding: '0px 24px' }}
					>
						{selected.name}
					</MuiTypography>
					<MuiTypography variant="body2">{selected.description}</MuiTypography>
				</div>
				<div className={styles.statsDateWrapper}>
					<div className={styles.recordWrapper}>
						<div>
							<MuiTypography
								variant="body2"
								data-translation-key="audiences_report_actions_in_last_30_days"
							>
								{t('audiences_report_actions_in_last_30_days')}
							</MuiTypography>
							<MuiTypography variant="h2">
								{stats.actions_last_30days ?? 0}
								<Sup value={actions30DaysProgress ?? 0} />
							</MuiTypography>
						</div>
						<Divider orientation="vertical" style={{ margin: '0px 24px' }} />
						<div>
							<MuiTypography
								variant="body2"
								data-translation-key="audiences_report_visitors_in_last_30_days"
							>
								{t('audiences_report_visitors_in_last_30_days')}
							</MuiTypography>
							<MuiTypography variant="h2">
								{stats.visitors_last_30days ?? 0}
								<Sup value={visitors30DaysProgress ?? 0} />
							</MuiTypography>
						</div>
					</div>
					<div className={styles.DatePickerWrapper}>
						<DatePickerComp
							startDate={start_time}
							setStartDate={setStartTime}
							endDate={end_time}
							setEndDate={setEndTime}
							startDateLimit={moment('1970-01-01')} //from start of a date
						/>
					</div>
				</div>
				{/**if api data fetching */}
				{loading && <Loader />}
				{/**if api data */}
				{!loading && chartData && chartData.length > 0 && (
					<CustomTabs
						style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}
						panelStyles={{ flexGrow: 1 }}
						tabs={[
							{
								label: isLast30Days
									? t('audiences_report_tab_visitors_in_last_30_days')
									: t('audiences_report_tab_visitors_in'),
								translationKey: isLast30Days
									? 'audiences_report_tab_visitors_in_last_30_days'
									: 'audiences_report_tab_visitors_in',
								content: (
									<AreaChart
										data={getAscendingFormattedChartData(
											chartData,
											30,
											'date',
											'visitors',
											'#FF9A44',
											'<b>Visitors In</b>' +
												'<br><b>%{x}</b><br>' +
												'<b>%{y}</b><br>' +
												'<extra></extra>'
										)}
										layout={layout([
											start_time.toISOString(),
											end_time.toISOString(),
										])}
										style={{ flexGrow: 1, height: '100%' }}
									/>
								),
								tabUrlName: '',
							},
							{
								label: isLast30Days
									? t('audiences_report_tab_actions_triggered_in_last_30_days')
									: t('audiences_report_tab_actions_triggered'),
								translationKey: isLast30Days
									? 'audiences_report_tab_actions_triggered_in_last_30_days'
									: 'audiences_report_tab_actions_triggered',
								content: (
									<AreaChart
										data={getAscendingFormattedChartData(
											chartData,
											30,
											'date',
											'actions',
											'#FF9A44',
											'<b>Actions Triggered</b>' +
												'<br><b>%{x}</b><br>' +
												'<b>%{y}</b><br>' +
												'<extra></extra>'
										)}
										layout={layout([
											start_time.toISOString(),
											end_time.toISOString(),
										])}
										style={{ flexGrow: 1, height: '100%' }}
									/>
								),
								tabUrlName: '',
							},
						]}
						page="visitors"
					/>
				)}
				{/**if no api data or empty array */}
				{!loading && !chartData?.length && (
					<div className={styles.noData}>No Data Found</div>
				)}
			</div>
		);
	}

	return null;
}
