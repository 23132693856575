import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Divider from '@material-ui/core/Divider';
import { Loader } from 'app/components/Loader';
import { Header } from '../../components/Header';
import { NotActivated } from '../../components/NotActivated';
import { ButtonSection } from '../../components/ButtonSection';
import { AuthorizationForm } from './AuthorizationForm';
import { Providers } from 'API';
import { HubSpotIcon } from './HubSpotIcon';
import {
	selectFetchingData,
	selectIntegrationByProvider,
} from '../../selectors';
import { actions } from '../../slice';
import styles from '../../style.module.scss';
import { IntegrationProps } from '../../types';

export const HubSpot = ({
	group,
	provider,
	organizationId,
	goToIntegration,
	baseUrl,
}: IntegrationProps) => {
	const dispatch = useDispatch();
	const hubspot = useSelector(selectIntegrationByProvider(Providers.hubspot));
	const loading = useSelector(selectFetchingData);

	const initialIntegrationObj = {
		integrationId: hubspot?.id,
		integration: hubspot?.id
			? null
			: {
					id: hubspot?.id,
					name: 'HubSpot',
					group,
					organizationId,
					source: true,
					provider,
			  },
		group,
		provider,
		attributes: null,
		lists: null,
		configuration: null,
		credentials: null,
	};

	const handleActiveState = (active: boolean) => {
		dispatch(
			actions.modifyIntegration(
				hubspot?.id
					? {
							integrationId: hubspot?.id,
							provider,
							configuration: {
								...hubspot?.configuration,
								active,
							},
					  }
					: {
							...initialIntegrationObj,
							configuration: { active },
					  }
			)
		);
	};

	if (loading) {
		return (
			<div className={styles.wrapper}>
				<Loader />
			</div>
		);
	}
	return (
		<div className={styles.wrapper}>
			<Header
				label="integrations_hubspot_title"
				changehandler={handleActiveState}
				value={hubspot?.configuration?.active ?? false}
			/>
			<Divider />
			{hubspot?.configuration?.active ? (
				<div className={`${styles.padding} ${styles.activeIntContentWrapper}`}>
					<AuthorizationForm provider={provider} baseUrl={baseUrl} />
				</div>
			) : (
				<NotActivated name="HubSpot source" Icon={<HubSpotIcon />} />
			)}
			<div className={styles.bottomSec}>
				<Divider />
				<ButtonSection
					disabled={!hubspot?.configuration?.active}
					onSave={goToIntegration}
					onCancel={goToIntegration}
				/>
			</div>
		</div>
	);
};
