import moment from 'moment';

interface APITime {
	startTime: any;
	endTime: any;
}
export function fetchResponse(url) {
	return fetch(url).then(response => response.json());
}

export const last7Days = (): APITime => {
	const startTime = moment().subtract(6, 'd').format('YYYY-MM-DD');
	const endTime = moment().format('YYYY-MM-DD');
	return { startTime, endTime };
};
