import { createSelector } from '@reduxjs/toolkit';

import { GoalType } from 'API';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.goalContainer || initialState;

export const selectLoading = createSelector(
	[selectDomain],
	goalContainerState => goalContainerState.loading
);

export const selectedGoal = createSelector(
	[selectDomain],
	goalContainerState => goalContainerState.selectedGoal
);

export const selectGoalByType = (t: GoalType | null) =>
	createSelector([selectDomain], goalContainerState =>
		goalContainerState.goals?.find(g => g.type === t)
	);

export const selectGoals = createSelector(
	[selectDomain],
	goalContainerState => goalContainerState.goals
);

export const selectPage = createSelector([selectDomain], goalContainerState =>
	goalContainerState.goals?.slice(
		goalContainerState.indexRange[0],
		goalContainerState.indexRange[1] + 1
	)
);

export const selectSearchGoalsLoader = createSelector(
	[selectDomain],
	goalContainerState => goalContainerState.searchGoalsLoader
);

export const selectFetchGoalLoading = createSelector(
	[selectDomain],
	goalContainerState => goalContainerState.goalLoading
);

export const selectNextToken = createSelector(
	[selectDomain],
	goalContainerState => goalContainerState.nextToken
);

export const selectIndexRange = createSelector(
	[selectDomain],
	goalContainerState => goalContainerState.indexRange
);
