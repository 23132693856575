import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
	Typography as MuiTypography,
	Divider,
	Button,
} from '@material-ui/core';
import { actions } from '../slice';
import { selectTriggerByID, selectModalLoading } from '../selectors';
import { Conditions } from 'app/components/Conditions';
import { ParentObject } from 'app/components/Conditions/types';
import { Loader } from 'app/components/Loader';
import styles from './style.module.scss';

export const ViewTrigger = () => {
	const match = useRouteMatch();
	const [t, i18n] = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { params } = match;
	const { id } = params as any;
	const selected = useSelector(selectTriggerByID(id));
	const loading = useSelector(selectModalLoading);

	const handleEditAudience = () => {
		history.push(`edit/${id}`);
	};

	//Get current Trigger Data
	useEffect(() => {
		dispatch(
			actions.getTrigger({
				id: id,
			})
		);
	}, [dispatch, id]);

	if (loading) {
		return <Loader />;
	}

	return (
		<div className={styles.viewWrapper}>
			<div className={styles.wrapper}>
				<div className={styles.headWrapper}>
					<MuiTypography variant="h3" className={styles.header}>
						{selected?.name}
					</MuiTypography>
					<Button
						data-translation-key="triggers_edit_trigger"
						type="button"
						onClick={handleEditAudience}
					>
						{t('triggers_edit_trigger')}
					</Button>
				</div>
				<MuiTypography variant="body2">{selected?.description}</MuiTypography>
			</div>
			<Divider />
			<div className={styles.wrapper}>
				<MuiTypography variant="h3" className={styles.header}>
					Conditions
				</MuiTypography>
				<DndProvider backend={HTML5Backend}>
					<Conditions
						parentObject={ParentObject.Trigger}
						conditions={selected?.conditions || null}
					/>
				</DndProvider>
			</div>
			<Divider />
		</div>
	);
};
