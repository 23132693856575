/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const findMembers = /* GraphQL */ `
  query FindMembers($member: memberInput!) {
    findMembers(member: $member) {
      Username
      Attributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      name
      description
      group
      organizationId
      destinationId
      configuration {
        provider
        ... on UniversalAnalyticsDestination {
          dimensionId
          category
          action
          label
        }
      }
      isArchived
      isActive
      firedLast30d
      createdAt
      trigger
      updatedAt
      triggers {
        items {
          id
          group
          actionId
          triggerId
          createdAt
          updatedAt
          action {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          trigger {
            id
            name
            description
            group
            organizationId
            conditions
            isArchived
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      destination {
        id
        name
        group
        organizationId
        credentials {
          createdAt
          updatedAt
          ... on MarketoCreds {
            apiHost
            clientId
            clientSecret
          }
          ... on SalesforceCreds {
            access_token
            refresh_token
            signature
            scope
            id_token
            instance_url
            id
            token_type
            issued_at
          }
          ... on SixSenseCreds {
            apiToken
          }
          ... on HubSpotCreds {
            accessToken
            refreshToken
            tokenType
            expiresIn
          }
        }
        configuration {
          active
          createdAt
          updatedAt
          ... on MarketoConfig {
            hiddenField
          }
          ... on SalesforceConfig {
            userId
          }
          ... on GoogleTagManagerConfig {
            dataLayerVarName
          }
          ... on UniversalAnalyticsConfig {
            uaTrackingId
          }
          ... on UniversalAnalyticsSourceConfig {
            uaTrackingId
          }
        }
        provider
        source
        createdAt
        updatedAt
        lists {
          items {
            id
            externalId
            group
            provider
            integrationId
            name
            description
            smart
            createdAt
            updatedAt
          }
          nextToken
        }
        attributes {
          items {
            id
            group
            provider
            integrationId
            fetch
            push
            name
            displayName
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        group
        organizationId
        destinationId
        configuration {
          provider
          ... on UniversalAnalyticsDestination {
            dimensionId
            category
            action
            label
          }
        }
        isArchived
        isActive
        firedLast30d
        createdAt
        trigger
        updatedAt
        triggers {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        destination {
          id
          name
          group
          organizationId
          credentials {
            createdAt
            updatedAt
            ... on MarketoCreds {
              apiHost
              clientId
              clientSecret
            }
            ... on SalesforceCreds {
              access_token
              refresh_token
              signature
              scope
              id_token
              instance_url
              id
              token_type
              issued_at
            }
            ... on SixSenseCreds {
              apiToken
            }
            ... on HubSpotCreds {
              accessToken
              refreshToken
              tokenType
              expiresIn
            }
          }
          configuration {
            active
            createdAt
            updatedAt
            ... on MarketoConfig {
              hiddenField
            }
            ... on SalesforceConfig {
              userId
            }
            ... on GoogleTagManagerConfig {
              dataLayerVarName
            }
            ... on UniversalAnalyticsConfig {
              uaTrackingId
            }
            ... on UniversalAnalyticsSourceConfig {
              uaTrackingId
            }
          }
          provider
          source
          createdAt
          updatedAt
          lists {
            nextToken
          }
          attributes {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const actionsByOrganization = /* GraphQL */ `
  query ActionsByOrganization(
    $organizationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    actionsByOrganization(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        group
        organizationId
        destinationId
        configuration {
          provider
          ... on UniversalAnalyticsDestination {
            dimensionId
            category
            action
            label
          }
        }
        isArchived
        isActive
        firedLast30d
        createdAt
        trigger
        updatedAt
        triggers {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        destination {
          id
          name
          group
          organizationId
          credentials {
            createdAt
            updatedAt
            ... on MarketoCreds {
              apiHost
              clientId
              clientSecret
            }
            ... on SalesforceCreds {
              access_token
              refresh_token
              signature
              scope
              id_token
              instance_url
              id
              token_type
              issued_at
            }
            ... on SixSenseCreds {
              apiToken
            }
            ... on HubSpotCreds {
              accessToken
              refreshToken
              tokenType
              expiresIn
            }
          }
          configuration {
            active
            createdAt
            updatedAt
            ... on MarketoConfig {
              hiddenField
            }
            ... on SalesforceConfig {
              userId
            }
            ... on GoogleTagManagerConfig {
              dataLayerVarName
            }
            ... on UniversalAnalyticsConfig {
              uaTrackingId
            }
            ... on UniversalAnalyticsSourceConfig {
              uaTrackingId
            }
          }
          provider
          source
          createdAt
          updatedAt
          lists {
            nextToken
          }
          attributes {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getActionTrigger = /* GraphQL */ `
  query GetActionTrigger($id: ID!) {
    getActionTrigger(id: $id) {
      id
      group
      actionId
      triggerId
      createdAt
      updatedAt
      action {
        id
        name
        description
        group
        organizationId
        destinationId
        configuration {
          provider
          ... on UniversalAnalyticsDestination {
            dimensionId
            category
            action
            label
          }
        }
        isArchived
        isActive
        firedLast30d
        createdAt
        trigger
        updatedAt
        triggers {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        destination {
          id
          name
          group
          organizationId
          credentials {
            createdAt
            updatedAt
            ... on MarketoCreds {
              apiHost
              clientId
              clientSecret
            }
            ... on SalesforceCreds {
              access_token
              refresh_token
              signature
              scope
              id_token
              instance_url
              id
              token_type
              issued_at
            }
            ... on SixSenseCreds {
              apiToken
            }
            ... on HubSpotCreds {
              accessToken
              refreshToken
              tokenType
              expiresIn
            }
          }
          configuration {
            active
            createdAt
            updatedAt
            ... on MarketoConfig {
              hiddenField
            }
            ... on SalesforceConfig {
              userId
            }
            ... on GoogleTagManagerConfig {
              dataLayerVarName
            }
            ... on UniversalAnalyticsConfig {
              uaTrackingId
            }
            ... on UniversalAnalyticsSourceConfig {
              uaTrackingId
            }
          }
          provider
          source
          createdAt
          updatedAt
          lists {
            nextToken
          }
          attributes {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      trigger {
        id
        name
        description
        group
        organizationId
        conditions
        isArchived
        createdAt
        updatedAt
        actions {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
    }
  }
`;
export const listActionTriggers = /* GraphQL */ `
  query ListActionTriggers(
    $filter: ModelActionTriggerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActionTriggers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group
        actionId
        triggerId
        createdAt
        updatedAt
        action {
          id
          name
          description
          group
          organizationId
          destinationId
          configuration {
            provider
            ... on UniversalAnalyticsDestination {
              dimensionId
              category
              action
              label
            }
          }
          isArchived
          isActive
          firedLast30d
          createdAt
          trigger
          updatedAt
          triggers {
            nextToken
          }
          destination {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        trigger {
          id
          name
          description
          group
          organizationId
          conditions
          isArchived
          createdAt
          updatedAt
          actions {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
`;
export const getTrigger = /* GraphQL */ `
  query GetTrigger($id: ID!) {
    getTrigger(id: $id) {
      id
      name
      description
      group
      organizationId
      conditions
      isArchived
      createdAt
      updatedAt
      actions {
        items {
          id
          group
          actionId
          triggerId
          createdAt
          updatedAt
          action {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          trigger {
            id
            name
            description
            group
            organizationId
            conditions
            isArchived
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listTriggers = /* GraphQL */ `
  query ListTriggers(
    $filter: ModelTriggerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTriggers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        group
        organizationId
        conditions
        isArchived
        createdAt
        updatedAt
        actions {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const triggersByOrganization = /* GraphQL */ `
  query TriggersByOrganization(
    $organizationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTriggerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    triggersByOrganization(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        group
        organizationId
        conditions
        isArchived
        createdAt
        updatedAt
        actions {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getAudience = /* GraphQL */ `
  query GetAudience($id: ID!) {
    getAudience(id: $id) {
      id
      name
      description
      group
      organizationId
      audienceType
      conditions
      visitors
      conversions
      individual
      accounts
      actionsCount
      isArchived
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listAudiences = /* GraphQL */ `
  query ListAudiences(
    $filter: ModelAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        group
        organizationId
        audienceType
        conditions
        visitors
        conversions
        individual
        accounts
        actionsCount
        isArchived
        createdAt
        updatedAt
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const audiencesByOrganization = /* GraphQL */ `
  query AudiencesByOrganization(
    $organizationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    audiencesByOrganization(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        group
        organizationId
        audienceType
        conditions
        visitors
        conversions
        individual
        accounts
        actionsCount
        isArchived
        createdAt
        updatedAt
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getGoal = /* GraphQL */ `
  query GetGoal($id: ID!) {
    getGoal(id: $id) {
      id
      name
      description
      group
      organizationId
      eventId
      trackEvent
      goalFrequency
      includeInRevenue
      calculateGoalValueAuto
      aggregateGoalValue
      defaultGoalValue
      triggeringType
      includeUrls {
        urls
        matchType
      }
      excludeUrls {
        urls
        matchType
      }
      isActive
      isArchived
      actionsCount
      type
      typeState
      attributes {
        apiname
        createdbyid
        defaultprobability
        description
        forecastcategory
        forecastcategoryname
        isactive
        isclosed
        iswon
        lastmodifiedbyid
        lastmodifieddate
        masterlabel
        sortorder
        systemmodstamp
        createddate
        id
      }
      conversionsLast30d
      createdAt
      updatedAt
      event {
        id
        name
        source
        group
        organizationId
        attributes {
          name
          matchType
          value
        }
        createdAt
        updatedAt
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listGoals = /* GraphQL */ `
  query ListGoals(
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        group
        organizationId
        eventId
        trackEvent
        goalFrequency
        includeInRevenue
        calculateGoalValueAuto
        aggregateGoalValue
        defaultGoalValue
        triggeringType
        includeUrls {
          urls
          matchType
        }
        excludeUrls {
          urls
          matchType
        }
        isActive
        isArchived
        actionsCount
        type
        typeState
        attributes {
          apiname
          createdbyid
          defaultprobability
          description
          forecastcategory
          forecastcategoryname
          isactive
          isclosed
          iswon
          lastmodifiedbyid
          lastmodifieddate
          masterlabel
          sortorder
          systemmodstamp
          createddate
          id
        }
        conversionsLast30d
        createdAt
        updatedAt
        event {
          id
          name
          source
          group
          organizationId
          attributes {
            name
            matchType
            value
          }
          createdAt
          updatedAt
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const goalsByOrganization = /* GraphQL */ `
  query GoalsByOrganization(
    $organizationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGoalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    goalsByOrganization(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        group
        organizationId
        eventId
        trackEvent
        goalFrequency
        includeInRevenue
        calculateGoalValueAuto
        aggregateGoalValue
        defaultGoalValue
        triggeringType
        includeUrls {
          urls
          matchType
        }
        excludeUrls {
          urls
          matchType
        }
        isActive
        isArchived
        actionsCount
        type
        typeState
        attributes {
          apiname
          createdbyid
          defaultprobability
          description
          forecastcategory
          forecastcategoryname
          isactive
          isclosed
          iswon
          lastmodifiedbyid
          lastmodifieddate
          masterlabel
          sortorder
          systemmodstamp
          createddate
          id
        }
        conversionsLast30d
        createdAt
        updatedAt
        event {
          id
          name
          source
          group
          organizationId
          attributes {
            name
            matchType
            value
          }
          createdAt
          updatedAt
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      name
      source
      group
      organizationId
      attributes {
        name
        matchType
        value
      }
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        source
        group
        organizationId
        attributes {
          name
          matchType
          value
        }
        createdAt
        updatedAt
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getPage = /* GraphQL */ `
  query GetPage($id: ID!) {
    getPage(id: $id) {
      id
      name
      group
      organizationId
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listPages = /* GraphQL */ `
  query ListPages(
    $filter: ModelPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        group
        organizationId
        createdAt
        updatedAt
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getIntegrationList = /* GraphQL */ `
  query GetIntegrationList($id: ID!) {
    getIntegrationList(id: $id) {
      id
      externalId
      group
      provider
      integrationId
      name
      description
      smart
      createdAt
      updatedAt
    }
  }
`;
export const listIntegrationLists = /* GraphQL */ `
  query ListIntegrationLists(
    $filter: ModelIntegrationListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        externalId
        group
        provider
        integrationId
        name
        description
        smart
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIntegration = /* GraphQL */ `
  query GetIntegration($id: ID!) {
    getIntegration(id: $id) {
      id
      name
      group
      organizationId
      credentials {
        createdAt
        updatedAt
        ... on MarketoCreds {
          apiHost
          clientId
          clientSecret
        }
        ... on SalesforceCreds {
          access_token
          refresh_token
          signature
          scope
          id_token
          instance_url
          id
          token_type
          issued_at
        }
        ... on SixSenseCreds {
          apiToken
        }
        ... on HubSpotCreds {
          accessToken
          refreshToken
          tokenType
          expiresIn
        }
      }
      configuration {
        active
        createdAt
        updatedAt
        ... on MarketoConfig {
          hiddenField
        }
        ... on SalesforceConfig {
          userId
        }
        ... on GoogleTagManagerConfig {
          dataLayerVarName
        }
        ... on UniversalAnalyticsConfig {
          uaTrackingId
        }
        ... on UniversalAnalyticsSourceConfig {
          uaTrackingId
        }
      }
      provider
      source
      createdAt
      updatedAt
      lists {
        items {
          id
          externalId
          group
          provider
          integrationId
          name
          description
          smart
          createdAt
          updatedAt
        }
        nextToken
      }
      attributes {
        items {
          id
          group
          provider
          integrationId
          fetch
          push
          name
          displayName
          createdAt
          updatedAt
        }
        nextToken
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listIntegrations = /* GraphQL */ `
  query ListIntegrations(
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        group
        organizationId
        credentials {
          createdAt
          updatedAt
          ... on MarketoCreds {
            apiHost
            clientId
            clientSecret
          }
          ... on SalesforceCreds {
            access_token
            refresh_token
            signature
            scope
            id_token
            instance_url
            id
            token_type
            issued_at
          }
          ... on SixSenseCreds {
            apiToken
          }
          ... on HubSpotCreds {
            accessToken
            refreshToken
            tokenType
            expiresIn
          }
        }
        configuration {
          active
          createdAt
          updatedAt
          ... on MarketoConfig {
            hiddenField
          }
          ... on SalesforceConfig {
            userId
          }
          ... on GoogleTagManagerConfig {
            dataLayerVarName
          }
          ... on UniversalAnalyticsConfig {
            uaTrackingId
          }
          ... on UniversalAnalyticsSourceConfig {
            uaTrackingId
          }
        }
        provider
        source
        createdAt
        updatedAt
        lists {
          items {
            id
            externalId
            group
            provider
            integrationId
            name
            description
            smart
            createdAt
            updatedAt
          }
          nextToken
        }
        attributes {
          items {
            id
            group
            provider
            integrationId
            fetch
            push
            name
            displayName
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const integrationsByOrganization = /* GraphQL */ `
  query IntegrationsByOrganization(
    $organizationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationsByOrganization(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        group
        organizationId
        credentials {
          createdAt
          updatedAt
          ... on MarketoCreds {
            apiHost
            clientId
            clientSecret
          }
          ... on SalesforceCreds {
            access_token
            refresh_token
            signature
            scope
            id_token
            instance_url
            id
            token_type
            issued_at
          }
          ... on SixSenseCreds {
            apiToken
          }
          ... on HubSpotCreds {
            accessToken
            refreshToken
            tokenType
            expiresIn
          }
        }
        configuration {
          active
          createdAt
          updatedAt
          ... on MarketoConfig {
            hiddenField
          }
          ... on SalesforceConfig {
            userId
          }
          ... on GoogleTagManagerConfig {
            dataLayerVarName
          }
          ... on UniversalAnalyticsConfig {
            uaTrackingId
          }
          ... on UniversalAnalyticsSourceConfig {
            uaTrackingId
          }
        }
        provider
        source
        createdAt
        updatedAt
        lists {
          items {
            id
            externalId
            group
            provider
            integrationId
            name
            description
            smart
            createdAt
            updatedAt
          }
          nextToken
        }
        attributes {
          items {
            id
            group
            provider
            integrationId
            fetch
            push
            name
            displayName
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      subjectId
      subjectType
      status
      title
      message
      group
      organizationId
      dismissedAt
      dismissedBy
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subjectId
        subjectType
        status
        title
        message
        group
        organizationId
        dismissedAt
        dismissedBy
        createdAt
        updatedAt
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const notificationsByOrganization = /* GraphQL */ `
  query NotificationsByOrganization(
    $organizationId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByOrganization(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subjectId
        subjectType
        status
        title
        message
        group
        organizationId
        dismissedAt
        dismissedBy
        createdAt
        updatedAt
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      group
      orgOwner
      installationType
      integrationCode
      antiFlickerCode
      asynchronousSnippet
      anonymize
      enableNonConsent
      disabled
      signupType
      trialExpiration
      invoiced
      flashHide
      activateWebsiteAttribution
      createdAt
      updatedAt
      actions {
        items {
          id
          name
          description
          group
          organizationId
          destinationId
          configuration {
            provider
            ... on UniversalAnalyticsDestination {
              dimensionId
              category
              action
              label
            }
          }
          isArchived
          isActive
          firedLast30d
          createdAt
          trigger
          updatedAt
          triggers {
            nextToken
          }
          destination {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      audiences {
        items {
          id
          name
          description
          group
          organizationId
          audienceType
          conditions
          visitors
          conversions
          individual
          accounts
          actionsCount
          isArchived
          createdAt
          updatedAt
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      goals {
        items {
          id
          name
          description
          group
          organizationId
          eventId
          trackEvent
          goalFrequency
          includeInRevenue
          calculateGoalValueAuto
          aggregateGoalValue
          defaultGoalValue
          triggeringType
          includeUrls {
            urls
            matchType
          }
          excludeUrls {
            urls
            matchType
          }
          isActive
          isArchived
          actionsCount
          type
          typeState
          attributes {
            apiname
            createdbyid
            defaultprobability
            description
            forecastcategory
            forecastcategoryname
            isactive
            isclosed
            iswon
            lastmodifiedbyid
            lastmodifieddate
            masterlabel
            sortorder
            systemmodstamp
            createddate
            id
          }
          conversionsLast30d
          createdAt
          updatedAt
          event {
            id
            name
            source
            group
            organizationId
            createdAt
            updatedAt
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      integrations {
        items {
          id
          name
          group
          organizationId
          credentials {
            createdAt
            updatedAt
            ... on MarketoCreds {
              apiHost
              clientId
              clientSecret
            }
            ... on SalesforceCreds {
              access_token
              refresh_token
              signature
              scope
              id_token
              instance_url
              id
              token_type
              issued_at
            }
            ... on SixSenseCreds {
              apiToken
            }
            ... on HubSpotCreds {
              accessToken
              refreshToken
              tokenType
              expiresIn
            }
          }
          configuration {
            active
            createdAt
            updatedAt
            ... on MarketoConfig {
              hiddenField
            }
            ... on SalesforceConfig {
              userId
            }
            ... on GoogleTagManagerConfig {
              dataLayerVarName
            }
            ... on UniversalAnalyticsConfig {
              uaTrackingId
            }
            ... on UniversalAnalyticsSourceConfig {
              uaTrackingId
            }
          }
          provider
          source
          createdAt
          updatedAt
          lists {
            nextToken
          }
          attributes {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $userEmail: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      userEmail: $userEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEmail
        name
        userId
        orgId
        group
        timeStamp
        createdAt
        updatedAt
        stripeCustomerId
      }
      nextToken
    }
  }
`;
export const usersByStripeId = /* GraphQL */ `
  query UsersByStripeId(
    $stripeCustomerId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByStripeId(
      stripeCustomerId: $stripeCustomerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEmail
        name
        userId
        orgId
        group
        timeStamp
        createdAt
        updatedAt
        stripeCustomerId
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userEmail
      name
      userId
      orgId
      group
      timeStamp
      createdAt
      updatedAt
      stripeCustomerId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userEmail
        name
        userId
        orgId
        group
        timeStamp
        createdAt
        updatedAt
        stripeCustomerId
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      stripeId
      amount
      receivedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripeId
        amount
        receivedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
