import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, User } from './types';

// The initial state of the AuthContainer container
export const initialState: ContainerState = {
	error: undefined,
	userError: undefined,
	cognitoUser: undefined,
	tempUser: {
		username: '',
		password: '',
	},
	loggedIn: false,
	checkingAuth: true,
	fetchingData: false,
	featureFlags: {
		actions: false,
		feApps: false,
		feAppsS3Uploader: false,
		feAppsHpeS3Uploader: false,
		triggers: false,
		marketoMilestone: false,
		marketoAdvancedSettings: false,
		testOrg: false,
		goalsUi: false,
		customIntegration: false,
		universalAnalyticsSourceIntegration: false,
		salesforceIntegration: false,
		demandbaseIntegration: false,
		clearbitIntegration: false,
		hubspotIntegration: false,
		sixSenseIntegration: false,
		accountMatchIntegration: false,
		googleOptimizeIntegration: true,
		audienceCondition: false,
		websiteAttribution: false,
	},
};

const authContainerSlice = createSlice({
	name: 'authContainer',
	initialState,
	reducers: {
		signUp(state, _action) {
			state.fetchingData = true;
			state.error = undefined;
		},
		signUpSuccess(state) {
			state.fetchingData = false;
			state.error = undefined;
		},
		signUpConfirm(state, _action) {
			state.fetchingData = true;
			state.error = undefined;
		},
		confirmSignIn(state, _action) {
			state.fetchingData = true;
			state.error = undefined;
		},
		signUpConfirmSuccess(state) {
			state.fetchingData = false;
			state.error = undefined;
		},
		login(state, _action) {
			state.fetchingData = true;
			state.error = undefined;
		},
		setNewPassword(state) {
			state.fetchingData = true;
			state.error = undefined;
		},
		forgotPassword(state, _action) {
			state.error = undefined;
		},
		forgotPasswordSubmit(state, _action) {
			state.fetchingData = true;
			state.error = undefined;
		},
		forgotPasswordSubmitSuccess(state) {
			state.fetchingData = false;
			state.error = undefined;
		},
		changePassword(state, _action) {
			state.fetchingData = true;
			state.error = undefined;
		},
		changePasswordSuccess(state, _action) {
			state.fetchingData = false;
		},
		signUpResendCode(state, _action) {
			state.fetchingData = true;
			state.error = undefined;
		},
		signUpResendCodeSucess(state, _action) {
			state.fetchingData = false;
			state.error = undefined;
		},
		logout() {},
		logoutSuccess(state) {
			state.cognitoUser = undefined;
		},
		loginSuccess(state) {
			state.fetchingData = false;
			state.loggedIn = true;
			state.error = undefined;
		},
		setCognitoUser(state, action: PayloadAction<User>) {
			state.cognitoUser = action.payload;
		},
		updateUser(state, action) {
			state.fetchingData = true;
		},
		updateUserSuccess(state, action) {
			state.fetchingData = false;
		},
		setUserPoolId(state, action: PayloadAction<string>) {
			state.userPoolId = action.payload;
		},
		setTempUser(state, action: PayloadAction<any>) {
			state.tempUser = action.payload;
			state.fetchingData = false;
		},
		completeNewPassword(state, action: PayloadAction<any>) {},
		fetchCognitoUser(state) {
			state.fetchingData = true;
			state.checkingAuth = true;
		},
		fetchCognitoUserSuccess(state) {
			state.fetchingData = false;
			state.checkingAuth = false;
		},
		setAuthError(state, action: PayloadAction<string>) {
			state.fetchingData = false;
			state.loggedIn = false;
			state.checkingAuth = false;
			state.error = action.payload;
		},
		clearAuthState(state) {
			state.fetchingData = false;
		},
		AuthenticatedUser(state) {
			state.fetchingData = false;
			state.loggedIn = true;
		},
		setUserError(state, action: PayloadAction<string>) {
			state.fetchingData = false;
			state.checkingAuth = false;
			state.userError = action.payload;
		},
		setFeatureFlag(state, action) {
			state.featureFlags = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.fetchingData = action.payload;
		},
	},
});

export const { actions, reducer, name: sliceKey } = authContainerSlice;
