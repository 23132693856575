import React from 'react';

export function CheckedIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g opacity="0.9">
				<path
					d="M7 10.707L4.5 8.2065L5.2065 7.5L7 9.293L10.7925 5.5L11.5 6.2075L7 10.707Z"
					fill="#79CD04"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M1 8C1 4.13401 4.13401 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM2 8C2 11.3137 4.68629 14 8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8Z"
					fill="#79CD04"
				/>
			</g>
		</svg>
	);
}
