import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import RichTextConverter from 'app/components/RichTextConverter';
import { Typography as MuiTypography } from '@material-ui/core';
import styles from '../style.module.scss';

interface Props {
	disabled: boolean;
	finalStep?: boolean;
}

const ButtonFooter = ({ disabled, finalStep = false }: Props) => {
	const history = useHistory();
	const [t, i18n] = useTranslation();
	return (
		<div className={`${styles.bottomSec}`}>
			<MuiTypography variant="body2">
				{t('setup_wizard_common_more')}{' '}
				<RichTextConverter
					html={t('globals_knowledge_base')}
					translationKey={'globals_knowledge_base'}
				/>
			</MuiTypography>
			<div className={styles.navigationWrapper}>
				<Button
					type="button"
					variant="contained"
					color="secondary"
					fullWidth
					onClick={() => history.goBack()}
				>
					{t('globals_cancel')}
				</Button>
				<Button type="submit" variant="contained" disabled={disabled} fullWidth>
					{finalStep ? 'Save' : t('globals_next_step')}
				</Button>
			</div>
		</div>
	);
};

export default ButtonFooter;
