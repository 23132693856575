/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const dismissNotification = /* GraphQL */ `
  mutation DismissNotification($id: ID!) {
    dismissNotification(id: $id)
  }
`;
export const editMarketoCredentials = /* GraphQL */ `
  mutation EditMarketoCredentials(
    $integrationId: ID!
    $input: MarketoCredsInput!
  ) {
    editMarketoCredentials(integrationId: $integrationId, input: $input) {
      apiHost
      clientId
      clientSecret
      createdAt
      updatedAt
    }
  }
`;
export const editSalesforceCredentials = /* GraphQL */ `
  mutation EditSalesforceCredentials(
    $integrationId: ID!
    $input: SalesforceCredsInput!
  ) {
    editSalesforceCredentials(integrationId: $integrationId, input: $input) {
      access_token
      refresh_token
      signature
      scope
      id_token
      instance_url
      id
      token_type
      issued_at
      createdAt
      updatedAt
    }
  }
`;
export const editSixSenseCredentials = /* GraphQL */ `
  mutation EditSixSenseCredentials(
    $integrationId: ID!
    $input: SixSenseCredsInput!
  ) {
    editSixSenseCredentials(integrationId: $integrationId, input: $input) {
      apiToken
      createdAt
      updatedAt
    }
  }
`;
export const editHubSpotCredentials = /* GraphQL */ `
  mutation EditHubSpotCredentials(
    $integrationId: ID!
    $input: HubSpotCredsInput!
  ) {
    editHubSpotCredentials(integrationId: $integrationId, input: $input) {
      accessToken
      refreshToken
      tokenType
      expiresIn
      createdAt
      updatedAt
    }
  }
`;
export const editCustomConfiguration = /* GraphQL */ `
  mutation EditCustomConfiguration(
    $integrationId: ID!
    $input: CustomConfigInput!
  ) {
    editCustomConfiguration(integrationId: $integrationId, input: $input) {
      createdAt
      updatedAt
      active
    }
  }
`;
export const editMarketoConfiguration = /* GraphQL */ `
  mutation EditMarketoConfiguration(
    $integrationId: ID!
    $input: MarketoConfigInput!
  ) {
    editMarketoConfiguration(integrationId: $integrationId, input: $input) {
      createdAt
      updatedAt
      active
      hiddenField
    }
  }
`;
export const editSalesforceConfiguration = /* GraphQL */ `
  mutation EditSalesforceConfiguration(
    $integrationId: ID!
    $input: SalesforceConfigInput!
  ) {
    editSalesforceConfiguration(integrationId: $integrationId, input: $input) {
      createdAt
      updatedAt
      active
      userId
    }
  }
`;
export const editGoogleTagManagerConfiguration = /* GraphQL */ `
  mutation EditGoogleTagManagerConfiguration(
    $integrationId: ID!
    $input: GoogleTagManagerConfigInput!
  ) {
    editGoogleTagManagerConfiguration(
      integrationId: $integrationId
      input: $input
    ) {
      active
      createdAt
      updatedAt
      dataLayerVarName
    }
  }
`;
export const editUniversalAnalyticsConfiguration = /* GraphQL */ `
  mutation EditUniversalAnalyticsConfiguration(
    $integrationId: ID!
    $input: UniversalAnalyticsConfigInput!
  ) {
    editUniversalAnalyticsConfiguration(
      integrationId: $integrationId
      input: $input
    ) {
      active
      createdAt
      updatedAt
      uaTrackingId
    }
  }
`;
export const editUniversalAnalyticsSourceConfiguration = /* GraphQL */ `
  mutation EditUniversalAnalyticsSourceConfiguration(
    $integrationId: ID!
    $input: UniversalAnalyticsSourceConfigInput!
  ) {
    editUniversalAnalyticsSourceConfiguration(
      integrationId: $integrationId
      input: $input
    ) {
      active
      createdAt
      updatedAt
      uaTrackingId
    }
  }
`;
export const editHubSpotConfiguration = /* GraphQL */ `
  mutation EditHubSpotConfiguration(
    $integrationId: ID!
    $input: HubSpotConfigInput!
  ) {
    editHubSpotConfiguration(integrationId: $integrationId, input: $input) {
      active
      createdAt
      updatedAt
    }
  }
`;
export const editClearbitConfiguration = /* GraphQL */ `
  mutation EditClearbitConfiguration(
    $integrationId: ID!
    $input: ClearbitConfigInput!
  ) {
    editClearbitConfiguration(integrationId: $integrationId, input: $input) {
      active
      createdAt
      updatedAt
    }
  }
`;
export const editDemandbaseConfiguration = /* GraphQL */ `
  mutation EditDemandbaseConfiguration(
    $integrationId: ID!
    $input: DemandbaseConfigInput!
  ) {
    editDemandbaseConfiguration(integrationId: $integrationId, input: $input) {
      active
      createdAt
      updatedAt
    }
  }
`;
export const editSixSenseConfiguration = /* GraphQL */ `
  mutation EditSixSenseConfiguration(
    $integrationId: ID!
    $input: SixSenseConfigInput!
  ) {
    editSixSenseConfiguration(integrationId: $integrationId, input: $input) {
      active
      createdAt
      updatedAt
    }
  }
`;
export const editAccountMatchConfiguration = /* GraphQL */ `
  mutation EditAccountMatchConfiguration(
    $integrationId: ID!
    $input: AccountMatchConfigInput!
  ) {
    editAccountMatchConfiguration(
      integrationId: $integrationId
      input: $input
    ) {
      active
      createdAt
      updatedAt
    }
  }
`;
export const editGoogleOptimizeConfiguration = /* GraphQL */ `
  mutation EditGoogleOptimizeConfiguration(
    $integrationId: ID!
    $input: GoogleOptimizeConfigInput!
  ) {
    editGoogleOptimizeConfiguration(
      integrationId: $integrationId
      input: $input
    ) {
      active
      createdAt
      updatedAt
    }
  }
`;
export const editUniversalAnalyticsDestination = /* GraphQL */ `
  mutation EditUniversalAnalyticsDestination(
    $actionId: ID!
    $input: UniversalAnalyticsDestinationInput!
  ) {
    editUniversalAnalyticsDestination(actionId: $actionId, input: $input) {
      provider
      dimensionId
      category
      action
      label
    }
  }
`;
export const editAudienceCondition = /* GraphQL */ `
  mutation EditAudienceCondition($audienceId: ID!, $input: ConditionInput!) {
    editAudienceCondition(audienceId: $audienceId, input: $input) {
      name
      typeOf
      ... on And {
        operands {
          name
          typeOf
          ... on And {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Or {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Not {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on MarketoLeaf {
            operator
            value
          }
          ... on GoalLeaf {
            operator
            value
          }
          ... on AttributeLeaf {
            operator
            value
            source
            attribute
          }
          ... on TriggerLeaf {
            triggerId
          }
        }
      }
      ... on Or {
        operands {
          name
          typeOf
          ... on And {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Or {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Not {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on MarketoLeaf {
            operator
            value
          }
          ... on GoalLeaf {
            operator
            value
          }
          ... on AttributeLeaf {
            operator
            value
            source
            attribute
          }
          ... on TriggerLeaf {
            triggerId
          }
        }
      }
      ... on Not {
        operands {
          name
          typeOf
          ... on And {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Or {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Not {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on MarketoLeaf {
            operator
            value
          }
          ... on GoalLeaf {
            operator
            value
          }
          ... on AttributeLeaf {
            operator
            value
            source
            attribute
          }
          ... on TriggerLeaf {
            triggerId
          }
        }
      }
      ... on MarketoLeaf {
        operator
        value
      }
      ... on GoalLeaf {
        operator
        value
      }
      ... on AttributeLeaf {
        operator
        value
        source
        attribute
      }
      ... on TriggerLeaf {
        triggerId
      }
    }
  }
`;
export const editTriggerCondition = /* GraphQL */ `
  mutation EditTriggerCondition($triggerId: ID!, $input: ConditionInput!) {
    editTriggerCondition(triggerId: $triggerId, input: $input) {
      name
      typeOf
      ... on And {
        operands {
          name
          typeOf
          ... on And {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Or {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Not {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on MarketoLeaf {
            operator
            value
          }
          ... on GoalLeaf {
            operator
            value
          }
          ... on AttributeLeaf {
            operator
            value
            source
            attribute
          }
          ... on TriggerLeaf {
            triggerId
          }
        }
      }
      ... on Or {
        operands {
          name
          typeOf
          ... on And {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Or {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Not {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on MarketoLeaf {
            operator
            value
          }
          ... on GoalLeaf {
            operator
            value
          }
          ... on AttributeLeaf {
            operator
            value
            source
            attribute
          }
          ... on TriggerLeaf {
            triggerId
          }
        }
      }
      ... on Not {
        operands {
          name
          typeOf
          ... on And {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Or {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on Not {
            operands {
              name
              typeOf
              ... on And {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Or {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on Not {
                operands {
                  name
                  typeOf
                  ... on MarketoLeaf {
                    operator
                    value
                  }
                  ... on GoalLeaf {
                    operator
                    value
                  }
                  ... on AttributeLeaf {
                    operator
                    value
                    source
                    attribute
                  }
                  ... on TriggerLeaf {
                    triggerId
                  }
                }
              }
              ... on MarketoLeaf {
                operator
                value
              }
              ... on GoalLeaf {
                operator
                value
              }
              ... on AttributeLeaf {
                operator
                value
                source
                attribute
              }
              ... on TriggerLeaf {
                triggerId
              }
            }
          }
          ... on MarketoLeaf {
            operator
            value
          }
          ... on GoalLeaf {
            operator
            value
          }
          ... on AttributeLeaf {
            operator
            value
            source
            attribute
          }
          ... on TriggerLeaf {
            triggerId
          }
        }
      }
      ... on MarketoLeaf {
        operator
        value
      }
      ... on GoalLeaf {
        operator
        value
      }
      ... on AttributeLeaf {
        operator
        value
        source
        attribute
      }
      ... on TriggerLeaf {
        triggerId
      }
    }
  }
`;
export const addMember = /* GraphQL */ `
  mutation AddMember($member: memberInput!) {
    addMember(member: $member) {
      Username
      Attributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
    }
  }
`;
export const identifyMember = /* GraphQL */ `
  mutation IdentifyMember($member: memberInput!) {
    identifyMember(member: $member) {
      UserStatus
      Enabled
      UserAttributes {
        Name
        Value
      }
    }
  }
`;
export const resendMemberInvitation = /* GraphQL */ `
  mutation ResendMemberInvitation($member: memberInput!) {
    resendMemberInvitation(member: $member) {
      Username
      Attributes {
        Name
        Value
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
    }
  }
`;
export const removeMember = /* GraphQL */ `
  mutation RemoveMember($member: memberInput!) {
    removeMember(member: $member)
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      destinationId
      configuration {
        provider
        ... on UniversalAnalyticsDestination {
          dimensionId
          category
          action
          label
        }
      }
      isArchived
      isActive
      firedLast30d
      createdAt
      trigger
      updatedAt
      triggers {
        items {
          id
          group
          actionId
          triggerId
          createdAt
          updatedAt
          action {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          trigger {
            id
            name
            description
            group
            organizationId
            conditions
            isArchived
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      destination {
        id
        name
        group
        organizationId
        credentials {
          createdAt
          updatedAt
          ... on MarketoCreds {
            apiHost
            clientId
            clientSecret
          }
          ... on SalesforceCreds {
            access_token
            refresh_token
            signature
            scope
            id_token
            instance_url
            id
            token_type
            issued_at
          }
          ... on SixSenseCreds {
            apiToken
          }
          ... on HubSpotCreds {
            accessToken
            refreshToken
            tokenType
            expiresIn
          }
        }
        configuration {
          active
          createdAt
          updatedAt
          ... on MarketoConfig {
            hiddenField
          }
          ... on SalesforceConfig {
            userId
          }
          ... on GoogleTagManagerConfig {
            dataLayerVarName
          }
          ... on UniversalAnalyticsConfig {
            uaTrackingId
          }
          ... on UniversalAnalyticsSourceConfig {
            uaTrackingId
          }
        }
        provider
        source
        createdAt
        updatedAt
        lists {
          items {
            id
            externalId
            group
            provider
            integrationId
            name
            description
            smart
            createdAt
            updatedAt
          }
          nextToken
        }
        attributes {
          items {
            id
            group
            provider
            integrationId
            fetch
            push
            name
            displayName
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      destinationId
      configuration {
        provider
        ... on UniversalAnalyticsDestination {
          dimensionId
          category
          action
          label
        }
      }
      isArchived
      isActive
      firedLast30d
      createdAt
      trigger
      updatedAt
      triggers {
        items {
          id
          group
          actionId
          triggerId
          createdAt
          updatedAt
          action {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          trigger {
            id
            name
            description
            group
            organizationId
            conditions
            isArchived
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      destination {
        id
        name
        group
        organizationId
        credentials {
          createdAt
          updatedAt
          ... on MarketoCreds {
            apiHost
            clientId
            clientSecret
          }
          ... on SalesforceCreds {
            access_token
            refresh_token
            signature
            scope
            id_token
            instance_url
            id
            token_type
            issued_at
          }
          ... on SixSenseCreds {
            apiToken
          }
          ... on HubSpotCreds {
            accessToken
            refreshToken
            tokenType
            expiresIn
          }
        }
        configuration {
          active
          createdAt
          updatedAt
          ... on MarketoConfig {
            hiddenField
          }
          ... on SalesforceConfig {
            userId
          }
          ... on GoogleTagManagerConfig {
            dataLayerVarName
          }
          ... on UniversalAnalyticsConfig {
            uaTrackingId
          }
          ... on UniversalAnalyticsSourceConfig {
            uaTrackingId
          }
        }
        provider
        source
        createdAt
        updatedAt
        lists {
          items {
            id
            externalId
            group
            provider
            integrationId
            name
            description
            smart
            createdAt
            updatedAt
          }
          nextToken
        }
        attributes {
          items {
            id
            group
            provider
            integrationId
            fetch
            push
            name
            displayName
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      destinationId
      configuration {
        provider
        ... on UniversalAnalyticsDestination {
          dimensionId
          category
          action
          label
        }
      }
      isArchived
      isActive
      firedLast30d
      createdAt
      trigger
      updatedAt
      triggers {
        items {
          id
          group
          actionId
          triggerId
          createdAt
          updatedAt
          action {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          trigger {
            id
            name
            description
            group
            organizationId
            conditions
            isArchived
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      destination {
        id
        name
        group
        organizationId
        credentials {
          createdAt
          updatedAt
          ... on MarketoCreds {
            apiHost
            clientId
            clientSecret
          }
          ... on SalesforceCreds {
            access_token
            refresh_token
            signature
            scope
            id_token
            instance_url
            id
            token_type
            issued_at
          }
          ... on SixSenseCreds {
            apiToken
          }
          ... on HubSpotCreds {
            accessToken
            refreshToken
            tokenType
            expiresIn
          }
        }
        configuration {
          active
          createdAt
          updatedAt
          ... on MarketoConfig {
            hiddenField
          }
          ... on SalesforceConfig {
            userId
          }
          ... on GoogleTagManagerConfig {
            dataLayerVarName
          }
          ... on UniversalAnalyticsConfig {
            uaTrackingId
          }
          ... on UniversalAnalyticsSourceConfig {
            uaTrackingId
          }
        }
        provider
        source
        createdAt
        updatedAt
        lists {
          items {
            id
            externalId
            group
            provider
            integrationId
            name
            description
            smart
            createdAt
            updatedAt
          }
          nextToken
        }
        attributes {
          items {
            id
            group
            provider
            integrationId
            fetch
            push
            name
            displayName
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createActionTrigger = /* GraphQL */ `
  mutation CreateActionTrigger(
    $input: CreateActionTriggerInput!
    $condition: ModelActionTriggerConditionInput
  ) {
    createActionTrigger(input: $input, condition: $condition) {
      id
      group
      actionId
      triggerId
      createdAt
      updatedAt
      action {
        id
        name
        description
        group
        organizationId
        destinationId
        configuration {
          provider
          ... on UniversalAnalyticsDestination {
            dimensionId
            category
            action
            label
          }
        }
        isArchived
        isActive
        firedLast30d
        createdAt
        trigger
        updatedAt
        triggers {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        destination {
          id
          name
          group
          organizationId
          credentials {
            createdAt
            updatedAt
            ... on MarketoCreds {
              apiHost
              clientId
              clientSecret
            }
            ... on SalesforceCreds {
              access_token
              refresh_token
              signature
              scope
              id_token
              instance_url
              id
              token_type
              issued_at
            }
            ... on SixSenseCreds {
              apiToken
            }
            ... on HubSpotCreds {
              accessToken
              refreshToken
              tokenType
              expiresIn
            }
          }
          configuration {
            active
            createdAt
            updatedAt
            ... on MarketoConfig {
              hiddenField
            }
            ... on SalesforceConfig {
              userId
            }
            ... on GoogleTagManagerConfig {
              dataLayerVarName
            }
            ... on UniversalAnalyticsConfig {
              uaTrackingId
            }
            ... on UniversalAnalyticsSourceConfig {
              uaTrackingId
            }
          }
          provider
          source
          createdAt
          updatedAt
          lists {
            nextToken
          }
          attributes {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      trigger {
        id
        name
        description
        group
        organizationId
        conditions
        isArchived
        createdAt
        updatedAt
        actions {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
    }
  }
`;
export const updateActionTrigger = /* GraphQL */ `
  mutation UpdateActionTrigger(
    $input: UpdateActionTriggerInput!
    $condition: ModelActionTriggerConditionInput
  ) {
    updateActionTrigger(input: $input, condition: $condition) {
      id
      group
      actionId
      triggerId
      createdAt
      updatedAt
      action {
        id
        name
        description
        group
        organizationId
        destinationId
        configuration {
          provider
          ... on UniversalAnalyticsDestination {
            dimensionId
            category
            action
            label
          }
        }
        isArchived
        isActive
        firedLast30d
        createdAt
        trigger
        updatedAt
        triggers {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        destination {
          id
          name
          group
          organizationId
          credentials {
            createdAt
            updatedAt
            ... on MarketoCreds {
              apiHost
              clientId
              clientSecret
            }
            ... on SalesforceCreds {
              access_token
              refresh_token
              signature
              scope
              id_token
              instance_url
              id
              token_type
              issued_at
            }
            ... on SixSenseCreds {
              apiToken
            }
            ... on HubSpotCreds {
              accessToken
              refreshToken
              tokenType
              expiresIn
            }
          }
          configuration {
            active
            createdAt
            updatedAt
            ... on MarketoConfig {
              hiddenField
            }
            ... on SalesforceConfig {
              userId
            }
            ... on GoogleTagManagerConfig {
              dataLayerVarName
            }
            ... on UniversalAnalyticsConfig {
              uaTrackingId
            }
            ... on UniversalAnalyticsSourceConfig {
              uaTrackingId
            }
          }
          provider
          source
          createdAt
          updatedAt
          lists {
            nextToken
          }
          attributes {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      trigger {
        id
        name
        description
        group
        organizationId
        conditions
        isArchived
        createdAt
        updatedAt
        actions {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteActionTrigger = /* GraphQL */ `
  mutation DeleteActionTrigger(
    $input: DeleteActionTriggerInput!
    $condition: ModelActionTriggerConditionInput
  ) {
    deleteActionTrigger(input: $input, condition: $condition) {
      id
      group
      actionId
      triggerId
      createdAt
      updatedAt
      action {
        id
        name
        description
        group
        organizationId
        destinationId
        configuration {
          provider
          ... on UniversalAnalyticsDestination {
            dimensionId
            category
            action
            label
          }
        }
        isArchived
        isActive
        firedLast30d
        createdAt
        trigger
        updatedAt
        triggers {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        destination {
          id
          name
          group
          organizationId
          credentials {
            createdAt
            updatedAt
            ... on MarketoCreds {
              apiHost
              clientId
              clientSecret
            }
            ... on SalesforceCreds {
              access_token
              refresh_token
              signature
              scope
              id_token
              instance_url
              id
              token_type
              issued_at
            }
            ... on SixSenseCreds {
              apiToken
            }
            ... on HubSpotCreds {
              accessToken
              refreshToken
              tokenType
              expiresIn
            }
          }
          configuration {
            active
            createdAt
            updatedAt
            ... on MarketoConfig {
              hiddenField
            }
            ... on SalesforceConfig {
              userId
            }
            ... on GoogleTagManagerConfig {
              dataLayerVarName
            }
            ... on UniversalAnalyticsConfig {
              uaTrackingId
            }
            ... on UniversalAnalyticsSourceConfig {
              uaTrackingId
            }
          }
          provider
          source
          createdAt
          updatedAt
          lists {
            nextToken
          }
          attributes {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      trigger {
        id
        name
        description
        group
        organizationId
        conditions
        isArchived
        createdAt
        updatedAt
        actions {
          items {
            id
            group
            actionId
            triggerId
            createdAt
            updatedAt
          }
          nextToken
        }
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
    }
  }
`;
export const createTrigger = /* GraphQL */ `
  mutation CreateTrigger(
    $input: CreateTriggerInput!
    $condition: ModelTriggerConditionInput
  ) {
    createTrigger(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      conditions
      isArchived
      createdAt
      updatedAt
      actions {
        items {
          id
          group
          actionId
          triggerId
          createdAt
          updatedAt
          action {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          trigger {
            id
            name
            description
            group
            organizationId
            conditions
            isArchived
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateTrigger = /* GraphQL */ `
  mutation UpdateTrigger(
    $input: UpdateTriggerInput!
    $condition: ModelTriggerConditionInput
  ) {
    updateTrigger(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      conditions
      isArchived
      createdAt
      updatedAt
      actions {
        items {
          id
          group
          actionId
          triggerId
          createdAt
          updatedAt
          action {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          trigger {
            id
            name
            description
            group
            organizationId
            conditions
            isArchived
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteTrigger = /* GraphQL */ `
  mutation DeleteTrigger(
    $input: DeleteTriggerInput!
    $condition: ModelTriggerConditionInput
  ) {
    deleteTrigger(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      conditions
      isArchived
      createdAt
      updatedAt
      actions {
        items {
          id
          group
          actionId
          triggerId
          createdAt
          updatedAt
          action {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          trigger {
            id
            name
            description
            group
            organizationId
            conditions
            isArchived
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createAudience = /* GraphQL */ `
  mutation CreateAudience(
    $input: CreateAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    createAudience(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      audienceType
      conditions
      visitors
      conversions
      individual
      accounts
      actionsCount
      isArchived
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateAudience = /* GraphQL */ `
  mutation UpdateAudience(
    $input: UpdateAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    updateAudience(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      audienceType
      conditions
      visitors
      conversions
      individual
      accounts
      actionsCount
      isArchived
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteAudience = /* GraphQL */ `
  mutation DeleteAudience(
    $input: DeleteAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    deleteAudience(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      audienceType
      conditions
      visitors
      conversions
      individual
      accounts
      actionsCount
      isArchived
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createGoal = /* GraphQL */ `
  mutation CreateGoal(
    $input: CreateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    createGoal(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      eventId
      trackEvent
      goalFrequency
      includeInRevenue
      calculateGoalValueAuto
      aggregateGoalValue
      defaultGoalValue
      triggeringType
      includeUrls {
        urls
        matchType
      }
      excludeUrls {
        urls
        matchType
      }
      isActive
      isArchived
      actionsCount
      type
      typeState
      attributes {
        apiname
        createdbyid
        defaultprobability
        description
        forecastcategory
        forecastcategoryname
        isactive
        isclosed
        iswon
        lastmodifiedbyid
        lastmodifieddate
        masterlabel
        sortorder
        systemmodstamp
        createddate
        id
      }
      conversionsLast30d
      createdAt
      updatedAt
      event {
        id
        name
        source
        group
        organizationId
        attributes {
          name
          matchType
          value
        }
        createdAt
        updatedAt
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateGoal = /* GraphQL */ `
  mutation UpdateGoal(
    $input: UpdateGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    updateGoal(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      eventId
      trackEvent
      goalFrequency
      includeInRevenue
      calculateGoalValueAuto
      aggregateGoalValue
      defaultGoalValue
      triggeringType
      includeUrls {
        urls
        matchType
      }
      excludeUrls {
        urls
        matchType
      }
      isActive
      isArchived
      actionsCount
      type
      typeState
      attributes {
        apiname
        createdbyid
        defaultprobability
        description
        forecastcategory
        forecastcategoryname
        isactive
        isclosed
        iswon
        lastmodifiedbyid
        lastmodifieddate
        masterlabel
        sortorder
        systemmodstamp
        createddate
        id
      }
      conversionsLast30d
      createdAt
      updatedAt
      event {
        id
        name
        source
        group
        organizationId
        attributes {
          name
          matchType
          value
        }
        createdAt
        updatedAt
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteGoal = /* GraphQL */ `
  mutation DeleteGoal(
    $input: DeleteGoalInput!
    $condition: ModelGoalConditionInput
  ) {
    deleteGoal(input: $input, condition: $condition) {
      id
      name
      description
      group
      organizationId
      eventId
      trackEvent
      goalFrequency
      includeInRevenue
      calculateGoalValueAuto
      aggregateGoalValue
      defaultGoalValue
      triggeringType
      includeUrls {
        urls
        matchType
      }
      excludeUrls {
        urls
        matchType
      }
      isActive
      isArchived
      actionsCount
      type
      typeState
      attributes {
        apiname
        createdbyid
        defaultprobability
        description
        forecastcategory
        forecastcategoryname
        isactive
        isclosed
        iswon
        lastmodifiedbyid
        lastmodifieddate
        masterlabel
        sortorder
        systemmodstamp
        createddate
        id
      }
      conversionsLast30d
      createdAt
      updatedAt
      event {
        id
        name
        source
        group
        organizationId
        attributes {
          name
          matchType
          value
        }
        createdAt
        updatedAt
        organization {
          id
          name
          group
          orgOwner
          installationType
          integrationCode
          antiFlickerCode
          asynchronousSnippet
          anonymize
          enableNonConsent
          disabled
          signupType
          trialExpiration
          invoiced
          flashHide
          activateWebsiteAttribution
          createdAt
          updatedAt
          actions {
            nextToken
          }
          audiences {
            nextToken
          }
          goals {
            nextToken
          }
          integrations {
            nextToken
          }
        }
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      source
      group
      organizationId
      attributes {
        name
        matchType
        value
      }
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      source
      group
      organizationId
      attributes {
        name
        matchType
        value
      }
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      source
      group
      organizationId
      attributes {
        name
        matchType
        value
      }
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createPage = /* GraphQL */ `
  mutation CreatePage(
    $input: CreatePageInput!
    $condition: ModelPageConditionInput
  ) {
    createPage(input: $input, condition: $condition) {
      id
      name
      group
      organizationId
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updatePage = /* GraphQL */ `
  mutation UpdatePage(
    $input: UpdatePageInput!
    $condition: ModelPageConditionInput
  ) {
    updatePage(input: $input, condition: $condition) {
      id
      name
      group
      organizationId
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deletePage = /* GraphQL */ `
  mutation DeletePage(
    $input: DeletePageInput!
    $condition: ModelPageConditionInput
  ) {
    deletePage(input: $input, condition: $condition) {
      id
      name
      group
      organizationId
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createIntegrationList = /* GraphQL */ `
  mutation CreateIntegrationList(
    $input: CreateIntegrationListInput!
    $condition: ModelIntegrationListConditionInput
  ) {
    createIntegrationList(input: $input, condition: $condition) {
      id
      externalId
      group
      provider
      integrationId
      name
      description
      smart
      createdAt
      updatedAt
    }
  }
`;
export const updateIntegrationList = /* GraphQL */ `
  mutation UpdateIntegrationList(
    $input: UpdateIntegrationListInput!
    $condition: ModelIntegrationListConditionInput
  ) {
    updateIntegrationList(input: $input, condition: $condition) {
      id
      externalId
      group
      provider
      integrationId
      name
      description
      smart
      createdAt
      updatedAt
    }
  }
`;
export const deleteIntegrationList = /* GraphQL */ `
  mutation DeleteIntegrationList(
    $input: DeleteIntegrationListInput!
    $condition: ModelIntegrationListConditionInput
  ) {
    deleteIntegrationList(input: $input, condition: $condition) {
      id
      externalId
      group
      provider
      integrationId
      name
      description
      smart
      createdAt
      updatedAt
    }
  }
`;
export const createIntegrationField = /* GraphQL */ `
  mutation CreateIntegrationField(
    $input: CreateIntegrationFieldInput!
    $condition: ModelIntegrationFieldConditionInput
  ) {
    createIntegrationField(input: $input, condition: $condition) {
      id
      group
      provider
      integrationId
      fetch
      push
      name
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const updateIntegrationField = /* GraphQL */ `
  mutation UpdateIntegrationField(
    $input: UpdateIntegrationFieldInput!
    $condition: ModelIntegrationFieldConditionInput
  ) {
    updateIntegrationField(input: $input, condition: $condition) {
      id
      group
      provider
      integrationId
      fetch
      push
      name
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const deleteIntegrationField = /* GraphQL */ `
  mutation DeleteIntegrationField(
    $input: DeleteIntegrationFieldInput!
    $condition: ModelIntegrationFieldConditionInput
  ) {
    deleteIntegrationField(input: $input, condition: $condition) {
      id
      group
      provider
      integrationId
      fetch
      push
      name
      displayName
      createdAt
      updatedAt
    }
  }
`;
export const createIntegration = /* GraphQL */ `
  mutation CreateIntegration(
    $input: CreateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    createIntegration(input: $input, condition: $condition) {
      id
      name
      group
      organizationId
      credentials {
        createdAt
        updatedAt
        ... on MarketoCreds {
          apiHost
          clientId
          clientSecret
        }
        ... on SalesforceCreds {
          access_token
          refresh_token
          signature
          scope
          id_token
          instance_url
          id
          token_type
          issued_at
        }
        ... on SixSenseCreds {
          apiToken
        }
        ... on HubSpotCreds {
          accessToken
          refreshToken
          tokenType
          expiresIn
        }
      }
      configuration {
        active
        createdAt
        updatedAt
        ... on MarketoConfig {
          hiddenField
        }
        ... on SalesforceConfig {
          userId
        }
        ... on GoogleTagManagerConfig {
          dataLayerVarName
        }
        ... on UniversalAnalyticsConfig {
          uaTrackingId
        }
        ... on UniversalAnalyticsSourceConfig {
          uaTrackingId
        }
      }
      provider
      source
      createdAt
      updatedAt
      lists {
        items {
          id
          externalId
          group
          provider
          integrationId
          name
          description
          smart
          createdAt
          updatedAt
        }
        nextToken
      }
      attributes {
        items {
          id
          group
          provider
          integrationId
          fetch
          push
          name
          displayName
          createdAt
          updatedAt
        }
        nextToken
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateIntegration = /* GraphQL */ `
  mutation UpdateIntegration(
    $input: UpdateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    updateIntegration(input: $input, condition: $condition) {
      id
      name
      group
      organizationId
      credentials {
        createdAt
        updatedAt
        ... on MarketoCreds {
          apiHost
          clientId
          clientSecret
        }
        ... on SalesforceCreds {
          access_token
          refresh_token
          signature
          scope
          id_token
          instance_url
          id
          token_type
          issued_at
        }
        ... on SixSenseCreds {
          apiToken
        }
        ... on HubSpotCreds {
          accessToken
          refreshToken
          tokenType
          expiresIn
        }
      }
      configuration {
        active
        createdAt
        updatedAt
        ... on MarketoConfig {
          hiddenField
        }
        ... on SalesforceConfig {
          userId
        }
        ... on GoogleTagManagerConfig {
          dataLayerVarName
        }
        ... on UniversalAnalyticsConfig {
          uaTrackingId
        }
        ... on UniversalAnalyticsSourceConfig {
          uaTrackingId
        }
      }
      provider
      source
      createdAt
      updatedAt
      lists {
        items {
          id
          externalId
          group
          provider
          integrationId
          name
          description
          smart
          createdAt
          updatedAt
        }
        nextToken
      }
      attributes {
        items {
          id
          group
          provider
          integrationId
          fetch
          push
          name
          displayName
          createdAt
          updatedAt
        }
        nextToken
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteIntegration = /* GraphQL */ `
  mutation DeleteIntegration(
    $input: DeleteIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    deleteIntegration(input: $input, condition: $condition) {
      id
      name
      group
      organizationId
      credentials {
        createdAt
        updatedAt
        ... on MarketoCreds {
          apiHost
          clientId
          clientSecret
        }
        ... on SalesforceCreds {
          access_token
          refresh_token
          signature
          scope
          id_token
          instance_url
          id
          token_type
          issued_at
        }
        ... on SixSenseCreds {
          apiToken
        }
        ... on HubSpotCreds {
          accessToken
          refreshToken
          tokenType
          expiresIn
        }
      }
      configuration {
        active
        createdAt
        updatedAt
        ... on MarketoConfig {
          hiddenField
        }
        ... on SalesforceConfig {
          userId
        }
        ... on GoogleTagManagerConfig {
          dataLayerVarName
        }
        ... on UniversalAnalyticsConfig {
          uaTrackingId
        }
        ... on UniversalAnalyticsSourceConfig {
          uaTrackingId
        }
      }
      provider
      source
      createdAt
      updatedAt
      lists {
        items {
          id
          externalId
          group
          provider
          integrationId
          name
          description
          smart
          createdAt
          updatedAt
        }
        nextToken
      }
      attributes {
        items {
          id
          group
          provider
          integrationId
          fetch
          push
          name
          displayName
          createdAt
          updatedAt
        }
        nextToken
      }
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      subjectId
      subjectType
      status
      title
      message
      group
      organizationId
      dismissedAt
      dismissedBy
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      subjectId
      subjectType
      status
      title
      message
      group
      organizationId
      dismissedAt
      dismissedBy
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      subjectId
      subjectType
      status
      title
      message
      group
      organizationId
      dismissedAt
      dismissedBy
      createdAt
      updatedAt
      organization {
        id
        name
        group
        orgOwner
        installationType
        integrationCode
        antiFlickerCode
        asynchronousSnippet
        anonymize
        enableNonConsent
        disabled
        signupType
        trialExpiration
        invoiced
        flashHide
        activateWebsiteAttribution
        createdAt
        updatedAt
        actions {
          items {
            id
            name
            description
            group
            organizationId
            destinationId
            isArchived
            isActive
            firedLast30d
            createdAt
            trigger
            updatedAt
          }
          nextToken
        }
        audiences {
          items {
            id
            name
            description
            group
            organizationId
            audienceType
            conditions
            visitors
            conversions
            individual
            accounts
            actionsCount
            isArchived
            createdAt
            updatedAt
          }
          nextToken
        }
        goals {
          items {
            id
            name
            description
            group
            organizationId
            eventId
            trackEvent
            goalFrequency
            includeInRevenue
            calculateGoalValueAuto
            aggregateGoalValue
            defaultGoalValue
            triggeringType
            isActive
            isArchived
            actionsCount
            type
            typeState
            conversionsLast30d
            createdAt
            updatedAt
          }
          nextToken
        }
        integrations {
          items {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      group
      orgOwner
      installationType
      integrationCode
      antiFlickerCode
      asynchronousSnippet
      anonymize
      enableNonConsent
      disabled
      signupType
      trialExpiration
      invoiced
      flashHide
      activateWebsiteAttribution
      createdAt
      updatedAt
      actions {
        items {
          id
          name
          description
          group
          organizationId
          destinationId
          configuration {
            provider
            ... on UniversalAnalyticsDestination {
              dimensionId
              category
              action
              label
            }
          }
          isArchived
          isActive
          firedLast30d
          createdAt
          trigger
          updatedAt
          triggers {
            nextToken
          }
          destination {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      audiences {
        items {
          id
          name
          description
          group
          organizationId
          audienceType
          conditions
          visitors
          conversions
          individual
          accounts
          actionsCount
          isArchived
          createdAt
          updatedAt
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      goals {
        items {
          id
          name
          description
          group
          organizationId
          eventId
          trackEvent
          goalFrequency
          includeInRevenue
          calculateGoalValueAuto
          aggregateGoalValue
          defaultGoalValue
          triggeringType
          includeUrls {
            urls
            matchType
          }
          excludeUrls {
            urls
            matchType
          }
          isActive
          isArchived
          actionsCount
          type
          typeState
          attributes {
            apiname
            createdbyid
            defaultprobability
            description
            forecastcategory
            forecastcategoryname
            isactive
            isclosed
            iswon
            lastmodifiedbyid
            lastmodifieddate
            masterlabel
            sortorder
            systemmodstamp
            createddate
            id
          }
          conversionsLast30d
          createdAt
          updatedAt
          event {
            id
            name
            source
            group
            organizationId
            createdAt
            updatedAt
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      integrations {
        items {
          id
          name
          group
          organizationId
          credentials {
            createdAt
            updatedAt
            ... on MarketoCreds {
              apiHost
              clientId
              clientSecret
            }
            ... on SalesforceCreds {
              access_token
              refresh_token
              signature
              scope
              id_token
              instance_url
              id
              token_type
              issued_at
            }
            ... on SixSenseCreds {
              apiToken
            }
            ... on HubSpotCreds {
              accessToken
              refreshToken
              tokenType
              expiresIn
            }
          }
          configuration {
            active
            createdAt
            updatedAt
            ... on MarketoConfig {
              hiddenField
            }
            ... on SalesforceConfig {
              userId
            }
            ... on GoogleTagManagerConfig {
              dataLayerVarName
            }
            ... on UniversalAnalyticsConfig {
              uaTrackingId
            }
            ... on UniversalAnalyticsSourceConfig {
              uaTrackingId
            }
          }
          provider
          source
          createdAt
          updatedAt
          lists {
            nextToken
          }
          attributes {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      group
      orgOwner
      installationType
      integrationCode
      antiFlickerCode
      asynchronousSnippet
      anonymize
      enableNonConsent
      disabled
      signupType
      trialExpiration
      invoiced
      flashHide
      activateWebsiteAttribution
      createdAt
      updatedAt
      actions {
        items {
          id
          name
          description
          group
          organizationId
          destinationId
          configuration {
            provider
            ... on UniversalAnalyticsDestination {
              dimensionId
              category
              action
              label
            }
          }
          isArchived
          isActive
          firedLast30d
          createdAt
          trigger
          updatedAt
          triggers {
            nextToken
          }
          destination {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      audiences {
        items {
          id
          name
          description
          group
          organizationId
          audienceType
          conditions
          visitors
          conversions
          individual
          accounts
          actionsCount
          isArchived
          createdAt
          updatedAt
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      goals {
        items {
          id
          name
          description
          group
          organizationId
          eventId
          trackEvent
          goalFrequency
          includeInRevenue
          calculateGoalValueAuto
          aggregateGoalValue
          defaultGoalValue
          triggeringType
          includeUrls {
            urls
            matchType
          }
          excludeUrls {
            urls
            matchType
          }
          isActive
          isArchived
          actionsCount
          type
          typeState
          attributes {
            apiname
            createdbyid
            defaultprobability
            description
            forecastcategory
            forecastcategoryname
            isactive
            isclosed
            iswon
            lastmodifiedbyid
            lastmodifieddate
            masterlabel
            sortorder
            systemmodstamp
            createddate
            id
          }
          conversionsLast30d
          createdAt
          updatedAt
          event {
            id
            name
            source
            group
            organizationId
            createdAt
            updatedAt
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      integrations {
        items {
          id
          name
          group
          organizationId
          credentials {
            createdAt
            updatedAt
            ... on MarketoCreds {
              apiHost
              clientId
              clientSecret
            }
            ... on SalesforceCreds {
              access_token
              refresh_token
              signature
              scope
              id_token
              instance_url
              id
              token_type
              issued_at
            }
            ... on SixSenseCreds {
              apiToken
            }
            ... on HubSpotCreds {
              accessToken
              refreshToken
              tokenType
              expiresIn
            }
          }
          configuration {
            active
            createdAt
            updatedAt
            ... on MarketoConfig {
              hiddenField
            }
            ... on SalesforceConfig {
              userId
            }
            ... on GoogleTagManagerConfig {
              dataLayerVarName
            }
            ... on UniversalAnalyticsConfig {
              uaTrackingId
            }
            ... on UniversalAnalyticsSourceConfig {
              uaTrackingId
            }
          }
          provider
          source
          createdAt
          updatedAt
          lists {
            nextToken
          }
          attributes {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      group
      orgOwner
      installationType
      integrationCode
      antiFlickerCode
      asynchronousSnippet
      anonymize
      enableNonConsent
      disabled
      signupType
      trialExpiration
      invoiced
      flashHide
      activateWebsiteAttribution
      createdAt
      updatedAt
      actions {
        items {
          id
          name
          description
          group
          organizationId
          destinationId
          configuration {
            provider
            ... on UniversalAnalyticsDestination {
              dimensionId
              category
              action
              label
            }
          }
          isArchived
          isActive
          firedLast30d
          createdAt
          trigger
          updatedAt
          triggers {
            nextToken
          }
          destination {
            id
            name
            group
            organizationId
            provider
            source
            createdAt
            updatedAt
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      audiences {
        items {
          id
          name
          description
          group
          organizationId
          audienceType
          conditions
          visitors
          conversions
          individual
          accounts
          actionsCount
          isArchived
          createdAt
          updatedAt
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      goals {
        items {
          id
          name
          description
          group
          organizationId
          eventId
          trackEvent
          goalFrequency
          includeInRevenue
          calculateGoalValueAuto
          aggregateGoalValue
          defaultGoalValue
          triggeringType
          includeUrls {
            urls
            matchType
          }
          excludeUrls {
            urls
            matchType
          }
          isActive
          isArchived
          actionsCount
          type
          typeState
          attributes {
            apiname
            createdbyid
            defaultprobability
            description
            forecastcategory
            forecastcategoryname
            isactive
            isclosed
            iswon
            lastmodifiedbyid
            lastmodifieddate
            masterlabel
            sortorder
            systemmodstamp
            createddate
            id
          }
          conversionsLast30d
          createdAt
          updatedAt
          event {
            id
            name
            source
            group
            organizationId
            createdAt
            updatedAt
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      integrations {
        items {
          id
          name
          group
          organizationId
          credentials {
            createdAt
            updatedAt
            ... on MarketoCreds {
              apiHost
              clientId
              clientSecret
            }
            ... on SalesforceCreds {
              access_token
              refresh_token
              signature
              scope
              id_token
              instance_url
              id
              token_type
              issued_at
            }
            ... on SixSenseCreds {
              apiToken
            }
            ... on HubSpotCreds {
              accessToken
              refreshToken
              tokenType
              expiresIn
            }
          }
          configuration {
            active
            createdAt
            updatedAt
            ... on MarketoConfig {
              hiddenField
            }
            ... on SalesforceConfig {
              userId
            }
            ... on GoogleTagManagerConfig {
              dataLayerVarName
            }
            ... on UniversalAnalyticsConfig {
              uaTrackingId
            }
            ... on UniversalAnalyticsSourceConfig {
              uaTrackingId
            }
          }
          provider
          source
          createdAt
          updatedAt
          lists {
            nextToken
          }
          attributes {
            nextToken
          }
          organization {
            id
            name
            group
            orgOwner
            installationType
            integrationCode
            antiFlickerCode
            asynchronousSnippet
            anonymize
            enableNonConsent
            disabled
            signupType
            trialExpiration
            invoiced
            flashHide
            activateWebsiteAttribution
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userEmail
      name
      userId
      orgId
      group
      timeStamp
      createdAt
      updatedAt
      stripeCustomerId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userEmail
      name
      userId
      orgId
      group
      timeStamp
      createdAt
      updatedAt
      stripeCustomerId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userEmail
      name
      userId
      orgId
      group
      timeStamp
      createdAt
      updatedAt
      stripeCustomerId
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      stripeId
      amount
      receivedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      stripeId
      amount
      receivedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      stripeId
      amount
      receivedAt
      createdAt
      updatedAt
    }
  }
`;
