import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { UpdateEventInput } from 'API';
// The initial state of the EventGoal container
export const initialState: ContainerState = {
	loading: false,
	error: undefined,
	events: [],
};

const eventContainerSlice = createSlice({
	name: 'eventContainer',
	initialState,
	reducers: {
		setLoading(state, action) {
			state.loading = action.payload;
		},
		fetchEvents(state, action) {
			state.loading = true;
		},
		fetchEventsSuccess(state, action) {
			state.loading = false;
			state.events = action.payload;
		},
		modifyEvent(state, action: PayloadAction<UpdateEventInput>) {
			state.loading = true;
		},
		modifyEventSuccess(state, action) {
			state.loading = false;
			if (state.events?.map(i => i.id).indexOf(action.payload.id) > -1) {
				state.events = state.events?.map(i =>
					i.id === action?.payload?.id ? action.payload : i
				);
			} else {
				state.events = [...state.events, action.payload];
			}
		},
	},
});

export const { actions, reducer, name: sliceKey } = eventContainerSlice;
