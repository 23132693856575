import { API, graphqlOperation } from 'aws-amplify';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { errorMessageHandler } from 'utils/errorMessage';
import { CreateActionTriggerInput, DeleteActionTriggerInput } from 'API';
import { createActionTrigger, deleteActionTrigger } from 'graphql/mutations';
import { actions } from './slice';

const errorMessage = (err: Error) =>
	errorMessageHandler(
		err,
		'Error occurred saving action / trigger relationship.'
	);

export function* handleError(err: Error) {
	const errMessage = errorMessage(err);
	yield call(toast.error, errorMessage);
	yield put(actions.setError(errMessage));
}

export function* handleCreateActionTrigger(
	action: PayloadAction<CreateActionTriggerInput>
) {
	try {
		const { data } = yield call(
			[API, 'graphql'],
			graphqlOperation(createActionTrigger, { input: action.payload })
		);
		return data?.createActionTrigger;
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleDeleteActionTrigger(
	action: PayloadAction<DeleteActionTriggerInput>
) {
	try {
		yield call(
			[API, 'graphql'],
			graphqlOperation(deleteActionTrigger, { input: action.payload })
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* actionTriggerContainerSaga() {
	yield takeEvery(actions.createActionTrigger.type, handleCreateActionTrigger);
	yield takeEvery(actions.deleteActionTrigger.type, handleDeleteActionTrigger);
}
