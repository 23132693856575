export const fetchDashboardStats = async (organizationId: string) => {
	try {
		const params = new URLSearchParams();
		params.append('organization_id', organizationId);
		const data = await fetch(
			`${
				process.env.REACT_APP_REPORT_ENDPOINT
			}/v2_main_dashboard/stats_overall?${params.toString()}`,
			{
				cache: 'no-cache',
			}
		);
		const json = await data.json();
		return json;
	} catch (err) {
		console.error(err);
		return { rows: [] };
	}
};

export const fetchDashboardChartData = async (
	organizationId: string,
	startDate: string,
	endDate: string
) => {
	try {
		const params = new URLSearchParams();
		params.append('organization_id', organizationId);
		params.append('start_date', startDate);
		params.append('end_date', endDate);
		const data = await fetch(
			`${
				process.env.REACT_APP_REPORT_ENDPOINT
			}/v2_main_dashboard/stats_by_date?${params.toString()}`,
			{
				cache: 'no-cache',
			}
		);
		const json = await data.json();
		return json;
	} catch (err) {
		console.error(err);
		return { rows: [] };
	}
};
