import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { find } from 'lodash';

// The initial state of the GoalContainer container
export const initialState: ContainerState = {
	loading: false,
	error: undefined,
	selectedGoal: {},
	goalLoading: false,
	goals: [],
	searchGoalsLoader: false,
	nextToken: undefined,
	indexRange: [0, 0],
};

const goalContainerSlice = createSlice({
	name: 'goalContainer',
	initialState,
	reducers: {
		someAction() {},
		setGoalError(state, _action: PayloadAction<string>) {
			state.error = _action.payload;
		},
		getGoal(state, _action) {
			state.goalLoading = true;
		},
		getGoalSuccess(state, _action) {
			state.goalLoading = false;
			state.selectedGoal = _action.payload;
		},
		createGoal(state, _action) {
			state.loading = true;
		},
		createGoalSuccess(state, _action) {
			state.loading = false;
			if (find(state.goals, { id: _action?.payload?.id }) !== undefined) {
				state.goals = state.goals?.map(i =>
					i.id === _action?.payload?.id ? _action.payload : i
				);
			} else {
				state.goals = [...(state.goals ?? []), _action.payload];
			}
		},
		createMultiGoals(_state, _action) {},
		updateGoal(state, _action) {
			state.loading = true;
		},
		updateGoalSuccess(state, _action) {
			state.loading = false;
			if (find(state.goals, { id: _action?.payload?.id }) !== undefined) {
				state.goals = state.goals?.map(i =>
					i.id === _action?.payload?.id ? _action.payload : i
				);
			} else {
				state.goals = [...(state.goals ?? []), _action.payload];
			}
		},
		archiveGoal(state, _action) {
			state.loading = true;
		},
		archiveGoalSuccess(state, _action) {
			state.loading = false;
			if (find(state.goals, { id: _action?.payload?.id }) !== undefined) {
				state.goals = state.goals?.filter(i => i.id !== _action?.payload?.id);
			}
		},
		clearSelectedGoal(state, _action) {
			state.selectedGoal = {};
		},
		fetchGoals(state, _action) {
			state.searchGoalsLoader = true;
			state.goals = [];
		},
		fetchGoalsSuccess(state, action) {
			state.searchGoalsLoader = false;
			state.goals = action.payload;
		},
		next(state, _action) {
			state.searchGoalsLoader = true;
		},
		previous(_state, _action) {},
		appendGoalList(state, action) {
			state.goals = [...state.goals, ...action.payload];
			state.searchGoalsLoader = false;
		},
		setNextToken(state, action) {
			state.nextToken = action.payload;
		},
		setIndexRange(state, action) {
			state.indexRange = action.payload;
			state.searchGoalsLoader = false;
		},
		modifyEventAndGoal(state, _action) {
			state.loading = true;
		},
		modifyEventAndGoalSuccess(state, _action) {
			state.loading = false;
		},
	},
});

export const { actions, reducer, name: sliceKey } = goalContainerSlice;
