import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import _ from 'lodash';
import { IOrganization, ContainerState } from './types';

export const initialState: ContainerState = {
	error: undefined,
	organizations: [],
	selectedOrganizationID: null,
	selectedOrganization: {} as IOrganization,
	fetchingData: false,
	updatingData: false,
	fetchingDataList: false,
	isPermissionDenied: false,
	changedOrgId: null,
	isCopied: false,
};

const organizationsContainerSlice = createSlice({
	name: 'organizationsContainer',
	initialState,
	reducers: {
		setOrganizationError(state, action: PayloadAction<string>) {
			state.fetchingData = false;
			state.error = action.payload;
		},
		fetchOrganizations(state, _action) {
			state.fetchingData = true;
		},
		updateOrganization(state, _action) {
			state.updatingData = true;
		},
		updateOrganizationSuccess(state, _action: PayloadAction<IOrganization>) {
			state.updatingData = false;
			state.organizations = _.map(state.organizations, org => {
				if (org.id === _action.payload.id) {
					org = _action.payload;
				}
				return org;
			});
			state.selectedOrganization =
				_action.payload.id === state.selectedOrganizationID
					? _action.payload
					: _.filter(
							state.organizations,
							org => org.id === state.selectedOrganizationID
					  )[0];
		},
		fetchOrganization(state, _action) {
			state.fetchingData = true;
		},
		changeOrg(state, action) {
			state.selectedOrganizationID = action.payload.id;
			state.selectedOrganization = action.payload;
			state.changedOrgId = action.payload.id;
		},
		setOrganizationsSuccess(state, action: PayloadAction<IOrganization[]>) {
			state.fetchingData = false;
			state.error = undefined;
			state.organizations = action.payload['organizationList'];
			state.selectedOrganizationID =
				action.payload['organizationList']?.filter(
					item => item.group === sessionStorage.getItem('selectedOrg')
				)[0]?.id || action.payload['currentOrg'].id;
			state.selectedOrganization =
				action.payload['organizationList']?.filter(
					item => item.group === sessionStorage.getItem('selectedOrg')
				)[0] || action.payload['currentOrg'];
		},
		selectOrganizationSuccess(state, action: PayloadAction<IOrganization>) {
			state.fetchingData = false;
			state.selectedOrganizationID = action.payload.id;
		},
		setPermissionDenied(state, action) {
			state.isPermissionDenied = action.payload;
		},
		setFetchingData(state, action) {
			state.fetchingData = action.payload;
		},
		setCopied(state) {
			state.isCopied = true;
		},
		setCopiedSuccess(state) {
			state.isCopied = false;
		},
	},
});

export const { actions, reducer, name: sliceKey } = organizationsContainerSlice;
