import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Typography as MuiTypography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import LogoPNG from 'app/components/Header/logo_white.png';
import styles from './style.module.scss';
import { selectCognitoUser } from 'app/containers/AuthContainer/selectors';
import { actions as authActions } from 'app/containers/AuthContainer/slice';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Footer } from 'app/components/Footer';
import menuStyles from 'app/components/Header/Menu/style.module.scss';
import { removeSessionItem } from 'app/components/Header/utility';

export const AccessDenied = () => {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const userData = useSelector(selectCognitoUser);

	const handleLogout = (): boolean => {
		dispatch(authActions.logout());
		return true;
	};
	return (
		<>
			{/*Header*/}
			<div className={styles.header}>
				<div className={styles.brandLogo}>
					<NavLink to="/">
						<img src={LogoPNG} alt="Nav Brand" />
					</NavLink>
				</div>

				<div
					className={menuStyles.accountMenu}
					style={{ position: 'relative' }}
				>
					<span className={styles.email}>{userData?.email}</span>
					<span className={menuStyles.btn}>
						<MoreVertIcon style={{ fontSize: '16px', marginLeft: '15px' }} />
						<div className={menuStyles.dropListWrpr}>
							<ul className={menuStyles.dropList}>
								<li>
									<span
										onClick={() => {
											removeSessionItem('selectedOrg');
											handleLogout();
										}}
									>
										{t('header_logout')}
									</span>
								</li>
							</ul>
						</div>
					</span>
				</div>
			</div>

			{/*Content*/}

			<div className={styles.contentWrapper}>
				<MuiTypography variant="h3">{t('access_denied_msg')}</MuiTypography>
				<MuiTypography variant="h3">
					{t('access_denied_request_msg')}
				</MuiTypography>
			</div>

			{/*Footer*/}
			<div className={styles.footerWrapper}>
				<Footer />
			</div>
		</>
	);
};
