import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../slice';
import { selectFetchingData, selectTempUser } from '../selectors';
import AuthWrapper from '../AuthWrapper';
import { TextField } from 'formik-material-ui';
import styles from './style.module.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextTitle from 'app/components/Typos/TextTitle';
import CustomButton from 'app/components/Typos/ButtonComponent';
import LinkComponent from 'app/components/Typos/LinkComponent';
import { AmplifyAuthStatus } from 'app/containers/AuthContainer/types';
import { passwordRequirements } from 'app/containers/AuthContainer/passwordRequirements';
import { history } from 'utils/history';

export const NewPasswordRequired = () => {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const isFetchingData: boolean = useSelector(selectFetchingData);
	const user = useSelector(selectTempUser);
	const submitForm = (userInfo): boolean => {
		dispatch(actions.completeNewPassword(userInfo));
		return true;
	};

	if (user.password === '') {
		history.push(AmplifyAuthStatus.login);
	}

	return (
		<AuthWrapper>
			<div className={styles.confirmWrapper}>
				<TextTitle>{t('create_new_password')}</TextTitle>
				<Formik
					initialValues={{
						newPassword: '',
					}}
					validationSchema={Yup.object().shape({
						newPassword: passwordRequirements(t),
					})}
					onSubmit={(values, { setSubmitting }) => {
						submitForm(values);
						setSubmitting(false);
					}}
				>
					{({ values, isValid, dirty, isSubmitting }) => {
						return (
							<Form>
								<Field
									name="newPassword"
									type="password"
									label={t('globals_password')}
									placeholder="Enter your new password"
									component={TextField}
									fullWidth
									variant="outlined"
								/>
								<div className={`mb-3 ${styles.confirmBtnWrapper}`}>
									<LinkComponent link={AmplifyAuthStatus.login}>
										{t('auth_common_back_to_login')}
									</LinkComponent>
									<CustomButton
										type="submit"
										disabled={
											isSubmitting || !(isValid && dirty) || isFetchingData
										}
										loader={isFetchingData}
									>
										{t('globals_confirm')}
									</CustomButton>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</AuthWrapper>
	);
};
