import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { InputLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { actions as authActions } from 'app/containers/AuthContainer/slice';
import {
	selectCognitoUser,
	selectFetchingData,
} from 'app/containers/AuthContainer/selectors';
import CustomButton from 'app/components/Typos/ButtonComponent';
import PhoneInput from 'react-phone-input-2';

export const BasicInfo = () => {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const user = useSelector(selectCognitoUser);
	const isFetchingData: boolean = useSelector(selectFetchingData);

	return (
		<div className={styles.wrapper}>
			<Formik
				enableReinitialize
				initialValues={{
					name: user?.name || '',
					email: user?.email || '',
					phoneNumber: user?.phone_number || '',
					website: user?.website || '',
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string().required(
						t('account_preferences_basic_info_name_required')
					),
					website: Yup.string().required('website is required'),
					email: Yup.string()
						.email('Email is invalid')
						.required('Email is required'),
				})}
				onSubmit={(fields, actions) => {
					dispatch(authActions.updateUser({ ...fields }));
					actions.setSubmitting(false);
				}}
			>
				{({ isValid, dirty, setFieldValue, values }) => {
					return (
						<Form>
							<InputLabel htmlFor="name" shrink={false}>
								{t('account_preferences_basic_info_name')}
							</InputLabel>
							<Field
								name="name"
								type="text"
								placeholder={t(
									'account_preferences_basic_info_name_placeholder'
								)}
								component={TextField}
								fullWidth
								variant="outlined"
							/>
							<InputLabel htmlFor="email" shrink={false}>
								{t('globals_email')}
							</InputLabel>
							<Field
								name="email"
								type="text"
								placeholder={t('globals_email_placeholder')}
								component={TextField}
								fullWidth
								variant="outlined"
								disabled={!!values.email}
							/>

							<PhoneInput
								inputProps={{
									name: 'phoneNumber',
								}}
								value={'' + values.phoneNumber}
								inputClass={`MuiInputBase-input MuiOutlinedInput-input`}
								containerClass={`MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth`}
								preferredCountries={['us']}
								country="us"
								enableSearch={true}
								enableAreaCodeStretch
								onChange={val => {
									const phoneNo = `+${val}`;
									setFieldValue('phoneNumber', phoneNo);
								}}
								isValid={inputNumber => {
									//>=4 bec it does not include '+'
									return inputNumber.length >= 4 ? true : false;
								}}
							/>

							<InputLabel htmlFor="website" shrink={false}>
								Website
							</InputLabel>

							<Field
								name="website"
								type="text"
								placeholder={'Enter website address'}
								component={TextField}
								fullWidth
								variant="outlined"
							/>
							<div className={styles.bottomSec}>
								<CustomButton
									type="submit"
									disabled={!(dirty && isValid) || isFetchingData}
									loader={isFetchingData}
									block
								>
									{t('globals_save_preferences')}
								</CustomButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
