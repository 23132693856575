import React from 'react';

import { OperatorDropDown } from '../OperatorDropDown';
import { ValueDropDown } from './ValueDropDown';
import { Operators, MarketoLeaf } from 'API';
import styles from '../../style.module.scss';

const operators = [Operators.equalTo];

interface Props extends MarketoLeaf {
	id: string;
	editable: boolean;
	handleUpdate: Function;
}

export const Marketo = (props: Props) => {
	const { id, editable, value, operator, handleUpdate } = props;
	const updateValue = (updateValue: string) =>
		handleUpdate(id, { value: updateValue });
	const updateOperator = (updateOperator: string) =>
		handleUpdate(id, { operator: updateOperator });

	return (
		<div className={styles.nodeExpression}>
			<div>Lead in list</div>
			<OperatorDropDown
				disabled={!editable}
				operator={operator || ''}
				operators={operators}
				setOperator={updateOperator}
			/>
			<ValueDropDown
				disabled={!editable}
				value={value || ''}
				setValue={updateValue}
			/>
		</div>
	);
};

export default Marketo;
