import React from 'react';

import { SourceDropDown } from './SourceDropDown';
import { OperatorDropDown } from '../OperatorDropDown';
import { ValueInput } from '../ValueInput';
import { Operators, AttributeLeaf } from 'API';
import styles from '../../style.module.scss';

const operators = [
	Operators.equalTo,
	Operators.notEqualTo,
	Operators.lessThan,
	Operators.greaterThan,
	Operators.regex,
	Operators.containsString,
	Operators.containsValue,
];

interface Props extends AttributeLeaf {
	id: string;
	editable: Boolean;
	handleUpdate: Function;
}

export const Attribute = (props: Props) => {
	const {
		id,
		editable,
		handleUpdate,
		source,
		attribute,
		value,
		operator,
	} = props;

	const updateSource = (updateSource: string) =>
		handleUpdate(id, { source: updateSource });
	const updateAttribute = (updateAttribute: string) =>
		handleUpdate(id, { attribute: updateAttribute });
	const updateValue = (updateValue: string) =>
		handleUpdate(id, { value: updateValue });
	const updateOperator = (updateOperator: string) =>
		handleUpdate(id, { operator: updateOperator });

	return (
		<div className={styles.nodeExpression}>
			<SourceDropDown
				disabled={!editable}
				source={source || ''}
				setSource={updateSource}
			/>
			<ValueInput
				disabled={!editable}
				value={attribute || ''}
				setValue={updateAttribute}
				placeholder="Attribute Name"
			/>
			<OperatorDropDown
				disabled={!editable}
				operator={operator || ''}
				operators={operators}
				setOperator={updateOperator}
			/>
			<ValueInput
				disabled={!editable}
				value={value || ''}
				setValue={updateValue}
				placeholder="Attribute Value"
			/>
		</div>
	);
};

export default Attribute;
