import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import CustomButton from 'app/components/Typos/ButtonComponent';
import {
	Button,
	InputLabel,
	Typography as MuiTypography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../slice';
import {
	selectMemberType,
	selectMemberLoadingState,
	selectUserName,
	selectUserEmail,
} from 'app/containers/Members/selectors';
import { AddMemberType } from '../types';
import _ from 'lodash';

interface Props {
	onAddMember: Function;
	onIdentifyMember: Function;
}

export const AddMember = (props: Props) => {
	const { onAddMember, onIdentifyMember } = props;
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const memberType = useSelector(selectMemberType);
	const addMemberLoadingState = useSelector(selectMemberLoadingState);
	const userFetchedName = useSelector(selectUserName);
	const userFetchedEmail = useSelector(selectUserEmail);
	const [inList, setInList] = useState(false);

	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	//check user email status
	const handleEmailChange = values => {
		if (validateEmail(values.userEmail)) {
			values?.userEmail.length && onIdentifyMember({ ...values });
		}
		return false;
	};

	//reset state
	useEffect(() => {
		return () => {
			dispatch(actions.resetAddMemberState());
		};
	}, [dispatch]);

	useEffect(() => {
		if (memberType === AddMemberType.InOrgUserConfirmed) {
			setInList(true);
		} else {
			setInList(false);
		}
	}, [memberType]);

	return (
		<div className={styles.wrapper}>
			<Formik
				enableReinitialize
				initialValues={{
					userName: userFetchedName || '',
					userEmail: userFetchedEmail || '',
				}}
				validationSchema={() => {
					return Yup.object().shape({
						userEmail: Yup.string()
							.email('Email is invalid')
							.required('Email is required'),
					});
				}}
				onSubmit={fields => {
					onAddMember(fields);
				}}
			>
				{({ errors, isValid, values, handleChange, setFieldTouched }) => {
					return (
						<Form>
							<MuiTypography variant="h3">
								{t('members_add_member_title')}
							</MuiTypography>

							<InputLabel htmlFor="userEmail" shrink={false}>
								{t('globals_email')}
							</InputLabel>

							<Field
								as="text"
								name="userEmail"
								type="text"
								placeholder="Enter email"
								component={TextField}
								fullWidth
								variant="outlined"
								InputProps={{
									onChange: e => {
										handleChange(e);
										if (e.target.value.length > 3) {
											setFieldTouched('userEmail', true, true);
										}
										const debounceSave = _.debounce(() => {
											handleEmailChange({
												...values,
												userEmail: e.target.value,
											});
										}, 500);
										debounceSave();
									},
								}}
							/>
							{inList && !errors?.userEmail && (
								<span className={styles.alreadyMemberMsg}>
									{t('members_add_member_user_already_exist')}
								</span>
							)}

							{!!memberType &&
							(memberType === AddMemberType.NewUser ||
								(memberType === AddMemberType.InOrgUserUnConfirmed &&
									!!values.userName?.length) ||
								(memberType === AddMemberType.NotInOrgUserConfirmed &&
									!!values.userName?.length)) ? (
								<>
									<InputLabel htmlFor="userName" shrink={false}>
										{t('globals_name')}
									</InputLabel>

									<Field
										name="userName"
										type="text"
										placeholder="Enter Name"
										component={TextField}
										fullWidth
										variant="outlined"
										disabled={memberType !== AddMemberType.NewUser}
									/>
								</>
							) : null}

							<div className={styles.bottomSec}>
								<CustomButton
									type="submit"
									block
									disabled={
										addMemberLoadingState ||
										inList ||
										!(
											isValid &&
											((memberType !== AddMemberType.NewUser && !!memberType) ||
												!!values.userName?.length)
										)
									}
									loader={addMemberLoadingState}
								>
									{memberType === AddMemberType.InOrgUserUnConfirmed
										? t('members_add_member_resend_invitation')
										: memberType === AddMemberType.NotInOrgUserConfirmed ||
										  memberType === AddMemberType.NotInOrgUserUnConfirmed
										? t('members_add_member_send_invitation')
										: t('members_add_member_btn_text')}
								</CustomButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
