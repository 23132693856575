import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import typography from 'theme/typography';
import overrides from 'theme/overrides';
import palette from 'theme/palette';

export default function createMyTheme(options: ThemeOptions) {
	return createMuiTheme({
		spacing: 10,
		palette,
		typography,
		overrides,
		...options,
	});
}
