import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'utils/history';
import { actions } from '../../slice';
import { selectUrlLoader } from '../../selectors';
import CustomButton from 'app/components/Typos/ButtonComponent';
import {
	selectIntegrationByProvider,
	getHubSpotConnectedUser,
} from '../../selectors';
import styles from './style.module.scss';
import { Providers, HubSpotCredsInput } from 'API';

const clearCredentials: HubSpotCredsInput = {
	accessToken: '',
	refreshToken: '',
	tokenType: '',
	expiresIn: '',
};

interface Props {
	baseUrl: string;
	provider: Providers;
}

export const AuthorizationForm = ({ baseUrl, provider }: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();
	const loading = useSelector(selectUrlLoader);
	const [t, i18n] = useTranslation();
	const hubspot = useSelector(selectIntegrationByProvider(Providers.hubspot));
	const identity = useSelector(getHubSpotConnectedUser);
	const {
		params: { tab },
	} = useRouteMatch();

	// get hubspot user identity
	useEffect(() => {
		if (hubspot?.credentials?.accessToken) {
			dispatch(
				actions.fetchHubSpotIdentity({
					accessToken: hubspot.credentials.accessToken,
				})
			);
		}
	}, [dispatch, hubspot]);

	const disconnectHubSpot = () => {
		dispatch(
			actions.modifyIntegration({
				...hubspot,
				integrationId: hubspot?.id,
				attributes: null,
				lists: null,
				configuration: null,
				credentials: clearCredentials,
			})
		);
		dispatch(actions.removeHubSpotIdentity({}));
	};

	// handle authorization return
	useEffect(() => {
		if (tab === 'authorization_success') {
			const code = query.get('code');
			if (window.opener) {
				window.opener.location.href =
					'authorization_success?code=' +
					encodeURIComponent(query.get('code') as any);
				window.close();
			}
			dispatch(actions.getHubSpotOauth({ code }));
			history.push(`${baseUrl}/${provider}/authorization`);
		}
	}, [baseUrl, dispatch, history, provider, query, tab]);

	return (
		<div>
			<Typography
				variant="body2"
				data-translation-key="integrations_hubspot_authorization_description"
			>
				{t('integrations_hubspot_authorization_description')}
			</Typography>
			{identity && (
				<>
					<TextField
						id="hubspot-domain"
						label="Domain"
						defaultValue={identity?.hubDomain}
						InputProps={{
							readOnly: true,
						}}
						fullWidth
						variant="outlined"
					/>
					<TextField
						id="hubspot-user"
						label="User"
						defaultValue={identity?.user}
						InputProps={{
							readOnly: true,
						}}
						fullWidth
						variant="outlined"
					/>
				</>
			)}
			<div className={styles.connectSec}>
				{identity ? (
					<>
						<span className={styles.connected}>{'Connected'}</span>
						<span style={{ color: '#FF7D5E' }} onClick={disconnectHubSpot}>
							{'Disconnect'}
						</span>
					</>
				) : (
					<CustomButton
						variant="contained"
						type="button"
						onClick={() => dispatch(actions.generateHubSpotUrl())}
						loader={loading}
						disabled={loading}
					>
						{t('integrations_hubspot_connect')}
					</CustomButton>
				)}
			</div>
		</div>
	);
};
