import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../slice';
import { Loader } from 'app/components/Loader';

export const CompleteSignUpContainer = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const userData = localStorage.getItem('user');
		const user = userData && JSON.parse(userData);
		localStorage.removeItem('user');
		dispatch(actions.signUp(user));
	}, [dispatch]);

	return <Loader />;
};
