import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
	black,
	white,
	primary: {
		contrastText: white,
		dark: colors.indigo[900],
		main: colors.indigo[500],
		light: colors.indigo[100],
	},
	buttonPrimary: {
		main: '#FAA675',
		light: '#FAA675',
		dark: '#FF9A44',
	},
	buttonSecondary: {
		main: '#E5E8F5',
		light: '#E5E8F5',
		dark: '#E5E8F5',
	},
	secondary: {
		contrastText: white,
		dark: colors.blue[900],
		main: colors.blue[500],
		light: colors.blue['A400'],
	},
	success: {
		contrastText: white,
		dark: '#63a705',
		main: '#79CD04',
		light: '#8eed0a',
	},
	info: {
		contrastText: white,
		dark: colors.blue[900],
		main: colors.blue[600],
		light: colors.blue[400],
	},
	warning: {
		contrastText: white,
		dark: colors.orange[900],
		main: colors.orange[600],
		light: colors.orange[400],
	},
	error: {
		contrastText: white,
		dark: '#f64920',
		main: '#FF7D5E',
		light: colors.red[400],
	},
	text: {
		primary: '#383D58',
		secondary: '#868DAC',
		link: '#048CFF',
	},
	background: {
		default: '#F4F6F8',
		paper: white,
	},
	// icon: colors.blueGrey[600],
	divider: '#E5E8F5',
};
