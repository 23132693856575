import React from 'react';

import { TriggerLeaf } from 'API';
import styles from '../../style.module.scss';

interface Props extends TriggerLeaf {
	id: string;
	triggerId: string;
	editable: boolean;
	handleUpdate: Function;
}

export const Event = (props: Props) => {
	return <div className={styles.nodeExpression}></div>;
};

export default Event;
