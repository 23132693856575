import React from 'react';
import { useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { Typography as MuiTypography } from '@material-ui/core';
import { actions } from '../slice';
import Button from '@material-ui/core/Button';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import styles from './style.module.scss';
import { ConditionType, Operators } from 'API';

export function CreateAudience() {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const history = useHistory();
	const org = useSelector(selectedOrganization);

	return (
		<div className={styles.wrapper}>
			<MuiTypography
				variant="h3"
				className={styles.header}
				style={{ padding: '0px 24px' }}
			>
				{t('audiences_create_audience_title')}
			</MuiTypography>
			<MuiTypography variant="body2">
				{t('audiences_create_audience_description')}
			</MuiTypography>
			<Divider />
			<Formik
				initialValues={{
					name: '',
					description: '',
				}}
				onSubmit={async values => {
					dispatch(
						actions.modifyAudience({
							audience: {
								...values,
								group: org.group.replace(/ /gi, '-').toLowerCase(),
								organizationId: org.id,
							},
							conditions: {
								typeOf: ConditionType.MARKETO,
								node: {
									operator: Operators.equalTo,
									value: '1',
								},
							},
						})
					);
					history.goBack();
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string().required('This Field is Required'),
					description: Yup.string().required('This Field is Required'),
				})}
			>
				{({ errors, touched }) => {
					return (
						<Form>
							<div className={styles.fieldWrapper}>
								<Field
									type="text"
									name="name"
									placeholder={t('audiences_create_audience_name_placeholder')}
									component={TextField}
									InputLabelProps={{ shrink: false }}
									fullWidth
									variant="outlined"
								/>
							</div>
							<div className={styles.fieldWrapper}>
								<Field
									type="text"
									name="description"
									placeholder={t('audiences_create_audience_new_description')}
									component={TextField}
									InputLabelProps={{ shrink: false }}
									fullWidth
									variant="outlined"
								/>
							</div>
							<div className={styles.bottomSec}>
								<Button
									type="submit"
									variant="contained"
									disabled={!touched || !errors}
									fullWidth
								>
									{t('globals_next_step')}
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
}
