import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../slice';
import { selectFetchingData } from '../selectors';
import AuthWrapper from '../AuthWrapper';
import { TextField } from 'formik-material-ui';
import styles from './style.module.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextTitle from 'app/components/Typos/TextTitle';
import CustomButton from 'app/components/Typos/ButtonComponent';
import TextComp from 'app/components/Typos/TextComponent';
import LinkComponent from 'app/components/Typos/LinkComponent';
import { history } from 'utils/history';
import queryString from 'query-string';
import { AmplifyAuthStatus } from 'app/containers/AuthContainer/types';
import { passwordRequirements } from 'app/containers/AuthContainer/passwordRequirements';
type confirmInfo = {
	username: string;
	code: string;
};

export const ConfirmSignUp = () => {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const queryParam = queryString.parse(history.location.search);
	const isFetchingData: boolean = useSelector(selectFetchingData);
	const submitForm = (userInfo: confirmInfo) =>
		dispatch(actions.signUpConfirm(userInfo));
	const handleResendCode = (username: string) =>
		dispatch(actions.signUpResendCode(username));

	return (
		<AuthWrapper>
			<div className={styles.confirmWrapper}>
				<TextTitle>{t('confirm_signup_title')}</TextTitle>
				<Formik
					initialValues={{
						username: queryParam.user || '',
						password: '',
						code: '',
					}}
					validationSchema={Yup.object().shape({
						username: Yup.string()
							.email(t('globals_email_invalid'))
							.required(t('globals_email_required')),
						password: passwordRequirements(t),
						code: Yup.string().required(
							t('auth_common_confirmation_code_required')
						),
					})}
					onSubmit={(values, { setSubmitting }) => {
						submitForm(values);
						setSubmitting(false);
					}}
				>
					{({ values, isValid, dirty, isSubmitting, setTouched, touched }) => {
						return (
							<Form>
								{!queryParam.user && (
									<Field
										type="text"
										name="username"
										placeholder={t('globals_email_placeholder')}
										label={t('globals_email')}
										component={TextField}
										fullWidth
										variant="outlined"
									/>
								)}
								<Field
									type="text"
									name="code"
									placeholder={t('auth_common_confirmation_code_placeholder')}
									label={t('auth_common_confirmation_code')}
									component={TextField}
									fullWidth
									variant="outlined"
								/>
								<Field
									name="password"
									type="password"
									label={t('globals_password')}
									placeholder={t('globals_password_placeholder')}
									component={TextField}
									fullWidth
									variant="outlined"
								/>
								<div className="mb-3">
									<TextComp>{t('confirm_signup_lost_code')}</TextComp>{' '}
									<CustomButton
										onClick={() => {
											setTouched({ username: true }, true);
											//either userEMail in url  or manually typed
											if (values?.username?.length > 0 || touched?.username) {
												handleResendCode(values.username);
											}
										}}
										feature="conditional-btn"
										variant="outlined"
										style={{ textTransform: 'capitalize', height: 'auto' }}
									>
										{t('confirm_signup_resend')}
									</CustomButton>
								</div>
								<div className={`mb-3 ${styles.confirmBtnWrapper}`}>
									<LinkComponent link={AmplifyAuthStatus.login}>
										{t('auth_common_back_to_login')}
									</LinkComponent>
									<CustomButton
										type="submit"
										disabled={
											isSubmitting || !(isValid && dirty) || isFetchingData
										}
										loader={isFetchingData}
									>
										{t('globals_confirm')}
									</CustomButton>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</AuthWrapper>
	);
};
