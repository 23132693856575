import { createSelector } from '@reduxjs/toolkit';
import { Providers } from 'API';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state?.integrationsContainer || initialState;

export const listIntegrations = createSelector(
	[selectDomain],
	integrationsState => integrationsState.integrations
);

export const selectIntegration = (id: string | undefined) =>
	createSelector([selectDomain], integrationsState => {
		return id ? integrationsState.integrations.find(i => i.id === id) : null;
	});

export const selectIntegrationByProvider = (provider: Providers) =>
	createSelector([selectDomain], integrationsState =>
		integrationsState.integrations.find(i => i.provider === provider)
	);

export const selectFetchingData = createSelector(
	[selectDomain],
	integrationsState => integrationsState.fetchingData
);

export const selectUrlLoader = createSelector(
	[selectDomain],
	integrationsState => integrationsState.gettingUrlLoader
);

export const getSfOpportunity = createSelector(
	[selectDomain],
	integrationsState => integrationsState.sfOpportunities
);

export const getSfConnectedUser = createSelector(
	[selectDomain],
	integrationsState => integrationsState.sfConnectedUser
);

export const getHubSpotConnectedUser = createSelector(
	[selectDomain],
	integrationsState => integrationsState.hubSpotConnectedUser
);

export const getReportData = createSelector(
	[selectDomain],
	integrationsState => integrationsState.reportData
);

export const getMarketoReportStats = createSelector(
	[selectDomain],
	integrationsState => integrationsState.marketoReportStats
);

export const getCheckedMarketoCreds = createSelector(
	[selectDomain],
	integrationsState => integrationsState.marketoCredentials
);

export const isMarketoCredsConnected = createSelector(
	[selectDomain],
	integrationsState => integrationsState.isMarketoCredsConnected
);

export const selectListOpen = createSelector(
	[selectDomain],
	integrationsState => integrationsState.listOpen
);

export const selectMarketoListsToBe = createSelector(
	[selectDomain],
	integrationsState => integrationsState.marketoListsToBe
);

export const selectAudienceToBeLists = createSelector(
	[selectDomain],
	integrationsState => integrationsState.audienceToBeLists
);

export const selectMarketoLeads = createSelector(
	[selectDomain],
	integrationsState => integrationsState.marketoLeads
);

export const selectAllDeselected = createSelector(
	[selectDomain],
	integrationsState => integrationsState.allDeselected
);

export const selectConfigurations = createSelector(
	[selectDomain],
	integrationsState => integrationsState.marketoConfigurations
);

export const selectFetchingSfOpportunities = createSelector(
	[selectDomain],
	integrationsState => integrationsState.fetchingSfOpportunities
);

export const getSelectedOpportunities = createSelector(
	[selectDomain],
	integrationsState => integrationsState.selectedOpportunities
);
