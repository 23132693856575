import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { useSelector } from 'react-redux';
import { selectFetchingData } from 'app/containers/IntegrationsContainer/selectors';
import CustomButton from 'app/components/Typos/ButtonComponent';
import styles from './style.module.scss';
import cx from 'classnames';

type Props = {
	disabled?: boolean;
	onSave: Function;
	onCancel: Function;
};

export const ButtonSection = ({
	disabled = false,
	onSave,
	onCancel,
}: Props) => {
	const [t, i18n] = useTranslation();
	const isFetchingData = useSelector(selectFetchingData);

	return (
		<DialogActions
			className={cx({
				[styles.buttons]: true,
				[styles.disabledButtons]: disabled,
			})}
		>
			<Button
				fullWidth
				variant="contained"
				onClick={() => onCancel()}
				style={{ background: '#868dac' }}
			>
				{t('globals_cancel')}
			</Button>
			<CustomButton
				type="button"
				onClick={() => onSave()}
				block
				disabled={isFetchingData || disabled}
			>
				{t('globals_save')}
			</CustomButton>
		</DialogActions>
	);
};
