import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typography as MuiTypography } from '@material-ui/core';
import { actions } from '../../../slice';
import {
	selectFetchingData,
	getReportData,
	getMarketoReportStats,
} from '../../../selectors';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import styles from './style.module.scss';
import { Loader } from 'app/components/Loader';
import { DatePickerComp } from 'app/components/DatePickerComp';
import _ from 'lodash';
import AreaChart from 'app/components/AreaChart';
import { layout } from 'app/components/AreaChart/fakeData';
import moment from 'moment';
import { Sup } from 'app/components/Sup';
import { getAscendingFormattedChartData } from 'utils/chartData';

export function MarketoReport(props) {
	const dispatch = useDispatch();
	const loading = useSelector(selectFetchingData);
	const orgData = useSelector(selectedOrganization);
	const reportData = useSelector(getReportData);
	const marketoReportStats = useSelector(getMarketoReportStats);
	const [t, i18n] = useTranslation();

	//provided date for given api 2020-09-01 to 2020-12-24
	const [start_time, setStartTime] = useState(moment().subtract(7, 'd'));
	//to show till last day date in datepicker
	const [end_time, setEndTime] = useState(moment().subtract(1, 'd'));

	//fetch Audience report stats
	useEffect(() => {
		dispatch(
			actions.fetchMarketoReportStats({
				organization_id: orgData?.id,
			})
		);
	}, [orgData, dispatch]);

	//fetch Report Data with correct api date format
	useEffect(() => {
		dispatch(
			actions.fetchMarketoReport({
				start_time: moment(start_time).format('YYYY-MM-DD'),
				end_time: moment(end_time).format('YYYY-MM-DD'),
				organization_id: orgData?.id,
			})
		);
	}, [dispatch, start_time, end_time, orgData]);

	//set new dates
	const setNewDateChange = (s, e) => {
		setStartTime(moment(s));
		setEndTime(moment(e));
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<div className={styles.reportWrapper}>
			<div className={styles.wrapper}>
				<MuiTypography
					variant="h3"
					className={styles.header}
					style={{ padding: '0px 24px' }}
				>
					{t('integrations_report_marketo_title')}
				</MuiTypography>
				<MuiTypography variant="body2">
					{t('integrations_report_marketo_description')}
				</MuiTypography>
			</div>
			<div className={styles.statsDateWrapper}>
				<div className={styles.recordWrapper}>
					<div>
						<MuiTypography variant="body2">
							{t('integrations_report_marketo_total_leads')}
						</MuiTypography>
						<MuiTypography variant="h2">
							{marketoReportStats[0]?.total_leads}
							<Sup value={marketoReportStats[0]?.total_leads_increment} />
						</MuiTypography>
					</div>
					<div>
						<MuiTypography variant="body2">
							{t('integrations_report_marketo_leads_imported')}{' '}
						</MuiTypography>
						<MuiTypography variant="h2">
							{marketoReportStats[0]?.leads_imported_last_7days}
							<Sup value={marketoReportStats[0]?.leads_imported_increment} />
						</MuiTypography>
					</div>
				</div>
				<div className={styles.DatePickerWrapper}>
					<DatePickerComp
						startDate={start_time}
						setStartDate={setStartTime}
						endDate={end_time}
						setEndDate={setEndTime}
						startDateLimit={moment('1970-01-01')} //from start of a date
					/>
				</div>
			</div>

			{reportData && reportData.length > 0 ? (
				<AreaChart
					data={getAscendingFormattedChartData(
						reportData,
						end_time.diff(start_time, 'days') + 1,
						'date',
						'updated_leads',
						'#FF9A44',
						'<b>Leads Imported</b>' +
							'<br><b>%{x}</b><br>' +
							'<b>%{y}</b><br>' +
							'<extra></extra>'
					)}
					layout={layout([start_time.toISOString(), end_time.toISOString()])}
					style={{ flexGrow: '1' }}
				/>
			) : (
				<div className={styles.noDataWrapper}>
					<span
						data-translation-key={'integrations_report_marketo_no_data_found'}
					>
						{t('integrations_report_marketo_no_data_found')}
					</span>
				</div>
			)}
		</div>
	);
}
