import React from 'react';

import { OperatorDropDown } from '../OperatorDropDown';
import { ValueInput } from '../ValueInput';
import { Operators, GoalLeaf } from 'API';
import styles from '../../style.module.scss';

const operators = [Operators.archived, Operators.notArchived];

interface Props extends GoalLeaf {
	id: string;
	editable: boolean;
	handleUpdate: Function;
}

export const Goal = (props: Props) => {
	const { id, editable, value, operator, handleUpdate } = props;

	const updateValue = (updateValue: string) =>
		handleUpdate(id, { value: updateValue });
	const updateOperator = (updateOperator: string) =>
		handleUpdate(id, { operator: updateOperator });

	return (
		<div className={styles.nodeExpression}>
			<OperatorDropDown
				disabled={!editable}
				operator={operator || ''}
				operators={operators}
				setOperator={updateOperator}
			/>
			<ValueInput
				disabled={!editable}
				value={value || ''}
				setValue={updateValue}
				placeholder="Goal ID"
			/>
		</div>
	);
};

export default Goal;
