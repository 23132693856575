import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Typography as MuiTypography,
	Divider,
	Button,
} from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Switch } from '@material-ui/core';
import { GoogleTagManagerIcon } from 'app/containers/IntegrationsContainer/Destinations/GoogleTagManager/GoogleTagManagerIcon';
import { UniversalAnalyticsIcon } from 'app/containers/IntegrationsContainer/Destinations/UniversalAnalytics/UniversalAnalyticsIcon';
import styles from './style.module.scss';
import { Conditions } from 'app/components/Conditions';
import { ParentObject } from 'app/components/Conditions/types';
import {
	selectedAudience,
	selectModalFetchingData,
} from 'app/containers/AudiencesContainer/selectors';
import { selectFeatureFlags } from 'app/containers/AuthContainer/selectors';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { actions as actionsAction } from 'app/containers/ActionsContainer/slice';
import { CustomMenu } from 'app/components/Menu';
import { actions as audienceAction } from '../slice';
import { Loader } from 'app/components/Loader';
import { Providers } from 'API';
import _ from 'lodash';

export const ViewAudience = () => {
	const history = useHistory();
	const [t, i18n] = useTranslation();
	const match = useRouteMatch();
	const { params } = match;
	const { id } = params as any;
	const dispatch = useDispatch();
	const selected = useSelector(selectedAudience);
	const org = useSelector(selectedOrganization);
	const loading = useSelector(selectModalFetchingData);
	// const currentAudienceActions = useSelector(selectActionByTriggerID(id));
	const [switchState, setSwitchState] = useState({});

	const handleChange = (
		i,
		event: React.ChangeEvent<HTMLInputElement>,
		checked,
		action
	) => {
		const list = Object.assign({}, switchState);
		list['switch-' + i] = checked;
		setSwitchState(list);
		const { destination, ...rest } = action;
		dispatch(
			actionsAction.modifyAction({
				id: action?.id,
				action: { ...rest, isActive: checked },
			})
		);
	};

	const setDynamicSwitchState = list => {
		if (!list) {
			return;
		}
		const switchState = {};

		list.forEach((item, index) => {
			switchState['switch-' + index] = item.isActive || false;
		});

		setSwitchState(switchState);
	};

	useEffect(() => {
		setDynamicSwitchState(selected?.actions?.items);
	}, [selected]);

	const handleEditAudience = () => {
		history.push(`edit/${id}`);
	};

	const handleCreateAction = () => {
		history.push(`create-action/${id}`);
	};

	//Get current Audience
	useEffect(() => {
		dispatch(
			audienceAction.getAudience({
				id: id,
			})
		);
	}, [dispatch, id]);

	const handleEditAction = actionId => history.push(`edit-action/${actionId}`);

	if (loading) {
		return <Loader />;
	}
	if (!_.isEmpty(selected)) {
		return (
			<div className={styles.viewWrapper}>
				<div className={styles.wrapper}>
					<div className={styles.headWrapper}>
						<MuiTypography variant="h3" className={styles.header}>
							{selected.name}
						</MuiTypography>
						<Button type="button" onClick={handleEditAudience}>
							{t('audiences_edit_audience')}
						</Button>
					</div>
					<MuiTypography variant="body2">{selected.description}</MuiTypography>
				</div>
				<Divider />
				<div className={styles.wrapper}>
					<MuiTypography variant="h3" className={styles.header}>
						Conditions
					</MuiTypography>
					<Conditions
						parentObject={ParentObject.Audience}
						conditions={selected?.conditions}
					/>
				</div>
				<Divider />
				<div className={`${styles.wrapper} ${styles.actions}`}>
					<MuiTypography variant="h3" className={styles.header}>
						{t('actions_title')}
					</MuiTypography>
					{selected?.actions?.items?.map((item, i) => {
						return (
							<div className={styles.actionWrap} key={item.id}>
								<span>
									{item?.destination?.provider ===
										Providers.universalAnalytics && <UniversalAnalyticsIcon />}
									{item?.destination?.provider ===
										Providers.googleTagManager && <GoogleTagManagerIcon />}
								</span>
								<div className={styles.actionDetail}>
									<div>
										<MuiTypography variant="body2">{item.name}</MuiTypography>
										<MuiTypography variant="body2">
											{item.description}
										</MuiTypography>
									</div>

									<CustomMenu
										id={item?.id}
										onEdit={() => handleEditAction(item?.id)}
									/>
								</div>
								<div className={styles.switch}>
									<FormGroup>
										<Switch
											key={i}
											checked={switchState['switch-' + i] || false}
											onChange={(event, checked) =>
												handleChange(i, event, checked, item)
											}
											value={switchState['switch-' + i]}
											inputProps={{ 'aria-label': 'active action' }}
										/>
									</FormGroup>
								</div>
							</div>
						);
					})}

					<div className={styles.addAction}>
						<Button
							variant="contained"
							type="button"
							onClick={handleCreateAction}
							fullWidth
						>
							{selected?.actions?.items.length ? '+' : 'Add Action'}
						</Button>
					</div>
				</div>
			</div>
		);
	}

	return null;
};
