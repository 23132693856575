import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import NameReportTableCell from 'app/components/NameReportTableCell';
import { NoDataFound } from 'app/components/NoDataFound';
import { ArchiveComp } from 'app/components/ArchiveComp';
import Pagination, {
	Props as PaginationProps,
} from 'app/components/Pagination';
import { actions as actionsSlice } from '../slice';
import { IAction } from '../types';
import { getFormattedSourceAndIcon } from './utils';
import styles from './style.module.scss';

interface Props extends PaginationProps {
	actions: IAction[];
}

const ActionTable = (props: Props) => {
	const { actions, ...paginationProps } = props;
	const [t, i18n] = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { path } = useRouteMatch();
	const [toArchive, setToArchive] = useState<{
		id: string;
		name: string;
	} | null>(null);

	const handleArchive = () => {
		dispatch(actionsSlice.archiveAction({ ...toArchive, isArchived: true }));
		setToArchive(null);
	};

	if (actions.length === 0) {
		return (
			<NoDataFound
				heading={{
					key: 'actions_no_actions_msg',
					text: t('actions_no_actions_msg'),
				}}
				description={{
					key: 'actions_no_actions_sub_msg',
					text: t('actions_no_actions_sub_msg'),
				}}
			/>
		);
	}
	return (
		<Paper className={styles.wrapperPaper}>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Fired (Last 30 Days)</TableCell>
							<TableCell>Trigger Count</TableCell>
							<TableCell>Last Updated</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{actions.map(
							({
								id,
								name,
								destination,
								description,
								updatedAt,
								firedLast30d,
								triggers,
							}) => (
								<TableRow key={id}>
									<NameReportTableCell
										id={id}
										name={name}
										icon={
											getFormattedSourceAndIcon(destination?.provider)?.Icon
										}
										description={description}
										handleViewClick={() => history.push(`${path}/${id}`)}
										handleArchiveClick={() => setToArchive({ id, name })}
										handleReportClick={() =>
											history.push(`${path}/report/${id}`)
										}
									/>
									<TableCell>{firedLast30d ?? 0}</TableCell>
									<TableCell>
										<span
											className={styles.clickable}
											onClick={() => history.push(`${path}/assign/${id}`)}
										>
											{triggers?.items?.length ?? 0}
										</span>
									</TableCell>
									<TableCell>
										{moment(updatedAt).format('MM/DD/YYYY')}
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<ArchiveComp
				open={!isEmpty(toArchive)}
				name={toArchive?.name ?? null}
				handleClose={() => setToArchive(null)}
				handleArchive={handleArchive}
			/>
			<Pagination {...paginationProps} />
		</Paper>
	);
};

export default ActionTable;
