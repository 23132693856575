import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectOrganizationDomain = (state: RootState) =>
	state?.organizationsContainer || initialState;

const selectOrganizationDomainAuth = (state: RootState) =>
	state?.authContainer || initialState;

export const selectError = createSelector(
	[selectOrganizationDomain],
	state => state.error
);

export const selectOrganizations = createSelector(
	[selectOrganizationDomain],
	stateOrganization => stateOrganization.organizations
);

export const selectedOrganization = createSelector(
	[selectOrganizationDomain, selectOrganizationDomainAuth],
	stateOrganization => stateOrganization.selectedOrganization
);

export const selectFetchingData = createSelector(
	[selectOrganizationDomain],
	state => state.fetchingData
);

export const selectFetchingDataList = createSelector(
	[selectOrganizationDomain],
	state => state.fetchingDataList
);

export const selectPermissionState = createSelector(
	[selectOrganizationDomain],
	state => state.isPermissionDenied
);

export const selectIsOrgChange = createSelector(
	[selectOrganizationDomain],
	state => state.changedOrgId
);
