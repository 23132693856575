export const FakeData = name => [
	{
		x: [
			'2020-11-12',
			'2020-11-14',
			'2020-11-16',
			'2020-11-18',
			'2020-11-20',
			'2020-11-22',
			'2020-11-24',
			'2020-11-26',
			'2020-11-28',
			'2020-11-30',
			'2020-12-02',
			'2020-12-04',
			'2020-12-06',
			'2020-12-08',
			'2020-12-10',
		],
		y: [
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
			Math.floor(Math.random() * 100),
		],
		type: 'scatter',
		fill: 'tonexty',
		text: 'Action Triggered',
		marker: { color: '#FF9A44' },
		line: { shape: 'spline', smoothing: 1.3 },
		hovertemplate:
			'<b>%{marker.size}</b><br><br>' +
			'%{xaxis.title.text}: %{x}<br>' +
			'Number Employed: %{marker.size:,}' +
			'<extra></extra>',
	},
];

export const layout = dateRange => ({
	hovermode: 'closest',
	hoverlabel: { bgcolor: '#FFF' },
	showlegend: false,
	margin: {
		l: 30,
		r: 10,
		t: 40,
		b: 30,
		pad: 5,
	},
	yaxis: {
		autorange: true,
		rangemode: 'nonnegative',
	},
	xaxis: {
		type: 'date',
		tickformat: '%d %b',
		hovermode: 'x undefined',
		autorange: false,
		range: dateRange,
	},
});
