import { API, graphqlOperation } from 'aws-amplify';
import { PayloadAction } from '@reduxjs/toolkit';
import { GraphQLResult } from '@aws-amplify/api';
import {
	all,
	call,
	put,
	takeLatest,
	takeEvery,
	select,
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { toast } from 'react-toastify';

import { errorMessageHandler } from 'utils/errorMessage';
import {
	Trigger,
	Condition,
	ConditionInput,
	CreateTriggerMutation,
	UpdateTriggerMutation,
	GetTriggerQuery,
	GetTriggerQueryVariables,
	TriggersByOrganizationQuery,
	TriggersByOrganizationQueryVariables,
} from 'API';
import { triggersByOrganization, getTrigger } from 'graphql/queries';
import {
	createTrigger,
	updateTrigger,
	editTriggerCondition,
} from 'graphql/mutations';
import { actions as actionTriggerActions } from 'app/containers/ActionTriggerContainer/slice';
import { handleCreateActionTrigger } from 'app/containers/ActionTriggerContainer/saga';
import { actions as actionActions } from 'app/containers/ActionsContainer/slice';
import { selectActionByID } from 'app/containers/ActionsContainer/selectors';
import { selectTriggerByID, selectIndexRange } from './selectors';
import { actions } from './slice';

const errorMessage = (err: Error) =>
	errorMessageHandler(err, 'Error occurred fetching Triggers.');

export function* handleError(err: Error) {
	const errMessage = errorMessage(err);
	yield put(actions.setTriggerError(errMessage));
	yield call(toast.error, errMessage);
}

export function* handleFetchTriggers(
	action: PayloadAction<TriggersByOrganizationQueryVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<TriggersByOrganizationQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(triggersByOrganization, action.payload)
		);
		yield put(
			actions.fetchTriggersSuccess(
				data?.triggersByOrganization?.items as Trigger[]
			)
		);
		yield put(actions.setNextToken(data?.triggersByOrganization?.nextToken));
		yield put(
			actions.setIndexRange([
				0,
				parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10') - 1,
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleGetTrigger(
	action: PayloadAction<GetTriggerQueryVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<GetTriggerQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(getTrigger, action.payload)
		);
		yield put(actions.getTriggerSuccess(data?.getTrigger as Trigger));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleNext() {
	try {
		const indexRange = yield select(selectIndexRange);
		yield put(
			actions.setIndexRange([
				indexRange[0] + parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
				indexRange[1] + parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handlePrevious() {
	try {
		const indexRange = yield select(selectIndexRange);
		yield put(
			actions.setIndexRange([
				indexRange[0] - parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
				indexRange[1] - parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleModifyTrigger(action: PayloadAction<Trigger>) {
	try {
		let triggerId: string | undefined = action.payload?.id;

		const { conditions, ...rest } = action.payload;
		triggerId = triggerId
			? yield* handleUpdateTrigger({ ...action, payload: rest })
			: yield* handleCreateTrigger({ ...action, payload: rest });

		if (conditions) {
			yield put(
				actions.editTriggerCondition({
					triggerId,
					conditions,
				})
			);
		}
		return triggerId;
	} catch (err) {
		yield call(handleError, err);
	}
}

function* handleCreateTrigger(action: PayloadAction<Trigger>) {
	try {
		const { data }: GraphQLResult<CreateTriggerMutation> = yield call(
			[API, 'graphql'],
			graphqlOperation(createTrigger, { input: action.payload })
		);
		yield put(actions.createTriggerSuccess(data?.createTrigger as Trigger));
		return data?.createTrigger?.id;
	} catch (err) {
		yield call(handleError, err);
	}
}

function* handleUpdateTrigger(action: PayloadAction<Trigger>) {
	try {
		const { data }: GraphQLResult<UpdateTriggerMutation> = yield call(
			[API, 'graphql'],
			graphqlOperation(updateTrigger, { input: action.payload })
		);
		yield put(actions.updateTriggerSuccess(data?.updateTrigger as Trigger));
		return data?.updateTrigger?.id;
	} catch (err) {
		yield call(handleError, err);
	}
}

function* handleEditTriggerCondition(
	action: PayloadAction<{ triggerId: string; conditions: ConditionInput }>
): SagaIterator {
	try {
		const { triggerId, conditions } = action.payload;
		const { data }: GraphQLResult<Condition> = yield call(
			[API, 'graphql'],
			graphqlOperation(editTriggerCondition, { triggerId, input: conditions })
		);
		yield put(
			actions.editTriggerConditionSuccess({
				id: action.payload.triggerId,
				conditions: JSON.stringify(data),
			})
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleAssignActions(
	action: PayloadAction<{
		group: string;
		triggerId: string;
		actionIds: string[];
	}>
) {
	try {
		const { group, triggerId, actionIds } = action.payload;
		const t = yield select(selectTriggerByID(triggerId));
		const existingActionIds = t.actions.items.map(a => a.actionId);
		const actionsToDelete = actionIds.length
			? existingActionIds.map(
					(actionId: string) => actionIds.indexOf(actionId) === -1
			  )
			: existingActionIds;
		const createActionTriggers = actionIds
			// filter actions that already have the assignment
			.filter((actionId: string) => existingActionIds.indexOf(actionId) === -1)
			.map((actionId: string) => {
				return put(
					actions.assignAction({
						group,
						actionId,
						trigger: t,
					})
				);
			});
		const deleteActionTriggers = actionsToDelete.map((actionId: string) =>
			put(actions.removeAction({ group, actionId, trigger: t }))
		);
		yield all(createActionTriggers);
		yield all(deleteActionTriggers);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleAssignAction(
	action: PayloadAction<{ group: string; actionId: string; trigger: Trigger }>
) {
	try {
		const { group, actionId, trigger: t } = action.payload;
		const a = yield select(selectActionByID(actionId));
		const actionTrigger = yield* handleCreateActionTrigger({
			...action,
			payload: {
				group,
				actionId,
				triggerId: t?.id ?? '',
			},
		});
		yield put(actions.assignActionSuccess(actionTrigger));
		yield put(actionActions.assignTriggerSuccess(actionTrigger));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleRemoveAction(
	action: PayloadAction<{ group: string; actionId: string; trigger: Trigger }>
) {
	try {
		const { actionId, trigger: t } = action.payload;
		const a = yield select(selectActionByID(actionId));
		const actionTriggerId =
			(t?.actions?.items || []).find(a => a?.actionId === actionId)?.id ?? null;
		if (actionTriggerId) {
			yield put(
				actionTriggerActions.deleteActionTrigger({ id: actionTriggerId })
			);
			yield put(
				actions.removeActionSuccess({
					action: a,
					trigger: t,
				})
			);
			yield put(
				actionActions.removeTriggerSuccess({
					action: a,
					trigger: t,
				})
			);
		}
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* triggersContainerSaga() {
	yield takeLatest(actions.fetchTriggers.type, handleFetchTriggers);
	yield takeLatest(actions.getTrigger.type, handleGetTrigger);
	yield takeLatest(actions.createTrigger.type, handleCreateTrigger);
	yield takeLatest(actions.updateTrigger.type, handleUpdateTrigger);
	yield takeLatest(actions.modifyTrigger.type, handleModifyTrigger);
	yield takeLatest(
		actions.editTriggerCondition.type,
		handleEditTriggerCondition
	);
	yield takeLatest(actions.next.type, handleNext);
	yield takeLatest(actions.previous.type, handlePrevious);
	yield takeLatest(actions.assignActions.type, handleAssignActions);
	yield takeEvery(actions.assignAction.type, handleAssignAction);
	yield takeEvery(actions.removeAction.type, handleRemoveAction);
}
