import React, { ChangeEvent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { formatTitleCase } from 'utils/formatTitleCase';

interface Props {
	disabled: boolean;
	operator: string;
	operators: string[];
	setOperator: (value: string) => void;
}

export const OperatorDropDown = ({
	disabled,
	operator,
	operators,
	setOperator,
}: Props) => {
	const menuItems: JSX.Element[] = operators.map(o => (
		<MenuItem key={o} value={o}>
			{formatTitleCase(o)}
		</MenuItem>
	));
	const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
		setOperator(event.target.value as string);
	};

	return (
		<Select
			disabled={disabled}
			id="operator-selector"
			value={operator}
			onChange={handleChange}
			variant="outlined"
		>
			{menuItems}
		</Select>
	);
};

export default OperatorDropDown;
