import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Providers } from 'API';
import { integrationsContainerSaga } from '../../saga';
import { reducer, sliceKey, actions } from '../../slice';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { Divider, Typography } from '@material-ui/core';
import commonIntStyles from 'app/containers/IntegrationsContainer/style.module.scss';
import { selectIntegrationByProvider } from 'app/containers/IntegrationsContainer/selectors';
import { Header } from '../../components/Header';
import { NotActivated } from '../../components/NotActivated';
import { ButtonSection } from '../../components/ButtonSection';
import { SixSenseIcon } from './SixSenseIcon';
import RichTextConverter from 'app/components/RichTextConverter';
import { IntegrationProps as Props } from '../../types';

const integrationName = '6Sense';

export function SixSense({
	group,
	provider,
	organizationId,
	goToIntegration,
}: Props) {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: integrationsContainerSaga });
	const history = useHistory();
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const sixSense = useSelector(selectIntegrationByProvider(Providers.sixSense));

	const [apiToken, setApiToken] = useState('');

	useEffect(() => {
		if (sixSense?.credentials) {
			setApiToken((sixSense?.credentials as any)?.apiToken || '');
		}
	}, [sixSense]);

	const initialIntegrationObj = {
		integrationId: sixSense?.id,
		integration: sixSense?.id
			? null
			: {
					id: sixSense?.id,
					name: integrationName,
					group,
					organizationId,
					source: true,
					provider,
			  },
		group,
		provider,
		attributes: undefined,
		lists: undefined,
		configuration: undefined,
		credentials: undefined,
	};

	//handle Integration Active state
	const handleActiveState = active => {
		dispatch(
			actions.modifyIntegration(
				sixSense?.id
					? {
							//if integration is created only update active
							integrationId: sixSense?.id,
							provider,
							configuration: {
								...sixSense?.configuration,
								active,
							},
					  }
					: {
							// if not, create integration and update active
							...initialIntegrationObj,
							configuration: { active },
					  }
			)
		);
	};

	//handleSave
	const handleSave = () => {
		dispatch(
			actions.modifyIntegration({
				...initialIntegrationObj,
				credentials: { ...sixSense?.credentials, apiToken },
			})
		);
		goToIntegration();
	};

	return (
		<div className={commonIntStyles.wrapper}>
			<Header
				label="integrations_6Sense_title"
				changehandler={handleActiveState}
				value={sixSense?.configuration?.active ?? false}
			/>
			<Divider />
			{sixSense?.configuration?.active ? (
				<>
					<Formik
						enableReinitialize
						initialValues={{
							apiToken,
						}}
						onSubmit={values => console.log(values)}
						validationSchema={Yup.object().shape({
							apiToken: Yup.string().required('Required'),
						})}
					>
						{({ handleChange }) => (
							<Form
								className={`${commonIntStyles.padding} ${commonIntStyles.activeIntContentWrapper}`}
							>
								<Typography
									variant="h6"
									data-translation-key={'integrations_6Sense_api_token'}
								>
									{t('integrations_6Sense_api_token')}
								</Typography>
								<Field
									type="text"
									name="apiToken"
									placeholder={t('integrations_6Sense_api_token_placeholder')}
									component={TextField}
									InputLabelProps={{ shrink: false }}
									fullWidth
									variant="outlined"
									InputProps={{
										onChange: e => {
											handleChange(e);
											setApiToken(e.target.value);
										},
									}}
								/>

								<Typography
									variant="body2"
									data-translation-key="integrations_6Sense_more_information"
								>
									{t('integrations_6Sense_more_information')}
									<RichTextConverter
										html={t('globals_knowledge_base')}
										translationKey={'globals_knowledge_base'}
									/>
								</Typography>
							</Form>
						)}
					</Formik>
				</>
			) : (
				<>
					<NotActivated name="6Sense source" Icon={<SixSenseIcon />} />
				</>
			)}

			<div className={commonIntStyles.bottomSec}>
				<Divider />
				<ButtonSection
					disabled={!sixSense?.configuration?.active || apiToken.length === 0}
					onSave={handleSave}
					onCancel={goToIntegration}
				/>
			</div>
		</div>
	);
}
