import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './style.module.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { InputLabel, Typography as MuiTypography } from '@material-ui/core';
import PasswordValidation from './passwordValidation';
import validatedValues from './utility/validation';
import { selectFetchingData } from 'app/containers/AuthContainer/selectors';
import { actions } from 'app/containers/AuthContainer/slice';
import { passwordRequirements } from 'app/containers/AuthContainer/passwordRequirements';
import CustomButton from 'app/components/Typos/ButtonComponent';

export const ChangePassword = () => {
	const dispatch = useDispatch();
	const isFetchingData: boolean = useSelector(selectFetchingData);
	const [t, i18n] = useTranslation();

	return (
		<div className={styles.wrapper}>
			<Formik
				initialValues={{
					oldPassword: '',
					newPassword: '',
					confirmPassword: '',
				}}
				validationSchema={Yup.object().shape({
					oldPassword: Yup.string().required(
						t('account_preferences_change_password_old_password_required')
					),
					newPassword: passwordRequirements(t),
					confirmPassword: Yup.string()
						.oneOf(
							[Yup.ref('newPassword')],
							t('account_preferences_change_password_match_error')
						)
						.required(
							t('account_preferences_change_password_confirm_password_required')
						),
				})}
				onSubmit={(values, { resetForm }) => {
					dispatch(actions.changePassword(values));
					resetForm();
				}}
			>
				{({ dirty, isValid, values }) => {
					const validatedObj = validatedValues(values);
					return (
						<Form>
							<InputLabel htmlFor="oldPassword" shrink={false}>
								{t('account_preferences_change_password_old_password')}
							</InputLabel>
							<Field
								name="oldPassword"
								type="password"
								placeholder={t(
									'account_preferences_change_password_old_password_placeholder'
								)}
								component={TextField}
								fullWidth
								variant="outlined"
							/>
							<InputLabel htmlFor="newPassword" shrink={false}>
								{t('account_preferences_change_password_new_password')}
							</InputLabel>
							<Field
								name="newPassword"
								type="password"
								placeholder={t(
									'account_preferences_change_password_new_password_placeholder'
								)}
								component={TextField}
								fullWidth
								variant="outlined"
							/>
							<InputLabel htmlFor="confirmPassword" shrink={false}>
								{t('account_preferences_change_password_confirm_password')}
							</InputLabel>
							<Field
								name="confirmPassword"
								type="password"
								placeholder={t(
									'account_preferences_change_password_confirm_password_placeholder'
								)}
								component={TextField}
								fullWidth
								variant="outlined"
							/>
							<PasswordValidation validatedObj={validatedObj} />
							<div className={`${styles.bottomSec}`}>
								<MuiTypography variant="body2">
									{t('account_preferences_change_password_bottom_description')}
								</MuiTypography>

								<CustomButton
									type="submit"
									disabled={!(dirty && isValid) || isFetchingData}
									loader={isFetchingData}
									block
								>
									{t('globals_save_preferences')}
								</CustomButton>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
