import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { Providers } from 'API';
import { integrationsContainerSaga } from '../../saga';
import { reducer, sliceKey, actions } from '../../slice';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { Divider, Typography } from '@material-ui/core';
import commonIntStyles from 'app/containers/IntegrationsContainer/style.module.scss';
import { selectIntegrationByProvider } from 'app/containers/IntegrationsContainer/selectors';
import { Header } from '../../components/Header';
import { NotActivated } from '../../components/NotActivated';
import { ButtonSection } from '../../components/ButtonSection';
import { GoogleTagManagerIcon } from './GoogleTagManagerIcon';
import RichTextConverter from 'app/components/RichTextConverter';

interface Props {
	group: string;
	provider: Providers;
	organizationId: string;
	name: string;
	source: boolean;
	baseUrl: string;
	goToIntegration: Function;
}

export function GoogleTagManager({
	group,
	provider,
	organizationId,
	name,
	source,
	baseUrl,
	goToIntegration,
}: Props) {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: integrationsContainerSaga });
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const gtm = useSelector(
		selectIntegrationByProvider(Providers.googleTagManager)
	);

	const [dataLayerVarName, setDataLayerVarName] = useState(
		t('integrations_google_tag_manager_data_layer_default') || ''
	);

	useEffect(() => {
		if (gtm?.configuration) {
			setDataLayerVarName(
				(gtm?.configuration as any)?.dataLayerVarName ||
					t('integrations_google_tag_manager_data_layer_default')
			);
		}
	}, [gtm, t]);

	const initialIntegrationObj = {
		integrationId: gtm?.id,
		integration: gtm?.id
			? null
			: {
					id: gtm?.id,
					name: name,
					group,
					organizationId,
					source,
					provider,
			  },
		group,
		provider,
		attributes: undefined,
		lists: undefined,
		configuration: undefined,
		credentials: undefined,
	};

	//handle Integration Active state
	const handleActiveState = active => {
		dispatch(
			actions.modifyIntegration(
				gtm?.id
					? {
							//if integration is created only update active
							integrationId: gtm?.id,
							provider,
							configuration: {
								...gtm?.configuration,
								active,
							},
					  }
					: {
							// if not, create integration and update active
							...initialIntegrationObj,
							configuration: { active },
					  }
			)
		);
	};

	//handleSave
	const handleSave = () => {
		dispatch(
			actions.modifyIntegration({
				...initialIntegrationObj,
				configuration: {
					...gtm?.configuration,
					dataLayerVarName,
				},
			})
		);
		goToIntegration();
	};

	return (
		<div className={commonIntStyles.wrapper}>
			<Header
				label="integrations_google_tag_manager_title"
				changehandler={handleActiveState}
				value={gtm?.configuration?.active ?? false}
			/>
			<Divider />
			{gtm?.configuration?.active ? (
				<>
					<Formik
						enableReinitialize
						initialValues={{
							dataLayerVarName,
						}}
						onSubmit={() => {}}
						validationSchema={Yup.object().shape({
							dataLayerVarName: Yup.string().required('Required'),
						})}
					>
						{({ handleChange }) => (
							<Form
								className={`${commonIntStyles.padding} ${commonIntStyles.activeIntContentWrapper}`}
							>
								<Typography
									variant="h6"
									data-translation-key={
										'integrations_google_tag_manager_data_layer_variable_name_label'
									}
								>
									{t(
										'integrations_google_tag_manager_data_layer_variable_name_label'
									)}
								</Typography>
								<Field
									type="text"
									name="dataLayerVarName"
									placeholder={t(
										'integrations_google_tag_manager_data_layer_variable_name_placeholder'
									)}
									component={TextField}
									InputLabelProps={{ shrink: false }}
									fullWidth
									variant="outlined"
									InputProps={{
										onChange: e => {
											handleChange(e);
											setDataLayerVarName(e.target.value);
										},
									}}
								/>

								<Typography
									variant="body2"
									data-translation-key="integrations_google_tag_manager_more_information"
								>
									{t('integrations_google_tag_manager_more_information')}
									<RichTextConverter
										html={t('globals_knowledge_base')}
										translationKey={'globals_knowledge_base'}
									/>
								</Typography>
							</Form>
						)}
					</Formik>
				</>
			) : (
				<>
					<NotActivated name={name} Icon={<GoogleTagManagerIcon />} />
				</>
			)}

			<div className={commonIntStyles.bottomSec}>
				<Divider />
				<ButtonSection
					disabled={
						!gtm?.configuration?.active || dataLayerVarName.length === 0
					}
					onSave={handleSave}
					onCancel={goToIntegration}
				/>
			</div>
		</div>
	);
}
