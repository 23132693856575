import React from 'react';
import { useDrag } from 'react-dnd';
import { Typography as MuiTypography } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@material-ui/icons/Delete';

import { ConditionType } from 'API';
import { Condition } from '../';
import { Bucket } from './Bucket';
import baseStyles from '../style.module.scss';

export const Operand = (props: any) => {
	const { typeOf, operands } = props;
	const {
		handleDrop,
		handleRemove,
		handleUpdate,
		parentObject,
		editable,
		...item
	} = props;
	const [{}, drag] = useDrag(() => ({
		type: 'conditions',
		item,
		canDrag: _monitor => editable || false,
		collect: monitor => ({
			opacity: monitor.isDragging() ? 0.4 : 1,
		}),
	}));
	const handleDropWithTarget = (item: any) => handleDrop(item, props);
	const showBucket =
		typeOf === ConditionType.NOT ? operands.length === 0 : true;

	return (
		<div className={baseStyles.wrapConditional} ref={drag}>
			<div className={baseStyles.componentHeader}>
				<MuiTypography variant="h6">{props.typeOf}</MuiTypography>
				{props.editable && (
					<span onClick={() => handleRemove(props.id)}>
						<DeleteIcon />
					</span>
				)}
			</div>
			<div>
				{operands.length > 0 &&
					operands.map((n: any) => (
						<Condition
							{...n}
							handleDrop={handleDrop}
							handleUpdate={handleUpdate}
							handleRemove={handleRemove}
							key={n.id || uuidv4()}
							parentObject={parentObject}
							editable={editable}
						/>
					))}
				{showBucket && (
					<Bucket
						editable={editable}
						operands={operands}
						handleDrop={handleDrop}
						handleDropWithTarget={handleDropWithTarget}
					/>
				)}
			</div>
		</div>
	);
};

export default Operand;
