import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { actions } from '../slice';
import { selectFetchingData } from '../selectors';
import AuthWrapper from '../AuthWrapper';
import { TextField } from 'formik-material-ui';
import styles from './style.module.scss';
import CustomButton from 'app/components/Typos/ButtonComponent';
import TextTitle from 'app/components/Typos/TextTitle';
import LinkComponent from 'app/components/Typos/LinkComponent';
import { AmplifyAuthStatus } from 'app/containers/AuthContainer/types';

export const ForgotPasswordContainer = () => {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const isFetchingData: boolean = useSelector(selectFetchingData);

	const submitForm = (userInfo): boolean => {
		dispatch(actions.forgotPassword(userInfo));
		return true;
	};
	return (
		<AuthWrapper>
			<div className={styles.ForgotWrapper}>
				<TextTitle>{t('forgot_password_title')}</TextTitle>
				<Formik
					initialValues={{ username: '' }}
					validationSchema={Yup.object().shape({
						username: Yup.string()
							.email(t('globals_email_invalid'))
							.required(t('globals_email_required')),
					})}
					onSubmit={(values, { setSubmitting }) => {
						submitForm(values);
						setSubmitting(false);
					}}
				>
					{({ dirty, isValid }) => {
						return (
							<Form>
								<Field
									type="text"
									name="username"
									placeholder={t('globals_email_placeholder')}
									label={t('globals_email')}
									variant="outlined"
									component={TextField}
									fullWidth
								/>

								<div className={`mb-3 ${styles.forgotBtnWrapper}`}>
									<LinkComponent link={AmplifyAuthStatus.login}>
										{t('auth_common_back_to_login')}
									</LinkComponent>
									<CustomButton
										type="submit"
										disabled={!(dirty && isValid) || isFetchingData}
										loader={isFetchingData}
									>
										{t('globals_submit')}
									</CustomButton>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</AuthWrapper>
	);
};
