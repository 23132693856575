import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicInfo } from './BasicInfo';
import { ChangePassword } from './ChangePassword';
import { useRouteMatch } from 'react-router-dom';
import { Typography as MuiTypography } from '@material-ui/core';
import { CustomTabs } from 'app/components/CustomTabs';
import styles from './style.module.scss';

const basicInfo = 'basic-info';
const changePassword = 'change-password';

const AccountPreferences = () => {
	const match = useRouteMatch();
	const { params } = match;
	const { page } = params as any;
	const [t, i18n] = useTranslation();

	if (page === basicInfo || page === changePassword) {
		return (
			<>
				<div className={styles.wrapper}>
					<MuiTypography variant="h3">
						{t('account_preferences_common_title')}
					</MuiTypography>
					<CustomTabs
						tabs={[
							{
								label: t('account_preferences_common_basic_info'),
								translationKey: 'account_preferences_common_basic_info',
								content: <BasicInfo />,
								tabUrlName: `${basicInfo}`,
							},
							{
								label: t('account_preferences_common_change_password'),
								translationKey: 'account_preferences_common_change_password',
								content: <ChangePassword />,
								tabUrlName: `${changePassword}`,
							},
						]}
						page={page}
					/>
				</div>
			</>
		);
	}
	return null;
};
export default AccountPreferences;
