import React, { useState } from 'react';
import { Field } from 'formik';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface Props {
	id: string;
	name: string;
	placeholder: string;
	fullWidth: boolean;
	value: string;
	onChange: (event: any) => void;
}

const PasswordReveal = (props: Props) => {
	const [showPassword, setShowPassword] = useState(false);
	const toggleShowPassword = () => setShowPassword(!showPassword);

	return (
		<Field {...props}>
			{(renderProps: any) => (
				<OutlinedInput
					{...props}
					{...renderProps}
					type={showPassword ? 'text' : 'password'}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={toggleShowPassword}
								onMouseDown={toggleShowPassword}
							>
								{showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					}
				/>
			)}
		</Field>
	);
};

export default PasswordReveal;
