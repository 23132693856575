import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import UniversalAnalyticsForm from './Form';
import ButtonFooter from '../ButtonFooter';
import styles from '../../style.module.scss';

interface Props {
	next: any;
	config: any;
}

const UniversalAnalytics = ({ next, config }: Props) => (
	<Formik
		enableReinitialize
		initialValues={{
			checkedDimension: !!config?.dimensionId?.length || false,
			dimensionId: config?.dimensionId || '',
			sendEvent: !!config?.category?.length || false,
			category: config?.category || '',
			action: config?.action || '',
			label: config?.label || '',
		}}
		onSubmit={async values => {
			const { checkedDimension, sendEvent, ...rest } = values;
			next({ configuration: rest });
		}}
		validationSchema={Yup.object().shape({
			dimensionId: Yup.string().when('checkedDimension', {
				is: true,
				then: Yup.string().required('Required'),
				otherwise: Yup.string(),
			}),
			category: Yup.string().when('sendEvent', {
				is: true,
				then: Yup.string().required('Required'),
				otherwise: Yup.string(),
			}),
			action: Yup.string().when('sendEvent', {
				is: true,
				then: Yup.string().required('Required'),
				otherwise: Yup.string(),
			}),
			label: Yup.string(),
		})}
	>
		{({ values, errors, touched }) => (
			<Form className={styles.overviewForm}>
				<UniversalAnalyticsForm
					values={values}
					errors={errors}
					touched={touched}
				/>
				<ButtonFooter disabled={!touched || !errors} finalStep />
			</Form>
		)}
	</Formik>
);

export default UniversalAnalytics;
