import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { Providers } from 'API';
import { integrationsContainerSaga } from '../../saga';
import { reducer, sliceKey, actions } from '../../slice';
import { Divider, Typography } from '@material-ui/core';
import commonIntStyles from 'app/containers/IntegrationsContainer/style.module.scss';
import { selectIntegrationByProvider } from 'app/containers/IntegrationsContainer/selectors';
import { Header } from '../../components/Header';
import { NotActivated } from '../../components/NotActivated';
import { ButtonSection } from '../../components/ButtonSection';
import { ClearbitRevealIcon } from './ClearbitRevealIcon';
import RichTextConverter from 'app/components/RichTextConverter';
import { IntegrationProps as Props } from '../../types';

const integrationName = 'Clearbit Reveal';

export function ClearBitReveal({
	group,
	provider,
	organizationId,
	baseUrl,
	goToIntegration,
}: Props) {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: integrationsContainerSaga });
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const clearbit = useSelector(selectIntegrationByProvider(Providers.clearbit));

	const initialIntegrationObj = {
		integrationId: clearbit?.id,
		integration: clearbit?.id
			? null
			: {
					id: clearbit?.id,
					name: integrationName,
					group,
					organizationId,
					source: true,
					provider,
			  },
		group,
		provider,
		attributes: undefined,
		lists: undefined,
		configuration: undefined,
		credentials: undefined,
	};

	//handle Integration Active state
	const handleActiveState = (active: boolean) => {
		dispatch(
			actions.modifyIntegration(
				clearbit?.id
					? {
							//if integration is created only update active
							integrationId: clearbit?.id,
							provider,
							configuration: {
								...clearbit?.configuration,
								active,
							},
					  }
					: {
							// if not, create integration and update active
							...initialIntegrationObj,
							configuration: { active },
					  }
			)
		);
	};
	return (
		<div className={commonIntStyles.wrapper}>
			<Header
				label="integrations_clearbit_title"
				changehandler={handleActiveState}
				value={clearbit?.configuration?.active ?? false}
			/>
			<Divider />
			{clearbit?.configuration?.active ? (
				<div
					className={`${commonIntStyles.padding} ${commonIntStyles.activeIntContentWrapper}`}
				>
					<Typography
						variant="body2"
						data-translation-key="integrations_clearbit_description"
					>
						{t('integrations_clearbit_description')}
					</Typography>
					<Typography
						variant="body2"
						data-translation-key="integrations_clearbit_more"
					>
						{t('integrations_clearbit_more')}
						<RichTextConverter
							html={t('globals_knowledge_base')}
							translationKey={'globals_knowledge_base'}
						/>
					</Typography>
				</div>
			) : (
				<NotActivated
					name="ClearBit Reveal source"
					Icon={<ClearbitRevealIcon />}
				/>
			)}

			<div className={commonIntStyles.bottomSec}>
				<Divider />
				<ButtonSection
					disabled={!clearbit?.configuration?.active}
					onSave={goToIntegration}
					onCancel={goToIntegration}
				/>
			</div>
		</div>
	);
}
