import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '@material-ui/core/Switch';
import styles from './style.module.scss';
import { Typography } from '@material-ui/core';

interface Props {
	label: string;
	changehandler: (active: boolean) => void;
	value: boolean;
}

export const Header = (props: Props) => {
	const { label, changehandler, value } = props;
	const [t, i18n] = useTranslation();
	const [active, setActive] = useState(value);

	useEffect(() => {
		setActive(value);
	}, [value]);

	const handleActiveChange = e => {
		setActive(e.target.checked);
		changehandler(e.target.checked);
	};

	return (
		<div className={styles.label}>
			<Typography variant="h3" data-translation-key={label}>
				{t(label)}
			</Typography>
			<Switch checked={active} onChange={handleActiveChange} />
		</div>
	);
};
