import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { Formik, Form, Field } from 'formik';
import { AkCodeBlock } from '@atlaskit/code';
import { useDispatch } from 'react-redux';
import { actions } from 'app/containers/OrganizationsContainer/slice';
import _ from 'lodash';
import RichTextConverter from 'app/components/RichTextConverter';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import {
	MenuItem,
	InputLabel,
	Typography as MuiTypography,
	Button,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import { Select } from 'formik-material-ui';
import * as Yup from 'yup';
import LinkComponent from 'app/components/Typos/LinkComponent';
import * as APIt from 'API';

interface Props {
	org: APIt.UpdateOrganizationInput;
}

const integrationCode = 'integrationCode';
const antiFlickerCode = 'antiFlickerCode';
const asynchronousSnippet = 'asynchronousSnippet';
const installationType = 'installationType';

export const FunnelenvyScript = ({ org }: Props) => {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const clientUrl = `https://cdn.funnelenvy.com/client/production/bundle-${org?.id}.js`;

	const codeBlock = `<script src="${clientUrl}"></script>
	<script type="text/javascript">window.funnelEnvy = new FunnelEnvy({customerId: "${org?.id}"});</script>`;

	return (
		<div className={styles.wrapper}>
			<Formik
				enableReinitialize
				initialValues={{
					installationType:
						org?.installationType || APIt.InstallationsType.asynchronous,
					flashHide: org?.flashHide ?? false,
				}}
				validationSchema={Yup.object().shape({
					installationType: Yup.string().required(
						t('org_preferences_fe_script_installation_type_required')
					),
				})}
				onSubmit={fields => {
					if (org && org?.id) {
						dispatch(
							actions.updateOrganization({
								id: org.id,
								...fields,
							})
						);
					}
				}}
			>
				{({ values, handleChange }) => {
					return (
						<Form>
							<InputLabel htmlFor={installationType} shrink={false}>
								{t('org_preferences_fe_script_installation_type')}
							</InputLabel>

							<Field
								name={installationType}
								component={Select}
								fullWidth
								placeholder="Choose Installation Type"
								variant="outlined"
								className={styles.installationType}
							>
								<MenuItem value={APIt.InstallationsType.synchronous}>
									{t('org_preferences_fe_script_synchronous_implementation')}
								</MenuItem>
								<MenuItem value={APIt.InstallationsType.asynchronous}>
									{t('org_preferences_fe_script_asynchronous_implementation')}
								</MenuItem>
							</Field>

							{values.installationType ===
							APIt.InstallationsType.synchronous ? (
								<>
									<InputLabel htmlFor={integrationCode} shrink={false}>
										{t('org_preferences_fe_script_integration_code')}
									</InputLabel>

									<CopyToClipboard
										text={codeBlock}
										onCopy={() => {
											dispatch(actions.setCopied());
										}}
									>
										<Tooltip title="Copy to Clipboard" placement="top" arrow>
											<div className={styles.codeBlock}>
												<AkCodeBlock
													theme={{
														textColor: 'black',
													}}
													text={_.get(org, 'integrationCode') || codeBlock}
												/>
											</div>
										</Tooltip>
									</CopyToClipboard>
								</>
							) : (
								<>
									{/* <InputLabel htmlFor={antiFlickerCode} shrink={false}>
										{t('org_preferences_fe_script_anti_flicker_code')}
									</InputLabel>

									<div className={styles.codeBlock}>
										<AkCodeBlock
											theme={{
												textColor: 'black',
											}}
											text={_.get(org, 'antiFlickerCode') || codeBlock}
										/>
									</div> */}

									<InputLabel htmlFor={asynchronousSnippet} shrink={false}>
										{t('org_preferences_fe_script_asynchronous_snippet')}
									</InputLabel>
									<CopyToClipboard
										text={codeBlock}
										onCopy={() => {
											dispatch(actions.setCopied());
										}}
									>
										<Tooltip title="Copy to Clipboard" placement="top" arrow>
											<div className={styles.codeBlock}>
												<AkCodeBlock
													theme={{
														textColor: 'black',
													}}
													text={_.get(org, 'asynchronousCode') || codeBlock}
												/>
											</div>
										</Tooltip>
									</CopyToClipboard>
								</>
							)}

							<FormControlLabel
								label={t('org_preferences_reduce_flash')}
								data-translation-key={'org_preferences_reduce_flash'}
								labelPlacement="end"
								control={
									<Field label="checkbox" type="checkbox" name="flashHide">
										{() => (
											<Checkbox
												name="flashHide"
												checked={values.flashHide}
												disableRipple
												onChange={handleChange}
											/>
										)}
									</Field>
								}
							/>

							<div className={`${styles.bottomSec}`}>
								<MuiTypography variant="body2">
									{t('org_preferences_common_more_info')}{' '}
									<RichTextConverter
										html={t('globals_knowledge_base')}
										translationKey={'globals_knowledge_base'}
									></RichTextConverter>
								</MuiTypography>

								<Button type="submit" variant="contained" fullWidth>
									{t('globals_save')}
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
