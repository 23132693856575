import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	(state && state.authContainer) || initialState;

export const selectError = createSelector(
	[selectDomain],
	authContainerState => authContainerState.error
);

export const selectFetchingData = createSelector(
	[selectDomain],
	authContainerState => authContainerState.fetchingData
);

export const selectCognitoUser = createSelector(
	[selectDomain],
	authContainerState => authContainerState.cognitoUser
);

export const selectTempUser = createSelector(
	[selectDomain],
	authContainerState => authContainerState.tempUser
);

export const selectCheckingAuth = createSelector(
	[selectDomain],
	authContainerState => authContainerState.checkingAuth
);

export const selectFeatureFlags = createSelector(
	[selectDomain],
	authContainerState => authContainerState.featureFlags
);
