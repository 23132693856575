import React from 'react';

export function SixSenseIcon() {
	return (
		<img
			src={'https://avatars.githubusercontent.com/u/6394693?s=200&v=4'}
			alt="6sense"
			width="64px"
			height="64px"
		/>
	);
}
