import React from 'react';

export function DemandBaseIcon() {
	return (
		<svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
			<title>0DF8E23E-8809-4DF6-A27F-2513DDFC4101</title>
			<desc>Created with sketchtool.</desc>
			<g
				id="Design"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Settings---Integrations---Data-sources"
					transform="translate(-864.000000, -286.000000)"
				>
					<g id="Demandbase" transform="translate(864.000000, 286.000000)">
						<path
							d="M10.2555408,-4.14336123e-16 L53.7444592,4.14336123e-16 C57.3105342,-2.4074122e-16 58.6036791,0.371302445 59.9073828,1.06853082 C61.2110865,1.76575919 62.2342408,2.78891348 62.9314692,4.09261719 C63.6286976,5.39632089 64,6.68946584 64,10.2555408 L64,53.7444592 C64,57.3105342 63.6286976,58.6036791 62.9314692,59.9073828 C62.2342408,61.2110865 61.2110865,62.2342408 59.9073828,62.9314692 C58.6036791,63.6286976 57.3105342,64 53.7444592,64 L10.2555408,64 C6.68946584,64 5.39632089,63.6286976 4.09261719,62.9314692 C2.78891348,62.2342408 1.76575919,61.2110865 1.06853082,59.9073828 C0.371302445,58.6036791 1.60494146e-16,57.3105342 -2.76224082e-16,53.7444592 L2.76224082e-16,10.2555408 C-1.60494146e-16,6.68946584 0.371302445,5.39632089 1.06853082,4.09261719 C1.76575919,2.78891348 2.78891348,1.76575919 4.09261719,1.06853082 C5.39632089,0.371302445 6.68946584,2.4074122e-16 10.2555408,-4.14336123e-16 Z"
							id="Rectangle"
							fill="#204970"
						></path>
						<g
							id="salesforce-2"
							transform="translate(20.000000, 15.000000)"
							fill="#FFFFFF"
							fillRule="nonzero"
						>
							<path
								d="M0.134436067,24.8343746 L0.134436067,14.8982049 L3.2264656,13.0900398 C4.77920217,12.1113453 6.46637481,11.2818747 6.58736727,11.2549875 C6.86833865,11.2549875 7.02966193,13.1828007 7.02966193,20.0000537 L7.02966193,28.772007 L10.8113485,28.6375709 C13.768942,28.4910356 15.0433959,28.3834868 15.7532183,27.9949665 C17.4403909,27.0848344 17.5882706,26.2271323 17.5882706,17.4282917 C17.5882706,13.0362654 17.5882706,9.09325556 17.4403909,8.61600752 C17.2925113,8.13875948 16.7305685,7.45179118 16.1820694,7.02294013 C15.2047192,6.29967409 14.9224034,6.29967409 7.57816108,6.16523802 L0,6.03214632 L0,0 L8.27588427,0.134436067 C16.1914799,0.275593937 16.9013023,0.303825511 18.5750313,0.988105091 C19.8333529,1.45190952 21.1199061,2.2558372 21.9493766,3.20630019 C24.3396499,5.52263362 24.474086,6.42201091 24.474086,17.3893052 C24.474086,27.836332 24.3396499,28.8809002 22.3580623,31.1434592 C19.9745108,34.0634106 17.1621083,34.706015 7.15199875,34.706015 L0,34.706015 L0.134436067,24.7819446 L0.134436067,24.8343746 Z"
								id="Path"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
