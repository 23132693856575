import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import {
	selectOrganizations,
	selectedOrganization,
} from 'app/containers/OrganizationsContainer/selectors';
import { actions } from 'app/containers/OrganizationsContainer/slice';
import { selectCognitoUser } from 'app/containers/AuthContainer/selectors';
import { getSessionItemValue, setSessionItem } from '../utility';
import styles from './styles.module.scss';
import { find } from 'lodash';

interface Props {}

const OrgSelector = (props: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const orgs = useSelector(selectOrganizations);
	const sessionOrg = getSessionItemValue('selectedOrg');
	const currentOrg = useSelector(selectedOrganization);
	const [selectedOrg, setOrg] = useState(currentOrg?.group);
	const handleChangeOrg = e => {
		setOrg(e.target.value);
		const org = find(orgs, { group: e.target.value });
		let currentOrgInUrl = history.location.pathname
			.split('/o/')?.[1]
			?.split('/')?.[0];
		if (currentOrgInUrl && org?.group) {
			history.push(
				history.location.pathname.replace(currentOrgInUrl, org?.group)
			);
		}
		setSessionItem('selectedOrg', e.target.value);
		dispatch(actions.changeOrg(org));
	};

	useEffect(() => {
		if (sessionOrg && sessionOrg !== 'undefined') {
			setOrg(sessionOrg);
		} else {
			const orgValue = currentOrg?.group ? currentOrg?.group : orgs[0]?.group;
			setOrg(orgValue);
			setSessionItem('selectedOrg', orgValue);
		}
	}, [currentOrg, sessionOrg, orgs, selectedOrg]);

	return (
		<>
			{orgs && (
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={selectedOrg}
					displayEmpty
					onChange={handleChangeOrg}
					className={styles.orgSelector}
				>
					{orgs.map((item, index) => {
						return (
							<MenuItem key={index} value={item.group}>
								{item.group}
							</MenuItem>
						);
					})}
				</Select>
			)}
		</>
	);
};

export default OrgSelector;
