import React from 'react';
// import Plot from 'react-plotly.js';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
const Plot = createPlotlyComponent(Plotly);

interface Props {
	data: Data[];
	layout: Object;
	style?: Object;
}

interface Data {
	x: any[];
	y: any[];
	type: string;
	mode?: string;
	marker?: Object;
}

function StackedChart(props: Props) {
	const { data, layout, style } = props;
	return (
		<>
			<Plot
				data={[...data]}
				style={{
					height: '500px',
					width: '100%',
					...style,
				}}
				layout={layout}
				config={{
					responsive: true,
					displaylogo: false,
					modeBarButtonsToRemove: ['toImage', 'lasso2d'],
				}}
			/>
		</>
	);
}

export default StackedChart;
