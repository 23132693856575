import React from 'react';

export function ArchiveIcon() {
	return (
		<svg width="16px" height="13px" viewBox="0 0 16 13" version="1.1">
			<title>Archive</title>
			<desc>Created with sketchtool.</desc>
			<g
				id="Design"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Triggers" transform="translate(-638.000000, -308.000000)">
					<g id="Arch" transform="translate(638.000000, 306.000000)">
						<rect
							id="Rectangle"
							fill="#000000"
							opacity="0"
							x="0"
							y="0"
							width="16"
							height="16"
						></rect>
						<path
							d="M16,3.04132769 C16,2.46621829 15.5175482,2 14.9224138,2 L1.07758621,2 C0.482451778,2 0,2.46621829 0,3.04132769 L0,4.5533355 C0.0010648848,5.03695158 0.346543137,5.45620963 0.834051724,5.56550602 L0.834051724,12.5090791 C0.834051724,13.7743198 1.89544564,14.8 3.20474138,14.8 L12.7952586,14.8 C14.1045544,14.8 15.1659483,13.7743198 15.1659483,12.5090791 L15.1659483,5.56342336 C15.6534569,5.45412698 15.9989351,5.03486893 16,4.55125285 L16,3.04132769 Z M13.8728448,12.5090791 C13.8728448,13.0841885 13.390393,13.5504068 12.7952586,13.5504068 L3.20474138,13.5504068 C2.60960695,13.5504068 2.12715517,13.0841885 2.12715517,12.5090791 L2.12715517,5.59258054 L13.8728448,5.59258054 L13.8728448,12.5090791 Z M10.5774319,6.8 C10.9212668,6.8 11.2,7.1581722 11.2,7.6 C11.2,8.0418278 10.9212668,8.4 10.5774319,8.4 L10.5774319,8.4 L5.42256809,8.4 C5.07873323,8.4 4.8,8.0418278 4.8,7.6 C4.8,7.1581722 5.07873323,6.8 5.42256809,6.8 L5.42256809,6.8 Z M14.7068966,3.24959323 L14.7068966,4.34298731 L1.29310345,4.34298731 L1.29310345,3.24959323 L14.7068966,3.24959323 Z"
							id="Shape"
							fill="#D7D9E4"
							fillRule="nonzero"
						></path>
					</g>
				</g>
			</g>
		</svg>
	);
}
