/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type MarketoCredsInput = {
  apiHost: string,
  clientId: string,
  clientSecret: string,
};

export type MarketoCreds = {
  __typename: "MarketoCreds",
  apiHost?: string | null,
  clientId?: string | null,
  clientSecret?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type Credentials = {
  __typename: "Credentials",
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type SalesforceCreds = {
  __typename: "SalesforceCreds",
  access_token?: string | null,
  refresh_token?: string | null,
  signature?: string | null,
  scope?: string | null,
  id_token?: string | null,
  instance_url?: string | null,
  id?: string | null,
  token_type?: string | null,
  issued_at?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type SixSenseCreds = {
  __typename: "SixSenseCreds",
  apiToken?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type HubSpotCreds = {
  __typename: "HubSpotCreds",
  accessToken?: string | null,
  refreshToken?: string | null,
  tokenType?: string | null,
  expiresIn?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type SalesforceCredsInput = {
  access_token: string,
  refresh_token: string,
  signature: string,
  scope: string,
  id_token: string,
  instance_url: string,
  id: string,
  token_type: string,
  issued_at: string,
};

export type SixSenseCredsInput = {
  apiToken: string,
};

export type HubSpotCredsInput = {
  accessToken: string,
  refreshToken: string,
  tokenType: string,
  expiresIn: string,
};

export type CustomConfigInput = {
  active?: boolean | null,
};

export type CustomConfig = {
  __typename: "CustomConfig",
  createdAt?: string | null,
  updatedAt?: string | null,
  active?: boolean | null,
};

export type Configuration = {
  __typename: "Configuration",
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type MarketoConfig = {
  __typename: "MarketoConfig",
  createdAt?: string | null,
  updatedAt?: string | null,
  active?: boolean | null,
  hiddenField?: string | null,
};

export type SalesforceConfig = {
  __typename: "SalesforceConfig",
  createdAt?: string | null,
  updatedAt?: string | null,
  active?: boolean | null,
  userId?: string | null,
};

export type GoogleTagManagerConfig = {
  __typename: "GoogleTagManagerConfig",
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  dataLayerVarName?: string | null,
};

export type UniversalAnalyticsConfig = {
  __typename: "UniversalAnalyticsConfig",
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  uaTrackingId?: string | null,
};

export type UniversalAnalyticsSourceConfig = {
  __typename: "UniversalAnalyticsSourceConfig",
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  uaTrackingId?: string | null,
};

export type HubSpotConfig = {
  __typename: "HubSpotConfig",
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type ClearbitConfig = {
  __typename: "ClearbitConfig",
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DemandbaseConfig = {
  __typename: "DemandbaseConfig",
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type SixSenseConfig = {
  __typename: "SixSenseConfig",
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type AccountMatchConfig = {
  __typename: "AccountMatchConfig",
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type GoogleOptimizeConfig = {
  __typename: "GoogleOptimizeConfig",
  active?: boolean | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type MarketoConfigInput = {
  active?: boolean | null,
  hiddenField?: string | null,
};

export type SalesforceConfigInput = {
  active?: boolean | null,
  userId?: string | null,
};

export type GoogleTagManagerConfigInput = {
  active?: boolean | null,
  dataLayerVarName?: string | null,
};

export type UniversalAnalyticsConfigInput = {
  active?: boolean | null,
  uaTrackingId?: string | null,
};

export type UniversalAnalyticsSourceConfigInput = {
  active?: boolean | null,
  uaTrackingId?: string | null,
};

export type HubSpotConfigInput = {
  active?: boolean | null,
};

export type ClearbitConfigInput = {
  active?: boolean | null,
};

export type DemandbaseConfigInput = {
  active?: boolean | null,
};

export type SixSenseConfigInput = {
  active?: boolean | null,
};

export type AccountMatchConfigInput = {
  active?: boolean | null,
};

export type GoogleOptimizeConfigInput = {
  active?: boolean | null,
};

export type UniversalAnalyticsDestinationInput = {
  dimensionId?: string | null,
  category?: string | null,
  action?: string | null,
  label?: string | null,
};

export type UniversalAnalyticsDestination = {
  __typename: "UniversalAnalyticsDestination",
  provider?: IntegrationDestination | null,
  dimensionId?: string | null,
  category?: string | null,
  action?: string | null,
  label?: string | null,
};

export type DestinationConfig = {
  __typename: "DestinationConfig",
  provider?: IntegrationDestination | null,
};

export enum IntegrationDestination {
  googleTagManager = "googleTagManager",
  universalAnalytics = "universalAnalytics",
}


export type ConditionInput = {
  name?: string | null,
  typeOf: ConditionType,
  operands?: Array< ConditionInput | null > | null,
  node?: LeafInput | null,
};

export enum ConditionType {
  AND = "AND",
  OR = "OR",
  NOT = "NOT",
  MARKETO = "MARKETO",
  GOAL = "GOAL",
  ATTRIBUTE = "ATTRIBUTE",
  AUDIENCE = "AUDIENCE",
  EVENT = "EVENT",
  PAGE = "PAGE",
}


export type LeafInput = {
  operator?: Operators | null,
  value?: string | null,
  source?: SourceType | null,
  attribute?: string | null,
  triggerId?: string | null,
};

export enum Operators {
  equalTo = "equalTo",
  archived = "archived",
  notArchived = "notArchived",
  notEqualTo = "notEqualTo",
  lessThan = "lessThan",
  greaterThan = "greaterThan",
  regex = "regex",
  containsString = "containsString",
  containsValue = "containsValue",
}


export enum SourceType {
  MARKETO = "MARKETO",
}


export type Condition = {
  __typename: "Condition",
  name?: string | null,
  typeOf?: ConditionType | null,
};

export type And = {
  __typename: "And",
  name?: string | null,
  typeOf: ConditionType,
  operands?:  Array<Condition | null > | null,
};

export type Or = {
  __typename: "Or",
  name?: string | null,
  typeOf: ConditionType,
  operands?:  Array<Condition | null > | null,
};

export type Not = {
  __typename: "Not",
  name?: string | null,
  typeOf: ConditionType,
  operands?:  Array<Condition | null > | null,
};

export type MarketoLeaf = {
  __typename: "MarketoLeaf",
  typeOf: ConditionType,
  name?: string | null,
  operator: Operators,
  value?: string | null,
};

export type GoalLeaf = {
  __typename: "GoalLeaf",
  typeOf: ConditionType,
  name?: string | null,
  operator: Operators,
  value?: string | null,
};

export type AttributeLeaf = {
  __typename: "AttributeLeaf",
  typeOf: ConditionType,
  name?: string | null,
  operator: Operators,
  value?: string | null,
  source: SourceType,
  attribute: string,
};

export type TriggerLeaf = {
  __typename: "TriggerLeaf",
  typeOf: ConditionType,
  name?: string | null,
  triggerId: string,
};

export type memberInput = {
  userEmail: string,
  userName?: string | null,
  userGroup: string,
};

export type cognitoUser = {
  __typename: "cognitoUser",
  Username?: string | null,
  Attributes?:  Array<cognitoUserAttribute | null > | null,
  UserCreateDate?: string | null,
  UserLastModifiedDate?: string | null,
  Enabled?: boolean | null,
  UserStatus?: string | null,
};

export type cognitoUserAttribute = {
  __typename: "cognitoUserAttribute",
  Name?: string | null,
  Value?: string | null,
};

export type userStatus = {
  __typename: "userStatus",
  UserStatus?: string | null,
  Enabled?: boolean | null,
  UserAttributes?:  Array<cognitoUserAttribute | null > | null,
};

export type CreateActionInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  group: string,
  organizationId: string,
  destinationId: string,
  isArchived?: boolean | null,
  isActive?: boolean | null,
  firedLast30d?: number | null,
  createdAt?: string | null,
  trigger?: string | null,
};

export type ModelActionConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  destinationId?: ModelIDInput | null,
  isArchived?: ModelBooleanInput | null,
  isActive?: ModelBooleanInput | null,
  firedLast30d?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  trigger?: ModelStringInput | null,
  and?: Array< ModelActionConditionInput | null > | null,
  or?: Array< ModelActionConditionInput | null > | null,
  not?: ModelActionConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Action = {
  __typename: "Action",
  id: string,
  name: string,
  description?: string | null,
  group: string,
  organizationId: string,
  destinationId: string,
  configuration?: DestinationConfig | null,
  isArchived?: boolean | null,
  isActive?: boolean | null,
  firedLast30d?: number | null,
  createdAt: string,
  trigger?: string | null,
  updatedAt: string,
  triggers?: ModelActionTriggerConnection | null,
  destination?: Integration | null,
  organization?: Organization | null,
};

export type ModelActionTriggerConnection = {
  __typename: "ModelActionTriggerConnection",
  items:  Array<ActionTrigger | null >,
  nextToken?: string | null,
};

export type ActionTrigger = {
  __typename: "ActionTrigger",
  id: string,
  group: string,
  actionId: string,
  triggerId: string,
  createdAt: string,
  updatedAt: string,
  action?: Action | null,
  trigger?: Trigger | null,
};

export type Trigger = {
  __typename: "Trigger",
  id: string,
  name?: string | null,
  description?: string | null,
  group: string,
  organizationId: string,
  conditions?: string | null,
  isArchived?: boolean | null,
  createdAt: string,
  updatedAt: string,
  actions?: ModelActionTriggerConnection | null,
  organization?: Organization | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name: string,
  group: string,
  orgOwner: string,
  installationType?: InstallationsType | null,
  integrationCode?: string | null,
  antiFlickerCode?: string | null,
  asynchronousSnippet?: string | null,
  anonymize?: boolean | null,
  enableNonConsent?: boolean | null,
  disabled?: boolean | null,
  signupType?: string | null,
  trialExpiration?: string | null,
  invoiced?: boolean | null,
  flashHide?: boolean | null,
  activateWebsiteAttribution?: boolean | null,
  createdAt: string,
  updatedAt: string,
  actions?: ModelActionConnection | null,
  audiences?: ModelAudienceConnection | null,
  goals?: ModelGoalConnection | null,
  integrations?: ModelIntegrationConnection | null,
};

export enum InstallationsType {
  synchronous = "synchronous",
  asynchronous = "asynchronous",
}


export type ModelActionConnection = {
  __typename: "ModelActionConnection",
  items:  Array<Action | null >,
  nextToken?: string | null,
};

export type ModelAudienceConnection = {
  __typename: "ModelAudienceConnection",
  items:  Array<Audience | null >,
  nextToken?: string | null,
};

export type Audience = {
  __typename: "Audience",
  id: string,
  name: string,
  description?: string | null,
  group: string,
  organizationId: string,
  audienceType?: AudienceType | null,
  conditions?: string | null,
  visitors?: number | null,
  conversions?: number | null,
  individual?: number | null,
  accounts?: number | null,
  actionsCount?: number | null,
  isArchived?: boolean | null,
  createdAt: string,
  updatedAt: string,
  organization?: Organization | null,
};

export enum AudienceType {
  marketo = "marketo",
  googleTagManager = "googleTagManager",
  universalAnalytics = "universalAnalytics",
  salesforce = "salesforce",
}


export type ModelGoalConnection = {
  __typename: "ModelGoalConnection",
  items:  Array<Goal | null >,
  nextToken?: string | null,
};

export type Goal = {
  __typename: "Goal",
  id: string,
  name: string,
  description?: string | null,
  group: string,
  organizationId: string,
  eventId?: string | null,
  trackEvent?: string | null,
  goalFrequency?: number | null,
  includeInRevenue?: boolean | null,
  calculateGoalValueAuto?: boolean | null,
  aggregateGoalValue?: boolean | null,
  defaultGoalValue?: number | null,
  triggeringType?: string | null,
  includeUrls?:  Array<Url | null > | null,
  excludeUrls?:  Array<Url | null > | null,
  isActive?: boolean | null,
  isArchived?: boolean | null,
  actionsCount?: number | null,
  type: GoalType,
  typeState: string,
  attributes?: GoalAttribute | null,
  conversionsLast30d?: number | null,
  createdAt: string,
  updatedAt: string,
  event?: Event | null,
  organization?: Organization | null,
};

export type Url = {
  __typename: "Url",
  urls?: string | null,
  matchType?: MatchTypes | null,
};

export enum MatchTypes {
  Equals = "Equals",
  Contains = "Contains",
  Regex = "Regex",
}


export enum GoalType {
  marketo = "marketo",
  universalAnalyticsSource = "universalAnalyticsSource",
  salesforce = "salesforce",
  demandbase = "demandbase",
  hubspot = "hubspot",
  clearbit = "clearbit",
  sixSense = "sixSense",
  accountMatch = "accountMatch",
  googleOptimize = "googleOptimize",
}


export type GoalAttribute = {
  __typename: "GoalAttribute",
  apiname?: string | null,
  createdbyid: string,
  defaultprobability?: string | null,
  description?: string | null,
  forecastcategory?: string | null,
  forecastcategoryname?: string | null,
  isactive?: boolean | null,
  isclosed?: boolean | null,
  iswon?: boolean | null,
  lastmodifiedbyid?: string | null,
  lastmodifieddate?: string | null,
  masterlabel?: string | null,
  sortorder?: string | null,
  systemmodstamp?: string | null,
  createddate?: string | null,
  id?: string | null,
};

export type Event = {
  __typename: "Event",
  id: string,
  name: string,
  source: EventSourceType,
  group: string,
  organizationId: string,
  attributes?:  Array<EventAttribute | null > | null,
  createdAt: string,
  updatedAt: string,
  organization?: Organization | null,
};

export enum EventSourceType {
  marketo = "marketo",
  universalAnalyticsSource = "universalAnalyticsSource",
  salesforce = "salesforce",
  demandbase = "demandbase",
  hubspot = "hubspot",
  clearbit = "clearbit",
  sixSense = "sixSense",
  accountMatch = "accountMatch",
  googleOptimize = "googleOptimize",
}


export type EventAttribute = {
  __typename: "EventAttribute",
  name?: string | null,
  matchType?: MatchTypes | null,
  value?: string | null,
};

export type ModelIntegrationConnection = {
  __typename: "ModelIntegrationConnection",
  items:  Array<Integration | null >,
  nextToken?: string | null,
};

export type Integration = {
  __typename: "Integration",
  id: string,
  name: string,
  group: string,
  organizationId: string,
  credentials?: Credentials | null,
  configuration?: Configuration | null,
  provider: Providers,
  source: boolean,
  createdAt: string,
  updatedAt: string,
  lists?: ModelIntegrationListConnection | null,
  attributes?: ModelIntegrationFieldConnection | null,
  organization?: Organization | null,
};

export enum Providers {
  marketo = "marketo",
  googleTagManager = "googleTagManager",
  universalAnalytics = "universalAnalytics",
  universalAnalyticsSource = "universalAnalyticsSource",
  salesforce = "salesforce",
  demandbase = "demandbase",
  hubspot = "hubspot",
  clearbit = "clearbit",
  sixSense = "sixSense",
  custom = "custom",
  accountMatch = "accountMatch",
  googleOptimize = "googleOptimize",
}


export type ModelIntegrationListConnection = {
  __typename: "ModelIntegrationListConnection",
  items:  Array<IntegrationList | null >,
  nextToken?: string | null,
};

export type IntegrationList = {
  __typename: "IntegrationList",
  id: string,
  externalId?: string | null,
  group: string,
  provider: Providers,
  integrationId: string,
  name?: string | null,
  description?: string | null,
  smart?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelIntegrationFieldConnection = {
  __typename: "ModelIntegrationFieldConnection",
  items:  Array<IntegrationField | null >,
  nextToken?: string | null,
};

export type IntegrationField = {
  __typename: "IntegrationField",
  id?: string | null,
  group: string,
  provider: Providers,
  integrationId: string,
  fetch?: boolean | null,
  push?: boolean | null,
  name: string,
  displayName?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateActionInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  group?: string | null,
  organizationId?: string | null,
  destinationId?: string | null,
  isArchived?: boolean | null,
  isActive?: boolean | null,
  firedLast30d?: number | null,
  createdAt?: string | null,
  trigger?: string | null,
};

export type DeleteActionInput = {
  id: string,
};

export type CreateActionTriggerInput = {
  id?: string | null,
  group: string,
  actionId: string,
  triggerId: string,
};

export type ModelActionTriggerConditionInput = {
  actionId?: ModelIDInput | null,
  triggerId?: ModelIDInput | null,
  and?: Array< ModelActionTriggerConditionInput | null > | null,
  or?: Array< ModelActionTriggerConditionInput | null > | null,
  not?: ModelActionTriggerConditionInput | null,
};

export type UpdateActionTriggerInput = {
  id: string,
  group?: string | null,
  actionId?: string | null,
  triggerId?: string | null,
};

export type DeleteActionTriggerInput = {
  id: string,
};

export type CreateTriggerInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  group: string,
  organizationId: string,
  conditions?: string | null,
  isArchived?: boolean | null,
  createdAt?: string | null,
};

export type ModelTriggerConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  conditions?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTriggerConditionInput | null > | null,
  or?: Array< ModelTriggerConditionInput | null > | null,
  not?: ModelTriggerConditionInput | null,
};

export type UpdateTriggerInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  group?: string | null,
  organizationId?: string | null,
  conditions?: string | null,
  isArchived?: boolean | null,
  createdAt?: string | null,
};

export type DeleteTriggerInput = {
  id: string,
};

export type CreateAudienceInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  group: string,
  organizationId: string,
  audienceType?: AudienceType | null,
  conditions?: string | null,
  visitors?: number | null,
  conversions?: number | null,
  individual?: number | null,
  accounts?: number | null,
  actionsCount?: number | null,
  isArchived?: boolean | null,
  createdAt?: string | null,
};

export type ModelAudienceConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  audienceType?: ModelAudienceTypeInput | null,
  conditions?: ModelStringInput | null,
  visitors?: ModelIntInput | null,
  conversions?: ModelIntInput | null,
  individual?: ModelIntInput | null,
  accounts?: ModelIntInput | null,
  actionsCount?: ModelIntInput | null,
  isArchived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAudienceConditionInput | null > | null,
  or?: Array< ModelAudienceConditionInput | null > | null,
  not?: ModelAudienceConditionInput | null,
};

export type ModelAudienceTypeInput = {
  eq?: AudienceType | null,
  ne?: AudienceType | null,
};

export type UpdateAudienceInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  group?: string | null,
  organizationId?: string | null,
  audienceType?: AudienceType | null,
  conditions?: string | null,
  visitors?: number | null,
  conversions?: number | null,
  individual?: number | null,
  accounts?: number | null,
  actionsCount?: number | null,
  isArchived?: boolean | null,
  createdAt?: string | null,
};

export type DeleteAudienceInput = {
  id: string,
};

export type CreateGoalInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  group: string,
  organizationId: string,
  eventId?: string | null,
  trackEvent?: string | null,
  goalFrequency?: number | null,
  includeInRevenue?: boolean | null,
  calculateGoalValueAuto?: boolean | null,
  aggregateGoalValue?: boolean | null,
  defaultGoalValue?: number | null,
  triggeringType?: string | null,
  includeUrls?: Array< UrlInput | null > | null,
  excludeUrls?: Array< UrlInput | null > | null,
  isActive?: boolean | null,
  isArchived?: boolean | null,
  actionsCount?: number | null,
  type: GoalType,
  typeState: string,
  attributes?: GoalAttributeInput | null,
  conversionsLast30d?: number | null,
  createdAt?: string | null,
};

export type UrlInput = {
  urls?: string | null,
  matchType?: MatchTypes | null,
};

export type GoalAttributeInput = {
  apiname?: string | null,
  createdbyid: string,
  defaultprobability?: string | null,
  description?: string | null,
  forecastcategory?: string | null,
  forecastcategoryname?: string | null,
  isactive?: boolean | null,
  isclosed?: boolean | null,
  iswon?: boolean | null,
  lastmodifiedbyid?: string | null,
  lastmodifieddate?: string | null,
  masterlabel?: string | null,
  sortorder?: string | null,
  systemmodstamp?: string | null,
  createddate?: string | null,
  id?: string | null,
};

export type ModelGoalConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  trackEvent?: ModelStringInput | null,
  goalFrequency?: ModelIntInput | null,
  includeInRevenue?: ModelBooleanInput | null,
  calculateGoalValueAuto?: ModelBooleanInput | null,
  aggregateGoalValue?: ModelBooleanInput | null,
  defaultGoalValue?: ModelIntInput | null,
  triggeringType?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  actionsCount?: ModelIntInput | null,
  type?: ModelGoalTypeInput | null,
  typeState?: ModelStringInput | null,
  conversionsLast30d?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelGoalConditionInput | null > | null,
  or?: Array< ModelGoalConditionInput | null > | null,
  not?: ModelGoalConditionInput | null,
};

export type ModelGoalTypeInput = {
  eq?: GoalType | null,
  ne?: GoalType | null,
};

export type UpdateGoalInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  group?: string | null,
  organizationId?: string | null,
  eventId?: string | null,
  trackEvent?: string | null,
  goalFrequency?: number | null,
  includeInRevenue?: boolean | null,
  calculateGoalValueAuto?: boolean | null,
  aggregateGoalValue?: boolean | null,
  defaultGoalValue?: number | null,
  triggeringType?: string | null,
  includeUrls?: Array< UrlInput | null > | null,
  excludeUrls?: Array< UrlInput | null > | null,
  isActive?: boolean | null,
  isArchived?: boolean | null,
  actionsCount?: number | null,
  type?: GoalType | null,
  typeState?: string | null,
  attributes?: GoalAttributeInput | null,
  conversionsLast30d?: number | null,
  createdAt?: string | null,
};

export type DeleteGoalInput = {
  id: string,
};

export type CreateEventInput = {
  id?: string | null,
  name: string,
  source: EventSourceType,
  group: string,
  organizationId: string,
  attributes?: Array< EventAttributeInput | null > | null,
};

export type EventAttributeInput = {
  name?: string | null,
  matchType?: MatchTypes | null,
  value?: string | null,
};

export type ModelEventConditionInput = {
  name?: ModelStringInput | null,
  source?: ModelEventSourceTypeInput | null,
  organizationId?: ModelIDInput | null,
  and?: Array< ModelEventConditionInput | null > | null,
  or?: Array< ModelEventConditionInput | null > | null,
  not?: ModelEventConditionInput | null,
};

export type ModelEventSourceTypeInput = {
  eq?: EventSourceType | null,
  ne?: EventSourceType | null,
};

export type UpdateEventInput = {
  id: string,
  name?: string | null,
  source?: EventSourceType | null,
  group?: string | null,
  organizationId?: string | null,
  attributes?: Array< EventAttributeInput | null > | null,
};

export type DeleteEventInput = {
  id: string,
};

export type CreatePageInput = {
  id?: string | null,
  name: string,
  group: string,
  organizationId: string,
};

export type ModelPageConditionInput = {
  name?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  and?: Array< ModelPageConditionInput | null > | null,
  or?: Array< ModelPageConditionInput | null > | null,
  not?: ModelPageConditionInput | null,
};

export type Page = {
  __typename: "Page",
  id: string,
  name: string,
  group: string,
  organizationId: string,
  createdAt: string,
  updatedAt: string,
  organization?: Organization | null,
};

export type UpdatePageInput = {
  id: string,
  name?: string | null,
  group?: string | null,
  organizationId?: string | null,
};

export type DeletePageInput = {
  id: string,
};

export type CreateIntegrationListInput = {
  id?: string | null,
  externalId?: string | null,
  group: string,
  provider: Providers,
  integrationId: string,
  name?: string | null,
  description?: string | null,
  smart?: boolean | null,
};

export type ModelIntegrationListConditionInput = {
  externalId?: ModelIDInput | null,
  provider?: ModelProvidersInput | null,
  integrationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  smart?: ModelBooleanInput | null,
  and?: Array< ModelIntegrationListConditionInput | null > | null,
  or?: Array< ModelIntegrationListConditionInput | null > | null,
  not?: ModelIntegrationListConditionInput | null,
};

export type ModelProvidersInput = {
  eq?: Providers | null,
  ne?: Providers | null,
};

export type UpdateIntegrationListInput = {
  id: string,
  externalId?: string | null,
  group?: string | null,
  provider?: Providers | null,
  integrationId?: string | null,
  name?: string | null,
  description?: string | null,
  smart?: boolean | null,
};

export type DeleteIntegrationListInput = {
  id: string,
};

export type CreateIntegrationFieldInput = {
  id?: string | null,
  group: string,
  provider: Providers,
  integrationId: string,
  fetch?: boolean | null,
  push?: boolean | null,
  name: string,
  displayName?: string | null,
};

export type ModelIntegrationFieldConditionInput = {
  provider?: ModelProvidersInput | null,
  integrationId?: ModelIDInput | null,
  fetch?: ModelBooleanInput | null,
  push?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  and?: Array< ModelIntegrationFieldConditionInput | null > | null,
  or?: Array< ModelIntegrationFieldConditionInput | null > | null,
  not?: ModelIntegrationFieldConditionInput | null,
};

export type UpdateIntegrationFieldInput = {
  id: string,
  group?: string | null,
  provider?: Providers | null,
  integrationId?: string | null,
  fetch?: boolean | null,
  push?: boolean | null,
  name?: string | null,
  displayName?: string | null,
};

export type DeleteIntegrationFieldInput = {
  id: string,
};

export type CreateIntegrationInput = {
  id?: string | null,
  name: string,
  group: string,
  organizationId: string,
  provider: Providers,
  source: boolean,
  createdAt?: string | null,
};

export type ModelIntegrationConditionInput = {
  name?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  provider?: ModelProvidersInput | null,
  source?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelIntegrationConditionInput | null > | null,
  or?: Array< ModelIntegrationConditionInput | null > | null,
  not?: ModelIntegrationConditionInput | null,
};

export type UpdateIntegrationInput = {
  id: string,
  name?: string | null,
  group?: string | null,
  organizationId?: string | null,
  provider?: Providers | null,
  source?: boolean | null,
  createdAt?: string | null,
};

export type DeleteIntegrationInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  subjectId: string,
  subjectType: Subjects,
  status: Status,
  title: string,
  message: string,
  group: string,
  organizationId: string,
  dismissedAt?: string | null,
  dismissedBy?: string | null,
  createdAt?: string | null,
};

export enum Subjects {
  ACTION = "ACTION",
  AUDIENCE = "AUDIENCE",
  INTEGRATION = "INTEGRATION",
  ORGANIZATION = "ORGANIZATION",
}


export enum Status {
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}


export type ModelNotificationConditionInput = {
  subjectId?: ModelIDInput | null,
  subjectType?: ModelSubjectsInput | null,
  status?: ModelStatusInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  dismissedAt?: ModelStringInput | null,
  dismissedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type ModelSubjectsInput = {
  eq?: Subjects | null,
  ne?: Subjects | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  subjectId: string,
  subjectType: Subjects,
  status: Status,
  title: string,
  message: string,
  group: string,
  organizationId: string,
  dismissedAt?: string | null,
  dismissedBy?: string | null,
  createdAt: string,
  updatedAt: string,
  organization?: Organization | null,
};

export type UpdateNotificationInput = {
  id: string,
  subjectId?: string | null,
  subjectType?: Subjects | null,
  status?: Status | null,
  title?: string | null,
  message?: string | null,
  group?: string | null,
  organizationId?: string | null,
  dismissedAt?: string | null,
  dismissedBy?: string | null,
  createdAt?: string | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateOrganizationInput = {
  id?: string | null,
  name: string,
  group: string,
  orgOwner: string,
  installationType?: InstallationsType | null,
  integrationCode?: string | null,
  antiFlickerCode?: string | null,
  asynchronousSnippet?: string | null,
  anonymize?: boolean | null,
  enableNonConsent?: boolean | null,
  disabled?: boolean | null,
  signupType?: string | null,
  trialExpiration?: string | null,
  invoiced?: boolean | null,
  flashHide?: boolean | null,
  activateWebsiteAttribution?: boolean | null,
};

export type ModelOrganizationConditionInput = {
  name?: ModelStringInput | null,
  orgOwner?: ModelStringInput | null,
  installationType?: ModelInstallationsTypeInput | null,
  integrationCode?: ModelStringInput | null,
  antiFlickerCode?: ModelStringInput | null,
  asynchronousSnippet?: ModelStringInput | null,
  anonymize?: ModelBooleanInput | null,
  enableNonConsent?: ModelBooleanInput | null,
  disabled?: ModelBooleanInput | null,
  signupType?: ModelStringInput | null,
  trialExpiration?: ModelStringInput | null,
  invoiced?: ModelBooleanInput | null,
  flashHide?: ModelBooleanInput | null,
  activateWebsiteAttribution?: ModelBooleanInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
};

export type ModelInstallationsTypeInput = {
  eq?: InstallationsType | null,
  ne?: InstallationsType | null,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string | null,
  group?: string | null,
  orgOwner?: string | null,
  installationType?: InstallationsType | null,
  integrationCode?: string | null,
  antiFlickerCode?: string | null,
  asynchronousSnippet?: string | null,
  anonymize?: boolean | null,
  enableNonConsent?: boolean | null,
  disabled?: boolean | null,
  signupType?: string | null,
  trialExpiration?: string | null,
  invoiced?: boolean | null,
  flashHide?: boolean | null,
  activateWebsiteAttribution?: boolean | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  userEmail?: string | null,
  name?: string | null,
  userId?: string | null,
  orgId?: string | null,
  group?: string | null,
  timeStamp?: string | null,
  stripeCustomerId?: string | null,
};

export type ModelUserConditionInput = {
  userEmail?: ModelStringInput | null,
  name?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  orgId?: ModelIDInput | null,
  timeStamp?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type User = {
  __typename: "User",
  id: string,
  userEmail?: string | null,
  name?: string | null,
  userId?: string | null,
  orgId?: string | null,
  group?: string | null,
  timeStamp?: string | null,
  createdAt: string,
  updatedAt: string,
  stripeCustomerId?: string | null,
};

export type UpdateUserInput = {
  id: string,
  userEmail?: string | null,
  name?: string | null,
  userId?: string | null,
  orgId?: string | null,
  group?: string | null,
  timeStamp?: string | null,
  stripeCustomerId?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreatePaymentInput = {
  id?: string | null,
  stripeId?: string | null,
  amount?: number | null,
  receivedAt?: string | null,
};

export type ModelPaymentConditionInput = {
  stripeId?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  receivedAt?: ModelStringInput | null,
  and?: Array< ModelPaymentConditionInput | null > | null,
  or?: Array< ModelPaymentConditionInput | null > | null,
  not?: ModelPaymentConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Payment = {
  __typename: "Payment",
  id: string,
  stripeId?: string | null,
  amount?: number | null,
  receivedAt?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePaymentInput = {
  id: string,
  stripeId?: string | null,
  amount?: number | null,
  receivedAt?: string | null,
};

export type DeletePaymentInput = {
  id: string,
};

export type ModelActionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  destinationId?: ModelIDInput | null,
  isArchived?: ModelBooleanInput | null,
  isActive?: ModelBooleanInput | null,
  firedLast30d?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  trigger?: ModelStringInput | null,
  and?: Array< ModelActionFilterInput | null > | null,
  or?: Array< ModelActionFilterInput | null > | null,
  not?: ModelActionFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelActionTriggerFilterInput = {
  id?: ModelIDInput | null,
  group?: ModelStringInput | null,
  actionId?: ModelIDInput | null,
  triggerId?: ModelIDInput | null,
  and?: Array< ModelActionTriggerFilterInput | null > | null,
  or?: Array< ModelActionTriggerFilterInput | null > | null,
  not?: ModelActionTriggerFilterInput | null,
};

export type ModelTriggerFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  conditions?: ModelStringInput | null,
  isArchived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTriggerFilterInput | null > | null,
  or?: Array< ModelTriggerFilterInput | null > | null,
  not?: ModelTriggerFilterInput | null,
};

export type ModelTriggerConnection = {
  __typename: "ModelTriggerConnection",
  items:  Array<Trigger | null >,
  nextToken?: string | null,
};

export type ModelAudienceFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  audienceType?: ModelAudienceTypeInput | null,
  conditions?: ModelStringInput | null,
  visitors?: ModelIntInput | null,
  conversions?: ModelIntInput | null,
  individual?: ModelIntInput | null,
  accounts?: ModelIntInput | null,
  actionsCount?: ModelIntInput | null,
  isArchived?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAudienceFilterInput | null > | null,
  or?: Array< ModelAudienceFilterInput | null > | null,
  not?: ModelAudienceFilterInput | null,
};

export type ModelGoalFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  eventId?: ModelIDInput | null,
  trackEvent?: ModelStringInput | null,
  goalFrequency?: ModelIntInput | null,
  includeInRevenue?: ModelBooleanInput | null,
  calculateGoalValueAuto?: ModelBooleanInput | null,
  aggregateGoalValue?: ModelBooleanInput | null,
  defaultGoalValue?: ModelIntInput | null,
  triggeringType?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  isArchived?: ModelBooleanInput | null,
  actionsCount?: ModelIntInput | null,
  type?: ModelGoalTypeInput | null,
  typeState?: ModelStringInput | null,
  conversionsLast30d?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelGoalFilterInput | null > | null,
  or?: Array< ModelGoalFilterInput | null > | null,
  not?: ModelGoalFilterInput | null,
};

export type ModelEventFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  source?: ModelEventSourceTypeInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  and?: Array< ModelEventFilterInput | null > | null,
  or?: Array< ModelEventFilterInput | null > | null,
  not?: ModelEventFilterInput | null,
};

export type ModelEventConnection = {
  __typename: "ModelEventConnection",
  items:  Array<Event | null >,
  nextToken?: string | null,
};

export type ModelPageFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  and?: Array< ModelPageFilterInput | null > | null,
  or?: Array< ModelPageFilterInput | null > | null,
  not?: ModelPageFilterInput | null,
};

export type ModelPageConnection = {
  __typename: "ModelPageConnection",
  items:  Array<Page | null >,
  nextToken?: string | null,
};

export type ModelIntegrationListFilterInput = {
  id?: ModelIDInput | null,
  externalId?: ModelIDInput | null,
  group?: ModelStringInput | null,
  provider?: ModelProvidersInput | null,
  integrationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  smart?: ModelBooleanInput | null,
  and?: Array< ModelIntegrationListFilterInput | null > | null,
  or?: Array< ModelIntegrationListFilterInput | null > | null,
  not?: ModelIntegrationListFilterInput | null,
};

export type ModelIntegrationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  provider?: ModelProvidersInput | null,
  source?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelIntegrationFilterInput | null > | null,
  or?: Array< ModelIntegrationFilterInput | null > | null,
  not?: ModelIntegrationFilterInput | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  subjectId?: ModelIDInput | null,
  subjectType?: ModelSubjectsInput | null,
  status?: ModelStatusInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  dismissedAt?: ModelStringInput | null,
  dismissedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  group?: ModelStringInput | null,
  orgOwner?: ModelStringInput | null,
  installationType?: ModelInstallationsTypeInput | null,
  integrationCode?: ModelStringInput | null,
  antiFlickerCode?: ModelStringInput | null,
  asynchronousSnippet?: ModelStringInput | null,
  anonymize?: ModelBooleanInput | null,
  enableNonConsent?: ModelBooleanInput | null,
  disabled?: ModelBooleanInput | null,
  signupType?: ModelStringInput | null,
  trialExpiration?: ModelStringInput | null,
  invoiced?: ModelBooleanInput | null,
  flashHide?: ModelBooleanInput | null,
  activateWebsiteAttribution?: ModelBooleanInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  userEmail?: ModelStringInput | null,
  name?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  orgId?: ModelIDInput | null,
  group?: ModelStringInput | null,
  timeStamp?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelPaymentFilterInput = {
  id?: ModelIDInput | null,
  stripeId?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  receivedAt?: ModelStringInput | null,
  and?: Array< ModelPaymentFilterInput | null > | null,
  or?: Array< ModelPaymentFilterInput | null > | null,
  not?: ModelPaymentFilterInput | null,
};

export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection",
  items:  Array<Payment | null >,
  nextToken?: string | null,
};

export type DismissNotificationMutationVariables = {
  id: string,
};

export type DismissNotificationMutation = {
  dismissNotification?: boolean | null,
};

export type EditMarketoCredentialsMutationVariables = {
  integrationId: string,
  input: MarketoCredsInput,
};

export type EditMarketoCredentialsMutation = {
  editMarketoCredentials?:  {
    __typename: "MarketoCreds",
    apiHost?: string | null,
    clientId?: string | null,
    clientSecret?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type EditSalesforceCredentialsMutationVariables = {
  integrationId: string,
  input: SalesforceCredsInput,
};

export type EditSalesforceCredentialsMutation = {
  editSalesforceCredentials?:  {
    __typename: "SalesforceCreds",
    access_token?: string | null,
    refresh_token?: string | null,
    signature?: string | null,
    scope?: string | null,
    id_token?: string | null,
    instance_url?: string | null,
    id?: string | null,
    token_type?: string | null,
    issued_at?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type EditSixSenseCredentialsMutationVariables = {
  integrationId: string,
  input: SixSenseCredsInput,
};

export type EditSixSenseCredentialsMutation = {
  editSixSenseCredentials?:  {
    __typename: "SixSenseCreds",
    apiToken?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type EditHubSpotCredentialsMutationVariables = {
  integrationId: string,
  input: HubSpotCredsInput,
};

export type EditHubSpotCredentialsMutation = {
  editHubSpotCredentials?:  {
    __typename: "HubSpotCreds",
    accessToken?: string | null,
    refreshToken?: string | null,
    tokenType?: string | null,
    expiresIn?: string | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type EditCustomConfigurationMutationVariables = {
  integrationId: string,
  input: CustomConfigInput,
};

export type EditCustomConfigurationMutation = {
  editCustomConfiguration?:  {
    __typename: "CustomConfig",
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
  } | null,
};

export type EditMarketoConfigurationMutationVariables = {
  integrationId: string,
  input: MarketoConfigInput,
};

export type EditMarketoConfigurationMutation = {
  editMarketoConfiguration?:  {
    __typename: "MarketoConfig",
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    hiddenField?: string | null,
  } | null,
};

export type EditSalesforceConfigurationMutationVariables = {
  integrationId: string,
  input: SalesforceConfigInput,
};

export type EditSalesforceConfigurationMutation = {
  editSalesforceConfiguration?:  {
    __typename: "SalesforceConfig",
    createdAt?: string | null,
    updatedAt?: string | null,
    active?: boolean | null,
    userId?: string | null,
  } | null,
};

export type EditGoogleTagManagerConfigurationMutationVariables = {
  integrationId: string,
  input: GoogleTagManagerConfigInput,
};

export type EditGoogleTagManagerConfigurationMutation = {
  editGoogleTagManagerConfiguration?:  {
    __typename: "GoogleTagManagerConfig",
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    dataLayerVarName?: string | null,
  } | null,
};

export type EditUniversalAnalyticsConfigurationMutationVariables = {
  integrationId: string,
  input: UniversalAnalyticsConfigInput,
};

export type EditUniversalAnalyticsConfigurationMutation = {
  editUniversalAnalyticsConfiguration?:  {
    __typename: "UniversalAnalyticsConfig",
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    uaTrackingId?: string | null,
  } | null,
};

export type EditUniversalAnalyticsSourceConfigurationMutationVariables = {
  integrationId: string,
  input: UniversalAnalyticsSourceConfigInput,
};

export type EditUniversalAnalyticsSourceConfigurationMutation = {
  editUniversalAnalyticsSourceConfiguration?:  {
    __typename: "UniversalAnalyticsSourceConfig",
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    uaTrackingId?: string | null,
  } | null,
};

export type EditHubSpotConfigurationMutationVariables = {
  integrationId: string,
  input: HubSpotConfigInput,
};

export type EditHubSpotConfigurationMutation = {
  editHubSpotConfiguration?:  {
    __typename: "HubSpotConfig",
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type EditClearbitConfigurationMutationVariables = {
  integrationId: string,
  input: ClearbitConfigInput,
};

export type EditClearbitConfigurationMutation = {
  editClearbitConfiguration?:  {
    __typename: "ClearbitConfig",
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type EditDemandbaseConfigurationMutationVariables = {
  integrationId: string,
  input: DemandbaseConfigInput,
};

export type EditDemandbaseConfigurationMutation = {
  editDemandbaseConfiguration?:  {
    __typename: "DemandbaseConfig",
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type EditSixSenseConfigurationMutationVariables = {
  integrationId: string,
  input: SixSenseConfigInput,
};

export type EditSixSenseConfigurationMutation = {
  editSixSenseConfiguration?:  {
    __typename: "SixSenseConfig",
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type EditAccountMatchConfigurationMutationVariables = {
  integrationId: string,
  input: AccountMatchConfigInput,
};

export type EditAccountMatchConfigurationMutation = {
  editAccountMatchConfiguration?:  {
    __typename: "AccountMatchConfig",
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type EditGoogleOptimizeConfigurationMutationVariables = {
  integrationId: string,
  input: GoogleOptimizeConfigInput,
};

export type EditGoogleOptimizeConfigurationMutation = {
  editGoogleOptimizeConfiguration?:  {
    __typename: "GoogleOptimizeConfig",
    active?: boolean | null,
    createdAt?: string | null,
    updatedAt?: string | null,
  } | null,
};

export type EditUniversalAnalyticsDestinationMutationVariables = {
  actionId: string,
  input: UniversalAnalyticsDestinationInput,
};

export type EditUniversalAnalyticsDestinationMutation = {
  editUniversalAnalyticsDestination?:  {
    __typename: "UniversalAnalyticsDestination",
    provider?: IntegrationDestination | null,
    dimensionId?: string | null,
    category?: string | null,
    action?: string | null,
    label?: string | null,
  } | null,
};

export type EditAudienceConditionMutationVariables = {
  audienceId: string,
  input: ConditionInput,
};

export type EditAudienceConditionMutation = {
  editAudienceCondition: ( {
      __typename: "And",
      name?: string | null,
      typeOf?: ConditionType | null,
      operands:  Array<( {
          __typename: "And",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Or",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Not",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "MarketoLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "GoalLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "AttributeLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
          source: SourceType,
          attribute: string,
        } | {
          __typename: "TriggerLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          triggerId: string,
        }
      ) | null > | null,
    } | {
      __typename: "Or",
      name?: string | null,
      typeOf?: ConditionType | null,
      operands:  Array<( {
          __typename: "And",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Or",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Not",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "MarketoLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "GoalLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "AttributeLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
          source: SourceType,
          attribute: string,
        } | {
          __typename: "TriggerLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          triggerId: string,
        }
      ) | null > | null,
    } | {
      __typename: "Not",
      name?: string | null,
      typeOf?: ConditionType | null,
      operands:  Array<( {
          __typename: "And",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Or",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Not",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "MarketoLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "GoalLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "AttributeLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
          source: SourceType,
          attribute: string,
        } | {
          __typename: "TriggerLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          triggerId: string,
        }
      ) | null > | null,
    } | {
      __typename: "MarketoLeaf",
      name?: string | null,
      typeOf?: ConditionType | null,
      operator: Operators,
      value?: string | null,
    } | {
      __typename: "GoalLeaf",
      name?: string | null,
      typeOf?: ConditionType | null,
      operator: Operators,
      value?: string | null,
    } | {
      __typename: "AttributeLeaf",
      name?: string | null,
      typeOf?: ConditionType | null,
      operator: Operators,
      value?: string | null,
      source: SourceType,
      attribute: string,
    } | {
      __typename: "TriggerLeaf",
      name?: string | null,
      typeOf?: ConditionType | null,
      triggerId: string,
    }
  ) | null,
};

export type EditTriggerConditionMutationVariables = {
  triggerId: string,
  input: ConditionInput,
};

export type EditTriggerConditionMutation = {
  editTriggerCondition: ( {
      __typename: "And",
      name?: string | null,
      typeOf?: ConditionType | null,
      operands:  Array<( {
          __typename: "And",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Or",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Not",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "MarketoLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "GoalLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "AttributeLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
          source: SourceType,
          attribute: string,
        } | {
          __typename: "TriggerLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          triggerId: string,
        }
      ) | null > | null,
    } | {
      __typename: "Or",
      name?: string | null,
      typeOf?: ConditionType | null,
      operands:  Array<( {
          __typename: "And",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Or",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Not",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "MarketoLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "GoalLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "AttributeLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
          source: SourceType,
          attribute: string,
        } | {
          __typename: "TriggerLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          triggerId: string,
        }
      ) | null > | null,
    } | {
      __typename: "Not",
      name?: string | null,
      typeOf?: ConditionType | null,
      operands:  Array<( {
          __typename: "And",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Or",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "Not",
          name?: string | null,
          typeOf?: ConditionType | null,
          operands:  Array<( {
              __typename: "And",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Or",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "Not",
              name?: string | null,
              typeOf?: ConditionType | null,
              operands:  Array<( {
                  __typename: "And",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Or",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "Not",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                } | {
                  __typename: "MarketoLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "GoalLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                } | {
                  __typename: "AttributeLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  operator: Operators,
                  value?: string | null,
                  source: SourceType,
                  attribute: string,
                } | {
                  __typename: "TriggerLeaf",
                  name?: string | null,
                  typeOf?: ConditionType | null,
                  triggerId: string,
                }
              ) | null > | null,
            } | {
              __typename: "MarketoLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "GoalLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
            } | {
              __typename: "AttributeLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              operator: Operators,
              value?: string | null,
              source: SourceType,
              attribute: string,
            } | {
              __typename: "TriggerLeaf",
              name?: string | null,
              typeOf?: ConditionType | null,
              triggerId: string,
            }
          ) | null > | null,
        } | {
          __typename: "MarketoLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "GoalLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
        } | {
          __typename: "AttributeLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          operator: Operators,
          value?: string | null,
          source: SourceType,
          attribute: string,
        } | {
          __typename: "TriggerLeaf",
          name?: string | null,
          typeOf?: ConditionType | null,
          triggerId: string,
        }
      ) | null > | null,
    } | {
      __typename: "MarketoLeaf",
      name?: string | null,
      typeOf?: ConditionType | null,
      operator: Operators,
      value?: string | null,
    } | {
      __typename: "GoalLeaf",
      name?: string | null,
      typeOf?: ConditionType | null,
      operator: Operators,
      value?: string | null,
    } | {
      __typename: "AttributeLeaf",
      name?: string | null,
      typeOf?: ConditionType | null,
      operator: Operators,
      value?: string | null,
      source: SourceType,
      attribute: string,
    } | {
      __typename: "TriggerLeaf",
      name?: string | null,
      typeOf?: ConditionType | null,
      triggerId: string,
    }
  ) | null,
};

export type AddMemberMutationVariables = {
  member: memberInput,
};

export type AddMemberMutation = {
  addMember?:  {
    __typename: "cognitoUser",
    Username?: string | null,
    Attributes?:  Array< {
      __typename: "cognitoUserAttribute",
      Name?: string | null,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: string | null,
  } | null,
};

export type IdentifyMemberMutationVariables = {
  member: memberInput,
};

export type IdentifyMemberMutation = {
  identifyMember?:  {
    __typename: "userStatus",
    UserStatus?: string | null,
    Enabled?: boolean | null,
    UserAttributes?:  Array< {
      __typename: "cognitoUserAttribute",
      Name?: string | null,
      Value?: string | null,
    } | null > | null,
  } | null,
};

export type ResendMemberInvitationMutationVariables = {
  member: memberInput,
};

export type ResendMemberInvitationMutation = {
  resendMemberInvitation?:  {
    __typename: "cognitoUser",
    Username?: string | null,
    Attributes?:  Array< {
      __typename: "cognitoUserAttribute",
      Name?: string | null,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: string | null,
  } | null,
};

export type RemoveMemberMutationVariables = {
  member: memberInput,
};

export type RemoveMemberMutation = {
  removeMember?: string | null,
};

export type CreateActionMutationVariables = {
  input: CreateActionInput,
  condition?: ModelActionConditionInput | null,
};

export type CreateActionMutation = {
  createAction?:  {
    __typename: "Action",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    destinationId: string,
    configuration: ( {
        __typename: "UniversalAnalyticsDestination",
        provider?: IntegrationDestination | null,
        dimensionId?: string | null,
        category?: string | null,
        action?: string | null,
        label?: string | null,
      }
    ) | null,
    isArchived?: boolean | null,
    isActive?: boolean | null,
    firedLast30d?: number | null,
    createdAt: string,
    trigger?: string | null,
    updatedAt: string,
    triggers?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    destination?:  {
      __typename: "Integration",
      id: string,
      name: string,
      group: string,
      organizationId: string,
      credentials: ( {
          __typename: "MarketoCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiHost?: string | null,
          clientId?: string | null,
          clientSecret?: string | null,
        } | {
          __typename: "SalesforceCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          access_token?: string | null,
          refresh_token?: string | null,
          signature?: string | null,
          scope?: string | null,
          id_token?: string | null,
          instance_url?: string | null,
          id?: string | null,
          token_type?: string | null,
          issued_at?: string | null,
        } | {
          __typename: "SixSenseCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiToken?: string | null,
        } | {
          __typename: "HubSpotCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          accessToken?: string | null,
          refreshToken?: string | null,
          tokenType?: string | null,
          expiresIn?: string | null,
        }
      ) | null,
      configuration: ( {
          __typename: "CustomConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "MarketoConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          hiddenField?: string | null,
        } | {
          __typename: "SalesforceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId?: string | null,
        } | {
          __typename: "GoogleTagManagerConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          dataLayerVarName?: string | null,
        } | {
          __typename: "UniversalAnalyticsConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "UniversalAnalyticsSourceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "HubSpotConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "ClearbitConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "DemandbaseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "SixSenseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "AccountMatchConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "GoogleOptimizeConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        }
      ) | null,
      provider: Providers,
      source: boolean,
      createdAt: string,
      updatedAt: string,
      lists?:  {
        __typename: "ModelIntegrationListConnection",
        items:  Array< {
          __typename: "IntegrationList",
          id: string,
          externalId?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          name?: string | null,
          description?: string | null,
          smart?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?:  {
        __typename: "ModelIntegrationFieldConnection",
        items:  Array< {
          __typename: "IntegrationField",
          id?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          fetch?: boolean | null,
          push?: boolean | null,
          name: string,
          displayName?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateActionMutationVariables = {
  input: UpdateActionInput,
  condition?: ModelActionConditionInput | null,
};

export type UpdateActionMutation = {
  updateAction?:  {
    __typename: "Action",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    destinationId: string,
    configuration: ( {
        __typename: "UniversalAnalyticsDestination",
        provider?: IntegrationDestination | null,
        dimensionId?: string | null,
        category?: string | null,
        action?: string | null,
        label?: string | null,
      }
    ) | null,
    isArchived?: boolean | null,
    isActive?: boolean | null,
    firedLast30d?: number | null,
    createdAt: string,
    trigger?: string | null,
    updatedAt: string,
    triggers?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    destination?:  {
      __typename: "Integration",
      id: string,
      name: string,
      group: string,
      organizationId: string,
      credentials: ( {
          __typename: "MarketoCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiHost?: string | null,
          clientId?: string | null,
          clientSecret?: string | null,
        } | {
          __typename: "SalesforceCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          access_token?: string | null,
          refresh_token?: string | null,
          signature?: string | null,
          scope?: string | null,
          id_token?: string | null,
          instance_url?: string | null,
          id?: string | null,
          token_type?: string | null,
          issued_at?: string | null,
        } | {
          __typename: "SixSenseCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiToken?: string | null,
        } | {
          __typename: "HubSpotCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          accessToken?: string | null,
          refreshToken?: string | null,
          tokenType?: string | null,
          expiresIn?: string | null,
        }
      ) | null,
      configuration: ( {
          __typename: "CustomConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "MarketoConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          hiddenField?: string | null,
        } | {
          __typename: "SalesforceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId?: string | null,
        } | {
          __typename: "GoogleTagManagerConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          dataLayerVarName?: string | null,
        } | {
          __typename: "UniversalAnalyticsConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "UniversalAnalyticsSourceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "HubSpotConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "ClearbitConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "DemandbaseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "SixSenseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "AccountMatchConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "GoogleOptimizeConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        }
      ) | null,
      provider: Providers,
      source: boolean,
      createdAt: string,
      updatedAt: string,
      lists?:  {
        __typename: "ModelIntegrationListConnection",
        items:  Array< {
          __typename: "IntegrationList",
          id: string,
          externalId?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          name?: string | null,
          description?: string | null,
          smart?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?:  {
        __typename: "ModelIntegrationFieldConnection",
        items:  Array< {
          __typename: "IntegrationField",
          id?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          fetch?: boolean | null,
          push?: boolean | null,
          name: string,
          displayName?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteActionMutationVariables = {
  input: DeleteActionInput,
  condition?: ModelActionConditionInput | null,
};

export type DeleteActionMutation = {
  deleteAction?:  {
    __typename: "Action",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    destinationId: string,
    configuration: ( {
        __typename: "UniversalAnalyticsDestination",
        provider?: IntegrationDestination | null,
        dimensionId?: string | null,
        category?: string | null,
        action?: string | null,
        label?: string | null,
      }
    ) | null,
    isArchived?: boolean | null,
    isActive?: boolean | null,
    firedLast30d?: number | null,
    createdAt: string,
    trigger?: string | null,
    updatedAt: string,
    triggers?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    destination?:  {
      __typename: "Integration",
      id: string,
      name: string,
      group: string,
      organizationId: string,
      credentials: ( {
          __typename: "MarketoCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiHost?: string | null,
          clientId?: string | null,
          clientSecret?: string | null,
        } | {
          __typename: "SalesforceCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          access_token?: string | null,
          refresh_token?: string | null,
          signature?: string | null,
          scope?: string | null,
          id_token?: string | null,
          instance_url?: string | null,
          id?: string | null,
          token_type?: string | null,
          issued_at?: string | null,
        } | {
          __typename: "SixSenseCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiToken?: string | null,
        } | {
          __typename: "HubSpotCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          accessToken?: string | null,
          refreshToken?: string | null,
          tokenType?: string | null,
          expiresIn?: string | null,
        }
      ) | null,
      configuration: ( {
          __typename: "CustomConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "MarketoConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          hiddenField?: string | null,
        } | {
          __typename: "SalesforceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId?: string | null,
        } | {
          __typename: "GoogleTagManagerConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          dataLayerVarName?: string | null,
        } | {
          __typename: "UniversalAnalyticsConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "UniversalAnalyticsSourceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "HubSpotConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "ClearbitConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "DemandbaseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "SixSenseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "AccountMatchConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "GoogleOptimizeConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        }
      ) | null,
      provider: Providers,
      source: boolean,
      createdAt: string,
      updatedAt: string,
      lists?:  {
        __typename: "ModelIntegrationListConnection",
        items:  Array< {
          __typename: "IntegrationList",
          id: string,
          externalId?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          name?: string | null,
          description?: string | null,
          smart?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?:  {
        __typename: "ModelIntegrationFieldConnection",
        items:  Array< {
          __typename: "IntegrationField",
          id?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          fetch?: boolean | null,
          push?: boolean | null,
          name: string,
          displayName?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateActionTriggerMutationVariables = {
  input: CreateActionTriggerInput,
  condition?: ModelActionTriggerConditionInput | null,
};

export type CreateActionTriggerMutation = {
  createActionTrigger?:  {
    __typename: "ActionTrigger",
    id: string,
    group: string,
    actionId: string,
    triggerId: string,
    createdAt: string,
    updatedAt: string,
    action?:  {
      __typename: "Action",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      destinationId: string,
      configuration: ( {
          __typename: "UniversalAnalyticsDestination",
          provider?: IntegrationDestination | null,
          dimensionId?: string | null,
          category?: string | null,
          action?: string | null,
          label?: string | null,
        }
      ) | null,
      isArchived?: boolean | null,
      isActive?: boolean | null,
      firedLast30d?: number | null,
      createdAt: string,
      trigger?: string | null,
      updatedAt: string,
      triggers?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      destination?:  {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    trigger?:  {
      __typename: "Trigger",
      id: string,
      name?: string | null,
      description?: string | null,
      group: string,
      organizationId: string,
      conditions?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateActionTriggerMutationVariables = {
  input: UpdateActionTriggerInput,
  condition?: ModelActionTriggerConditionInput | null,
};

export type UpdateActionTriggerMutation = {
  updateActionTrigger?:  {
    __typename: "ActionTrigger",
    id: string,
    group: string,
    actionId: string,
    triggerId: string,
    createdAt: string,
    updatedAt: string,
    action?:  {
      __typename: "Action",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      destinationId: string,
      configuration: ( {
          __typename: "UniversalAnalyticsDestination",
          provider?: IntegrationDestination | null,
          dimensionId?: string | null,
          category?: string | null,
          action?: string | null,
          label?: string | null,
        }
      ) | null,
      isArchived?: boolean | null,
      isActive?: boolean | null,
      firedLast30d?: number | null,
      createdAt: string,
      trigger?: string | null,
      updatedAt: string,
      triggers?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      destination?:  {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    trigger?:  {
      __typename: "Trigger",
      id: string,
      name?: string | null,
      description?: string | null,
      group: string,
      organizationId: string,
      conditions?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteActionTriggerMutationVariables = {
  input: DeleteActionTriggerInput,
  condition?: ModelActionTriggerConditionInput | null,
};

export type DeleteActionTriggerMutation = {
  deleteActionTrigger?:  {
    __typename: "ActionTrigger",
    id: string,
    group: string,
    actionId: string,
    triggerId: string,
    createdAt: string,
    updatedAt: string,
    action?:  {
      __typename: "Action",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      destinationId: string,
      configuration: ( {
          __typename: "UniversalAnalyticsDestination",
          provider?: IntegrationDestination | null,
          dimensionId?: string | null,
          category?: string | null,
          action?: string | null,
          label?: string | null,
        }
      ) | null,
      isArchived?: boolean | null,
      isActive?: boolean | null,
      firedLast30d?: number | null,
      createdAt: string,
      trigger?: string | null,
      updatedAt: string,
      triggers?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      destination?:  {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    trigger?:  {
      __typename: "Trigger",
      id: string,
      name?: string | null,
      description?: string | null,
      group: string,
      organizationId: string,
      conditions?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type CreateTriggerMutationVariables = {
  input: CreateTriggerInput,
  condition?: ModelTriggerConditionInput | null,
};

export type CreateTriggerMutation = {
  createTrigger?:  {
    __typename: "Trigger",
    id: string,
    name?: string | null,
    description?: string | null,
    group: string,
    organizationId: string,
    conditions?: string | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateTriggerMutationVariables = {
  input: UpdateTriggerInput,
  condition?: ModelTriggerConditionInput | null,
};

export type UpdateTriggerMutation = {
  updateTrigger?:  {
    __typename: "Trigger",
    id: string,
    name?: string | null,
    description?: string | null,
    group: string,
    organizationId: string,
    conditions?: string | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteTriggerMutationVariables = {
  input: DeleteTriggerInput,
  condition?: ModelTriggerConditionInput | null,
};

export type DeleteTriggerMutation = {
  deleteTrigger?:  {
    __typename: "Trigger",
    id: string,
    name?: string | null,
    description?: string | null,
    group: string,
    organizationId: string,
    conditions?: string | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateAudienceMutationVariables = {
  input: CreateAudienceInput,
  condition?: ModelAudienceConditionInput | null,
};

export type CreateAudienceMutation = {
  createAudience?:  {
    __typename: "Audience",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    audienceType?: AudienceType | null,
    conditions?: string | null,
    visitors?: number | null,
    conversions?: number | null,
    individual?: number | null,
    accounts?: number | null,
    actionsCount?: number | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateAudienceMutationVariables = {
  input: UpdateAudienceInput,
  condition?: ModelAudienceConditionInput | null,
};

export type UpdateAudienceMutation = {
  updateAudience?:  {
    __typename: "Audience",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    audienceType?: AudienceType | null,
    conditions?: string | null,
    visitors?: number | null,
    conversions?: number | null,
    individual?: number | null,
    accounts?: number | null,
    actionsCount?: number | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteAudienceMutationVariables = {
  input: DeleteAudienceInput,
  condition?: ModelAudienceConditionInput | null,
};

export type DeleteAudienceMutation = {
  deleteAudience?:  {
    __typename: "Audience",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    audienceType?: AudienceType | null,
    conditions?: string | null,
    visitors?: number | null,
    conversions?: number | null,
    individual?: number | null,
    accounts?: number | null,
    actionsCount?: number | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateGoalMutationVariables = {
  input: CreateGoalInput,
  condition?: ModelGoalConditionInput | null,
};

export type CreateGoalMutation = {
  createGoal?:  {
    __typename: "Goal",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    eventId?: string | null,
    trackEvent?: string | null,
    goalFrequency?: number | null,
    includeInRevenue?: boolean | null,
    calculateGoalValueAuto?: boolean | null,
    aggregateGoalValue?: boolean | null,
    defaultGoalValue?: number | null,
    triggeringType?: string | null,
    includeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    excludeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    isActive?: boolean | null,
    isArchived?: boolean | null,
    actionsCount?: number | null,
    type: GoalType,
    typeState: string,
    attributes?:  {
      __typename: "GoalAttribute",
      apiname?: string | null,
      createdbyid: string,
      defaultprobability?: string | null,
      description?: string | null,
      forecastcategory?: string | null,
      forecastcategoryname?: string | null,
      isactive?: boolean | null,
      isclosed?: boolean | null,
      iswon?: boolean | null,
      lastmodifiedbyid?: string | null,
      lastmodifieddate?: string | null,
      masterlabel?: string | null,
      sortorder?: string | null,
      systemmodstamp?: string | null,
      createddate?: string | null,
      id?: string | null,
    } | null,
    conversionsLast30d?: number | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      source: EventSourceType,
      group: string,
      organizationId: string,
      attributes?:  Array< {
        __typename: "EventAttribute",
        name?: string | null,
        matchType?: MatchTypes | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateGoalMutationVariables = {
  input: UpdateGoalInput,
  condition?: ModelGoalConditionInput | null,
};

export type UpdateGoalMutation = {
  updateGoal?:  {
    __typename: "Goal",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    eventId?: string | null,
    trackEvent?: string | null,
    goalFrequency?: number | null,
    includeInRevenue?: boolean | null,
    calculateGoalValueAuto?: boolean | null,
    aggregateGoalValue?: boolean | null,
    defaultGoalValue?: number | null,
    triggeringType?: string | null,
    includeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    excludeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    isActive?: boolean | null,
    isArchived?: boolean | null,
    actionsCount?: number | null,
    type: GoalType,
    typeState: string,
    attributes?:  {
      __typename: "GoalAttribute",
      apiname?: string | null,
      createdbyid: string,
      defaultprobability?: string | null,
      description?: string | null,
      forecastcategory?: string | null,
      forecastcategoryname?: string | null,
      isactive?: boolean | null,
      isclosed?: boolean | null,
      iswon?: boolean | null,
      lastmodifiedbyid?: string | null,
      lastmodifieddate?: string | null,
      masterlabel?: string | null,
      sortorder?: string | null,
      systemmodstamp?: string | null,
      createddate?: string | null,
      id?: string | null,
    } | null,
    conversionsLast30d?: number | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      source: EventSourceType,
      group: string,
      organizationId: string,
      attributes?:  Array< {
        __typename: "EventAttribute",
        name?: string | null,
        matchType?: MatchTypes | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteGoalMutationVariables = {
  input: DeleteGoalInput,
  condition?: ModelGoalConditionInput | null,
};

export type DeleteGoalMutation = {
  deleteGoal?:  {
    __typename: "Goal",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    eventId?: string | null,
    trackEvent?: string | null,
    goalFrequency?: number | null,
    includeInRevenue?: boolean | null,
    calculateGoalValueAuto?: boolean | null,
    aggregateGoalValue?: boolean | null,
    defaultGoalValue?: number | null,
    triggeringType?: string | null,
    includeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    excludeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    isActive?: boolean | null,
    isArchived?: boolean | null,
    actionsCount?: number | null,
    type: GoalType,
    typeState: string,
    attributes?:  {
      __typename: "GoalAttribute",
      apiname?: string | null,
      createdbyid: string,
      defaultprobability?: string | null,
      description?: string | null,
      forecastcategory?: string | null,
      forecastcategoryname?: string | null,
      isactive?: boolean | null,
      isclosed?: boolean | null,
      iswon?: boolean | null,
      lastmodifiedbyid?: string | null,
      lastmodifieddate?: string | null,
      masterlabel?: string | null,
      sortorder?: string | null,
      systemmodstamp?: string | null,
      createddate?: string | null,
      id?: string | null,
    } | null,
    conversionsLast30d?: number | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      source: EventSourceType,
      group: string,
      organizationId: string,
      attributes?:  Array< {
        __typename: "EventAttribute",
        name?: string | null,
        matchType?: MatchTypes | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateEventMutationVariables = {
  input: CreateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type CreateEventMutation = {
  createEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    source: EventSourceType,
    group: string,
    organizationId: string,
    attributes?:  Array< {
      __typename: "EventAttribute",
      name?: string | null,
      matchType?: MatchTypes | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateEventMutationVariables = {
  input: UpdateEventInput,
  condition?: ModelEventConditionInput | null,
};

export type UpdateEventMutation = {
  updateEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    source: EventSourceType,
    group: string,
    organizationId: string,
    attributes?:  Array< {
      __typename: "EventAttribute",
      name?: string | null,
      matchType?: MatchTypes | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteEventMutationVariables = {
  input: DeleteEventInput,
  condition?: ModelEventConditionInput | null,
};

export type DeleteEventMutation = {
  deleteEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    source: EventSourceType,
    group: string,
    organizationId: string,
    attributes?:  Array< {
      __typename: "EventAttribute",
      name?: string | null,
      matchType?: MatchTypes | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreatePageMutationVariables = {
  input: CreatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type CreatePageMutation = {
  createPage?:  {
    __typename: "Page",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdatePageMutationVariables = {
  input: UpdatePageInput,
  condition?: ModelPageConditionInput | null,
};

export type UpdatePageMutation = {
  updatePage?:  {
    __typename: "Page",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeletePageMutationVariables = {
  input: DeletePageInput,
  condition?: ModelPageConditionInput | null,
};

export type DeletePageMutation = {
  deletePage?:  {
    __typename: "Page",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateIntegrationListMutationVariables = {
  input: CreateIntegrationListInput,
  condition?: ModelIntegrationListConditionInput | null,
};

export type CreateIntegrationListMutation = {
  createIntegrationList?:  {
    __typename: "IntegrationList",
    id: string,
    externalId?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    name?: string | null,
    description?: string | null,
    smart?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIntegrationListMutationVariables = {
  input: UpdateIntegrationListInput,
  condition?: ModelIntegrationListConditionInput | null,
};

export type UpdateIntegrationListMutation = {
  updateIntegrationList?:  {
    __typename: "IntegrationList",
    id: string,
    externalId?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    name?: string | null,
    description?: string | null,
    smart?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIntegrationListMutationVariables = {
  input: DeleteIntegrationListInput,
  condition?: ModelIntegrationListConditionInput | null,
};

export type DeleteIntegrationListMutation = {
  deleteIntegrationList?:  {
    __typename: "IntegrationList",
    id: string,
    externalId?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    name?: string | null,
    description?: string | null,
    smart?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIntegrationFieldMutationVariables = {
  input: CreateIntegrationFieldInput,
  condition?: ModelIntegrationFieldConditionInput | null,
};

export type CreateIntegrationFieldMutation = {
  createIntegrationField?:  {
    __typename: "IntegrationField",
    id?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    fetch?: boolean | null,
    push?: boolean | null,
    name: string,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateIntegrationFieldMutationVariables = {
  input: UpdateIntegrationFieldInput,
  condition?: ModelIntegrationFieldConditionInput | null,
};

export type UpdateIntegrationFieldMutation = {
  updateIntegrationField?:  {
    __typename: "IntegrationField",
    id?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    fetch?: boolean | null,
    push?: boolean | null,
    name: string,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteIntegrationFieldMutationVariables = {
  input: DeleteIntegrationFieldInput,
  condition?: ModelIntegrationFieldConditionInput | null,
};

export type DeleteIntegrationFieldMutation = {
  deleteIntegrationField?:  {
    __typename: "IntegrationField",
    id?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    fetch?: boolean | null,
    push?: boolean | null,
    name: string,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateIntegrationMutationVariables = {
  input: CreateIntegrationInput,
  condition?: ModelIntegrationConditionInput | null,
};

export type CreateIntegrationMutation = {
  createIntegration?:  {
    __typename: "Integration",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    credentials: ( {
        __typename: "MarketoCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiHost?: string | null,
        clientId?: string | null,
        clientSecret?: string | null,
      } | {
        __typename: "SalesforceCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        access_token?: string | null,
        refresh_token?: string | null,
        signature?: string | null,
        scope?: string | null,
        id_token?: string | null,
        instance_url?: string | null,
        id?: string | null,
        token_type?: string | null,
        issued_at?: string | null,
      } | {
        __typename: "SixSenseCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiToken?: string | null,
      } | {
        __typename: "HubSpotCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        accessToken?: string | null,
        refreshToken?: string | null,
        tokenType?: string | null,
        expiresIn?: string | null,
      }
    ) | null,
    configuration: ( {
        __typename: "CustomConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "MarketoConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        hiddenField?: string | null,
      } | {
        __typename: "SalesforceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId?: string | null,
      } | {
        __typename: "GoogleTagManagerConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        dataLayerVarName?: string | null,
      } | {
        __typename: "UniversalAnalyticsConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "UniversalAnalyticsSourceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "HubSpotConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "ClearbitConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "DemandbaseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "SixSenseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "AccountMatchConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "GoogleOptimizeConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      }
    ) | null,
    provider: Providers,
    source: boolean,
    createdAt: string,
    updatedAt: string,
    lists?:  {
      __typename: "ModelIntegrationListConnection",
      items:  Array< {
        __typename: "IntegrationList",
        id: string,
        externalId?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        name?: string | null,
        description?: string | null,
        smart?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?:  {
      __typename: "ModelIntegrationFieldConnection",
      items:  Array< {
        __typename: "IntegrationField",
        id?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        fetch?: boolean | null,
        push?: boolean | null,
        name: string,
        displayName?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateIntegrationMutationVariables = {
  input: UpdateIntegrationInput,
  condition?: ModelIntegrationConditionInput | null,
};

export type UpdateIntegrationMutation = {
  updateIntegration?:  {
    __typename: "Integration",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    credentials: ( {
        __typename: "MarketoCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiHost?: string | null,
        clientId?: string | null,
        clientSecret?: string | null,
      } | {
        __typename: "SalesforceCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        access_token?: string | null,
        refresh_token?: string | null,
        signature?: string | null,
        scope?: string | null,
        id_token?: string | null,
        instance_url?: string | null,
        id?: string | null,
        token_type?: string | null,
        issued_at?: string | null,
      } | {
        __typename: "SixSenseCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiToken?: string | null,
      } | {
        __typename: "HubSpotCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        accessToken?: string | null,
        refreshToken?: string | null,
        tokenType?: string | null,
        expiresIn?: string | null,
      }
    ) | null,
    configuration: ( {
        __typename: "CustomConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "MarketoConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        hiddenField?: string | null,
      } | {
        __typename: "SalesforceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId?: string | null,
      } | {
        __typename: "GoogleTagManagerConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        dataLayerVarName?: string | null,
      } | {
        __typename: "UniversalAnalyticsConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "UniversalAnalyticsSourceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "HubSpotConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "ClearbitConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "DemandbaseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "SixSenseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "AccountMatchConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "GoogleOptimizeConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      }
    ) | null,
    provider: Providers,
    source: boolean,
    createdAt: string,
    updatedAt: string,
    lists?:  {
      __typename: "ModelIntegrationListConnection",
      items:  Array< {
        __typename: "IntegrationList",
        id: string,
        externalId?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        name?: string | null,
        description?: string | null,
        smart?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?:  {
      __typename: "ModelIntegrationFieldConnection",
      items:  Array< {
        __typename: "IntegrationField",
        id?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        fetch?: boolean | null,
        push?: boolean | null,
        name: string,
        displayName?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteIntegrationMutationVariables = {
  input: DeleteIntegrationInput,
  condition?: ModelIntegrationConditionInput | null,
};

export type DeleteIntegrationMutation = {
  deleteIntegration?:  {
    __typename: "Integration",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    credentials: ( {
        __typename: "MarketoCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiHost?: string | null,
        clientId?: string | null,
        clientSecret?: string | null,
      } | {
        __typename: "SalesforceCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        access_token?: string | null,
        refresh_token?: string | null,
        signature?: string | null,
        scope?: string | null,
        id_token?: string | null,
        instance_url?: string | null,
        id?: string | null,
        token_type?: string | null,
        issued_at?: string | null,
      } | {
        __typename: "SixSenseCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiToken?: string | null,
      } | {
        __typename: "HubSpotCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        accessToken?: string | null,
        refreshToken?: string | null,
        tokenType?: string | null,
        expiresIn?: string | null,
      }
    ) | null,
    configuration: ( {
        __typename: "CustomConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "MarketoConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        hiddenField?: string | null,
      } | {
        __typename: "SalesforceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId?: string | null,
      } | {
        __typename: "GoogleTagManagerConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        dataLayerVarName?: string | null,
      } | {
        __typename: "UniversalAnalyticsConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "UniversalAnalyticsSourceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "HubSpotConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "ClearbitConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "DemandbaseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "SixSenseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "AccountMatchConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "GoogleOptimizeConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      }
    ) | null,
    provider: Providers,
    source: boolean,
    createdAt: string,
    updatedAt: string,
    lists?:  {
      __typename: "ModelIntegrationListConnection",
      items:  Array< {
        __typename: "IntegrationList",
        id: string,
        externalId?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        name?: string | null,
        description?: string | null,
        smart?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?:  {
      __typename: "ModelIntegrationFieldConnection",
      items:  Array< {
        __typename: "IntegrationField",
        id?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        fetch?: boolean | null,
        push?: boolean | null,
        name: string,
        displayName?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    subjectId: string,
    subjectType: Subjects,
    status: Status,
    title: string,
    message: string,
    group: string,
    organizationId: string,
    dismissedAt?: string | null,
    dismissedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    subjectId: string,
    subjectType: Subjects,
    status: Status,
    title: string,
    message: string,
    group: string,
    organizationId: string,
    dismissedAt?: string | null,
    dismissedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    subjectId: string,
    subjectType: Subjects,
    status: Status,
    title: string,
    message: string,
    group: string,
    organizationId: string,
    dismissedAt?: string | null,
    dismissedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    group: string,
    orgOwner: string,
    installationType?: InstallationsType | null,
    integrationCode?: string | null,
    antiFlickerCode?: string | null,
    asynchronousSnippet?: string | null,
    anonymize?: boolean | null,
    enableNonConsent?: boolean | null,
    disabled?: boolean | null,
    signupType?: string | null,
    trialExpiration?: string | null,
    invoiced?: boolean | null,
    flashHide?: boolean | null,
    activateWebsiteAttribution?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionConnection",
      items:  Array< {
        __typename: "Action",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        destinationId: string,
        configuration: ( {
            __typename: "UniversalAnalyticsDestination",
            provider?: IntegrationDestination | null,
            dimensionId?: string | null,
            category?: string | null,
            action?: string | null,
            label?: string | null,
          }
        ) | null,
        isArchived?: boolean | null,
        isActive?: boolean | null,
        firedLast30d?: number | null,
        createdAt: string,
        trigger?: string | null,
        updatedAt: string,
        triggers?:  {
          __typename: "ModelActionTriggerConnection",
          nextToken?: string | null,
        } | null,
        destination?:  {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    audiences?:  {
      __typename: "ModelAudienceConnection",
      items:  Array< {
        __typename: "Audience",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        audienceType?: AudienceType | null,
        conditions?: string | null,
        visitors?: number | null,
        conversions?: number | null,
        individual?: number | null,
        accounts?: number | null,
        actionsCount?: number | null,
        isArchived?: boolean | null,
        createdAt: string,
        updatedAt: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    goals?:  {
      __typename: "ModelGoalConnection",
      items:  Array< {
        __typename: "Goal",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        eventId?: string | null,
        trackEvent?: string | null,
        goalFrequency?: number | null,
        includeInRevenue?: boolean | null,
        calculateGoalValueAuto?: boolean | null,
        aggregateGoalValue?: boolean | null,
        defaultGoalValue?: number | null,
        triggeringType?: string | null,
        includeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        excludeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        isActive?: boolean | null,
        isArchived?: boolean | null,
        actionsCount?: number | null,
        type: GoalType,
        typeState: string,
        attributes?:  {
          __typename: "GoalAttribute",
          apiname?: string | null,
          createdbyid: string,
          defaultprobability?: string | null,
          description?: string | null,
          forecastcategory?: string | null,
          forecastcategoryname?: string | null,
          isactive?: boolean | null,
          isclosed?: boolean | null,
          iswon?: boolean | null,
          lastmodifiedbyid?: string | null,
          lastmodifieddate?: string | null,
          masterlabel?: string | null,
          sortorder?: string | null,
          systemmodstamp?: string | null,
          createddate?: string | null,
          id?: string | null,
        } | null,
        conversionsLast30d?: number | null,
        createdAt: string,
        updatedAt: string,
        event?:  {
          __typename: "Event",
          id: string,
          name: string,
          source: EventSourceType,
          group: string,
          organizationId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    integrations?:  {
      __typename: "ModelIntegrationConnection",
      items:  Array< {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    group: string,
    orgOwner: string,
    installationType?: InstallationsType | null,
    integrationCode?: string | null,
    antiFlickerCode?: string | null,
    asynchronousSnippet?: string | null,
    anonymize?: boolean | null,
    enableNonConsent?: boolean | null,
    disabled?: boolean | null,
    signupType?: string | null,
    trialExpiration?: string | null,
    invoiced?: boolean | null,
    flashHide?: boolean | null,
    activateWebsiteAttribution?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionConnection",
      items:  Array< {
        __typename: "Action",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        destinationId: string,
        configuration: ( {
            __typename: "UniversalAnalyticsDestination",
            provider?: IntegrationDestination | null,
            dimensionId?: string | null,
            category?: string | null,
            action?: string | null,
            label?: string | null,
          }
        ) | null,
        isArchived?: boolean | null,
        isActive?: boolean | null,
        firedLast30d?: number | null,
        createdAt: string,
        trigger?: string | null,
        updatedAt: string,
        triggers?:  {
          __typename: "ModelActionTriggerConnection",
          nextToken?: string | null,
        } | null,
        destination?:  {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    audiences?:  {
      __typename: "ModelAudienceConnection",
      items:  Array< {
        __typename: "Audience",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        audienceType?: AudienceType | null,
        conditions?: string | null,
        visitors?: number | null,
        conversions?: number | null,
        individual?: number | null,
        accounts?: number | null,
        actionsCount?: number | null,
        isArchived?: boolean | null,
        createdAt: string,
        updatedAt: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    goals?:  {
      __typename: "ModelGoalConnection",
      items:  Array< {
        __typename: "Goal",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        eventId?: string | null,
        trackEvent?: string | null,
        goalFrequency?: number | null,
        includeInRevenue?: boolean | null,
        calculateGoalValueAuto?: boolean | null,
        aggregateGoalValue?: boolean | null,
        defaultGoalValue?: number | null,
        triggeringType?: string | null,
        includeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        excludeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        isActive?: boolean | null,
        isArchived?: boolean | null,
        actionsCount?: number | null,
        type: GoalType,
        typeState: string,
        attributes?:  {
          __typename: "GoalAttribute",
          apiname?: string | null,
          createdbyid: string,
          defaultprobability?: string | null,
          description?: string | null,
          forecastcategory?: string | null,
          forecastcategoryname?: string | null,
          isactive?: boolean | null,
          isclosed?: boolean | null,
          iswon?: boolean | null,
          lastmodifiedbyid?: string | null,
          lastmodifieddate?: string | null,
          masterlabel?: string | null,
          sortorder?: string | null,
          systemmodstamp?: string | null,
          createddate?: string | null,
          id?: string | null,
        } | null,
        conversionsLast30d?: number | null,
        createdAt: string,
        updatedAt: string,
        event?:  {
          __typename: "Event",
          id: string,
          name: string,
          source: EventSourceType,
          group: string,
          organizationId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    integrations?:  {
      __typename: "ModelIntegrationConnection",
      items:  Array< {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    group: string,
    orgOwner: string,
    installationType?: InstallationsType | null,
    integrationCode?: string | null,
    antiFlickerCode?: string | null,
    asynchronousSnippet?: string | null,
    anonymize?: boolean | null,
    enableNonConsent?: boolean | null,
    disabled?: boolean | null,
    signupType?: string | null,
    trialExpiration?: string | null,
    invoiced?: boolean | null,
    flashHide?: boolean | null,
    activateWebsiteAttribution?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionConnection",
      items:  Array< {
        __typename: "Action",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        destinationId: string,
        configuration: ( {
            __typename: "UniversalAnalyticsDestination",
            provider?: IntegrationDestination | null,
            dimensionId?: string | null,
            category?: string | null,
            action?: string | null,
            label?: string | null,
          }
        ) | null,
        isArchived?: boolean | null,
        isActive?: boolean | null,
        firedLast30d?: number | null,
        createdAt: string,
        trigger?: string | null,
        updatedAt: string,
        triggers?:  {
          __typename: "ModelActionTriggerConnection",
          nextToken?: string | null,
        } | null,
        destination?:  {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    audiences?:  {
      __typename: "ModelAudienceConnection",
      items:  Array< {
        __typename: "Audience",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        audienceType?: AudienceType | null,
        conditions?: string | null,
        visitors?: number | null,
        conversions?: number | null,
        individual?: number | null,
        accounts?: number | null,
        actionsCount?: number | null,
        isArchived?: boolean | null,
        createdAt: string,
        updatedAt: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    goals?:  {
      __typename: "ModelGoalConnection",
      items:  Array< {
        __typename: "Goal",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        eventId?: string | null,
        trackEvent?: string | null,
        goalFrequency?: number | null,
        includeInRevenue?: boolean | null,
        calculateGoalValueAuto?: boolean | null,
        aggregateGoalValue?: boolean | null,
        defaultGoalValue?: number | null,
        triggeringType?: string | null,
        includeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        excludeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        isActive?: boolean | null,
        isArchived?: boolean | null,
        actionsCount?: number | null,
        type: GoalType,
        typeState: string,
        attributes?:  {
          __typename: "GoalAttribute",
          apiname?: string | null,
          createdbyid: string,
          defaultprobability?: string | null,
          description?: string | null,
          forecastcategory?: string | null,
          forecastcategoryname?: string | null,
          isactive?: boolean | null,
          isclosed?: boolean | null,
          iswon?: boolean | null,
          lastmodifiedbyid?: string | null,
          lastmodifieddate?: string | null,
          masterlabel?: string | null,
          sortorder?: string | null,
          systemmodstamp?: string | null,
          createddate?: string | null,
          id?: string | null,
        } | null,
        conversionsLast30d?: number | null,
        createdAt: string,
        updatedAt: string,
        event?:  {
          __typename: "Event",
          id: string,
          name: string,
          source: EventSourceType,
          group: string,
          organizationId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    integrations?:  {
      __typename: "ModelIntegrationConnection",
      items:  Array< {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    userEmail?: string | null,
    name?: string | null,
    userId?: string | null,
    orgId?: string | null,
    group?: string | null,
    timeStamp?: string | null,
    createdAt: string,
    updatedAt: string,
    stripeCustomerId?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    userEmail?: string | null,
    name?: string | null,
    userId?: string | null,
    orgId?: string | null,
    group?: string | null,
    timeStamp?: string | null,
    createdAt: string,
    updatedAt: string,
    stripeCustomerId?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    userEmail?: string | null,
    name?: string | null,
    userId?: string | null,
    orgId?: string | null,
    group?: string | null,
    timeStamp?: string | null,
    createdAt: string,
    updatedAt: string,
    stripeCustomerId?: string | null,
  } | null,
};

export type CreatePaymentMutationVariables = {
  input: CreatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type CreatePaymentMutation = {
  createPayment?:  {
    __typename: "Payment",
    id: string,
    stripeId?: string | null,
    amount?: number | null,
    receivedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePaymentMutationVariables = {
  input: UpdatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type UpdatePaymentMutation = {
  updatePayment?:  {
    __typename: "Payment",
    id: string,
    stripeId?: string | null,
    amount?: number | null,
    receivedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePaymentMutationVariables = {
  input: DeletePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type DeletePaymentMutation = {
  deletePayment?:  {
    __typename: "Payment",
    id: string,
    stripeId?: string | null,
    amount?: number | null,
    receivedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type FindMembersQueryVariables = {
  member: memberInput,
};

export type FindMembersQuery = {
  findMembers?:  Array< {
    __typename: "cognitoUser",
    Username?: string | null,
    Attributes?:  Array< {
      __typename: "cognitoUserAttribute",
      Name?: string | null,
      Value?: string | null,
    } | null > | null,
    UserCreateDate?: string | null,
    UserLastModifiedDate?: string | null,
    Enabled?: boolean | null,
    UserStatus?: string | null,
  } | null > | null,
};

export type GetActionQueryVariables = {
  id: string,
};

export type GetActionQuery = {
  getAction?:  {
    __typename: "Action",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    destinationId: string,
    configuration: ( {
        __typename: "UniversalAnalyticsDestination",
        provider?: IntegrationDestination | null,
        dimensionId?: string | null,
        category?: string | null,
        action?: string | null,
        label?: string | null,
      }
    ) | null,
    isArchived?: boolean | null,
    isActive?: boolean | null,
    firedLast30d?: number | null,
    createdAt: string,
    trigger?: string | null,
    updatedAt: string,
    triggers?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    destination?:  {
      __typename: "Integration",
      id: string,
      name: string,
      group: string,
      organizationId: string,
      credentials: ( {
          __typename: "MarketoCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiHost?: string | null,
          clientId?: string | null,
          clientSecret?: string | null,
        } | {
          __typename: "SalesforceCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          access_token?: string | null,
          refresh_token?: string | null,
          signature?: string | null,
          scope?: string | null,
          id_token?: string | null,
          instance_url?: string | null,
          id?: string | null,
          token_type?: string | null,
          issued_at?: string | null,
        } | {
          __typename: "SixSenseCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiToken?: string | null,
        } | {
          __typename: "HubSpotCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          accessToken?: string | null,
          refreshToken?: string | null,
          tokenType?: string | null,
          expiresIn?: string | null,
        }
      ) | null,
      configuration: ( {
          __typename: "CustomConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "MarketoConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          hiddenField?: string | null,
        } | {
          __typename: "SalesforceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId?: string | null,
        } | {
          __typename: "GoogleTagManagerConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          dataLayerVarName?: string | null,
        } | {
          __typename: "UniversalAnalyticsConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "UniversalAnalyticsSourceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "HubSpotConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "ClearbitConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "DemandbaseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "SixSenseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "AccountMatchConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "GoogleOptimizeConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        }
      ) | null,
      provider: Providers,
      source: boolean,
      createdAt: string,
      updatedAt: string,
      lists?:  {
        __typename: "ModelIntegrationListConnection",
        items:  Array< {
          __typename: "IntegrationList",
          id: string,
          externalId?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          name?: string | null,
          description?: string | null,
          smart?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?:  {
        __typename: "ModelIntegrationFieldConnection",
        items:  Array< {
          __typename: "IntegrationField",
          id?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          fetch?: boolean | null,
          push?: boolean | null,
          name: string,
          displayName?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListActionsQueryVariables = {
  filter?: ModelActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListActionsQuery = {
  listActions?:  {
    __typename: "ModelActionConnection",
    items:  Array< {
      __typename: "Action",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      destinationId: string,
      configuration: ( {
          __typename: "UniversalAnalyticsDestination",
          provider?: IntegrationDestination | null,
          dimensionId?: string | null,
          category?: string | null,
          action?: string | null,
          label?: string | null,
        }
      ) | null,
      isArchived?: boolean | null,
      isActive?: boolean | null,
      firedLast30d?: number | null,
      createdAt: string,
      trigger?: string | null,
      updatedAt: string,
      triggers?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      destination?:  {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ActionsByOrganizationQueryVariables = {
  organizationId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ActionsByOrganizationQuery = {
  actionsByOrganization?:  {
    __typename: "ModelActionConnection",
    items:  Array< {
      __typename: "Action",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      destinationId: string,
      configuration: ( {
          __typename: "UniversalAnalyticsDestination",
          provider?: IntegrationDestination | null,
          dimensionId?: string | null,
          category?: string | null,
          action?: string | null,
          label?: string | null,
        }
      ) | null,
      isArchived?: boolean | null,
      isActive?: boolean | null,
      firedLast30d?: number | null,
      createdAt: string,
      trigger?: string | null,
      updatedAt: string,
      triggers?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      destination?:  {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetActionTriggerQueryVariables = {
  id: string,
};

export type GetActionTriggerQuery = {
  getActionTrigger?:  {
    __typename: "ActionTrigger",
    id: string,
    group: string,
    actionId: string,
    triggerId: string,
    createdAt: string,
    updatedAt: string,
    action?:  {
      __typename: "Action",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      destinationId: string,
      configuration: ( {
          __typename: "UniversalAnalyticsDestination",
          provider?: IntegrationDestination | null,
          dimensionId?: string | null,
          category?: string | null,
          action?: string | null,
          label?: string | null,
        }
      ) | null,
      isArchived?: boolean | null,
      isActive?: boolean | null,
      firedLast30d?: number | null,
      createdAt: string,
      trigger?: string | null,
      updatedAt: string,
      triggers?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      destination?:  {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    trigger?:  {
      __typename: "Trigger",
      id: string,
      name?: string | null,
      description?: string | null,
      group: string,
      organizationId: string,
      conditions?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type ListActionTriggersQueryVariables = {
  filter?: ModelActionTriggerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListActionTriggersQuery = {
  listActionTriggers?:  {
    __typename: "ModelActionTriggerConnection",
    items:  Array< {
      __typename: "ActionTrigger",
      id: string,
      group: string,
      actionId: string,
      triggerId: string,
      createdAt: string,
      updatedAt: string,
      action?:  {
        __typename: "Action",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        destinationId: string,
        configuration: ( {
            __typename: "UniversalAnalyticsDestination",
            provider?: IntegrationDestination | null,
            dimensionId?: string | null,
            category?: string | null,
            action?: string | null,
            label?: string | null,
          }
        ) | null,
        isArchived?: boolean | null,
        isActive?: boolean | null,
        firedLast30d?: number | null,
        createdAt: string,
        trigger?: string | null,
        updatedAt: string,
        triggers?:  {
          __typename: "ModelActionTriggerConnection",
          nextToken?: string | null,
        } | null,
        destination?:  {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      trigger?:  {
        __typename: "Trigger",
        id: string,
        name?: string | null,
        description?: string | null,
        group: string,
        organizationId: string,
        conditions?: string | null,
        isArchived?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionTriggerConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTriggerQueryVariables = {
  id: string,
};

export type GetTriggerQuery = {
  getTrigger?:  {
    __typename: "Trigger",
    id: string,
    name?: string | null,
    description?: string | null,
    group: string,
    organizationId: string,
    conditions?: string | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListTriggersQueryVariables = {
  filter?: ModelTriggerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTriggersQuery = {
  listTriggers?:  {
    __typename: "ModelTriggerConnection",
    items:  Array< {
      __typename: "Trigger",
      id: string,
      name?: string | null,
      description?: string | null,
      group: string,
      organizationId: string,
      conditions?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TriggersByOrganizationQueryVariables = {
  organizationId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTriggerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TriggersByOrganizationQuery = {
  triggersByOrganization?:  {
    __typename: "ModelTriggerConnection",
    items:  Array< {
      __typename: "Trigger",
      id: string,
      name?: string | null,
      description?: string | null,
      group: string,
      organizationId: string,
      conditions?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAudienceQueryVariables = {
  id: string,
};

export type GetAudienceQuery = {
  getAudience?:  {
    __typename: "Audience",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    audienceType?: AudienceType | null,
    conditions?: string | null,
    visitors?: number | null,
    conversions?: number | null,
    individual?: number | null,
    accounts?: number | null,
    actionsCount?: number | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListAudiencesQueryVariables = {
  filter?: ModelAudienceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAudiencesQuery = {
  listAudiences?:  {
    __typename: "ModelAudienceConnection",
    items:  Array< {
      __typename: "Audience",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      audienceType?: AudienceType | null,
      conditions?: string | null,
      visitors?: number | null,
      conversions?: number | null,
      individual?: number | null,
      accounts?: number | null,
      actionsCount?: number | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AudiencesByOrganizationQueryVariables = {
  organizationId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAudienceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AudiencesByOrganizationQuery = {
  audiencesByOrganization?:  {
    __typename: "ModelAudienceConnection",
    items:  Array< {
      __typename: "Audience",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      audienceType?: AudienceType | null,
      conditions?: string | null,
      visitors?: number | null,
      conversions?: number | null,
      individual?: number | null,
      accounts?: number | null,
      actionsCount?: number | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGoalQueryVariables = {
  id: string,
};

export type GetGoalQuery = {
  getGoal?:  {
    __typename: "Goal",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    eventId?: string | null,
    trackEvent?: string | null,
    goalFrequency?: number | null,
    includeInRevenue?: boolean | null,
    calculateGoalValueAuto?: boolean | null,
    aggregateGoalValue?: boolean | null,
    defaultGoalValue?: number | null,
    triggeringType?: string | null,
    includeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    excludeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    isActive?: boolean | null,
    isArchived?: boolean | null,
    actionsCount?: number | null,
    type: GoalType,
    typeState: string,
    attributes?:  {
      __typename: "GoalAttribute",
      apiname?: string | null,
      createdbyid: string,
      defaultprobability?: string | null,
      description?: string | null,
      forecastcategory?: string | null,
      forecastcategoryname?: string | null,
      isactive?: boolean | null,
      isclosed?: boolean | null,
      iswon?: boolean | null,
      lastmodifiedbyid?: string | null,
      lastmodifieddate?: string | null,
      masterlabel?: string | null,
      sortorder?: string | null,
      systemmodstamp?: string | null,
      createddate?: string | null,
      id?: string | null,
    } | null,
    conversionsLast30d?: number | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      source: EventSourceType,
      group: string,
      organizationId: string,
      attributes?:  Array< {
        __typename: "EventAttribute",
        name?: string | null,
        matchType?: MatchTypes | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListGoalsQueryVariables = {
  filter?: ModelGoalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGoalsQuery = {
  listGoals?:  {
    __typename: "ModelGoalConnection",
    items:  Array< {
      __typename: "Goal",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      eventId?: string | null,
      trackEvent?: string | null,
      goalFrequency?: number | null,
      includeInRevenue?: boolean | null,
      calculateGoalValueAuto?: boolean | null,
      aggregateGoalValue?: boolean | null,
      defaultGoalValue?: number | null,
      triggeringType?: string | null,
      includeUrls?:  Array< {
        __typename: "Url",
        urls?: string | null,
        matchType?: MatchTypes | null,
      } | null > | null,
      excludeUrls?:  Array< {
        __typename: "Url",
        urls?: string | null,
        matchType?: MatchTypes | null,
      } | null > | null,
      isActive?: boolean | null,
      isArchived?: boolean | null,
      actionsCount?: number | null,
      type: GoalType,
      typeState: string,
      attributes?:  {
        __typename: "GoalAttribute",
        apiname?: string | null,
        createdbyid: string,
        defaultprobability?: string | null,
        description?: string | null,
        forecastcategory?: string | null,
        forecastcategoryname?: string | null,
        isactive?: boolean | null,
        isclosed?: boolean | null,
        iswon?: boolean | null,
        lastmodifiedbyid?: string | null,
        lastmodifieddate?: string | null,
        masterlabel?: string | null,
        sortorder?: string | null,
        systemmodstamp?: string | null,
        createddate?: string | null,
        id?: string | null,
      } | null,
      conversionsLast30d?: number | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        source: EventSourceType,
        group: string,
        organizationId: string,
        attributes?:  Array< {
          __typename: "EventAttribute",
          name?: string | null,
          matchType?: MatchTypes | null,
          value?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GoalsByOrganizationQueryVariables = {
  organizationId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGoalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GoalsByOrganizationQuery = {
  goalsByOrganization?:  {
    __typename: "ModelGoalConnection",
    items:  Array< {
      __typename: "Goal",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      eventId?: string | null,
      trackEvent?: string | null,
      goalFrequency?: number | null,
      includeInRevenue?: boolean | null,
      calculateGoalValueAuto?: boolean | null,
      aggregateGoalValue?: boolean | null,
      defaultGoalValue?: number | null,
      triggeringType?: string | null,
      includeUrls?:  Array< {
        __typename: "Url",
        urls?: string | null,
        matchType?: MatchTypes | null,
      } | null > | null,
      excludeUrls?:  Array< {
        __typename: "Url",
        urls?: string | null,
        matchType?: MatchTypes | null,
      } | null > | null,
      isActive?: boolean | null,
      isArchived?: boolean | null,
      actionsCount?: number | null,
      type: GoalType,
      typeState: string,
      attributes?:  {
        __typename: "GoalAttribute",
        apiname?: string | null,
        createdbyid: string,
        defaultprobability?: string | null,
        description?: string | null,
        forecastcategory?: string | null,
        forecastcategoryname?: string | null,
        isactive?: boolean | null,
        isclosed?: boolean | null,
        iswon?: boolean | null,
        lastmodifiedbyid?: string | null,
        lastmodifieddate?: string | null,
        masterlabel?: string | null,
        sortorder?: string | null,
        systemmodstamp?: string | null,
        createddate?: string | null,
        id?: string | null,
      } | null,
      conversionsLast30d?: number | null,
      createdAt: string,
      updatedAt: string,
      event?:  {
        __typename: "Event",
        id: string,
        name: string,
        source: EventSourceType,
        group: string,
        organizationId: string,
        attributes?:  Array< {
          __typename: "EventAttribute",
          name?: string | null,
          matchType?: MatchTypes | null,
          value?: string | null,
        } | null > | null,
        createdAt: string,
        updatedAt: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    source: EventSourceType,
    group: string,
    organizationId: string,
    attributes?:  Array< {
      __typename: "EventAttribute",
      name?: string | null,
      matchType?: MatchTypes | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListEventsQueryVariables = {
  filter?: ModelEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEventsQuery = {
  listEvents?:  {
    __typename: "ModelEventConnection",
    items:  Array< {
      __typename: "Event",
      id: string,
      name: string,
      source: EventSourceType,
      group: string,
      organizationId: string,
      attributes?:  Array< {
        __typename: "EventAttribute",
        name?: string | null,
        matchType?: MatchTypes | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPageQueryVariables = {
  id: string,
};

export type GetPageQuery = {
  getPage?:  {
    __typename: "Page",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListPagesQueryVariables = {
  filter?: ModelPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPagesQuery = {
  listPages?:  {
    __typename: "ModelPageConnection",
    items:  Array< {
      __typename: "Page",
      id: string,
      name: string,
      group: string,
      organizationId: string,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIntegrationListQueryVariables = {
  id: string,
};

export type GetIntegrationListQuery = {
  getIntegrationList?:  {
    __typename: "IntegrationList",
    id: string,
    externalId?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    name?: string | null,
    description?: string | null,
    smart?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListIntegrationListsQueryVariables = {
  filter?: ModelIntegrationListFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntegrationListsQuery = {
  listIntegrationLists?:  {
    __typename: "ModelIntegrationListConnection",
    items:  Array< {
      __typename: "IntegrationList",
      id: string,
      externalId?: string | null,
      group: string,
      provider: Providers,
      integrationId: string,
      name?: string | null,
      description?: string | null,
      smart?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetIntegrationQueryVariables = {
  id: string,
};

export type GetIntegrationQuery = {
  getIntegration?:  {
    __typename: "Integration",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    credentials: ( {
        __typename: "MarketoCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiHost?: string | null,
        clientId?: string | null,
        clientSecret?: string | null,
      } | {
        __typename: "SalesforceCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        access_token?: string | null,
        refresh_token?: string | null,
        signature?: string | null,
        scope?: string | null,
        id_token?: string | null,
        instance_url?: string | null,
        id?: string | null,
        token_type?: string | null,
        issued_at?: string | null,
      } | {
        __typename: "SixSenseCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiToken?: string | null,
      } | {
        __typename: "HubSpotCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        accessToken?: string | null,
        refreshToken?: string | null,
        tokenType?: string | null,
        expiresIn?: string | null,
      }
    ) | null,
    configuration: ( {
        __typename: "CustomConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "MarketoConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        hiddenField?: string | null,
      } | {
        __typename: "SalesforceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId?: string | null,
      } | {
        __typename: "GoogleTagManagerConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        dataLayerVarName?: string | null,
      } | {
        __typename: "UniversalAnalyticsConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "UniversalAnalyticsSourceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "HubSpotConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "ClearbitConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "DemandbaseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "SixSenseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "AccountMatchConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "GoogleOptimizeConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      }
    ) | null,
    provider: Providers,
    source: boolean,
    createdAt: string,
    updatedAt: string,
    lists?:  {
      __typename: "ModelIntegrationListConnection",
      items:  Array< {
        __typename: "IntegrationList",
        id: string,
        externalId?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        name?: string | null,
        description?: string | null,
        smart?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?:  {
      __typename: "ModelIntegrationFieldConnection",
      items:  Array< {
        __typename: "IntegrationField",
        id?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        fetch?: boolean | null,
        push?: boolean | null,
        name: string,
        displayName?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListIntegrationsQueryVariables = {
  filter?: ModelIntegrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListIntegrationsQuery = {
  listIntegrations?:  {
    __typename: "ModelIntegrationConnection",
    items:  Array< {
      __typename: "Integration",
      id: string,
      name: string,
      group: string,
      organizationId: string,
      credentials: ( {
          __typename: "MarketoCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiHost?: string | null,
          clientId?: string | null,
          clientSecret?: string | null,
        } | {
          __typename: "SalesforceCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          access_token?: string | null,
          refresh_token?: string | null,
          signature?: string | null,
          scope?: string | null,
          id_token?: string | null,
          instance_url?: string | null,
          id?: string | null,
          token_type?: string | null,
          issued_at?: string | null,
        } | {
          __typename: "SixSenseCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiToken?: string | null,
        } | {
          __typename: "HubSpotCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          accessToken?: string | null,
          refreshToken?: string | null,
          tokenType?: string | null,
          expiresIn?: string | null,
        }
      ) | null,
      configuration: ( {
          __typename: "CustomConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "MarketoConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          hiddenField?: string | null,
        } | {
          __typename: "SalesforceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId?: string | null,
        } | {
          __typename: "GoogleTagManagerConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          dataLayerVarName?: string | null,
        } | {
          __typename: "UniversalAnalyticsConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "UniversalAnalyticsSourceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "HubSpotConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "ClearbitConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "DemandbaseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "SixSenseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "AccountMatchConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "GoogleOptimizeConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        }
      ) | null,
      provider: Providers,
      source: boolean,
      createdAt: string,
      updatedAt: string,
      lists?:  {
        __typename: "ModelIntegrationListConnection",
        items:  Array< {
          __typename: "IntegrationList",
          id: string,
          externalId?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          name?: string | null,
          description?: string | null,
          smart?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?:  {
        __typename: "ModelIntegrationFieldConnection",
        items:  Array< {
          __typename: "IntegrationField",
          id?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          fetch?: boolean | null,
          push?: boolean | null,
          name: string,
          displayName?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type IntegrationsByOrganizationQueryVariables = {
  organizationId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelIntegrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type IntegrationsByOrganizationQuery = {
  integrationsByOrganization?:  {
    __typename: "ModelIntegrationConnection",
    items:  Array< {
      __typename: "Integration",
      id: string,
      name: string,
      group: string,
      organizationId: string,
      credentials: ( {
          __typename: "MarketoCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiHost?: string | null,
          clientId?: string | null,
          clientSecret?: string | null,
        } | {
          __typename: "SalesforceCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          access_token?: string | null,
          refresh_token?: string | null,
          signature?: string | null,
          scope?: string | null,
          id_token?: string | null,
          instance_url?: string | null,
          id?: string | null,
          token_type?: string | null,
          issued_at?: string | null,
        } | {
          __typename: "SixSenseCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiToken?: string | null,
        } | {
          __typename: "HubSpotCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          accessToken?: string | null,
          refreshToken?: string | null,
          tokenType?: string | null,
          expiresIn?: string | null,
        }
      ) | null,
      configuration: ( {
          __typename: "CustomConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "MarketoConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          hiddenField?: string | null,
        } | {
          __typename: "SalesforceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId?: string | null,
        } | {
          __typename: "GoogleTagManagerConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          dataLayerVarName?: string | null,
        } | {
          __typename: "UniversalAnalyticsConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "UniversalAnalyticsSourceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "HubSpotConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "ClearbitConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "DemandbaseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "SixSenseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "AccountMatchConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "GoogleOptimizeConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        }
      ) | null,
      provider: Providers,
      source: boolean,
      createdAt: string,
      updatedAt: string,
      lists?:  {
        __typename: "ModelIntegrationListConnection",
        items:  Array< {
          __typename: "IntegrationList",
          id: string,
          externalId?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          name?: string | null,
          description?: string | null,
          smart?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?:  {
        __typename: "ModelIntegrationFieldConnection",
        items:  Array< {
          __typename: "IntegrationField",
          id?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          fetch?: boolean | null,
          push?: boolean | null,
          name: string,
          displayName?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    subjectId: string,
    subjectType: Subjects,
    status: Status,
    title: string,
    message: string,
    group: string,
    organizationId: string,
    dismissedAt?: string | null,
    dismissedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      subjectId: string,
      subjectType: Subjects,
      status: Status,
      title: string,
      message: string,
      group: string,
      organizationId: string,
      dismissedAt?: string | null,
      dismissedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByOrganizationQueryVariables = {
  organizationId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByOrganizationQuery = {
  notificationsByOrganization?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      subjectId: string,
      subjectType: Subjects,
      status: Status,
      title: string,
      message: string,
      group: string,
      organizationId: string,
      dismissedAt?: string | null,
      dismissedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    group: string,
    orgOwner: string,
    installationType?: InstallationsType | null,
    integrationCode?: string | null,
    antiFlickerCode?: string | null,
    asynchronousSnippet?: string | null,
    anonymize?: boolean | null,
    enableNonConsent?: boolean | null,
    disabled?: boolean | null,
    signupType?: string | null,
    trialExpiration?: string | null,
    invoiced?: boolean | null,
    flashHide?: boolean | null,
    activateWebsiteAttribution?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionConnection",
      items:  Array< {
        __typename: "Action",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        destinationId: string,
        configuration: ( {
            __typename: "UniversalAnalyticsDestination",
            provider?: IntegrationDestination | null,
            dimensionId?: string | null,
            category?: string | null,
            action?: string | null,
            label?: string | null,
          }
        ) | null,
        isArchived?: boolean | null,
        isActive?: boolean | null,
        firedLast30d?: number | null,
        createdAt: string,
        trigger?: string | null,
        updatedAt: string,
        triggers?:  {
          __typename: "ModelActionTriggerConnection",
          nextToken?: string | null,
        } | null,
        destination?:  {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    audiences?:  {
      __typename: "ModelAudienceConnection",
      items:  Array< {
        __typename: "Audience",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        audienceType?: AudienceType | null,
        conditions?: string | null,
        visitors?: number | null,
        conversions?: number | null,
        individual?: number | null,
        accounts?: number | null,
        actionsCount?: number | null,
        isArchived?: boolean | null,
        createdAt: string,
        updatedAt: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    goals?:  {
      __typename: "ModelGoalConnection",
      items:  Array< {
        __typename: "Goal",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        eventId?: string | null,
        trackEvent?: string | null,
        goalFrequency?: number | null,
        includeInRevenue?: boolean | null,
        calculateGoalValueAuto?: boolean | null,
        aggregateGoalValue?: boolean | null,
        defaultGoalValue?: number | null,
        triggeringType?: string | null,
        includeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        excludeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        isActive?: boolean | null,
        isArchived?: boolean | null,
        actionsCount?: number | null,
        type: GoalType,
        typeState: string,
        attributes?:  {
          __typename: "GoalAttribute",
          apiname?: string | null,
          createdbyid: string,
          defaultprobability?: string | null,
          description?: string | null,
          forecastcategory?: string | null,
          forecastcategoryname?: string | null,
          isactive?: boolean | null,
          isclosed?: boolean | null,
          iswon?: boolean | null,
          lastmodifiedbyid?: string | null,
          lastmodifieddate?: string | null,
          masterlabel?: string | null,
          sortorder?: string | null,
          systemmodstamp?: string | null,
          createddate?: string | null,
          id?: string | null,
        } | null,
        conversionsLast30d?: number | null,
        createdAt: string,
        updatedAt: string,
        event?:  {
          __typename: "Event",
          id: string,
          name: string,
          source: EventSourceType,
          group: string,
          organizationId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    integrations?:  {
      __typename: "ModelIntegrationConnection",
      items:  Array< {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByEmailQueryVariables = {
  userEmail?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByEmailQuery = {
  usersByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userEmail?: string | null,
      name?: string | null,
      userId?: string | null,
      orgId?: string | null,
      group?: string | null,
      timeStamp?: string | null,
      createdAt: string,
      updatedAt: string,
      stripeCustomerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByStripeIdQueryVariables = {
  stripeCustomerId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByStripeIdQuery = {
  usersByStripeId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userEmail?: string | null,
      name?: string | null,
      userId?: string | null,
      orgId?: string | null,
      group?: string | null,
      timeStamp?: string | null,
      createdAt: string,
      updatedAt: string,
      stripeCustomerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    userEmail?: string | null,
    name?: string | null,
    userId?: string | null,
    orgId?: string | null,
    group?: string | null,
    timeStamp?: string | null,
    createdAt: string,
    updatedAt: string,
    stripeCustomerId?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      userEmail?: string | null,
      name?: string | null,
      userId?: string | null,
      orgId?: string | null,
      group?: string | null,
      timeStamp?: string | null,
      createdAt: string,
      updatedAt: string,
      stripeCustomerId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentQueryVariables = {
  id: string,
};

export type GetPaymentQuery = {
  getPayment?:  {
    __typename: "Payment",
    id: string,
    stripeId?: string | null,
    amount?: number | null,
    receivedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPaymentsQueryVariables = {
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentsQuery = {
  listPayments?:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      id: string,
      stripeId?: string | null,
      amount?: number | null,
      receivedAt?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateActionSubscription = {
  onCreateAction?:  {
    __typename: "Action",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    destinationId: string,
    configuration: ( {
        __typename: "UniversalAnalyticsDestination",
        provider?: IntegrationDestination | null,
        dimensionId?: string | null,
        category?: string | null,
        action?: string | null,
        label?: string | null,
      }
    ) | null,
    isArchived?: boolean | null,
    isActive?: boolean | null,
    firedLast30d?: number | null,
    createdAt: string,
    trigger?: string | null,
    updatedAt: string,
    triggers?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    destination?:  {
      __typename: "Integration",
      id: string,
      name: string,
      group: string,
      organizationId: string,
      credentials: ( {
          __typename: "MarketoCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiHost?: string | null,
          clientId?: string | null,
          clientSecret?: string | null,
        } | {
          __typename: "SalesforceCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          access_token?: string | null,
          refresh_token?: string | null,
          signature?: string | null,
          scope?: string | null,
          id_token?: string | null,
          instance_url?: string | null,
          id?: string | null,
          token_type?: string | null,
          issued_at?: string | null,
        } | {
          __typename: "SixSenseCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiToken?: string | null,
        } | {
          __typename: "HubSpotCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          accessToken?: string | null,
          refreshToken?: string | null,
          tokenType?: string | null,
          expiresIn?: string | null,
        }
      ) | null,
      configuration: ( {
          __typename: "CustomConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "MarketoConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          hiddenField?: string | null,
        } | {
          __typename: "SalesforceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId?: string | null,
        } | {
          __typename: "GoogleTagManagerConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          dataLayerVarName?: string | null,
        } | {
          __typename: "UniversalAnalyticsConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "UniversalAnalyticsSourceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "HubSpotConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "ClearbitConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "DemandbaseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "SixSenseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "AccountMatchConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "GoogleOptimizeConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        }
      ) | null,
      provider: Providers,
      source: boolean,
      createdAt: string,
      updatedAt: string,
      lists?:  {
        __typename: "ModelIntegrationListConnection",
        items:  Array< {
          __typename: "IntegrationList",
          id: string,
          externalId?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          name?: string | null,
          description?: string | null,
          smart?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?:  {
        __typename: "ModelIntegrationFieldConnection",
        items:  Array< {
          __typename: "IntegrationField",
          id?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          fetch?: boolean | null,
          push?: boolean | null,
          name: string,
          displayName?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateActionSubscription = {
  onUpdateAction?:  {
    __typename: "Action",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    destinationId: string,
    configuration: ( {
        __typename: "UniversalAnalyticsDestination",
        provider?: IntegrationDestination | null,
        dimensionId?: string | null,
        category?: string | null,
        action?: string | null,
        label?: string | null,
      }
    ) | null,
    isArchived?: boolean | null,
    isActive?: boolean | null,
    firedLast30d?: number | null,
    createdAt: string,
    trigger?: string | null,
    updatedAt: string,
    triggers?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    destination?:  {
      __typename: "Integration",
      id: string,
      name: string,
      group: string,
      organizationId: string,
      credentials: ( {
          __typename: "MarketoCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiHost?: string | null,
          clientId?: string | null,
          clientSecret?: string | null,
        } | {
          __typename: "SalesforceCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          access_token?: string | null,
          refresh_token?: string | null,
          signature?: string | null,
          scope?: string | null,
          id_token?: string | null,
          instance_url?: string | null,
          id?: string | null,
          token_type?: string | null,
          issued_at?: string | null,
        } | {
          __typename: "SixSenseCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiToken?: string | null,
        } | {
          __typename: "HubSpotCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          accessToken?: string | null,
          refreshToken?: string | null,
          tokenType?: string | null,
          expiresIn?: string | null,
        }
      ) | null,
      configuration: ( {
          __typename: "CustomConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "MarketoConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          hiddenField?: string | null,
        } | {
          __typename: "SalesforceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId?: string | null,
        } | {
          __typename: "GoogleTagManagerConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          dataLayerVarName?: string | null,
        } | {
          __typename: "UniversalAnalyticsConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "UniversalAnalyticsSourceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "HubSpotConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "ClearbitConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "DemandbaseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "SixSenseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "AccountMatchConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "GoogleOptimizeConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        }
      ) | null,
      provider: Providers,
      source: boolean,
      createdAt: string,
      updatedAt: string,
      lists?:  {
        __typename: "ModelIntegrationListConnection",
        items:  Array< {
          __typename: "IntegrationList",
          id: string,
          externalId?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          name?: string | null,
          description?: string | null,
          smart?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?:  {
        __typename: "ModelIntegrationFieldConnection",
        items:  Array< {
          __typename: "IntegrationField",
          id?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          fetch?: boolean | null,
          push?: boolean | null,
          name: string,
          displayName?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteActionSubscription = {
  onDeleteAction?:  {
    __typename: "Action",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    destinationId: string,
    configuration: ( {
        __typename: "UniversalAnalyticsDestination",
        provider?: IntegrationDestination | null,
        dimensionId?: string | null,
        category?: string | null,
        action?: string | null,
        label?: string | null,
      }
    ) | null,
    isArchived?: boolean | null,
    isActive?: boolean | null,
    firedLast30d?: number | null,
    createdAt: string,
    trigger?: string | null,
    updatedAt: string,
    triggers?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    destination?:  {
      __typename: "Integration",
      id: string,
      name: string,
      group: string,
      organizationId: string,
      credentials: ( {
          __typename: "MarketoCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiHost?: string | null,
          clientId?: string | null,
          clientSecret?: string | null,
        } | {
          __typename: "SalesforceCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          access_token?: string | null,
          refresh_token?: string | null,
          signature?: string | null,
          scope?: string | null,
          id_token?: string | null,
          instance_url?: string | null,
          id?: string | null,
          token_type?: string | null,
          issued_at?: string | null,
        } | {
          __typename: "SixSenseCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          apiToken?: string | null,
        } | {
          __typename: "HubSpotCreds",
          createdAt?: string | null,
          updatedAt?: string | null,
          accessToken?: string | null,
          refreshToken?: string | null,
          tokenType?: string | null,
          expiresIn?: string | null,
        }
      ) | null,
      configuration: ( {
          __typename: "CustomConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "MarketoConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          hiddenField?: string | null,
        } | {
          __typename: "SalesforceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          userId?: string | null,
        } | {
          __typename: "GoogleTagManagerConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          dataLayerVarName?: string | null,
        } | {
          __typename: "UniversalAnalyticsConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "UniversalAnalyticsSourceConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
          uaTrackingId?: string | null,
        } | {
          __typename: "HubSpotConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "ClearbitConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "DemandbaseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "SixSenseConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "AccountMatchConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        } | {
          __typename: "GoogleOptimizeConfig",
          active?: boolean | null,
          createdAt?: string | null,
          updatedAt?: string | null,
        }
      ) | null,
      provider: Providers,
      source: boolean,
      createdAt: string,
      updatedAt: string,
      lists?:  {
        __typename: "ModelIntegrationListConnection",
        items:  Array< {
          __typename: "IntegrationList",
          id: string,
          externalId?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          name?: string | null,
          description?: string | null,
          smart?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      attributes?:  {
        __typename: "ModelIntegrationFieldConnection",
        items:  Array< {
          __typename: "IntegrationField",
          id?: string | null,
          group: string,
          provider: Providers,
          integrationId: string,
          fetch?: boolean | null,
          push?: boolean | null,
          name: string,
          displayName?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateActionTriggerSubscription = {
  onCreateActionTrigger?:  {
    __typename: "ActionTrigger",
    id: string,
    group: string,
    actionId: string,
    triggerId: string,
    createdAt: string,
    updatedAt: string,
    action?:  {
      __typename: "Action",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      destinationId: string,
      configuration: ( {
          __typename: "UniversalAnalyticsDestination",
          provider?: IntegrationDestination | null,
          dimensionId?: string | null,
          category?: string | null,
          action?: string | null,
          label?: string | null,
        }
      ) | null,
      isArchived?: boolean | null,
      isActive?: boolean | null,
      firedLast30d?: number | null,
      createdAt: string,
      trigger?: string | null,
      updatedAt: string,
      triggers?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      destination?:  {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    trigger?:  {
      __typename: "Trigger",
      id: string,
      name?: string | null,
      description?: string | null,
      group: string,
      organizationId: string,
      conditions?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateActionTriggerSubscription = {
  onUpdateActionTrigger?:  {
    __typename: "ActionTrigger",
    id: string,
    group: string,
    actionId: string,
    triggerId: string,
    createdAt: string,
    updatedAt: string,
    action?:  {
      __typename: "Action",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      destinationId: string,
      configuration: ( {
          __typename: "UniversalAnalyticsDestination",
          provider?: IntegrationDestination | null,
          dimensionId?: string | null,
          category?: string | null,
          action?: string | null,
          label?: string | null,
        }
      ) | null,
      isArchived?: boolean | null,
      isActive?: boolean | null,
      firedLast30d?: number | null,
      createdAt: string,
      trigger?: string | null,
      updatedAt: string,
      triggers?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      destination?:  {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    trigger?:  {
      __typename: "Trigger",
      id: string,
      name?: string | null,
      description?: string | null,
      group: string,
      organizationId: string,
      conditions?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteActionTriggerSubscription = {
  onDeleteActionTrigger?:  {
    __typename: "ActionTrigger",
    id: string,
    group: string,
    actionId: string,
    triggerId: string,
    createdAt: string,
    updatedAt: string,
    action?:  {
      __typename: "Action",
      id: string,
      name: string,
      description?: string | null,
      group: string,
      organizationId: string,
      destinationId: string,
      configuration: ( {
          __typename: "UniversalAnalyticsDestination",
          provider?: IntegrationDestination | null,
          dimensionId?: string | null,
          category?: string | null,
          action?: string | null,
          label?: string | null,
        }
      ) | null,
      isArchived?: boolean | null,
      isActive?: boolean | null,
      firedLast30d?: number | null,
      createdAt: string,
      trigger?: string | null,
      updatedAt: string,
      triggers?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      destination?:  {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    trigger?:  {
      __typename: "Trigger",
      id: string,
      name?: string | null,
      description?: string | null,
      group: string,
      organizationId: string,
      conditions?: string | null,
      isArchived?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionTriggerConnection",
        items:  Array< {
          __typename: "ActionTrigger",
          id: string,
          group: string,
          actionId: string,
          triggerId: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateTriggerSubscription = {
  onCreateTrigger?:  {
    __typename: "Trigger",
    id: string,
    name?: string | null,
    description?: string | null,
    group: string,
    organizationId: string,
    conditions?: string | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateTriggerSubscription = {
  onUpdateTrigger?:  {
    __typename: "Trigger",
    id: string,
    name?: string | null,
    description?: string | null,
    group: string,
    organizationId: string,
    conditions?: string | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteTriggerSubscription = {
  onDeleteTrigger?:  {
    __typename: "Trigger",
    id: string,
    name?: string | null,
    description?: string | null,
    group: string,
    organizationId: string,
    conditions?: string | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionTriggerConnection",
      items:  Array< {
        __typename: "ActionTrigger",
        id: string,
        group: string,
        actionId: string,
        triggerId: string,
        createdAt: string,
        updatedAt: string,
        action?:  {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null,
        trigger?:  {
          __typename: "Trigger",
          id: string,
          name?: string | null,
          description?: string | null,
          group: string,
          organizationId: string,
          conditions?: string | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateAudienceSubscription = {
  onCreateAudience?:  {
    __typename: "Audience",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    audienceType?: AudienceType | null,
    conditions?: string | null,
    visitors?: number | null,
    conversions?: number | null,
    individual?: number | null,
    accounts?: number | null,
    actionsCount?: number | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateAudienceSubscription = {
  onUpdateAudience?:  {
    __typename: "Audience",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    audienceType?: AudienceType | null,
    conditions?: string | null,
    visitors?: number | null,
    conversions?: number | null,
    individual?: number | null,
    accounts?: number | null,
    actionsCount?: number | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteAudienceSubscription = {
  onDeleteAudience?:  {
    __typename: "Audience",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    audienceType?: AudienceType | null,
    conditions?: string | null,
    visitors?: number | null,
    conversions?: number | null,
    individual?: number | null,
    accounts?: number | null,
    actionsCount?: number | null,
    isArchived?: boolean | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateGoalSubscription = {
  onCreateGoal?:  {
    __typename: "Goal",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    eventId?: string | null,
    trackEvent?: string | null,
    goalFrequency?: number | null,
    includeInRevenue?: boolean | null,
    calculateGoalValueAuto?: boolean | null,
    aggregateGoalValue?: boolean | null,
    defaultGoalValue?: number | null,
    triggeringType?: string | null,
    includeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    excludeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    isActive?: boolean | null,
    isArchived?: boolean | null,
    actionsCount?: number | null,
    type: GoalType,
    typeState: string,
    attributes?:  {
      __typename: "GoalAttribute",
      apiname?: string | null,
      createdbyid: string,
      defaultprobability?: string | null,
      description?: string | null,
      forecastcategory?: string | null,
      forecastcategoryname?: string | null,
      isactive?: boolean | null,
      isclosed?: boolean | null,
      iswon?: boolean | null,
      lastmodifiedbyid?: string | null,
      lastmodifieddate?: string | null,
      masterlabel?: string | null,
      sortorder?: string | null,
      systemmodstamp?: string | null,
      createddate?: string | null,
      id?: string | null,
    } | null,
    conversionsLast30d?: number | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      source: EventSourceType,
      group: string,
      organizationId: string,
      attributes?:  Array< {
        __typename: "EventAttribute",
        name?: string | null,
        matchType?: MatchTypes | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateGoalSubscription = {
  onUpdateGoal?:  {
    __typename: "Goal",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    eventId?: string | null,
    trackEvent?: string | null,
    goalFrequency?: number | null,
    includeInRevenue?: boolean | null,
    calculateGoalValueAuto?: boolean | null,
    aggregateGoalValue?: boolean | null,
    defaultGoalValue?: number | null,
    triggeringType?: string | null,
    includeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    excludeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    isActive?: boolean | null,
    isArchived?: boolean | null,
    actionsCount?: number | null,
    type: GoalType,
    typeState: string,
    attributes?:  {
      __typename: "GoalAttribute",
      apiname?: string | null,
      createdbyid: string,
      defaultprobability?: string | null,
      description?: string | null,
      forecastcategory?: string | null,
      forecastcategoryname?: string | null,
      isactive?: boolean | null,
      isclosed?: boolean | null,
      iswon?: boolean | null,
      lastmodifiedbyid?: string | null,
      lastmodifieddate?: string | null,
      masterlabel?: string | null,
      sortorder?: string | null,
      systemmodstamp?: string | null,
      createddate?: string | null,
      id?: string | null,
    } | null,
    conversionsLast30d?: number | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      source: EventSourceType,
      group: string,
      organizationId: string,
      attributes?:  Array< {
        __typename: "EventAttribute",
        name?: string | null,
        matchType?: MatchTypes | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteGoalSubscription = {
  onDeleteGoal?:  {
    __typename: "Goal",
    id: string,
    name: string,
    description?: string | null,
    group: string,
    organizationId: string,
    eventId?: string | null,
    trackEvent?: string | null,
    goalFrequency?: number | null,
    includeInRevenue?: boolean | null,
    calculateGoalValueAuto?: boolean | null,
    aggregateGoalValue?: boolean | null,
    defaultGoalValue?: number | null,
    triggeringType?: string | null,
    includeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    excludeUrls?:  Array< {
      __typename: "Url",
      urls?: string | null,
      matchType?: MatchTypes | null,
    } | null > | null,
    isActive?: boolean | null,
    isArchived?: boolean | null,
    actionsCount?: number | null,
    type: GoalType,
    typeState: string,
    attributes?:  {
      __typename: "GoalAttribute",
      apiname?: string | null,
      createdbyid: string,
      defaultprobability?: string | null,
      description?: string | null,
      forecastcategory?: string | null,
      forecastcategoryname?: string | null,
      isactive?: boolean | null,
      isclosed?: boolean | null,
      iswon?: boolean | null,
      lastmodifiedbyid?: string | null,
      lastmodifieddate?: string | null,
      masterlabel?: string | null,
      sortorder?: string | null,
      systemmodstamp?: string | null,
      createddate?: string | null,
      id?: string | null,
    } | null,
    conversionsLast30d?: number | null,
    createdAt: string,
    updatedAt: string,
    event?:  {
      __typename: "Event",
      id: string,
      name: string,
      source: EventSourceType,
      group: string,
      organizationId: string,
      attributes?:  Array< {
        __typename: "EventAttribute",
        name?: string | null,
        matchType?: MatchTypes | null,
        value?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        group: string,
        orgOwner: string,
        installationType?: InstallationsType | null,
        integrationCode?: string | null,
        antiFlickerCode?: string | null,
        asynchronousSnippet?: string | null,
        anonymize?: boolean | null,
        enableNonConsent?: boolean | null,
        disabled?: boolean | null,
        signupType?: string | null,
        trialExpiration?: string | null,
        invoiced?: boolean | null,
        flashHide?: boolean | null,
        activateWebsiteAttribution?: boolean | null,
        createdAt: string,
        updatedAt: string,
        actions?:  {
          __typename: "ModelActionConnection",
          nextToken?: string | null,
        } | null,
        audiences?:  {
          __typename: "ModelAudienceConnection",
          nextToken?: string | null,
        } | null,
        goals?:  {
          __typename: "ModelGoalConnection",
          nextToken?: string | null,
        } | null,
        integrations?:  {
          __typename: "ModelIntegrationConnection",
          nextToken?: string | null,
        } | null,
      } | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateEventSubscription = {
  onCreateEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    source: EventSourceType,
    group: string,
    organizationId: string,
    attributes?:  Array< {
      __typename: "EventAttribute",
      name?: string | null,
      matchType?: MatchTypes | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateEventSubscription = {
  onUpdateEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    source: EventSourceType,
    group: string,
    organizationId: string,
    attributes?:  Array< {
      __typename: "EventAttribute",
      name?: string | null,
      matchType?: MatchTypes | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteEventSubscription = {
  onDeleteEvent?:  {
    __typename: "Event",
    id: string,
    name: string,
    source: EventSourceType,
    group: string,
    organizationId: string,
    attributes?:  Array< {
      __typename: "EventAttribute",
      name?: string | null,
      matchType?: MatchTypes | null,
      value?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreatePageSubscription = {
  onCreatePage?:  {
    __typename: "Page",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdatePageSubscription = {
  onUpdatePage?:  {
    __typename: "Page",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeletePageSubscription = {
  onDeletePage?:  {
    __typename: "Page",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateIntegrationListSubscription = {
  onCreateIntegrationList?:  {
    __typename: "IntegrationList",
    id: string,
    externalId?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    name?: string | null,
    description?: string | null,
    smart?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIntegrationListSubscription = {
  onUpdateIntegrationList?:  {
    __typename: "IntegrationList",
    id: string,
    externalId?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    name?: string | null,
    description?: string | null,
    smart?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIntegrationListSubscription = {
  onDeleteIntegrationList?:  {
    __typename: "IntegrationList",
    id: string,
    externalId?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    name?: string | null,
    description?: string | null,
    smart?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIntegrationFieldSubscription = {
  onCreateIntegrationField?:  {
    __typename: "IntegrationField",
    id?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    fetch?: boolean | null,
    push?: boolean | null,
    name: string,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateIntegrationFieldSubscription = {
  onUpdateIntegrationField?:  {
    __typename: "IntegrationField",
    id?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    fetch?: boolean | null,
    push?: boolean | null,
    name: string,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteIntegrationFieldSubscription = {
  onDeleteIntegrationField?:  {
    __typename: "IntegrationField",
    id?: string | null,
    group: string,
    provider: Providers,
    integrationId: string,
    fetch?: boolean | null,
    push?: boolean | null,
    name: string,
    displayName?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateIntegrationSubscription = {
  onCreateIntegration?:  {
    __typename: "Integration",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    credentials: ( {
        __typename: "MarketoCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiHost?: string | null,
        clientId?: string | null,
        clientSecret?: string | null,
      } | {
        __typename: "SalesforceCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        access_token?: string | null,
        refresh_token?: string | null,
        signature?: string | null,
        scope?: string | null,
        id_token?: string | null,
        instance_url?: string | null,
        id?: string | null,
        token_type?: string | null,
        issued_at?: string | null,
      } | {
        __typename: "SixSenseCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiToken?: string | null,
      } | {
        __typename: "HubSpotCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        accessToken?: string | null,
        refreshToken?: string | null,
        tokenType?: string | null,
        expiresIn?: string | null,
      }
    ) | null,
    configuration: ( {
        __typename: "CustomConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "MarketoConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        hiddenField?: string | null,
      } | {
        __typename: "SalesforceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId?: string | null,
      } | {
        __typename: "GoogleTagManagerConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        dataLayerVarName?: string | null,
      } | {
        __typename: "UniversalAnalyticsConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "UniversalAnalyticsSourceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "HubSpotConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "ClearbitConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "DemandbaseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "SixSenseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "AccountMatchConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "GoogleOptimizeConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      }
    ) | null,
    provider: Providers,
    source: boolean,
    createdAt: string,
    updatedAt: string,
    lists?:  {
      __typename: "ModelIntegrationListConnection",
      items:  Array< {
        __typename: "IntegrationList",
        id: string,
        externalId?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        name?: string | null,
        description?: string | null,
        smart?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?:  {
      __typename: "ModelIntegrationFieldConnection",
      items:  Array< {
        __typename: "IntegrationField",
        id?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        fetch?: boolean | null,
        push?: boolean | null,
        name: string,
        displayName?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateIntegrationSubscription = {
  onUpdateIntegration?:  {
    __typename: "Integration",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    credentials: ( {
        __typename: "MarketoCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiHost?: string | null,
        clientId?: string | null,
        clientSecret?: string | null,
      } | {
        __typename: "SalesforceCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        access_token?: string | null,
        refresh_token?: string | null,
        signature?: string | null,
        scope?: string | null,
        id_token?: string | null,
        instance_url?: string | null,
        id?: string | null,
        token_type?: string | null,
        issued_at?: string | null,
      } | {
        __typename: "SixSenseCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiToken?: string | null,
      } | {
        __typename: "HubSpotCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        accessToken?: string | null,
        refreshToken?: string | null,
        tokenType?: string | null,
        expiresIn?: string | null,
      }
    ) | null,
    configuration: ( {
        __typename: "CustomConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "MarketoConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        hiddenField?: string | null,
      } | {
        __typename: "SalesforceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId?: string | null,
      } | {
        __typename: "GoogleTagManagerConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        dataLayerVarName?: string | null,
      } | {
        __typename: "UniversalAnalyticsConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "UniversalAnalyticsSourceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "HubSpotConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "ClearbitConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "DemandbaseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "SixSenseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "AccountMatchConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "GoogleOptimizeConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      }
    ) | null,
    provider: Providers,
    source: boolean,
    createdAt: string,
    updatedAt: string,
    lists?:  {
      __typename: "ModelIntegrationListConnection",
      items:  Array< {
        __typename: "IntegrationList",
        id: string,
        externalId?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        name?: string | null,
        description?: string | null,
        smart?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?:  {
      __typename: "ModelIntegrationFieldConnection",
      items:  Array< {
        __typename: "IntegrationField",
        id?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        fetch?: boolean | null,
        push?: boolean | null,
        name: string,
        displayName?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteIntegrationSubscription = {
  onDeleteIntegration?:  {
    __typename: "Integration",
    id: string,
    name: string,
    group: string,
    organizationId: string,
    credentials: ( {
        __typename: "MarketoCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiHost?: string | null,
        clientId?: string | null,
        clientSecret?: string | null,
      } | {
        __typename: "SalesforceCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        access_token?: string | null,
        refresh_token?: string | null,
        signature?: string | null,
        scope?: string | null,
        id_token?: string | null,
        instance_url?: string | null,
        id?: string | null,
        token_type?: string | null,
        issued_at?: string | null,
      } | {
        __typename: "SixSenseCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        apiToken?: string | null,
      } | {
        __typename: "HubSpotCreds",
        createdAt?: string | null,
        updatedAt?: string | null,
        accessToken?: string | null,
        refreshToken?: string | null,
        tokenType?: string | null,
        expiresIn?: string | null,
      }
    ) | null,
    configuration: ( {
        __typename: "CustomConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "MarketoConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        hiddenField?: string | null,
      } | {
        __typename: "SalesforceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        userId?: string | null,
      } | {
        __typename: "GoogleTagManagerConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        dataLayerVarName?: string | null,
      } | {
        __typename: "UniversalAnalyticsConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "UniversalAnalyticsSourceConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
        uaTrackingId?: string | null,
      } | {
        __typename: "HubSpotConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "ClearbitConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "DemandbaseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "SixSenseConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "AccountMatchConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      } | {
        __typename: "GoogleOptimizeConfig",
        active?: boolean | null,
        createdAt?: string | null,
        updatedAt?: string | null,
      }
    ) | null,
    provider: Providers,
    source: boolean,
    createdAt: string,
    updatedAt: string,
    lists?:  {
      __typename: "ModelIntegrationListConnection",
      items:  Array< {
        __typename: "IntegrationList",
        id: string,
        externalId?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        name?: string | null,
        description?: string | null,
        smart?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    attributes?:  {
      __typename: "ModelIntegrationFieldConnection",
      items:  Array< {
        __typename: "IntegrationField",
        id?: string | null,
        group: string,
        provider: Providers,
        integrationId: string,
        fetch?: boolean | null,
        push?: boolean | null,
        name: string,
        displayName?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    subjectId: string,
    subjectType: Subjects,
    status: Status,
    title: string,
    message: string,
    group: string,
    organizationId: string,
    dismissedAt?: string | null,
    dismissedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    subjectId: string,
    subjectType: Subjects,
    status: Status,
    title: string,
    message: string,
    group: string,
    organizationId: string,
    dismissedAt?: string | null,
    dismissedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    subjectId: string,
    subjectType: Subjects,
    status: Status,
    title: string,
    message: string,
    group: string,
    organizationId: string,
    dismissedAt?: string | null,
    dismissedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      group: string,
      orgOwner: string,
      installationType?: InstallationsType | null,
      integrationCode?: string | null,
      antiFlickerCode?: string | null,
      asynchronousSnippet?: string | null,
      anonymize?: boolean | null,
      enableNonConsent?: boolean | null,
      disabled?: boolean | null,
      signupType?: string | null,
      trialExpiration?: string | null,
      invoiced?: boolean | null,
      flashHide?: boolean | null,
      activateWebsiteAttribution?: boolean | null,
      createdAt: string,
      updatedAt: string,
      actions?:  {
        __typename: "ModelActionConnection",
        items:  Array< {
          __typename: "Action",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          destinationId: string,
          isArchived?: boolean | null,
          isActive?: boolean | null,
          firedLast30d?: number | null,
          createdAt: string,
          trigger?: string | null,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      audiences?:  {
        __typename: "ModelAudienceConnection",
        items:  Array< {
          __typename: "Audience",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          audienceType?: AudienceType | null,
          conditions?: string | null,
          visitors?: number | null,
          conversions?: number | null,
          individual?: number | null,
          accounts?: number | null,
          actionsCount?: number | null,
          isArchived?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      goals?:  {
        __typename: "ModelGoalConnection",
        items:  Array< {
          __typename: "Goal",
          id: string,
          name: string,
          description?: string | null,
          group: string,
          organizationId: string,
          eventId?: string | null,
          trackEvent?: string | null,
          goalFrequency?: number | null,
          includeInRevenue?: boolean | null,
          calculateGoalValueAuto?: boolean | null,
          aggregateGoalValue?: boolean | null,
          defaultGoalValue?: number | null,
          triggeringType?: string | null,
          isActive?: boolean | null,
          isArchived?: boolean | null,
          actionsCount?: number | null,
          type: GoalType,
          typeState: string,
          conversionsLast30d?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      integrations?:  {
        __typename: "ModelIntegrationConnection",
        items:  Array< {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    group: string,
    orgOwner: string,
    installationType?: InstallationsType | null,
    integrationCode?: string | null,
    antiFlickerCode?: string | null,
    asynchronousSnippet?: string | null,
    anonymize?: boolean | null,
    enableNonConsent?: boolean | null,
    disabled?: boolean | null,
    signupType?: string | null,
    trialExpiration?: string | null,
    invoiced?: boolean | null,
    flashHide?: boolean | null,
    activateWebsiteAttribution?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionConnection",
      items:  Array< {
        __typename: "Action",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        destinationId: string,
        configuration: ( {
            __typename: "UniversalAnalyticsDestination",
            provider?: IntegrationDestination | null,
            dimensionId?: string | null,
            category?: string | null,
            action?: string | null,
            label?: string | null,
          }
        ) | null,
        isArchived?: boolean | null,
        isActive?: boolean | null,
        firedLast30d?: number | null,
        createdAt: string,
        trigger?: string | null,
        updatedAt: string,
        triggers?:  {
          __typename: "ModelActionTriggerConnection",
          nextToken?: string | null,
        } | null,
        destination?:  {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    audiences?:  {
      __typename: "ModelAudienceConnection",
      items:  Array< {
        __typename: "Audience",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        audienceType?: AudienceType | null,
        conditions?: string | null,
        visitors?: number | null,
        conversions?: number | null,
        individual?: number | null,
        accounts?: number | null,
        actionsCount?: number | null,
        isArchived?: boolean | null,
        createdAt: string,
        updatedAt: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    goals?:  {
      __typename: "ModelGoalConnection",
      items:  Array< {
        __typename: "Goal",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        eventId?: string | null,
        trackEvent?: string | null,
        goalFrequency?: number | null,
        includeInRevenue?: boolean | null,
        calculateGoalValueAuto?: boolean | null,
        aggregateGoalValue?: boolean | null,
        defaultGoalValue?: number | null,
        triggeringType?: string | null,
        includeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        excludeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        isActive?: boolean | null,
        isArchived?: boolean | null,
        actionsCount?: number | null,
        type: GoalType,
        typeState: string,
        attributes?:  {
          __typename: "GoalAttribute",
          apiname?: string | null,
          createdbyid: string,
          defaultprobability?: string | null,
          description?: string | null,
          forecastcategory?: string | null,
          forecastcategoryname?: string | null,
          isactive?: boolean | null,
          isclosed?: boolean | null,
          iswon?: boolean | null,
          lastmodifiedbyid?: string | null,
          lastmodifieddate?: string | null,
          masterlabel?: string | null,
          sortorder?: string | null,
          systemmodstamp?: string | null,
          createddate?: string | null,
          id?: string | null,
        } | null,
        conversionsLast30d?: number | null,
        createdAt: string,
        updatedAt: string,
        event?:  {
          __typename: "Event",
          id: string,
          name: string,
          source: EventSourceType,
          group: string,
          organizationId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    integrations?:  {
      __typename: "ModelIntegrationConnection",
      items:  Array< {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    group: string,
    orgOwner: string,
    installationType?: InstallationsType | null,
    integrationCode?: string | null,
    antiFlickerCode?: string | null,
    asynchronousSnippet?: string | null,
    anonymize?: boolean | null,
    enableNonConsent?: boolean | null,
    disabled?: boolean | null,
    signupType?: string | null,
    trialExpiration?: string | null,
    invoiced?: boolean | null,
    flashHide?: boolean | null,
    activateWebsiteAttribution?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionConnection",
      items:  Array< {
        __typename: "Action",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        destinationId: string,
        configuration: ( {
            __typename: "UniversalAnalyticsDestination",
            provider?: IntegrationDestination | null,
            dimensionId?: string | null,
            category?: string | null,
            action?: string | null,
            label?: string | null,
          }
        ) | null,
        isArchived?: boolean | null,
        isActive?: boolean | null,
        firedLast30d?: number | null,
        createdAt: string,
        trigger?: string | null,
        updatedAt: string,
        triggers?:  {
          __typename: "ModelActionTriggerConnection",
          nextToken?: string | null,
        } | null,
        destination?:  {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    audiences?:  {
      __typename: "ModelAudienceConnection",
      items:  Array< {
        __typename: "Audience",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        audienceType?: AudienceType | null,
        conditions?: string | null,
        visitors?: number | null,
        conversions?: number | null,
        individual?: number | null,
        accounts?: number | null,
        actionsCount?: number | null,
        isArchived?: boolean | null,
        createdAt: string,
        updatedAt: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    goals?:  {
      __typename: "ModelGoalConnection",
      items:  Array< {
        __typename: "Goal",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        eventId?: string | null,
        trackEvent?: string | null,
        goalFrequency?: number | null,
        includeInRevenue?: boolean | null,
        calculateGoalValueAuto?: boolean | null,
        aggregateGoalValue?: boolean | null,
        defaultGoalValue?: number | null,
        triggeringType?: string | null,
        includeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        excludeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        isActive?: boolean | null,
        isArchived?: boolean | null,
        actionsCount?: number | null,
        type: GoalType,
        typeState: string,
        attributes?:  {
          __typename: "GoalAttribute",
          apiname?: string | null,
          createdbyid: string,
          defaultprobability?: string | null,
          description?: string | null,
          forecastcategory?: string | null,
          forecastcategoryname?: string | null,
          isactive?: boolean | null,
          isclosed?: boolean | null,
          iswon?: boolean | null,
          lastmodifiedbyid?: string | null,
          lastmodifieddate?: string | null,
          masterlabel?: string | null,
          sortorder?: string | null,
          systemmodstamp?: string | null,
          createddate?: string | null,
          id?: string | null,
        } | null,
        conversionsLast30d?: number | null,
        createdAt: string,
        updatedAt: string,
        event?:  {
          __typename: "Event",
          id: string,
          name: string,
          source: EventSourceType,
          group: string,
          organizationId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    integrations?:  {
      __typename: "ModelIntegrationConnection",
      items:  Array< {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    group: string,
    orgOwner: string,
    installationType?: InstallationsType | null,
    integrationCode?: string | null,
    antiFlickerCode?: string | null,
    asynchronousSnippet?: string | null,
    anonymize?: boolean | null,
    enableNonConsent?: boolean | null,
    disabled?: boolean | null,
    signupType?: string | null,
    trialExpiration?: string | null,
    invoiced?: boolean | null,
    flashHide?: boolean | null,
    activateWebsiteAttribution?: boolean | null,
    createdAt: string,
    updatedAt: string,
    actions?:  {
      __typename: "ModelActionConnection",
      items:  Array< {
        __typename: "Action",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        destinationId: string,
        configuration: ( {
            __typename: "UniversalAnalyticsDestination",
            provider?: IntegrationDestination | null,
            dimensionId?: string | null,
            category?: string | null,
            action?: string | null,
            label?: string | null,
          }
        ) | null,
        isArchived?: boolean | null,
        isActive?: boolean | null,
        firedLast30d?: number | null,
        createdAt: string,
        trigger?: string | null,
        updatedAt: string,
        triggers?:  {
          __typename: "ModelActionTriggerConnection",
          nextToken?: string | null,
        } | null,
        destination?:  {
          __typename: "Integration",
          id: string,
          name: string,
          group: string,
          organizationId: string,
          provider: Providers,
          source: boolean,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    audiences?:  {
      __typename: "ModelAudienceConnection",
      items:  Array< {
        __typename: "Audience",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        audienceType?: AudienceType | null,
        conditions?: string | null,
        visitors?: number | null,
        conversions?: number | null,
        individual?: number | null,
        accounts?: number | null,
        actionsCount?: number | null,
        isArchived?: boolean | null,
        createdAt: string,
        updatedAt: string,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    goals?:  {
      __typename: "ModelGoalConnection",
      items:  Array< {
        __typename: "Goal",
        id: string,
        name: string,
        description?: string | null,
        group: string,
        organizationId: string,
        eventId?: string | null,
        trackEvent?: string | null,
        goalFrequency?: number | null,
        includeInRevenue?: boolean | null,
        calculateGoalValueAuto?: boolean | null,
        aggregateGoalValue?: boolean | null,
        defaultGoalValue?: number | null,
        triggeringType?: string | null,
        includeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        excludeUrls?:  Array< {
          __typename: "Url",
          urls?: string | null,
          matchType?: MatchTypes | null,
        } | null > | null,
        isActive?: boolean | null,
        isArchived?: boolean | null,
        actionsCount?: number | null,
        type: GoalType,
        typeState: string,
        attributes?:  {
          __typename: "GoalAttribute",
          apiname?: string | null,
          createdbyid: string,
          defaultprobability?: string | null,
          description?: string | null,
          forecastcategory?: string | null,
          forecastcategoryname?: string | null,
          isactive?: boolean | null,
          isclosed?: boolean | null,
          iswon?: boolean | null,
          lastmodifiedbyid?: string | null,
          lastmodifieddate?: string | null,
          masterlabel?: string | null,
          sortorder?: string | null,
          systemmodstamp?: string | null,
          createddate?: string | null,
          id?: string | null,
        } | null,
        conversionsLast30d?: number | null,
        createdAt: string,
        updatedAt: string,
        event?:  {
          __typename: "Event",
          id: string,
          name: string,
          source: EventSourceType,
          group: string,
          organizationId: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    integrations?:  {
      __typename: "ModelIntegrationConnection",
      items:  Array< {
        __typename: "Integration",
        id: string,
        name: string,
        group: string,
        organizationId: string,
        credentials: ( {
            __typename: "MarketoCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiHost?: string | null,
            clientId?: string | null,
            clientSecret?: string | null,
          } | {
            __typename: "SalesforceCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            access_token?: string | null,
            refresh_token?: string | null,
            signature?: string | null,
            scope?: string | null,
            id_token?: string | null,
            instance_url?: string | null,
            id?: string | null,
            token_type?: string | null,
            issued_at?: string | null,
          } | {
            __typename: "SixSenseCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            apiToken?: string | null,
          } | {
            __typename: "HubSpotCreds",
            createdAt?: string | null,
            updatedAt?: string | null,
            accessToken?: string | null,
            refreshToken?: string | null,
            tokenType?: string | null,
            expiresIn?: string | null,
          }
        ) | null,
        configuration: ( {
            __typename: "CustomConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "MarketoConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            hiddenField?: string | null,
          } | {
            __typename: "SalesforceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            userId?: string | null,
          } | {
            __typename: "GoogleTagManagerConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            dataLayerVarName?: string | null,
          } | {
            __typename: "UniversalAnalyticsConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "UniversalAnalyticsSourceConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
            uaTrackingId?: string | null,
          } | {
            __typename: "HubSpotConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "ClearbitConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "DemandbaseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "SixSenseConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "AccountMatchConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          } | {
            __typename: "GoogleOptimizeConfig",
            active?: boolean | null,
            createdAt?: string | null,
            updatedAt?: string | null,
          }
        ) | null,
        provider: Providers,
        source: boolean,
        createdAt: string,
        updatedAt: string,
        lists?:  {
          __typename: "ModelIntegrationListConnection",
          nextToken?: string | null,
        } | null,
        attributes?:  {
          __typename: "ModelIntegrationFieldConnection",
          nextToken?: string | null,
        } | null,
        organization?:  {
          __typename: "Organization",
          id: string,
          name: string,
          group: string,
          orgOwner: string,
          installationType?: InstallationsType | null,
          integrationCode?: string | null,
          antiFlickerCode?: string | null,
          asynchronousSnippet?: string | null,
          anonymize?: boolean | null,
          enableNonConsent?: boolean | null,
          disabled?: boolean | null,
          signupType?: string | null,
          trialExpiration?: string | null,
          invoiced?: boolean | null,
          flashHide?: boolean | null,
          activateWebsiteAttribution?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    userEmail?: string | null,
    name?: string | null,
    userId?: string | null,
    orgId?: string | null,
    group?: string | null,
    timeStamp?: string | null,
    createdAt: string,
    updatedAt: string,
    stripeCustomerId?: string | null,
  } | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    userEmail?: string | null,
    name?: string | null,
    userId?: string | null,
    orgId?: string | null,
    group?: string | null,
    timeStamp?: string | null,
    createdAt: string,
    updatedAt: string,
    stripeCustomerId?: string | null,
  } | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    userEmail?: string | null,
    name?: string | null,
    userId?: string | null,
    orgId?: string | null,
    group?: string | null,
    timeStamp?: string | null,
    createdAt: string,
    updatedAt: string,
    stripeCustomerId?: string | null,
  } | null,
};

export type OnCreatePaymentSubscription = {
  onCreatePayment?:  {
    __typename: "Payment",
    id: string,
    stripeId?: string | null,
    amount?: number | null,
    receivedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePaymentSubscription = {
  onUpdatePayment?:  {
    __typename: "Payment",
    id: string,
    stripeId?: string | null,
    amount?: number | null,
    receivedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePaymentSubscription = {
  onDeletePayment?:  {
    __typename: "Payment",
    id: string,
    stripeId?: string | null,
    amount?: number | null,
    receivedAt?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
