import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography as MuiTypography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import styles from './style.module.scss';

const PasswordValidation = props => {
	const { validatedObj } = props;
	const [t, i18n] = useTranslation();

	return (
		<div>
			<MuiTypography variant="body2">
				{t('account_preferences_change_password_valid_password_description')}
			</MuiTypography>
			<MuiTypography variant="body2">
				{!validatedObj['length'] ? (
					<span className={styles.unChecked}>
						<CheckIcon />
					</span>
				) : (
					<span className={styles.checked}>
						<CheckIcon />
					</span>
				)}
				{t('account_preferences_change_password_eight_char')}
			</MuiTypography>
			<MuiTypography variant="body2">
				{!validatedObj['numeric'] ? (
					<span className={styles.unChecked}>
						<CheckIcon />
					</span>
				) : (
					<span className={styles.checked}>
						<CheckIcon />
					</span>
				)}
				{t('account_preferences_change_password_one_numeric')}
			</MuiTypography>
			<MuiTypography variant="body2">
				{!validatedObj['uppercase'] ? (
					<span className={styles.unChecked}>
						<CheckIcon />
					</span>
				) : (
					<span className={styles.checked}>
						<CheckIcon />
					</span>
				)}
				{t('account_preferences_change_password_one_uppercase')}
			</MuiTypography>
			<MuiTypography variant="body2">
				{!validatedObj['lc'] ? (
					<span className={styles.unChecked}>
						<CheckIcon />
					</span>
				) : (
					<span className={styles.checked}>
						<CheckIcon />
					</span>
				)}
				{t('account_preferences_change_password_one_lowercase')}
			</MuiTypography>
			<MuiTypography variant="body2">
				{!validatedObj['special'] ? (
					<span className={styles.unChecked}>
						<CheckIcon />
					</span>
				) : (
					<span className={styles.checked}>
						<CheckIcon />
					</span>
				)}
				{t('account_preferences_change_password_one_special_character')}
			</MuiTypography>
		</div>
	);
};

export default PasswordValidation;
