import React from 'react';
import CustomButton from 'app/components/Typos/ButtonComponent';
import { Typography as MuiTypography } from '@material-ui/core';
import { AllSetImage } from './AllSetImage';
import styles from './style.module.scss';

export const GoToDashboard = ({ goToDashboard }) => {
	return (
		<div className={styles.wrapper}>
			<AllSetImage />
			<div>
				<MuiTypography variant="h2">You're all set!</MuiTypography>
				<MuiTypography variant="body2" className={styles.description}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. In non
					elementum dui. Ut lobortis, mi non elementum bibendum, turpis nisi
					auctor ipsum, ut volutpat elit dui a neque.
				</MuiTypography>
				<CustomButton block onClick={() => goToDashboard()}>
					Go to Dashboard
				</CustomButton>
			</div>
		</div>
	);
};
