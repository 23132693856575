import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { NoDataFound } from 'app/components/NoDataFound';
import NameReportTableCell from 'app/components/NameReportTableCell';
import { ArchiveComp } from 'app/components/ArchiveComp';
import Pagination, {
	Props as PaginationProps,
} from 'app/components/Pagination';
import { actions } from '../slice';
import { IAudience } from '../types';
import styles from './style.module.scss';

interface ExtendedAudience extends IAudience {
	visitors?: number;
	individuals?: number;
}

interface Props extends PaginationProps {
	audiences: ExtendedAudience[];
}

const AudienceTable = (props: Props) => {
	const { audiences, ...paginationProps } = props;
	const [t, i18n] = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { path } = useRouteMatch();
	const [toArchive, setToArchive] = useState<{
		id?: string;
		name?: string;
	} | null>(null);

	const handleArchive = () => {
		dispatch(actions.archiveAudience({ ...toArchive, isArchived: true }));
		setToArchive(null);
	};

	if (!audiences.length) {
		return (
			<NoDataFound
				heading={{
					key: 'audiences_no_audience_msg',
					text: t('audiences_no_audience_msg'),
				}}
				description={{
					key: 'audiences_no_audience_sub_msg',
					text: t('audiences_no_audience_sub_msg'),
				}}
			/>
		);
	}
	return (
		<Paper className={styles.wrapperPaper}>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Visitors (Trailing 7 Days)</TableCell>
							<TableCell>Individual (Trailing 7 Days)</TableCell>
							<TableCell>Last Updated</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{audiences.map(
							({ id, name, description, visitors, individuals, updatedAt }) => (
								<TableRow key={id}>
									<NameReportTableCell
										id={id}
										name={name}
										description={description}
										handleViewClick={() => history.push(`${path}/${id}`)}
										handleReportClick={() =>
											history.push(`${path}/report/${id}`)
										}
										handleArchiveClick={() => setToArchive({ id, name })}
									/>
									<TableCell>{visitors ?? 0}</TableCell>
									<TableCell>{individuals ?? 0}</TableCell>
									<TableCell>
										{moment(updatedAt).format('MM/DD/YYYY')}
									</TableCell>
								</TableRow>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<ArchiveComp
				open={!isEmpty(toArchive)}
				name={toArchive?.name ?? null}
				handleClose={() => setToArchive(null)}
				handleArchive={handleArchive}
			/>
			<Pagination {...paginationProps} />
		</Paper>
	);
};

export default AudienceTable;
