import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { selectFeatureFlags } from 'app/containers/AuthContainer/selectors';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { Integration } from '../Integration';
import { Marketo } from './Marketo';
import { MarketoIcon } from './Marketo/MarketoIcon';
import { SalesforceIcon } from './Salesforce/SalesforceIcon';
import { UniversalAnalyticsIcon } from './UniversalAnalyticsSource/UniversalAnalyticsIcon';
import { UniversalAnalyticsSource } from './UniversalAnalyticsSource';
import { Custom } from './Custom';
import { ClearBitReveal } from './ClearbitReveal';
import { ClearbitRevealIcon } from './ClearbitReveal/ClearbitRevealIcon';
import { HubSpot } from './HubSpot';
import { HubSpotIcon } from './HubSpot/HubSpotIcon';
import { Demandbase } from './Demandbase';
import { DemandBaseIcon } from './Demandbase/DemandBaseIcon';
import { SixSense } from './SixSense';
import { SixSenseIcon } from './SixSense/SixSenseIcon';
import { AccountMatch } from './AccountMatch';
import { AccountMatchIcon } from './AccountMatch/AccountMatchIcon';
import { AccountLookup } from './AccountLookup';
import { AccountLookupIcon } from './AccountLookup/AccountLookupIcon';
import { GoogleOptimize } from './GoogleOptimize';
import { GoogleOptimizeIcon } from './GoogleOptimize/GoogleOptimizeIcon';
import integrationsList from '../integrationsList.json';
import { convertOrganizationToGroup } from 'utils/groupNameConvert';
import { Salesforce } from './Salesforce';
import { Providers } from 'API';
import styles from './style.module.scss';

export const DataSources = () => {
	const history = useHistory();
	const match = useRouteMatch();
	const org = useSelector(selectedOrganization);
	const {
		testOrg,
		customIntegration,
		marketoMilestone,
		universalAnalyticsSourceIntegration,
		salesforceIntegration,
		demandbaseIntegration,
		clearbitIntegration,
		hubspotIntegration,
		sixSenseIntegration,
		accountMatchIntegration,
		googleOptimizeIntegration,
	} = useSelector(selectFeatureFlags);

	//Return provider Component
	const retComp = (val, data) => {
		let comp = {
			custom: <Custom {...data} />,
			marketo: <Marketo {...data} />,
			salesforce: <Salesforce {...data} />,
			universalAnalyticsSource: <UniversalAnalyticsSource {...data} />,
			clearbit: <ClearBitReveal {...data} />,
			hubspot: <HubSpot {...data} />,
			demandbase: <Demandbase {...data} />,
			sixSense: <SixSense {...data} />,
			accountMatch: <AccountMatch {...data} />,
			accountLookup: <AccountLookup {...data} />,
			googleOptimize: <GoogleOptimize {...data} />,
		};
		return comp[val] || <Marketo {...data} />;
	};

	//Return Icon Component
	const retIcon = val => {
		let iconComp = {
			marketo: <MarketoIcon />,
			custom: <AccountLookupIcon />,
			salesforce: <SalesforceIcon />,
			universalAnalyticsSource: <UniversalAnalyticsIcon />,
			hubspot: <HubSpotIcon />,
			clearbit: <ClearbitRevealIcon />,
			demandbase: <DemandBaseIcon />,
			sixSense: <SixSenseIcon />,
			accountMatch: <AccountMatchIcon />,
			accountLookup: <AccountLookupIcon />,
			googleOptimize: <GoogleOptimizeIcon />,
		};
		return iconComp[val] || <MarketoIcon />;
	};

	//filter source integrations
	let integrationData = _.filter(integrationsList, item => item.source);
	//show Marketo in default cond and other with `testOrg` feature flag
	integrationData = _.filter(integrationData, item => {
		if (testOrg) return true;
		switch (item.provider) {
			case Providers.custom:
				return customIntegration;
			case Providers.marketo:
				return marketoMilestone;
			case Providers.salesforce:
				return salesforceIntegration;
			case Providers.universalAnalyticsSource:
				return universalAnalyticsSourceIntegration;
			case Providers.clearbit:
				return clearbitIntegration;
			case Providers.hubspot:
				return hubspotIntegration;
			case Providers.demandbase:
				return demandbaseIntegration;
			case Providers.sixSense:
				return sixSenseIntegration;
			case Providers.accountMatch:
				return accountMatchIntegration;
			case Providers.googleOptimize:
				return googleOptimizeIntegration;
			default:
				return false;
		}
	});

	if (!_.isEmpty(org)) {
		return (
			<>
				<div className={styles.optionsWrapper}>
					{integrationData.map(integration => {
						const { name, provider } = integration;
						let baseUrl;
						if (match.url.includes(provider)) {
							baseUrl = match.url.split(`/${provider}`)?.[0]; // integrations
						} else if (match.url.includes('integrations/')) {
							baseUrl = match.url.split('integrations/')[0] + 'integrations'; //integrations
						} else {
							baseUrl = match.url.split('integrations')[0] + 'integrations'; //integrations
						}
						return (
							<Integration
								key={provider}
								provider={provider}
								name={name}
								icon={retIcon(provider)}
								compo={retComp(provider, {
									...integration,
									group: convertOrganizationToGroup(org.group),
									organizationId: org?.id,
									baseUrl: baseUrl,
								})}
								onClickHandler={() => history.push(`${baseUrl}/${provider}`)}
								baseUrl={baseUrl}
							/>
						);
					})}
				</div>
			</>
		);
	}
	return null;
};
