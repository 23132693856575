import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DialogContent } from '@material-ui/core';
import {
	isMarketoCredsConnected,
	getCheckedMarketoCreds,
} from 'app/containers/IntegrationsContainer/selectors';
import { actions } from '../../../slice';
import { checkConnection } from './checkConnection';
import { AuthorizationFormFields } from './FormFields';
import CustomButton from 'app/components/Typos/ButtonComponent';
import { MessageTag } from '../../../components/MessageTag';
import styles from '../style.module.scss';

interface Props {
	credentials: any;
}

export function AuthorizationForm({ credentials }: Props) {
	const dispatch = useDispatch();
	const [error, setError] = useState<string | null>();
	const isConnected = useSelector(isMarketoCredsConnected);
	const savedCreds = useSelector(getCheckedMarketoCreds);
	const [loading, setLoading] = useState(false);
	const handleCheckConnection = async values => {
		try {
			const { apiHost, clientId, clientSecret } = values;
			setLoading(true);
			setError(null);
			await checkConnection(apiHost, clientId, clientSecret);
			dispatch(
				actions.setMarketoCredentials({
					apiHost,
					clientId,
					clientSecret,
				})
			);
			dispatch(actions.setIsMarketoCredsConnected(true));
			setLoading(false);
		} catch (err) {
			setError(err?.message || err);
			setLoading(false);
			dispatch(actions.setIsMarketoCredsConnected(false));
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				apiHost: '',
				clientId: '',
				clientSecret: '',
				...credentials,
				...savedCreds,
			}}
			onSubmit={handleCheckConnection}
			validationSchema={Yup.object().shape({
				apiHost: Yup.string().required('Required'),
				clientId: Yup.string().required('Required'),
				clientSecret: Yup.string().required('Required'),
			})}
		>
			{({ values, setFieldValue, dirty, isValid }) => (
				<Form className={styles.formWrapper}>
					<DialogContent>
						<AuthorizationFormFields
							values={values}
							setFieldValue={setFieldValue}
						/>
						{error && <MessageTag type="error" message={error} />}
						{isConnected && !dirty && (
							<MessageTag type="success" message={'Connection Successful'} />
						)}
						<CustomButton
							type="submit"
							loader={loading}
							disabled={!isValid}
							style={{
								marginTop: '20px',
								textTransform: 'capitalize',
								padding: '8px 20px',
								height: 'auto',
								fontSize: '12px',
								lineHeight: '20px',
							}}
						>
							Check Connection
						</CustomButton>
					</DialogContent>
				</Form>
			)}
		</Formik>
	);
}
