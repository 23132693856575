/**
 *
 * Dashboard
 *
 */
import React from 'react';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Header } from 'app/components/Header';
import { Footer } from 'app/components/Footer';
import { Loader } from 'app/components/Loader';
import {
	sliceKey as authSliceKey,
	actions as authActions,
	reducer as authReducer,
} from 'app/containers/AuthContainer/slice';
import {
	sliceKey as organizationSliceKey,
	reducer as organizationReducer,
	actions as orgActions,
} from 'app/containers/OrganizationsContainer/slice';
import { sliceKey, reducer } from './slice';
import { organizationContainerSaga } from '../OrganizationsContainer/saga';
import { dashboardSaga } from './saga';
import { authContainerSaga } from '../AuthContainer/saga';
import { NotFoundPage } from 'app/components/NotFoundPage';
import { NotAuthorizedPage } from 'app/components/NotAuthorizedPage';
import {
	selectFetchingData,
	selectedOrganization,
	selectOrganizations,
} from '../OrganizationsContainer/selectors';
import { Routes } from './routes';
import { find } from 'lodash';
import { setSessionItem } from 'app/components/Header/utility';

export const getEncodedStr = str => encodeURIComponent(str);
export const getDecodedStr = encodedStr => decodeURIComponent(encodedStr);

export function Main({ history, match }) {
	useInjectReducer({ key: authSliceKey, reducer: authReducer });
	useInjectReducer({ key: organizationSliceKey, reducer: organizationReducer });
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: authSliceKey, saga: authContainerSaga });
	useInjectSaga({ key: organizationSliceKey, saga: organizationContainerSaga });
	useInjectSaga({ key: sliceKey, saga: dashboardSaga });

	const dispatch = useDispatch();
	const fetchingOrganizations = useSelector(selectFetchingData);
	const currentOrg = useSelector(selectedOrganization);
	const orgs = useSelector(selectOrganizations);

	const handleLogout = () => {
		dispatch(authActions.logout());
		return true;
	};

	let urlOrg = window.location.href.split('/o/')?.[1]?.split('/')?.[0];

	if (!urlOrg && currentOrg) {
		urlOrg = getEncodedStr(currentOrg?.group);
	}
	let encodedOrg = `/o/${urlOrg}/`;

	if (orgs.length > 0 && urlOrg) {
		const org = find(orgs, { group: getDecodedStr(urlOrg) });
		if (org) {
			setSessionItem('selectedOrg', org?.group);
			dispatch(orgActions.changeOrg(org));
		} else {
			//if user not authorized for organization or not added to that organization
			return <NotAuthorizedPage />;
		}
	}

	if (fetchingOrganizations) return <Loader />;

	return (
		<>
			<Header handleLogout={handleLogout} baseUrl={encodedOrg} />
			<div
				style={{
					paddingTop: '47px',
					backgroundColor: '#F5F7FB',
				}}
			>
				<Switch>
					{/**need to use this because of salesforce static redirect url**/}
					<Route
						path={`/integrations/salesforce/authorization_success`}
						render={props => (
							<Redirect
								to={`${encodedOrg}integrations/salesforce/authorization_success${props.location.search}`}
							/>
						)}
					/>
					{/**need to use this because of hubspot static redirect url**/}
					<Route
						path={`/integrations/hubspot/authorization_success`}
						render={props => (
							<Redirect
								to={`${encodedOrg}integrations/hubspot/authorization_success${props.location.search}`}
							/>
						)}
					/>
					<Route path={`${encodedOrg}`} component={Routes} />
					<Route
						path="/"
						render={props => {
							const { location } = props;
							const { pathname } = location;
							const path = pathname.substring(
								pathname.indexOf('/') + 1,
								pathname.length
							);
							return <Redirect to={`${encodedOrg}${path}`} />;
						}}
					/>
					<Route component={NotFoundPage} />
				</Switch>
			</div>
			<Footer />
		</>
	);
}
