import { put, takeLatest } from 'redux-saga/effects';
import { actions } from './slice';
import * as _ from 'lodash';

export function* doSomething() {
	yield put(actions.updateMessageSuccess('dummy generator function'));
}

export function* dashboardSaga() {
	yield takeLatest(actions.updateMessage.type, doSomething);
}
