import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, IAction } from './types';

export const initialState: ContainerState = {
	error: undefined,
	fetchingData: false,
	actions: [],
	selectedAction: {},
	nextToken: undefined,
	indexRange: [0, 0],
};

const actionContainerSlice = createSlice({
	name: 'actionContainer',
	initialState,
	reducers: {
		setActionError(state, action) {
			state.fetchingData = false;
			state.error = action.payload;
		},
		fetchActions(state, _action) {
			state.fetchingData = true;
		},
		fetchActionsSuccess(state, action) {
			state.fetchingData = false;
			state.actions = action.payload;
		},
		fetchAction(state, _action) {
			state.fetchingData = true;
		},
		fetchActionSuccess(state, action: PayloadAction<IAction>) {
			state.selectedAction = action.payload;
			state.fetchingData = false;
		},
		modifyActions(_state, _action) {},
		modifyAction(_state, _action) {},
		modifyActionSuccess(state, action) {
			state.fetchingData = false;
			if (state.actions.map(a => a.id).indexOf(action.payload.id) > -1) {
				state.actions = state.actions.map(a =>
					a.id === action?.payload?.id ? action.payload : a
				);
			} else {
				state.actions = [...state.actions, action.payload];
			}
		},
		editActionConfiguration(_state, _action) {},
		editActionConfigurationSuccess(state, action) {
			state.actions = state.actions.map(a =>
				a.id !== action.payload.actionId
					? a
					: { ...a, configuration: action.payload.configuration }
			);
		},
		next(state, _action) {
			state.fetchingData = true;
		},
		previous(_state, _action) {},
		appendActionsList(state, action) {
			state.actions = [...state.actions, ...action.payload];
			state.fetchingData = false;
		},
		setNextToken(state, action) {
			state.nextToken = action.payload;
		},
		setIndexRange(state, action) {
			state.indexRange = action.payload;
			state.fetchingData = false;
		},
		archiveAction(state, _action) {
			state.fetchingData = true;
		},
		archiveActionSuccess(state, _action) {
			state.fetchingData = false;
			//filter out the archived actions from state
			state.actions = state.actions?.filter(i => i?.isArchived === false);
		},
		assignTriggers(_state, _action) {},
		assignTrigger(_state, _action) {},
		assignTriggerSuccess(state, action) {
			state.actions = state.actions?.map(a =>
				a.id !== action.payload.actionId
					? a
					: ({
							...a,
							triggers: {
								...a.triggers,
								items: [...a.triggers?.items, action.payload],
							},
					  } as IAction)
			);
		},
		removeTrigger(_state, _action) {},
		removeTriggerSuccess(state, action) {
			state.actions = state.actions?.map(a =>
				a.id !== action.payload.action.id
					? a
					: ({
							...a,
							triggers: {
								...a.triggers,
								items: a?.triggers?.items.filter(
									t => t?.triggerId !== action.payload.trigger.id
								),
							},
					  } as IAction)
			);
		},
	},
});

export const { actions, reducer, name: sliceKey } = actionContainerSlice;
