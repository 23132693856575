import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { Trigger } from 'API';

export const initialState: ContainerState = {
	error: undefined,
	triggers: [],
	loading: false,
	modalLoading: false,
	nextToken: undefined,
	indexRange: [0, 0],
};

const triggersContainerSlice = createSlice({
	name: 'triggersContainer',
	initialState,
	reducers: {
		setTriggerError(state, action: PayloadAction<string>) {
			state.error = action.payload;
			state.loading = false;
		},
		fetchTriggers(state, _action) {
			state.loading = true;
		},
		fetchTriggersSuccess(state, action: PayloadAction<Trigger[]>) {
			state.loading = false;
			state.triggers = action.payload;
		},
		getTrigger(state, _action) {
			state.modalLoading = true;
		},
		getTriggerSuccess(state, action: PayloadAction<Trigger>) {
			state.triggers = [
				...state.triggers.filter(t => t.id !== action.payload.id),
				action.payload,
			];
			state.modalLoading = false;
		},
		createTrigger(state, _action) {
			state.loading = true;
		},
		createTriggerSuccess(state, action: PayloadAction<Trigger>) {
			state.triggers = [...state.triggers, action.payload];
			state.loading = false;
		},
		updateTrigger(state, _action) {
			state.loading = true;
		},
		updateTriggerSuccess(state, action: PayloadAction<Trigger>) {
			state.triggers = state.triggers.map(t => {
				if (t.id !== action.payload.id) return t;
				return action.payload;
			});
			state.loading = false;
		},
		modifyTrigger(_state, _action) {},
		editTriggerCondition(_state, _action) {},
		editTriggerConditionSuccess(
			state,
			action: PayloadAction<{ id: string; conditions: string }>
		) {
			state.triggers = state.triggers.map(t => {
				if (t.id !== action.payload.id) return t;
				return { ...t, conditions: action.payload.conditions };
			});
		},
		saveTriggerUpdateAction(_state, _action) {},
		next(state, _action) {
			state.loading = true;
		},
		previous(_state, _action) {},
		appendTriggersList(state, action) {
			state.triggers = [...state.triggers, ...action.payload];
			state.loading = false;
		},
		setNextToken(state, action) {
			state.nextToken = action.payload;
		},
		setIndexRange(state, action) {
			state.indexRange = action.payload;
			state.loading = false;
		},
		assignActions(_state, _action) {},
		assignAction(_state, _action) {},
		assignActionSuccess(state, action) {
			state.triggers = state.triggers?.map(t =>
				t.id !== action.payload.triggerId
					? t
					: ({
							...t,
							actions: {
								...t.actions,
								items: [...t.actions?.items, action.payload],
							},
					  } as Trigger)
			);
		},
		removeAction(_state, _action) {},
		removeActionSuccess(state, action) {
			state.triggers = state.triggers?.map(t =>
				t.id !== action.payload.trigger.id
					? t
					: ({
							...t,
							actions: {
								...t.actions,
								items: (t?.actions?.items || []).filter(
									a => a?.actionId !== action.payload.action.id
								),
							},
					  } as Trigger)
			);
		},
	},
});

export const { actions, reducer, name: sliceKey } = triggersContainerSlice;
