import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Typography as MuiTypography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './slice';
import { selectedOrganization } from '../OrganizationsContainer/selectors';
import { DataSources } from './DataSources';
import { Destinations } from './Destinations';
import integrationsList from './integrationsList.json';
import styles from './style.module.scss';
import RichTextConverter from 'app/components/RichTextConverter';
import { CustomTabs } from 'app/components/CustomTabs';

export function IntegrationsContainer() {
	const [t, i18n] = useTranslation();
	const match = useRouteMatch();
	const [value, setValue] = React.useState(0);
	const { params } = match;
	const { page } = params as any;
	const org = useSelector(selectedOrganization);
	const dispatch = useDispatch();

	useEffect(() => {
		if (org && org.id) {
			dispatch(
				actions.fetchingIntegrations({
					organizationId: org.id,
				})
			);
		}
	}, [dispatch, org]);

	const sourceList = integrationsList.filter(
		data => data && data.source === true
	);
	const segmentList = integrationsList.filter(
		data => data && data.source === false
	);

	useEffect(() => {
		sourceList.forEach(item => {
			if (page === item.provider || page === 'add-dataSource') {
				setValue(0);
			}
		});
		segmentList.forEach(item => {
			if (page === item.provider || page === 'add-destinations') {
				setValue(1);
			}
		});
	}, [page, segmentList, sourceList]);

	return (
		<div className={styles.integrations}>
			<MuiTypography variant="h3" data-translation-key="integrations_title">
				{t('integrations_title')}
			</MuiTypography>
			<MuiTypography variant="body2">
				<RichTextConverter
					html={t('integrations_description')}
					translationKey={'integrations_description'}
				></RichTextConverter>
			</MuiTypography>

			<CustomTabs
				tabs={[
					{
						translationKey: 'integrations_tab_data_source_label',
						content: <DataSources />,
						label: t('integrations_tab_data_source_label'),
					},
					{
						translationKey: 'integrations_tab_destination_label',
						content: <Destinations />,
						label: t('integrations_tab_destination_label'),
					},
				]}
				defaultTabValue={value}
				theme="transparent"
			/>
		</div>
	);
}
