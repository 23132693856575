import React from 'react';
import { Field, FieldArray } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import { Lead } from './fetchLeadsFromMarketo';
import styles from './style.module.scss';
import { CheckedIcon } from './checkedIcon';
import cx from 'classnames';
import { NoMatchFound } from '../NoMatchFound';

interface Props {
	leads?: Lead[];
	search: string;
	values: any;
	setValues: Function;
}

export function AddListFields({
	leads = [],
	search,
	values,
	setValues,
}: Props) {
	const searchValue = search.toLowerCase();
	const handleToggleselection = id => {
		setValues({
			...values,
			[id]: !values[id],
		});
	};

	if (leads.filter(a => a?.nameLowerCase?.includes(searchValue)).length === 0) {
		return <NoMatchFound />;
	}

	return (
		<FieldArray
			name="selectedLeads"
			render={() => (
				<div className={styles.listWrapper}>
					{leads
						.filter(a => a?.nameLowerCase?.includes(searchValue))
						.map(({ name, id }) => (
							<div key={id} className={styles.labelWrap}>
								<span className={styles.label}>{name}</span>
								<Field type="checkbox" name={id}>
									{() => (
										<div className={styles.checkWrapper}>
											<Checkbox
												checked={values[id] || false}
												onChange={e => {
													setValues({
														...values,
														[id]: e.target.checked,
													});
												}}
												disableRipple
												checkedIcon={<CheckedIcon />}
												className={cx({
													[styles.checkBox]: true,
													[styles.showSelected]: values[id] === true,
												})}
											/>
											<span className={styles.onHoverText}>
												{values[id] ? (
													<span
														style={{ color: '#FF7D5E' }}
														onClick={() => handleToggleselection(id)}
													>
														Deselect
													</span>
												) : (
													<span
														style={{ color: '#048CFF' }}
														onClick={() => handleToggleselection(id)}
													>
														Add
													</span>
												)}
											</span>
										</div>
									)}
								</Field>
							</div>
						))}
				</div>
			)}
		/>
	);
}
