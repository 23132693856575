import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Typography as MuiTypography } from '@material-ui/core';
import { AkCodeBlock } from '@atlaskit/code';
import { actions } from 'app/containers/OrganizationsContainer/slice';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import styles from '../../style.module.scss';

interface Props {
	selectedListName: string;
}
const codeBlock = `
funnelEnvy.updatedAudiences = {
	"funnelEnvy": {
		"audiences": {
			"mql2": true
		}
	}
},
funnelEnvy.updatedLeadScore = {
	"funnelEnvy": {
		"leadScore": {
			"leadScore": "<lead-score>"
		}
	}
}
`;

//to prevent throwing error in some places where its not fixed
const UniversalAnalyticsForm = () => {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();

	return (
		<>
			<MuiTypography variant="body2">
				The following objects will be sent to the data layer depending on
				audience attributes
			</MuiTypography>
			<CopyToClipboard
				text={codeBlock}
				onCopy={() => {
					dispatch(actions.setCopied());
				}}
			>
				<Tooltip title="Copy to Clipboard" placement="top" arrow>
					<div className={styles.codeBlock}>
						<AkCodeBlock
							theme={{
								textColor: 'black',
							}}
							text={codeBlock}
						/>
					</div>
				</Tooltip>
			</CopyToClipboard>
		</>
	);
};

export default UniversalAnalyticsForm;
