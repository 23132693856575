import React, { useEffect } from 'react';

import { Loader } from 'app/components/Loader';
import styles from './style.module.scss';

interface Props {
	portalUrl: string | undefined;
}

export const StripeRedirect = ({ portalUrl }: Props) => {
	useEffect(() => {
		if (portalUrl) {
			window.location.href = portalUrl;
		}
	}, [portalUrl]);

	return (
		<div className={styles.wrapper}>
			<Loader />
		</div>
	);
};
