/**
 *
 * AuthContainer
 *
 */
import React, { memo, useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useSelector, useDispatch } from 'react-redux';
//import { Hub } from '@aws-amplify/core';
import { selectCognitoUser, selectCheckingAuth } from './selectors';
import { reducer, sliceKey, actions } from './slice';
import {
	reducer as orgReducer,
	sliceKey as orgSliceKey,
	actions as organizationActions,
} from 'app/containers/OrganizationsContainer/slice';
import { organizationContainerSaga } from 'app/containers/OrganizationsContainer/saga';
import { authContainerSaga } from './saga';
import {
	selectIsOrgChange,
	selectedOrganization,
} from 'app/containers/OrganizationsContainer/selectors';
import {
	withLDProvider,
	useLDClient,
	useFlags,
} from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

interface Props {
	children: any;
}

const AuthContainer = memo((props: Props) => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: authContainerSaga });
	useInjectReducer({ key: orgSliceKey, reducer: orgReducer });
	useInjectSaga({ key: orgSliceKey, saga: organizationContainerSaga });
	const ldClient: any = useLDClient();
	const launchdarklyFlags = useFlags();
	const dispatch = useDispatch();
	const cognitoUser: any = useSelector(selectCognitoUser);
	const checkingAuth = useSelector(selectCheckingAuth);
	const isOrgChange = useSelector(selectIsOrgChange);
	const currentOrg: any = useSelector(selectedOrganization);

	useEffect(() => {
		dispatch(actions.fetchCognitoUser());
	}, [dispatch, isOrgChange]);

	useEffect(() => {
		cognitoUser && dispatch(organizationActions.fetchOrganizations({}));
	}, [dispatch, cognitoUser]);

	useEffect(() => {
		if (cognitoUser && !_.isEmpty(currentOrg)) {
			ldClient?.identify({
				key: cognitoUser?.sub,
				name: cognitoUser?.name || cognitoUser?.email,
				email: cognitoUser?.email,
				custom: {
					organization: currentOrg?.group,
				},
			});
		}
	}, [cognitoUser, dispatch, ldClient, currentOrg]);

	const Flags = _.size(launchdarklyFlags);

	if (Flags) {
		dispatch(actions.setFeatureFlag(launchdarklyFlags));
	}
	return <>{!Flags || checkingAuth ? null : props.children(cognitoUser)}</>;
});

export * from './AuthRoutes/PrivateRoute';
export * from './AuthRoutes/PublicRoute';

export * from './Login';
export * from './SignUp';
export * from './SignUp/CompleteSignUp';
export * from './ForgotPassword';
export * from './ConfirmSignUp';
export * from './NewPasswordRequired';
export * from './ConfirmSignIn';
export * from './ForgotPasswordSubmit';
export * from './types';

export default withLDProvider({
	clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_ID || '',
	user: { key: 'null-user', anonymous: true },
})(AuthContainer);
