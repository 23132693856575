import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import { actions } from './slice';
import * as APIt from 'API';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { GraphQLResult } from '@aws-amplify/api';
import { findMembers } from 'graphql/queries';
import { addMember, removeMember, identifyMember } from 'graphql/mutations';
import { errorMessageHandler } from 'utils/errorMessage';
import { toast } from 'react-toastify';
import { history } from 'utils/history';
import _ from 'lodash';
import { AddMemberType } from './types';
import { getCognitoAttribute } from './utility';

const errorMessage = (err: Error) =>
	errorMessageHandler(err, 'Error occurred fetching member.');

export function* handleError(err: Error) {
	const errMessage = errorMessage(err);
	yield call(toast.error, errMessage);
	yield put(actions.setLoading(false));
}

export function* handleGetMembers(
	action: PayloadAction<APIt.memberInput>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.FindMembersQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(findMembers, { member: action.payload })
		);
		yield put(actions.getMembersSuccess(data?.findMembers));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleIdentifyUser(
	action: PayloadAction<APIt.memberInput>
): SagaIterator {
	try {
		const { userName, userEmail, userGroup } = action.payload;
		const { data } = yield call(
			[API, 'graphql'],
			graphqlOperation(identifyMember, { member: action.payload })
		);
		//verify user data to proceed
		yield call(
			verifyConditions,
			data?.identifyMember,
			userGroup,
			userEmail,
			userName
		);
		//set loading false
		yield put(actions.identifyUserSuccess());
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* verifyConditions(
	enteredUserInfo,
	groupName,
	userEmail,
	userName
) {
	try {
		if (!enteredUserInfo?.UserAttributes) {
			//if user is null
			yield put(actions.setUserEmail(userEmail));
			yield put(actions.setUserFetchedName(''));
			yield put(actions.setDisableUserName(false));
			yield put(actions.setDisableAddMember(false));
			yield put(actions.setMemberType(AddMemberType.NewUser));
		} else {
			//if entered user is not null
			//members list
			const { data }: GraphQLResult<APIt.FindMembersQuery> = yield call(
				[API, 'graphql'],
				graphqlOperation(findMembers, {
					member: { userGroup: groupName, userEmail, userName },
				})
			);

			//if user is already in the member list
			const isInMember = _.filter(data?.findMembers, item => {
				return (
					item?.Username ===
					_.filter(enteredUserInfo?.UserAttributes, {
						Name: 'sub',
					})[0]?.Value
				);
			});

			//get user group
			const searchUserGroup = getCognitoAttribute(
				enteredUserInfo,
				'custom:organization'
			);
			const name = getCognitoAttribute(enteredUserInfo, 'name');
			const email = getCognitoAttribute(enteredUserInfo, 'email');

			const notInListUser = !isInMember?.length;
			const outsideUser = notInListUser && searchUserGroup !== groupName;
			const confirmedUser = enteredUserInfo?.UserStatus;

			if (confirmedUser === 'CONFIRMED') {
				if (outsideUser) {
					yield put(actions.setUserFetchedName(!!name ? name : ''));
					yield put(actions.setUserEmail(!!email ? email : userEmail));
					yield put(actions.setMemberType(AddMemberType.NotInOrgUserConfirmed));
				} else {
					if (searchUserGroup === groupName && notInListUser) {
						yield put(actions.setUserEmail(!!email ? email : userEmail));
						yield put(actions.setUserFetchedName(!!name ? name : ''));
						yield put(actions.setMemberType(AddMemberType.OldOrgUserConfirmed));
					} else {
						yield put(actions.setUserEmail(!!email ? email : userEmail));
						yield put(actions.setUserFetchedName(!!name ? name : ''));
						yield put(actions.setMemberType(AddMemberType.InOrgUserConfirmed));
					}
				}
			} else {
				if (outsideUser) {
					yield put(actions.setUserEmail(!!email ? email : userEmail));
					yield put(actions.setUserFetchedName(!!name ? name : ''));
					yield put(
						actions.setMemberType(AddMemberType.NotInOrgUserUnConfirmed)
					);
				} else {
					yield put(actions.setUserFetchedName(!!name ? name : ''));
					yield put(actions.setUserEmail(!!email ? email : userEmail));
					yield put(actions.setMemberType(AddMemberType.InOrgUserUnConfirmed));
				}
			}
		}
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleRemoveMember(
	action: PayloadAction<APIt.memberInput>
): SagaIterator {
	try {
		yield call(
			[API, 'graphql'],
			graphqlOperation(removeMember, { member: action.payload })
		);
		yield put(actions.removeMemberSuccess(action.payload));
		toast.success('Member Removed!');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleAddMember(
	action: PayloadAction<APIt.memberInput>
): SagaIterator {
	try {
		yield call(
			[API, 'graphql'],
			graphqlOperation(addMember, { member: action.payload })
		);
		yield call(toast.success, 'Member invitation sent!');
		history.push('/members');
		const { authContainer, organizationsContainer } = yield select();
		const userEmail = authContainer?.cognitoUser?.email;
		const userGroup = organizationsContainer?.selectedOrganization?.group;
		yield put(
			actions.getMembers({
				userEmail,
				userGroup,
			})
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* membersSaga() {
	yield takeLatest(actions.getMembers.type, handleGetMembers);
	yield takeLatest(actions.addMember.type, handleAddMember);
	yield takeLatest(actions.removeMember.type, handleRemoveMember);
	yield takeLatest(actions.identifyUser.type, handleIdentifyUser);
}
