/**
 *
 * Footer
 *
 */
import React from 'react';
import LogoPNG from './footerLogo.png';
import { NavLink } from 'react-router-dom';

import styles from './style.module.scss';

interface Props {}

export function Footer(props: Props) {
	return (
		<div className={styles.footerLogo}>
			<NavLink to="/">
				<img src={LogoPNG} alt="Nav Brand" />
			</NavLink>
		</div>
	);
}
