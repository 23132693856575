import React, { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography as MuiTypography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Divider } from '@material-ui/core';

import { Subjects, Status } from 'API';
import { actions } from 'app/containers/NotificationsContainer/slice';
import styles from './style.module.scss';

interface Props {
	id: string;
	subjectId: string;
	subjectType: string;
	message: string;
	timing: string;
	type: Status;
}

function getNotificationURL(
	subjectType: string,
	subjectId: string
): string | null {
	switch (subjectType) {
		case Subjects.INTEGRATION:
			return '/integrations';
		default:
			return null;
	}
}

export const Notifications = (props: Props) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { subjectType, subjectId, id, message, timing, type } = props;
	const url = getNotificationURL(subjectType, subjectId);
	const onNotificationClick = () => {
		url && history.push(url);
	};
	const dismissNotification = (event: MouseEvent, id: string) => {
		event.stopPropagation();
		dispatch(actions.dismissNotification(id));
	};

	return (
		<>
			<div onClick={onNotificationClick} className={styles.notifList}>
				<div>
					<span
						className={styles.round}
						style={
							type === 'SUCCESS'
								? { backgroundColor: 'green' }
								: { backgroundColor: 'orange' }
						}
					></span>
				</div>
				<div className={styles.content}>
					<MuiTypography variant="body2" className={styles.notiMsg}>
						{message}
					</MuiTypography>
					<MuiTypography variant="body2" className={styles.timing}>
						{new Date(timing).toLocaleString()}
					</MuiTypography>
				</div>
				<CloseIcon
					onClick={event => dismissNotification(event, id)}
					className={styles.close}
				/>
			</div>
			<Divider />
		</>
	);
};
