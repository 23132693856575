import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import moment, { Moment } from 'moment';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography as MuiTypography } from '@material-ui/core';
import { actions } from '../slice';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { selectedGoal } from 'app/containers/GoalContainer/selectors';
import styles from './style.module.scss';
import { Loader } from 'app/components/Loader';
import { DatePickerComp } from 'app/components/DatePickerComp';
import _ from 'lodash';
import { AreaChart, generateScatterPlot, Data } from 'app/components/AreaChart';
import { layout } from 'app/components/AreaChart/fakeData.js';

export const GoalReport = () => {
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const { params } = match;
	const { id } = params as any;
	const organization = useSelector(selectedOrganization);
	const selected = useSelector(selectedGoal);
	const today = moment();
	const [endDate, setEndDate] = useState<Moment>(today);
	const [startDate, setStartDate] = useState<Moment>(
		today.clone().subtract(30, 'days')
	);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<Data[]>([]);

	useEffect(() => {
		dispatch(
			actions.getGoal({
				id: id,
			})
		);
	}, [dispatch, id]);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const params = new URLSearchParams();
				params.append('organization_id', organization?.id);
				params.append('event_name', selected?.event?.name);
				params.append('start_time', startDate.format('YYYY-MM-DD'));
				params.append('end_time', endDate.format('YYYY-MM-DD'));
				const response = await fetch(
					`${
						process.env.REACT_APP_REPORT_ENDPOINT
					}/v2_goals/goals-stats-by-date?${params.toString()}`,
					{
						cache: 'no-cache',
					}
				);
				const json = await response.json();
				const sortedData = orderBy(
					json.rows,
					d => moment(d.date).format('YYYYMMDD'),
					['asc']
				);
				const plotData = generateScatterPlot(sortedData, 'date', 'conversions');
				setData(plotData);
			} catch (err) {
				setData([]);
			}
			setLoading(false);
		};
		fetchData();
	}, [startDate, endDate, organization.id, organization, selected]);

	if (!_.isEmpty(selected)) {
		return (
			<div className={styles.reportWrapper}>
				<div className={styles.wrapper}>
					<MuiTypography
						variant="h3"
						className={styles.header}
						style={{ padding: '0px 24px' }}
					>
						{selected.name}
					</MuiTypography>
					<MuiTypography variant="body2">{selected.description}</MuiTypography>
				</div>
				<div className={styles.statsDateWrapper}>
					<div className={styles.DatePickerWrapper}>
						<DatePickerComp
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							startDateLimit={today}
						/>
					</div>
				</div>
				<div>
					{!loading && (
						<AreaChart
							data={data}
							layout={layout([startDate.toISOString(), endDate.toISOString()])}
						/>
					)}
					{loading && <Loader />}
				</div>
			</div>
		);
	}
	return null;
};
