import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { actions, reducer, sliceKey } from './slice';
import { selectMembers, selectLoadingState } from './selectors';
import Modal from '@material-ui/core/Modal';
import { membersSaga } from './saga';
import _ from 'lodash';
import { Typography as MuiTypography } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import styles from './style.module.scss';
import { AddMember } from './AddMember';
import { Loader } from 'app/components/Loader';
import { getCognitoAttribute } from './utility';
//table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';

import { selectCognitoUser } from 'app/containers/AuthContainer/selectors';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';

import { MemberEditor } from './memberEditor';
import RichTextConverter from 'app/components/RichTextConverter';

export const Members = memo(() => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: membersSaga });
	const [t, i18n] = useTranslation();
	const match = useRouteMatch();
	const history = useHistory();
	const members = useSelector(selectMembers);
	const loading = useSelector(selectLoadingState);
	const [filteredData, setFilteredData] = useState([] as any);

	const org = useSelector(selectedOrganization);
	const dispatch = useDispatch();
	const cognitoUser = useSelector(selectCognitoUser);
	const userEmail = cognitoUser && cognitoUser?.email;
	const userSub = cognitoUser && cognitoUser?.sub;
	const handleAddMemberNavigation = () => {
		history.push(`${match.url}/add`);
	};

	const handleSearch = e => {
		if (e.target.value.length >= 3) {
			const updatedData =
				members &&
				members.filter(data => {
					const memberName = getCognitoAttribute(data, 'name');
					const memberEmail = getCognitoAttribute(data, 'email');
					return (
						(memberName &&
							memberName
								.toLowerCase()
								.includes(e.target.value.toLowerCase())) ||
						(memberEmail &&
							memberEmail.toLowerCase().includes(e.target.value.toLowerCase()))
					);
				});
			setFilteredData(updatedData);
		} else {
			//show all members
			setFilteredData(members);
		}
	};

	const removeMember = userInfo => {
		const { Username } = userInfo;
		dispatch(
			actions.removeMember({
				userEmail: Username,
				userGroup: org?.group,
			})
		);
	};

	const addMember = userInfo => {
		const { userName, userEmail } = userInfo;
		dispatch(
			actions.addMember({
				userEmail,
				userName,
				userGroup: org?.group,
			})
		);
		handleCloseAddMember();
	};

	const identifyUser = userInfo => {
		const { userName, userEmail } = userInfo;
		dispatch(
			actions.identifyUser({
				userEmail,
				userName,
				userGroup: org?.group,
			})
		);
	};

	const handleCloseAddMember = () => {
		history.push(`${match.url}`);
	};

	useEffect(() => {
		org?.group &&
			dispatch(
				actions.getMembers({
					userGroup: org?.group,
					userEmail: userEmail,
				})
			);
	}, [dispatch, cognitoUser, userEmail, org]);

	useEffect(() => {
		setFilteredData(members);
	}, [members]);

	return (
		<>
			<div className={styles.titleWrapper}>
				<MuiTypography variant="h3">{t('members_title')}</MuiTypography>
				<MuiTypography variant="body2">
					<RichTextConverter
						html={t('members_description')}
						translationKey={'members_description'}
					></RichTextConverter>
				</MuiTypography>
			</div>
			<div className={styles.searchWrapper}>
				<div className={styles.search}>
					<span className={styles.searchIcon}>
						<SearchIcon />
					</span>
					<InputBase
						placeholder={t('members_search_member_placeholder')}
						inputProps={{
							'aria-label': 'search',
							'data-translation-key': 'members_search_member_placeholder',
						}}
						className={styles.searchInput}
						onChange={e => handleSearch(e)}
					/>
				</div>
				<Button
					type="button"
					onClick={handleAddMemberNavigation}
					variant="contained"
					data-translation-key="members_add_member"
				>
					{t('members_add_member')}
				</Button>
			</div>
			<div className={styles.tableWrapper}>
				{loading ? (
					<Loader style={{ minHeight: '400px' }} />
				) : !filteredData || !filteredData.length ? (
					<div className={styles.noMembersWrapper}>
						<div>
							<span
								style={{ margin: '10px 0px' }}
								data-translation-key="members_search_no_member_found_description"
							>
								{t('members_search_no_member_found')}
							</span>
							<MuiTypography
								variant="body2"
								data-translation-key="members_search_no_member_found_description"
							>
								{t('members_search_no_member_found_description')}
							</MuiTypography>
							<Button
								type="button"
								onClick={handleAddMemberNavigation}
								variant="contained"
								data-translation-key="members_add_member"
							>
								{t('members_add_member')}
							</Button>
						</div>
					</div>
				) : (
					<Paper className={styles.wrapperPaper}>
						<TableContainer component={Paper}>
							<Table className={styles.table} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Email</TableCell>
										<TableCell>Status</TableCell>
										<TableCell>Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredData.map((item, index) => {
										return (
											<TableRow key={index}>
												<TableCell>
													{getCognitoAttribute(item, 'name') ||
														getCognitoAttribute(item, 'email')}
												</TableCell>
												<TableCell>
													{getCognitoAttribute(item, 'email')}
												</TableCell>
												<TableCell>{_.get(item, 'UserStatus')}</TableCell>
												<TableCell>
													{getCognitoAttribute(item, 'sub') !==
														_.get(org, 'orgOwner') &&
														getCognitoAttribute(item, 'sub') !==
															cognitoUser?.sub && (
															<MemberEditor
																userInfo={item}
																removeMember={removeMember}
																onAddMember={addMember}
															/>
														)}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				)}
			</div>

			<Switch>
				<Route
					path={`${match.url}/add`}
					render={() => {
						return (
							<Modal open={true} onClose={handleCloseAddMember}>
								<div className={styles.addMemberWrapper}>
									<span
										className={styles.closeBtn}
										data-testid="close-add-member"
										onClick={handleCloseAddMember}
									>
										<CloseIcon />
									</span>
									<AddMember
										onAddMember={addMember}
										onIdentifyMember={identifyUser}
									/>
								</div>
							</Modal>
						);
					}}
				/>
			</Switch>
		</>
	);
});
