import React from 'react';
import styles from './style.module.scss';

interface Props {
	value: string | Number;
}

export const Sup = (props: Props) => {
	const { value } = props;
	return (
		<sup className={styles.sup}>
			{Number(value) >= 0 ? (
				<span className={styles.supPos}>{`+${value}`}</span>
			) : (
				<span className={styles.supNeg}>{value}</span>
			)}
		</sup>
	);
};
