import React from 'react';

export function ReportIcon() {
	return (
		<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
			<title>Report</title>
			<desc>Created with sketchtool.</desc>
			<g
				id="Design"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g id="Goals" transform="translate(-615.000000, -307.000000)">
					<g id="Report" transform="translate(614.000000, 306.000000)">
						<rect
							id="Rectangle"
							fill="#000000"
							opacity="0"
							x="0"
							y="0"
							width="16"
							height="16"
						>
							<title>Report</title>
						</rect>
						<g
							id="stats"
							transform="translate(2.000000, 2.000000)"
							fill="#FF9A44"
							fillRule="nonzero"
							stroke="#FF9A44"
							strokeWidth="0.2"
						>
							<path
								d="M1.6922449,9.66367347 C1.77795918,9.72244898 2.10857143,9.88653061 2.3877551,9.53387755 L4.27836735,6.77142857 L6.27918367,8.70122449 C6.3844898,8.80163265 6.52897959,8.8555102 6.67591837,8.83836735 C6.82040816,8.82367347 6.95265306,8.7477551 7.03836735,8.6277551 L10.3567347,3.86938776 L10.4767347,5.46122449 C10.5061224,5.86530612 10.8857143,5.93142857 11.0130612,5.92163265 C11.2873469,5.89959184 11.4955102,5.66204082 11.4734694,5.38530612 L11.2628571,2.58612245 C11.2432653,2.31183673 11.0032653,2.10367347 10.7265306,2.12571429 L7.92734694,2.33632653 C7.65306122,2.35591837 7.44489796,2.59591837 7.46693878,2.87265306 C7.48653061,3.14693878 7.72408163,3.35265306 8.00326531,3.33306122 L9.59510204,3.21306122 L6.55346939,7.5722449 L4.5477551,5.63755102 C4.44244898,5.53469388 4.2955102,5.48326531 4.15102041,5.50040816 C4.00408163,5.51510204 3.87183673,5.59346939 3.78857143,5.71591837 L1.56244898,8.96816327 C1.40571429,9.19591837 1.4644898,9.50693878 1.6922449,9.66367347 L1.6922449,9.66367347 Z"
								id="Path"
							></path>
							<path
								d="M11.5004082,11.0008163 L0.999183673,11.0008163 L0.999183673,0.499591837 C0.999183673,0.222857143 0.776326531,0 0.499591837,0 C0.222857143,0 0,0.222857143 0,0.499591837 L0,11.5004082 C0,11.7771429 0.222857143,12 0.499591837,12 L11.5004082,12 C11.7771429,12 12,11.7771429 12,11.5004082 C12,11.2236735 11.7771429,11.0008163 11.5004082,11.0008163 Z"
								id="Path"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
