import React from 'react';

export function GoogleTagManagerIcon() {
	return (
		<svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
			<title>44F5325E-F3CA-451F-A09A-6EB40D638B03</title>
			<desc>Created with sketchtool.</desc>
			<g
				id="Design"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Settings---Integrations---Destinations"
					transform="translate(-688.000000, -462.000000)"
				>
					<g
						id="Google-Tag-Manager"
						transform="translate(688.000000, 462.000000)"
					>
						<path
							d="M10.2555408,-4.14336123e-16 L53.7444592,4.14336123e-16 C57.3105342,-2.4074122e-16 58.6036791,0.371302445 59.9073828,1.06853082 C61.2110865,1.76575919 62.2342408,2.78891348 62.9314692,4.09261719 C63.6286976,5.39632089 64,6.68946584 64,10.2555408 L64,53.7444592 C64,57.3105342 63.6286976,58.6036791 62.9314692,59.9073828 C62.2342408,61.2110865 61.2110865,62.2342408 59.9073828,62.9314692 C58.6036791,63.6286976 57.3105342,64 53.7444592,64 L10.2555408,64 C6.68946584,64 5.39632089,63.6286976 4.09261719,62.9314692 C2.78891348,62.2342408 1.76575919,61.2110865 1.06853082,59.9073828 C0.371302445,58.6036791 1.60494146e-16,57.3105342 -2.76224082e-16,53.7444592 L2.76224082e-16,10.2555408 C-1.60494146e-16,6.68946584 0.371302445,5.39632089 1.06853082,4.09261719 C1.76575919,2.78891348 2.78891348,1.76575919 4.09261719,1.06853082 C5.39632089,0.371302445 6.68946584,2.4074122e-16 10.2555408,-4.14336123e-16 Z"
							id="Rectangle"
							fill="#F5F7FB"
						></path>
						<g
							id="google-tag-manager"
							transform="translate(12.000000, 12.000000)"
							fillRule="nonzero"
						>
							<polygon
								id="Path"
								fill="#8AB4F8"
								points="23.4852287 38.4783488 16.5439093 31.5289357 31.4544719 16.466208 38.5236746 23.533792"
							></polygon>
							<path
								d="M23.5354108,8.54876568 L16.4678268,1.47956293 L1.48118171,16.466208 C-0.471064346,18.4168353 -0.472683124,21.5783084 1.47794415,23.5305544 C1.47956293,23.5321732 1.48118171,23.533792 1.48118171,23.533792 L16.4678268,38.5204371 L23.4010522,31.5548361 L12.0760826,20.0097127 L23.5354108,8.54876568 Z"
								id="Path"
								fill="#4285F4"
							></path>
							<path
								d="M38.5220558,16.466208 L23.5354108,1.47956293 C21.5831647,-0.472683124 18.4184541,-0.472683124 16.466208,1.47956293 C14.513962,3.43180898 14.513962,6.59651963 16.466208,8.5471469 L31.460947,23.533792 C33.413193,25.486038 36.5779037,25.486038 38.528531,23.533792 C40.480777,21.5815459 40.480777,18.4168353 38.528531,16.466208 L38.5220558,16.466208 Z"
								id="Path"
								fill="#8AB4F8"
							></path>
							<circle
								id="Oval"
								fill="#246FDB"
								cx="19.9465803"
								cy="35.0449211"
								r="4.95346014"
							></circle>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
