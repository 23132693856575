/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';

import { Switch, Router } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import 'react-toastify/dist/ReactToastify.css';
import { history } from 'utils/history';

// other route
import { Dashboard } from './containers/Dashboard';
import { NotFoundPage } from './components/NotFoundPage/Loadable';

// auth route
import AuthContainer, {
	PrivateRoute,
	PublicRoute,
	LoginContainer,
	SignUpContainer,
	CompleteSignUpContainer,
	ForgotPasswordContainer,
	ConfirmSignUp,
	NewPasswordRequired,
	ConfirmSignIn,
	AmplifyAuthStatus,
	ForgotPasswordSubmitContainer,
} from './containers/AuthContainer';

const App = () => {
	return (
		<Router history={history}>
			<Helmet titleTemplate="%s - Backstage App" defaultTitle="Backstage App">
				<meta name="description" content="Backstage App" />
			</Helmet>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable={false}
				pauseOnHover
			/>
			<AuthContainer>
				{() => (
					<Switch>
						<PublicRoute
							path={AmplifyAuthStatus.login}
							exact
							component={LoginContainer}
						/>
						<PublicRoute
							path={AmplifyAuthStatus.signUp}
							exact
							component={SignUpContainer}
						/>
						<PublicRoute
							path={AmplifyAuthStatus.completeSignUp}
							exact
							component={CompleteSignUpContainer}
						/>
						<PublicRoute
							path={AmplifyAuthStatus.confirmSignUp}
							exact
							component={ConfirmSignUp}
						/>
						<PublicRoute
							path={AmplifyAuthStatus.newPasswordRequired}
							exact
							component={NewPasswordRequired}
						/>
						<PublicRoute
							path={AmplifyAuthStatus.confirmSignIn}
							exact
							component={ConfirmSignIn}
						/>
						<PublicRoute
							path={AmplifyAuthStatus.forgotPassword}
							exact
							component={ForgotPasswordContainer}
						/>
						<PublicRoute
							path={AmplifyAuthStatus.forgotPasswordSubmit}
							exact
							component={ForgotPasswordSubmitContainer}
						/>
						<PrivateRoute path="/" component={Dashboard} />
						<PublicRoute component={NotFoundPage} />
					</Switch>
				)}
			</AuthContainer>
			<GlobalStyle />
		</Router>
	);
};

export default App;
