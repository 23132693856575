import React, { memo, useEffect } from 'react';
import { Tab, Tabs, AppBar } from '@material-ui/core';
import { TabPanel } from './TabPanel';
import { useHistory } from 'react-router-dom';
import styles from './style.module.scss';
import { isEmpty } from 'lodash';

export interface Data {
	label: string;
	content: React.ReactNode;
	tabUrlName?: string; //only pass if tabs has a route
	disabled?: boolean;
	translationKey?: string;
}
interface Props {
	tabs: Data[];
	page?: string; //pass to make current tab active with help of current url
	theme?: 'transparent';
	style?: React.CSSProperties;
	panelStyles?: React.CSSProperties;
	defaultTabValue?: number; //only pass if tab has no url and want to set a defautl tab
	changeDefaultTab?: Function; //pass to change tab defautlt value from parent
	centeredTab?: boolean;
}

export const a11yProps = (index: any, translationKey?: string) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
		'data-translation-key': translationKey,
	};
};

export const CustomTabs = memo((props: Props) => {
	const {
		tabs,
		page,
		theme,
		style,
		panelStyles,
		defaultTabValue,
		centeredTab,
		changeDefaultTab,
	} = props;
	const history = useHistory();

	let tabNameToIndex = {},
		indexToTabName = {};

	tabs.map((tabData, index) => {
		if (tabData.tabUrlName && tabData.tabUrlName.length > 0) {
			tabNameToIndex[tabData.tabUrlName] = index;
			indexToTabName[index] = tabData.tabUrlName;
		}
		return null;
	});

	const [value, setValue] = React.useState(0);

	// set tab value based on page params
	useEffect(() => {
		if (page && tabNameToIndex[page]) {
			setValue(tabNameToIndex[page]);
		}
	}, [page, tabNameToIndex, value]);

	//set tab value based on default Tab Value prop
	useEffect(() => {
		if (defaultTabValue !== undefined) {
			setValue(defaultTabValue);
		}
	}, [defaultTabValue]);

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		if (!isEmpty(indexToTabName)) {
			history.push(`${indexToTabName[newValue]}`);
		}
		setValue(newValue);
		//change default value in parent
		if (changeDefaultTab) {
			changeDefaultTab(newValue);
		}
	};

	return (
		<div
			className={
				theme === 'transparent'
					? styles.transparentTabWrapper
					: styles.tabWrapper
			}
			style={{ ...style }}
		>
			<AppBar
				position="static"
				className={
					theme === 'transparent' ? styles.transparentTabBar : styles.tabBar
				}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="simple tabs"
					data-testid="custom-tabs"
					className={
						theme === 'transparent' ? styles.transparentTabs : styles.tabs
					}
				>
					{tabs.length > 0
						? tabs.map((prop, key) => {
								return (
									<Tab
										label={prop.label}
										data-testid={`tab-${key}`}
										key={key}
										disabled={prop.disabled ?? false}
										{...a11yProps(key, prop?.translationKey)}
										className={centeredTab ? styles.centeredTab : styles.tab}
									/>
								);
						  })
						: null}
				</Tabs>
			</AppBar>
			{tabs.length
				? tabs.map((prop, key) => {
						return (
							<TabPanel
								key={key}
								data-testid={`panel-${key}`}
								value={value}
								index={key}
								theme={theme}
								style={{ ...panelStyles }}
							>
								{prop.content}
							</TabPanel>
						);
				  })
				: ''}
		</div>
	);
});
