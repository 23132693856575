import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CustomButton from 'app/components/Typos/ButtonComponent';
import cx from 'classnames';
import styles from './style.module.scss';
import audienceImg from 'app/containers/AudiencesContainer/firstAudience.png';
import { CheckedIcon } from 'app/components/CheckedIcon';

interface Props {
	list: any[];
	values: { [key: string]: boolean };
	setValues: Function;
	handleSubmit: () => void;
	cancel: () => void;
	objectName: string;
}

export const SelectedList = ({
	list,
	values,
	setValues,
	handleSubmit,
	cancel,
	objectName,
}: Props) => {
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState('');

	const filteredList = list
		.filter(item => (open ? true : values[item?.id ?? '']))
		.filter(item => item?.name?.toLowerCase().includes(search.toLowerCase()));

	return (
		<div>
			<DialogTitle>Create Assignments</DialogTitle>
			<DialogContent className={styles.dialog}>
				{filteredList.length > 0 && (
					<div className={styles.selectedListHeader}>
						<TextField
							value={search}
							onChange={e => setSearch(e.target.value)}
							fullWidth
							placeholder="Search ..."
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon className={styles.searchIcon} />
									</InputAdornment>
								),
							}}
							className={styles.search}
						/>
						{!open && (
							<span className={styles.addIcon} onClick={() => setOpen(true)}>
								<AddIcon />
							</span>
						)}
					</div>
				)}
				<Divider />
				{filteredList.length <= 0 && (
					<NoneSelected
						open={() => setOpen(true)}
						title={
							open
								? `No ${objectName} created yet`
								: 'No assignments have been made yet'
						}
						subTitle={open ? '' : ''}
					/>
				)}
				<List values={values} list={filteredList} setValues={setValues} />
			</DialogContent>
			<DialogActions>
				{open ? (
					<CustomButton type="button" onClick={() => setOpen(false)} block>
						Done
					</CustomButton>
				) : (
					<>
						<Button
							fullWidth
							variant="contained"
							onClick={cancel}
							style={{ background: '#868dac' }}
						>
							Cancel
						</Button>
						<CustomButton type="button" onClick={handleSubmit} block>
							Save
						</CustomButton>
					</>
				)}
			</DialogActions>
		</div>
	);
};

const NoneSelected = ({ open, title, subTitle }) => (
	<div className={styles.noDataImported}>
		<span className={styles.noImgWrapper}>
			<img src={audienceImg} alt="audience img" />
		</span>
		<Typography variant="h5">{title}</Typography>
		<Typography variant="body2">{subTitle}</Typography>
		{open && (
			<span className={styles.addIcon} onClick={open}>
				<AddIcon />
			</span>
		)}
	</div>
);

const List = ({ list, values, setValues }) => {
	return (
		<div className={styles.wrapper}>
			{list.map(item => (
				<div key={item.id} className={styles.labelWrap}>
					<span className={styles.label}>{item.name}</span>
					<div className={styles.checkWrapper}>
						<Checkbox
							checked={values[item.id] || false}
							onChange={e => setValues({ ...values, id: e.target.checked })}
							disableRipple
							checkedIcon={<CheckedIcon />}
							className={cx({
								[styles.checkBox]: true,
								[styles.showSelected]: values[item.id] === true,
							})}
						/>
						<span className={styles.onHoverText}>
							{values[item.id] ? (
								<span
									className={styles.deselect}
									onClick={() => setValues({ ...values, [item.id]: false })}
								>
									Deselect
								</span>
							) : (
								<span
									className={styles.add}
									onClick={() => setValues({ ...values, [item.id]: true })}
								>
									Add
								</span>
							)}
						</span>
					</div>
				</div>
			))}
		</div>
	);
};

export default SelectedList;
