import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Typography as MuiTypography } from '@material-ui/core';
import styles from '../../style.module.scss';
import GoogleTagManagerForm from './Form';
import ButtonFooter from '../ButtonFooter';

interface Props {
	next: any;
	config: any;
}

const GoogleTagManager = ({ next, config }: Props) => {
	const [t, i18n] = useTranslation();
	console.log(config);

	return (
		<Formik initialValues={{}} onSubmit={() => next({ configuration: null })}>
			<Form className={styles.gtmForm}>
				<MuiTypography variant="h3">
					{t('setup_wizard_action_google_tag_manager')}
				</MuiTypography>
				<GoogleTagManagerForm />
				<ButtonFooter disabled={false} finalStep />
			</Form>
		</Formik>
	);
};

export default GoogleTagManager;
