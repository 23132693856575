import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { SelectedList } from 'app/components/SelectedList';
import { selectTriggers } from 'app/containers/TriggersContainer/selectors';
import { actions } from '../slice';
import { selectActionByID } from '../selectors';
import styles from './style.module.scss';

interface Props {
	cancel: () => void;
}

export const AssignTriggers = ({ cancel }: Props) => {
	const dispatch = useDispatch();
	const {
		params: { id },
	} = useRouteMatch();
	const action = useSelector(selectActionByID(id));
	const triggers = useSelector(selectTriggers);
	const selectedTriggers = action?.triggers?.items.map(i => i?.triggerId) ?? [];
	const initalValues = triggers.reduce((accum, trigger) => {
		accum[trigger.id ?? ''] = selectedTriggers.indexOf(trigger.id) !== -1;
		return accum;
	}, {});
	const [values, setValues] = useState(initalValues);

	const submit = () => {
		dispatch(
			actions.assignTriggers({
				group: action?.group,
				actionId: id,
				triggerIds: Object.keys(values).filter(id => values[id]),
			})
		);
		cancel();
	};

	return (
		<div className={styles.wrapper}>
			<SelectedList
				values={values}
				list={triggers}
				setValues={setValues}
				handleSubmit={submit}
				cancel={cancel}
				objectName="triggers"
			/>
		</div>
	);
};

export default AssignTriggers;
