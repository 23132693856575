/* --- STATE --- */
import * as APIt from 'API';

export interface cognitoUserAttribute {
	Name: string;
	Value: string;
}
export interface cognitoUser {
	Username: string;
	Attributes: [cognitoUserAttribute];
	UserCreateDate: string;
	UserLastModifiedDate: string;
	Enabled: boolean;
	UserStatus: string;
}
export interface MembersState {
	members: [cognitoUser] | null;
	loading: boolean;
	memberLoading: boolean;
	memberType: AddMemberType | undefined;
	userStatusMessage: string;
	userFetchedName: string;
	userPassedEmail: string;
	disableUserName: boolean;
	disableAddMember: boolean;
}

export enum AddMemberType {
	'NewUser' = 'NewUser',
	'InOrgUserConfirmed' = 'InOrgUserConfirmed',
	'InOrgUserUnConfirmed' = 'InOrgUserUnConfirmed',
	'NotInOrgUserConfirmed' = 'NotInOrgUserConfirmed',
	'NotInOrgUserUnConfirmed' = 'NotInOrgUserUnConfirmed',
	'OldOrgUserConfirmed' = 'OldOrgUserConfirmed',
}

export type ContainerState = MembersState;
