import React from 'react';
import Button from '@material-ui/core/Button';
import styles from './style.module.scss';

export interface Props {
	hasNext: boolean;
	handleNext: () => void;
	hasPrevious: boolean;
	handlePrevious: () => void;
}

const Pagination = ({
	hasNext,
	handleNext,
	hasPrevious,
	handlePrevious,
}: Props) => (
	<div className={styles.pagination}>
		<Button
			variant="contained"
			onClick={handlePrevious}
			disabled={!hasPrevious}
			fullWidth
		>
			Prev
		</Button>
		<Button
			variant="contained"
			onClick={handleNext}
			disabled={!hasNext}
			fullWidth
		>
			Next
		</Button>
	</div>
);

export default Pagination;
