import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { Typography } from '@material-ui/core';

interface Props {
	name: string;
	Icon: React.ReactNode;
}

export const NotActivated = (props: Props) => {
	const [t, i18n] = useTranslation();
	const { name, Icon } = props;

	return (
		<div className={styles.notActiveWrapper}>
			{Icon}
			<Typography variant="body2" className={styles.belowIconTxt}>
				{name} {t('integrations_not_yet_activated_msg')}
			</Typography>
		</div>
	);
};
