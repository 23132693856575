import React from 'react';
import { useTranslation } from 'react-i18next';
import { history } from 'utils/history';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';

import styles from './style.module.scss';
import CustomButton from 'app/components/Typos/ButtonComponent';
import TextTitle from 'app/components/Typos/TextTitle';
import TextComp from 'app/components/Typos/TextComponent';
import LinkComponent from 'app/components/Typos/LinkComponent';
import { AmplifyAuthStatus } from 'app/containers/AuthContainer/types';

import { actions } from '../slice';
import { selectFetchingData } from '../selectors';
import AuthWrapper from '../AuthWrapper';

export const LoginContainer = () => {
	const { email } = queryString?.parse(history?.location?.search);
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const isFetchingData: boolean = useSelector(selectFetchingData);
	const handleUserLogin = userInfo => {
		return dispatch(actions.login(userInfo));
	};

	return (
		<AuthWrapper>
			<div className={styles.LoginWrapper}>
				<TextTitle>{t('login_title')}</TextTitle>
				<TextComp>
					{t('login_page_no_account')}{' '}
					<LinkComponent link={AmplifyAuthStatus.signUp}>
						{t('globals_signup')}
					</LinkComponent>
				</TextComp>
				<Formik
					initialValues={{ username: email || '', password: '' }}
					onSubmit={(values, { setSubmitting }) => {
						handleUserLogin(values);
						setSubmitting(false);
					}}
					validationSchema={Yup.object().shape({
						username: Yup.string()
							.email(t('globals_email_invalid'))
							.required(t('globals_email_required')),
						password: Yup.string().required(t('globals_password_required')),
					})}
				>
					{({ isValid, dirty }) => {
						return (
							<Form>
								<Field
									type="text"
									name="username"
									label={t('globals_email')}
									component={TextField}
									placeholder={t('globals_email_placeholder')}
									fullWidth
									variant="outlined"
								/>
								<Field
									type="password"
									name="password"
									label={t('globals_password')}
									fullWidth
									component={TextField}
									placeholder={t('globals_password_placeholder')}
									variant="outlined"
								/>
								<div className={`form-group  ${styles.forgotSection}`}>
									<LinkComponent link={AmplifyAuthStatus.forgotPassword}>
										{t('login_page_forgot_password')}
									</LinkComponent>
								</div>
								<CustomButton
									type="submit"
									block
									disabled={isFetchingData || !(isValid && dirty)}
									loader={isFetchingData}
								>
									{t('globals_login')}
								</CustomButton>
							</Form>
						);
					}}
				</Formik>
			</div>
		</AuthWrapper>
	);
};
