import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { Typography as MuiTypography, Divider } from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styles from './style.module.scss';

interface GroupingProps {
	name: string;
	conditions: any[];
}

interface ConditionProps {
	displayName: string;
	item: any;
	type: 'conditions' | 'leaves';
}

export const SideBar = ({ conditions }) => {
	const groupings = Object.keys(conditions).map(key => (
		<Grouping key={key} name={key} conditions={conditions[key]} />
	));
	return <>{groupings}</>;
};

const Grouping = ({ name, conditions }: GroupingProps) => {
	const [expand, setExpand] = useState(false);
	const handleExpandToggle = () => setExpand(!expand);

	return (
		<div className={styles.indiComp}>
			<div className={styles.condName} onClick={handleExpandToggle}>
				<MuiTypography variant="h5">{name}</MuiTypography>
				<span>{expand ? <ExpandMoreIcon /> : <ChevronRightIcon />}</span>
			</div>
			<Divider />
			{expand && conditions.map(c => <Condition key={c.displayName} {...c} />)}
		</div>
	);
};

const Condition = (props: ConditionProps) => {
	const { displayName, item, type } = props;
	const [{ isDragging }, drag] = useDrag(() => ({
		type,
		item: item,
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	}));

	return (
		<>
			<div
				className={styles.condition}
				style={{ opacity: isDragging ? 0.5 : 1 }}
				ref={drag}
			>
				<MoreVertIcon />
				<MuiTypography variant="body2">{displayName}</MuiTypography>
			</div>
			<Divider />
		</>
	);
};
