import React from 'react';

export const UniversalAnalyticsIcon = () => (
	<svg
		width="64"
		height="64"
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<rect width="64" height="64" fill="url(#pattern0)" />
		<defs>
			<pattern
				id="pattern0"
				patternContentUnits="objectBoundingBox"
				width="1"
				height="1"
			>
				<use xlinkHref="#image0" transform="scale(0.0078125)" />
			</pattern>
			<image
				id="image0"
				width="128"
				height="128"
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAEJklEQVR4Ae2cwW4TQRBERxziXwlfbZMjJ/uOhPxFPjj3QQuOtGl5UERthyn3Q4rkWbHtnq7qqt4NTGv8kSvQ9+25H9qpH9prP7Q++c+S46m/tK/yxgnQ2g38y+Sg3yPlZckdDMUK3Dr/XoHnv7ZvR3H73G4i+yMyXkFQrICh9L8jg7h9bocAxTkAASDAO0l1I0Rx+PTtuwEe89UrUDxCLKjbujh8+vbdAI/56hUoHiEW1G1dHD59+26Ax3z1ChSPEAvqti4On759N8BjvnoFikeIBXVbF4dP374b4DFfvQLFI8SCuq2Lw6dv3w3wmK9egeIRYkHd1sXh07fvBnjMV69A8QixoG7r4vDp23cDPOarV6B4hFhQt3Vx+PTtuwEe89UrUDxCLKjbujh8+vbdAI/56hUoHiEW1G1dHD59+26Ax3z1ChSPEAvqti4On759N8BjvnoFikeIBXVbF4dP374b4DFfvQLFI8SCuq2Lw6dv3w3wmK9egeIRYkHd1sXh07fvBnjMV69A8QixoG7r4vDp23cDPOarV6B4hFhQt7UFfP3n03M/7079vHvt512f6mf+cwH/eoDF9AS4gX+ZCvQ1CSFALodunT9X10OAXNDX0aeUfQiwhij387TS/0YCLAACuE3+63xz0dsgOgqQe/r4BhDlhoAAEGDeJ4BlDmAGQAGcSZCL3gbRsQAsAAtItJkNejQ3BAqAAqAAKMBkvwF8ewvIU0Cu/C/RsQAsAAvAArCArHcN+RoufgMWgAVgAVgAFoAFrB+9Zvqc2J1ZoK/jig6dfzszADMAM0CiyuS3sPgNKAAKgAKgADwFrAe3LT+LAp1/OxaABWABWAAWsKXsr2Pla7j4DVgAFoAFYAFYwFq2t/wsCnT+7VgAFoAFYAFYwJayv46Vr+HiN2ABWAAWgAVgAWvZ3vKzKND5t2MBn2ABU5/DN9M//7qXS6I8b9npo1ht+nP47hV9pmv2BPhzAufcg9ZMgMdcHoAA8x2/Gos88/oBCED3KwSDABM/oinAfvReCAABRhO2w/X5///9Rzvxf/09FAAFcOj0UY4ogKocKAAKMOouh+soAApQvIMhAASQfuPIDFCcQBAAAjgMe6McGQKZAYp3MASAAAyBahdUvp8hsLiCQAAIMJqwHa7zFKDaFwqAAjh0+ihHFAAFKN7BEAAC8B5A7YLK9zMEFlcQCAABRhO2w3WeAlT7QgFQAIdOH+WIAqAAxTsYAkAA3gOoXVD5fobA4goCASDAaMJ2uM5TgGpfKAAK4NDpoxxRABSgeAdDAAjAewC1Cyrf/wBDIAdFKgT+lnuY82h42+j6dRkCT5IEKsV7hHu/f+kbgfH5cfbtyGHRKgl/PPX+YqkCl75vz78P/L+dGH7s590VNfiHoXghwaIEHkS49n07voH/C2jOqDYHbZhgAAAAAElFTkSuQmCC"
			/>
		</defs>
	</svg>
);
