import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField } from 'formik-material-ui';

import PasswordReveal from 'app/components/PasswordReveal';

export function AuthorizationFormFields({ values, setFieldValue }) {
	const [t, i18n] = useTranslation();

	return (
		<>
			<InputLabel
				htmlFor="api-host"
				data-translation-key={'integrations_marketo_authorization_api_host'}
			>
				{t('integrations_marketo_authorization_api_host')}
			</InputLabel>
			<Field
				id="api-host"
				type="text"
				name="apiHost"
				placeholder={t(
					'integrations_marketo_authorization_api_host_placeholder'
				)}
				component={TextField}
				fullWidth
				InputLabelProps={{ shrink: false }}
				variant="outlined"
			/>
			<InputLabel
				htmlFor="client-id"
				data-translation-key={'integrations_marketo_authorization_client_id'}
			>
				{t('integrations_marketo_authorization_client_id')}
			</InputLabel>
			<Field
				id="client-id"
				type="text"
				name="clientId"
				placeholder={t(
					'integrations_marketo_authorization_client_id_placeholder'
				)}
				component={TextField}
				fullWidth
				InputLabelProps={{ shrink: false }}
				variant="outlined"
			/>
			<InputLabel
				htmlFor="client-secret"
				data-translation-key={
					'integrations_marketo_authorization_client_secret'
				}
			>
				{t('integrations_marketo_authorization_client_secret')}
			</InputLabel>
			<PasswordReveal
				id="client-secret"
				name="clientSecret"
				placeholder={t(
					'integrations_marketo_authorization_client_secret_placeholder'
				)}
				fullWidth
				value={values.clientSecret}
				onChange={event => setFieldValue('clientSecret', event.target.value)}
			/>
		</>
	);
}
