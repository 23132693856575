import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { boolean } from 'yargs';
import { ContainerState, AddMemberType } from './types';
// The initial state of the Members container
export const initialState: ContainerState = {
	members: null,
	loading: false,
	memberLoading: false,
	memberType: undefined,
	userStatusMessage: '',
	userFetchedName: '',
	userPassedEmail: '',
	disableUserName: true,
	disableAddMember: true,
};

const membersSlice = createSlice({
	name: 'members',
	initialState,
	reducers: {
		getMembers(state, action: PayloadAction<any>) {
			state.loading = true;
		},
		getMembersSuccess(state, action) {
			state.members = action.payload as any;
			state.loading = false;
		},
		addMember(state, action: PayloadAction<any>) {
			state.loading = true;
		},
		addMemberSuccess(state, action) {
			//state.members = action.payload as any;
			state.loading = false;
		},
		identifyUser(state, action: PayloadAction<any>) {
			state.memberLoading = true;
			state.memberType = undefined;
			state.userFetchedName = '';
			state.userPassedEmail = action.payload?.userEmail;
		},
		identifyUserSuccess(state) {
			state.memberLoading = false;
		},
		removeMember(_state, _action: PayloadAction<any>) {},
		removeMemberSuccess(state, action) {
			state.members = (state?.members || []).filter(
				m => m.Username !== action.payload.userEmail
			) as any;
		},
		searchMember(state, action) {},
		setUserStatusMsg(state, action) {
			state.userStatusMessage = action.payload;
		},
		setUserFetchedName(state, action) {
			state.userFetchedName = action.payload;
		},
		setUserEmail(state, action) {
			state.userPassedEmail = action.payload;
		},
		setDisableUserName(state, action) {
			state.disableUserName = action.payload;
		},
		setDisableAddMember(state, action) {
			state.disableAddMember = action.payload;
		},
		setMemberType(state, action) {
			state.memberType = action.payload;
		},
		resetAddMemberState(state) {
			state.userPassedEmail = '';
			state.userFetchedName = '';
			state.memberType = undefined;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
	},
});

export const { actions, reducer, name: sliceKey } = membersSlice;
