import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../slice';
import { selectFetchingData } from '../selectors';
import AuthWrapper from '../AuthWrapper';
import styles from './style.module.scss';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import TextTitle from 'app/components/Typos/TextTitle';
import CustomButton from 'app/components/Typos/ButtonComponent';
import LinkComponent from 'app/components/Typos/LinkComponent';
import { AmplifyAuthStatus } from 'app/containers/AuthContainer/types';

interface Props {
	path: string;
}

export const ConfirmSignIn = (prpos: Props) => {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const isFetchingData: boolean = useSelector(selectFetchingData);
	const submitForm = userInfo => {
		return dispatch(actions.confirmSignIn(userInfo));
	};
	return (
		<AuthWrapper>
			<div className={styles.confirmWrapper}>
				<TextTitle>{t('confirm_signin_title')}</TextTitle>
				<Formik
					initialValues={{
						code: '',
					}}
					validationSchema={Yup.object().shape({
						code: Yup.string().required(
							t('auth_common_confirmation_code_required')
						),
					})}
					onSubmit={(values, { setSubmitting }) => {
						submitForm(values);
						setSubmitting(false);
					}}
				>
					{({ values, errors, touched, isValid, dirty, isSubmitting }) => {
						return (
							<Form>
								<Field
									type="text"
									name="code"
									placeholder={t('auth_common_confirmation_code_placeholder')}
									label={t('auth_common_confirmation_code')}
									component={TextField}
									fullWidth
									variant="outlined"
								/>

								<div className={`mb-3 ${styles.confirmBtnWrapper}`}>
									<LinkComponent link={AmplifyAuthStatus.login}>
										{t('auth_common_back_to_login')}
									</LinkComponent>
									<CustomButton
										type="submit"
										disabled={
											isSubmitting || !(isValid && dirty) || isFetchingData
										}
										loader={isFetchingData}
									>
										{t('globals_confirm')}
									</CustomButton>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</AuthWrapper>
	);
};
