import React from 'react';
import cx from 'classnames';
import styles from './style.module.scss';

interface Props {
	message: string;
	type: 'success' | 'error';
	translationKey?: string;
}

export const MessageTag = ({ message, type, translationKey = '' }: Props) => {
	return (
		<div
			className={cx({
				[styles.label]: true,
				[styles.success]: type === 'success',
				[styles.error]: type === 'error',
			})}
			data-translation-key={translationKey}
		>
			<span>{message}</span>
		</div>
	);
};
