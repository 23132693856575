import React from 'react';
import { Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import styles from '../../style.module.scss';

const UniversalAnalyticsForm = ({ values, errors, touched }) => (
	<Box mb={3}>
		<Field
			component={CheckboxWithLabel}
			type="checkbox"
			name="checkedDimension"
			Label={{ label: 'Set as Dimension' }}
			validate={(value: boolean) =>
				!value && !values.sendEvent ? 'Must make a selection' : null
			}
		/>
		{values.checkedDimension && (
			<>
				<InputLabel htmlFor="dimensionId">Dimension ID</InputLabel>
				<Field
					type="text"
					name="dimensionId"
					placeholder="e.g. 1"
					component={TextField}
					InputLabelProps={{ shrink: false }}
					fullWidth
					variant="outlined"
				/>
			</>
		)}
		<Divider />
		<Field
			component={CheckboxWithLabel}
			type="checkbox"
			name="sendEvent"
			Label={{ label: 'Send as Event' }}
			validate={(value: boolean) =>
				!value && !values.checkedDimension ? 'Must make a selection' : null
			}
		/>
		{errors.checkedDimension &&
			errors.sendEvent &&
			(touched.checkedDimension || touched.sendEvent) && (
				<p className={styles.errorMessage}>Must make a selection</p>
			)}
		{values.sendEvent && (
			<>
				<InputLabel htmlFor="eventCategory">Event Category</InputLabel>
				<Field
					type="text"
					name="category"
					placeholder="e.g. Funnelenvy"
					component={TextField}
					InputLabelProps={{ shrink: false }}
					fullWidth
					variant="outlined"
				/>
				<InputLabel htmlFor="eventAction">Event Action</InputLabel>
				<Field
					type="text"
					name="action"
					placeholder="e.g. Identity"
					component={TextField}
					InputLabelProps={{ shrink: false }}
					fullWidth
					variant="outlined"
				/>
				<InputLabel htmlFor="eventLabel">Event Label</InputLabel>
				<Field
					type="text"
					name="label"
					placeholder="e.g. Your Audience Destination Name"
					component={TextField}
					InputLabelProps={{ shrink: false }}
					fullWidth
					variant="outlined"
				/>
			</>
		)}
	</Box>
);

export default UniversalAnalyticsForm;
