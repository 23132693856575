import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import {
	Typography as MuiTypography,
	InputAdornment,
	TextField,
	DialogContent,
	Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { SelectedListFields } from './selectedListFields';
import { Lead, fetchLeadsFromMarketo } from './fetchLeadsFromMarketo';
import {
	isMarketoCredsConnected,
	selectAllDeselected,
} from 'app/containers/IntegrationsContainer/selectors';
import { actions } from 'app/containers/IntegrationsContainer/slice';
import styles from '../style.module.scss';
import dataImportStyles from './style.module.scss';
import { MessageTag } from '../../../components/MessageTag';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import AddIcon from '@material-ui/icons/Add';
import { isEmpty } from 'lodash';
import { Loader } from 'app/components/Loader';
import { NoSelectedList } from './NoSelectedList';

interface Props {
	credentials: any;
	selectedList: any[];
	leads: Lead[];
	lists: any;
}

export function DataImportForm({
	credentials,
	selectedList,
	leads,
	lists = [],
}: Props) {
	const [search, setSearch] = useState('');
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const isConnected = useSelector(isMarketoCredsConnected);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error | undefined>(undefined);
	const organization = useSelector(selectedOrganization);
	const isAllDeselected = useSelector(selectAllDeselected);

	useEffect(() => {
		let isMounted = true;
		const fetchData = async () => {
			if (leads.length > 0) return;
			try {
				setLoading(true);
				const data = await fetchLeadsFromMarketo(
					organization.id,
					credentials?.apiHost,
					credentials?.clientId,
					credentials?.clientSecret
				);
				if (isMounted) {
					dispatch(actions.setMarketoLeads(data.result));
					setLoading(false);
				}
			} catch (err) {
				setError(err);
			}
		};
		if (!isEmpty(credentials)) {
			fetchData();
		}
		return () => {
			isMounted = false;
		};
	}, [organization.id, leads, credentials, dispatch]);

	//merge both marketo list and to be marketo list
	let mergedList;

	if (selectedList.length > 0) {
		mergedList = [...selectedList];
	} else {
		mergedList = [...lists, ...selectedList];
	}
	//if all the saved list are deselected
	if (isAllDeselected) {
		mergedList = [];
	}

	const initialValues = mergedList.reduce((accum, list) => {
		accum[list.externalId] = true;
		return accum;
	}, {});

	const onKeyDown = (keyEvent: any) => {
		if (keyEvent.key.toLowerCase() === 'enter') {
			keyEvent.preventDefault();
		}
	};

	if (loading) return <Loader />;

	//show authorize first msg if credentials not verified
	if (!isConnected && isEmpty(credentials)) {
		return (
			<div className={dataImportStyles.tagWrapper}>
				<MessageTag
					type="error"
					message={
						'Please check the authorization before using this functionality.'
					}
				/>
			</div>
		);
	}
	//if no list available, show not list and add list button
	if ((isConnected || !isEmpty(credentials)) && mergedList.length === 0) {
		return (
			<NoSelectedList onAddClick={() => dispatch(actions.setListOpen(true))} />
		);
	} else {
		return (
			<>
				<Formik
					enableReinitialize
					initialValues={{ ...initialValues }}
					onSubmit={() => {}}
				>
					{({ setValues, values }) => (
						<Form className={styles.formWrapper} onKeyDown={onKeyDown}>
							<DialogContent className={styles.addListContent}>
								<div className={styles.selectedListHeader}>
									<MuiTypography variant="h5">Selected lists</MuiTypography>
									<TextField
										value={search}
										onChange={e => setSearch(e.target.value)}
										fullWidth
										placeholder={t(
											'integrations_marketo_data_import_search_placeholder'
										)}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon className={dataImportStyles.searchIcon} />
												</InputAdornment>
											),
										}}
										className={styles.search}
									/>
									<span
										className={styles.addIcon}
										onClick={() => dispatch(actions.setListOpen(true))}
									>
										<AddIcon />
									</span>
								</div>
								<Divider />
								<SelectedListFields
									search={search}
									values={values}
									setValues={setValues}
									lists={mergedList}
									leads={leads}
								/>
							</DialogContent>
						</Form>
					)}
				</Formik>
			</>
		);
	}
}
