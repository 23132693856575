import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { Typography as MuiTypography } from '@material-ui/core';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { actions as audienceActions } from 'app/containers/AudiencesContainer/slice';
import { actions as triggerActions } from 'app/containers/TriggersContainer/slice';
import CustomButton from 'app/components/Typos/ButtonComponent';
import { Operand } from './Operands';
import { Leaf } from './Leaves';
import { ConditionType } from 'API';
import { Bucket } from './Operands/Bucket';
import { ParentObject } from './types';
import style from './style.module.scss';
import {
	getConditionsFromString,
	findNodeAndUpdate,
	findNodeAndRemove,
	findTargetAndAddNode,
	formatConditionsForSave,
} from './utils';

interface Props {
	editable?: boolean;
	parentObject: ParentObject;
	conditions: string | null;
}

export const Conditions = ({
	conditions,
	parentObject,
	editable = false,
}: Props) => {
	const history = useHistory();
	const match = useRouteMatch<{ id: string }>();
	const dispatch = useDispatch();
	const organization = useSelector(selectedOrganization);
	const [conditionState, setConditionState] = useState(
		getConditionsFromString(conditions)
	);
	const { params } = match;

	const handleConditionChange = (
		node: any,
		target: any,
		wrapLeave: boolean = false
	) => {
		// Handle if condition is empty
		if (_.isEmpty(target)) {
			setConditionState({ ...node, id: uuidv4() });
			return;
		}
		if (node?.id && !wrapLeave) {
			setConditionState((condition: any) =>
				findNodeAndRemove(condition, node.id)
			);
		}
		const addNode = node?.id ? node : { ...node, id: uuidv4() };
		setConditionState((condition: any) =>
			findTargetAndAddNode(condition, target, addNode)
		);
	};

	const handleConditionUpdate = (id: string, node: any) => {
		setConditionState((condition: any) =>
			findNodeAndUpdate(id, node, condition)
		);
	};

	const handleRemoveNode = (nodeId: string) => {
		setConditionState((condition: any) => findNodeAndRemove(condition, nodeId));
	};

	const cancel = () => history.goBack();
	const saveConditions = () => {
		const conditions = formatConditionsForSave(conditionState);
		if (parentObject === ParentObject.Audience) {
			dispatch(
				audienceActions.addConditional({
					audienceId: params.id,
					input: conditions,
				})
			);
		} else if (parentObject === ParentObject.Trigger) {
			const trigger: any = {
				group: organization.group,
				organizationId: organization.id,
				conditions,
			};
			if (params.id) trigger.id = params.id;
			dispatch(triggerActions.modifyTrigger(trigger));
		}
		cancel();
	};

	if (_.isEmpty(conditionState) && editable) {
		return (
			<Bucket
				editable
				handleDropWithTarget={(item: any) => handleConditionChange(item, {})}
			/>
		);
	} else if (_.isEmpty(conditionState)) {
		return <MuiTypography variant="body2">Condition is empty.</MuiTypography>;
	}
	return (
		<>
			<Condition
				{...conditionState}
				handleDrop={handleConditionChange}
				handleRemove={handleRemoveNode}
				handleUpdate={handleConditionUpdate}
				parentObject={parentObject}
				editable={editable || false}
			/>
			{editable && (
				<div className={style.buttonBlock}>
					<CustomButton block onClick={cancel}>
						Cancel
					</CustomButton>
					<CustomButton block onClick={saveConditions}>
						Save
					</CustomButton>
				</div>
			)}
		</>
	);
};

export const Condition = props => {
	const { handleDrop, handleUpdate, ...rest } = props;
	switch (props.typeOf) {
		case ConditionType.OR:
		case ConditionType.AND:
		case ConditionType.NOT:
			return (
				<Operand
					{...rest}
					handleDrop={handleDrop}
					handleUpdate={handleUpdate}
				/>
			);
		case ConditionType.MARKETO:
		case ConditionType.ATTRIBUTE:
		case ConditionType.GOAL:
		case ConditionType.AUDIENCE:
		case ConditionType.EVENT:
		case ConditionType.PAGE:
			return (
				<Leaf {...rest} handleUpdate={handleUpdate} handleDrop={handleDrop} />
			);
		default:
			return null;
	}
};

export default Conditions;
