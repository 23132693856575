import React, { ReactElement, ReactNode } from 'react';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './style.module.scss';

interface Props {
	feature?: string;
	variant?: 'contained' | 'text' | 'outlined';
	disabled?: boolean;
	style?: object;
	startIcon?: ReactElement;
	endIcon?: ReactElement;
	loader?: boolean;
	children: ReactNode;
	block?: boolean;
	type?: 'button' | 'submit' | 'reset';
	onClick?: () => void;
}
const CustomButton = ({
	feature,
	disabled,
	children,
	block,
	type = 'button',
	style,
	startIcon,
	endIcon,
	loader,
	variant = 'contained',
	onClick,
}: Props) => {
	return (
		<Button
			variant={variant}
			disabled={disabled}
			className={
				feature === 'conditional-btn'
					? styles.conditionalButton
					: feature === 'icon-with-btn'
					? styles.iconWithBtn
					: styles.defaultBtn
			}
			style={style}
			startIcon={startIcon}
			endIcon={endIcon}
			fullWidth={block}
			type={type}
			onClick={onClick}
		>
			{loader ? (
				<>
					{' '}
					{children} <CircularProgress className={styles.loader} />
				</>
			) : (
				children
			)}
		</Button>
	);
};

export default CustomButton;
