/**
 *
 * JourneysContainer
 *
 */

import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey } from './slice';
import { selectJourneysContainer } from './selectors';
import { journeysContainerSaga } from './saga';

interface Props {}

export const JourneysContainer = memo((props: Props) => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: journeysContainerSaga });

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const journeysContainer = useSelector(selectJourneysContainer);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const dispatch = useDispatch();

	return (
		<>
			<div>Journeys Container</div>
		</>
	);
});
