import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Typography as MuiTypography,
	Divider,
	Button,
} from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from './style.module.scss';
import { actions } from '../slice';
import { selectedGoal, selectFetchGoalLoading } from '../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'app/components/Loader';
import _ from 'lodash';
import CheckIcon from '@material-ui/icons/Check';

export const ViewGoal = props => {
	const history = useHistory();
	const [t, i18n] = useTranslation();
	const match = useRouteMatch();
	const { params } = match;
	const { id } = params as any;
	const dispatch = useDispatch();
	const selected = useSelector(selectedGoal);
	const loading = useSelector(selectFetchGoalLoading);

	const handleEditGoal = () => {
		history.push(`edit-goal/${id}`);
	};

	useEffect(() => {
		dispatch(
			actions.getGoal({
				id: id,
			})
		);
	}, [dispatch, id]);

	if (loading) {
		return <Loader />;
	}
	if (!_.isEmpty(selected)) {
		return (
			<div className={styles.viewWrapper}>
				<div className={styles.wrapper}>
					<div className={styles.headWrapper}>
						<MuiTypography variant="h3" className={styles.header}>
							{selected.name}
						</MuiTypography>
						<Button
							type="button"
							onClick={handleEditGoal}
							className={styles.edit}
						>
							{t('goals_view_edit_goal')}
						</Button>
					</div>
					<MuiTypography variant="body2">
						{selected.includeInRevenue ? (
							<span style={{ color: '#79CD04' }} className={styles.include}>
								<span className={styles.check} style={{ color: '#79CD04' }}>
									<CheckIcon />
								</span>
								{t('goals_view_including_in_revenue_journey')}
							</span>
						) : (
							''
						)}
					</MuiTypography>
				</div>
				<Divider />
				<div className={styles.wrapper}>
					<div style={{ display: 'flex' }}>
						<MuiTypography variant="body2" className={styles.left}>
							{t('goals_view_description')}
						</MuiTypography>
						<span>{selected.description}</span>
					</div>
				</div>
				<Divider />
				<div className={styles.wrapper}>
					<div style={{ display: 'flex' }}>
						<MuiTypography variant="body2" className={styles.left}>
							{'Source'}
						</MuiTypography>
						<span>{selected.type}</span>
					</div>
				</div>
				<Divider />
				<div className={styles.wrapper}>
					<div style={{ display: 'flex' }}>
						<MuiTypography variant="body2" className={styles.left}>
							{t('goals_view_goal_value')}
						</MuiTypography>
						<span>{selected.defaultGoalValue}</span>
					</div>
				</div>
				<Divider />

				<div className={styles.wrapper}>
					{/* <MuiTypography variant="h3" className={styles.goalsTriggering}>
						{t('goals_view_goal_triggering')}
					</MuiTypography>
					<div className={styles.urlsView}>
						<MuiTypography variant="body2" className={styles.left}>
							{t('goals_view_including_urls')}
						</MuiTypography>
						<div style={{ width: '100%' }}>
							{selected?.includeUrls?.map((list, i) => (
								<div key={i} className={styles.urlCont}>
									<span>{list.urls}</span>
									<span>{list.matchType}</span>
								</div>
							))}
						</div>
					</div> */}

					{/* <div className={styles.urlsView}>
						<MuiTypography variant="body2" className={styles.left}>
							{t('goals_view_excluding_urls')}
						</MuiTypography>
						<div style={{ width: '100%' }}>
							{selected?.excludeUrls?.map((list, i) => (
								<div key={i} className={styles.urlCont}>
									<span>{list.urls}</span>
									<span>{list.matchType}</span>
								</div>
							))}
						</div>
					</div> */}
				</div>
			</div>
		);
	}

	return null;
};
