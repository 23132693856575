import React from 'react';
import {
	useDrag,
	useDrop,
	DragSourceMonitor,
	DropTargetMonitor,
} from 'react-dnd';
import { Typography as MuiTypography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuidv4 } from 'uuid';

// Condition Leaves
import { Attribute } from './Condition/Attribute';
import { Goal } from './Condition/Goal';
import { Marketo } from './Condition/Marketo';
// Trigger Leaf
import { Audience } from './Trigger/Audience';
import { Goal as TriggerGoal } from './Trigger/Goal';
import { Event } from './Trigger/Event';
import { Page } from './Trigger/Page';

import { ConditionType } from 'API';
import { ParentObject } from '../types';
import styles from './style.module.scss';
import baseStyles from '../style.module.scss';

export const Leaf = (props: any) => {
	const {
		parentObject,
		handleUpdate,
		handleDrop,
		handleRemove,
		editable,
		...item
	} = props;
	const [{}, drag] = useDrag(() => ({
		type: 'leaves',
		item,
		canDrag: _monitor => editable || false,
		collect: (monitor: DragSourceMonitor) => ({
			opacity: monitor.isDragging() ? 0.4 : 1,
		}),
	}));

	const handleDropWithTarget = (item: any) => handleDrop(props, item, true);

	const [{ isOverCurrent }, drop] = useDrop(() => ({
		accept: 'conditions',
		canDrop: _monitor => editable || false,
		drop: (item: any, monitor) => {
			// Non-greedy bucket (inner bucket doesn't trigger outter buckets)
			if (monitor.didDrop()) return;
			handleDropWithTarget({ ...item, id: uuidv4() });
			return undefined;
		},
		collect: (monitor: DropTargetMonitor) => ({
			isOverCurrent: monitor.isOver({ shallow: true }),
		}),
	}));

	return (
		<div
			ref={drag}
			className={baseStyles.wrapConditional}
			style={{
				border:
					isOverCurrent && editable
						? '2px dashed #048cff'
						: '1px solid rgba(169, 182, 193, 0.5)',
			}}
		>
			<div ref={drop} className={baseStyles.componentHeader}>
				<MuiTypography variant="h6">{props.typeOf}</MuiTypography>
				{editable && (
					<span onClick={() => handleRemove(props.id)}>
						<DeleteIcon />
					</span>
				)}
			</div>
			<div className={styles.formWrapper}>
				{parentObject === ParentObject.Audience ? (
					<ConditionLeaf
						{...item}
						editable={editable}
						handleUpdate={handleUpdate}
					/>
				) : (
					<TriggerLeaf
						{...item}
						editable={editable}
						handleUpdate={handleUpdate}
					/>
				)}
			</div>
		</div>
	);
};

const ConditionLeaf = props => {
	const { editable, handleUpdate, ...node } = props;
	switch (node.typeOf) {
		case ConditionType.ATTRIBUTE:
			return (
				<Attribute {...node} editable={editable} handleUpdate={handleUpdate} />
			);
		case ConditionType.GOAL:
			return <Goal {...node} editable={editable} handleUpdate={handleUpdate} />;
		case ConditionType.MARKETO:
			return (
				<Marketo {...node} editable={editable} handleUpdate={handleUpdate} />
			);
		default:
			return null;
	}
};

const TriggerLeaf = props => {
	const { editable, handleUpdate, ...node } = props;
	switch (node.typeOf) {
		case ConditionType.AUDIENCE:
			return (
				<Audience {...node} editable={editable} handleUpdate={handleUpdate} />
			);
		case ConditionType.GOAL:
			return (
				<TriggerGoal
					{...node}
					editable={editable}
					handleUpdate={handleUpdate}
				/>
			);
		case ConditionType.EVENT:
			return (
				<Event {...node} editable={editable} handleUpdate={handleUpdate} />
			);
		case ConditionType.PAGE:
			return <Page {...node} editable={editable} handleUpdate={handleUpdate} />;
		default:
			return null;
	}
};

export default Leaf;
