import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';

// The initial state of the Dashboard container
export const initialState: ContainerState = {
	isLoaded: false,
	message: 'Old message',
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		someAction() {},
		toggleLoading(state) {
			state.isLoaded = !state.isLoaded;
		},
		updateMessage(state) {
			state.isLoaded = true;
		},
		updateMessageSuccess(state, action: PayloadAction<string>) {
			state.message = action.payload;
		},
	},
});

export const { actions, reducer, name: sliceKey } = dashboardSlice;
