import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state.journeysContainer || initialState;

export const selectJourneysContainer = createSelector(
	[selectDomain],
	journeysContainerState => journeysContainerState
);
