import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import { Formik, Form, Field } from 'formik';
import { Switch } from 'formik-material-ui';
import { FormControlLabel, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { actions } from 'app/containers/OrganizationsContainer/slice';
import * as APIt from 'API';

interface Props {
	org: APIt.UpdateOrganizationInput;
}

export const WebsiteAttribution = ({ org }: Props) => {
	const { activateWebsiteAttribution } = org;
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();

	return (
		<div className={styles.wrapper}>
			<Formik
				enableReinitialize
				initialValues={{
					activateWebsiteAttribution: activateWebsiteAttribution ?? false,
				}}
				onSubmit={fields => {
					if (org && org.id !== '') {
						dispatch(
							actions.updateOrganization({
								id: org.id,
								activateWebsiteAttribution: fields.activateWebsiteAttribution,
							})
						);
					}
				}}
			>
				{({ values, handleChange }) => {
					return (
						<Form>
							<div className={`${styles.switchWrapper}`}>
								<FormControlLabel
									label={t(
										'org_preferences_website_attribution_activation_label'
									)}
									labelPlacement="start"
									control={
										<Field
											label="switch"
											name="activateWebsiteAttribution"
											type="checkbox"
											component={Switch}
											onChange={handleChange}
											checked={values.activateWebsiteAttribution}
										/>
									}
								/>
							</div>

							<div className={`${styles.bottomSec}`}>
								<Button type="submit" variant="contained" fullWidth>
									{t('globals_save')}
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};
