import React, { ReactNode } from 'react';
import MuiTypography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import { ReportIcon } from 'app/components/ReportIcon';
import { ArchiveIcon } from 'app/components/ArchiveIcon';
import styles from './style.module.scss';

interface Props {
	id?: string;
	name?: string | null;
	description?: string | null;
	icon?: ReactNode;
	handleViewClick?: () => void;
	handleArchiveClick?: () => void;
	handleReportClick?: () => void;
}

const NameReportTableCell = ({
	id,
	name,
	description,
	icon,
	handleArchiveClick,
	handleReportClick,
	handleViewClick,
}: Props) => (
	<TableCell key={id} style={{ width: '600px' }}>
		<div className={styles.section}>
			<div className={styles.iconWrapper}>
				{icon}
				<div>
					<span className={styles.name} onClick={handleViewClick}>
						{name}
					</span>
					<br />
					{description && (
						<MuiTypography variant="body2" className={styles.description}>
							{description}
						</MuiTypography>
					)}
				</div>
			</div>
			<div style={{ width: '64px' }}>
				{handleReportClick && (
					<span data-testid={'reportIcon'} onClick={handleReportClick}>
						<ReportIcon />
					</span>
				)}
				{handleArchiveClick && (
					<span data-testid={'archiveIcon'} onClick={handleArchiveClick}>
						<ArchiveIcon />
					</span>
				)}
			</div>
		</div>
	</TableCell>
);

export default NameReportTableCell;
