import React from 'react';
import { createPortal } from 'react-dom';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import styles from './style.module.scss';

interface Props {
	children: React.ReactNode;
	onCloseModel: () => void;
	modalInnerWrapStyle?: object;
	closeStyle?: object;
	closeBtnStyle?: object;
}

export function ModalContainer(props: Props) {
	const [open, setOpen] = React.useState(true);
	const handleClose = () => {
		setOpen(false);
		setTimeout(() => {
			props.onCloseModel();
		}, 600);
	};
	const { children, modalInnerWrapStyle, closeStyle, closeBtnStyle } = props;
	return createPortal(
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={styles.modal}
				open={open}
				disableScrollLock={false}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<div
						className={styles.modalInnerWrapper}
						style={{ ...modalInnerWrapStyle }}
					>
						<span
							className={styles.closeBtn}
							data-testid="close-element"
							onClick={handleClose}
							style={{ ...closeBtnStyle }}
						>
							<CloseIcon style={{ ...closeStyle }} />
						</span>
						{children}
					</div>
				</Fade>
			</Modal>
		</div>,
		document.getElementById('modal_root') as HTMLElement
	);
}
