export interface Lead {
	id: string;
	name: string;
	nameLowerCase?: string;
	description?: string;
	smart: boolean;
}

export async function fetchLeadsFromMarketo(
	organizationId: string,
	apiHost?: string,
	clientId?: string,
	clientSecret?: string
): Promise<{ success: boolean; result: Lead[] }> {
	const response = await fetch(
		`${process.env.REACT_APP_MARKETO_LISTS_ENDPOINT}/marketo/list`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authentication: `Basic ${btoa(organizationId)}`,
			},
			body: JSON.stringify(
				apiHost && clientId && clientSecret
					? {
							apiHost,
							clientId,
							clientSecret,
					  }
					: {}
			),
		}
	);
	const data = await response.json();
	if (!data.success)
		throw new Error(
			'Failed to connect to marketo list source. Please double check your credentials.'
		);
	return data;
}
