import { API, graphqlOperation } from 'aws-amplify';
import { PayloadAction } from '@reduxjs/toolkit';
import { GraphQLResult } from '@aws-amplify/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { toast } from 'react-toastify';
import { select } from 'redux-saga/effects';
import { selectCognitoUser } from '../AuthContainer/selectors';
import { convertOrganizationToGroup } from 'utils/groupNameConvert';
import { getOrganization, listOrganizations } from 'graphql/queries';
import { updateOrganization } from 'graphql/mutations';
import * as APIt from 'API';
import { IOrganization } from './types';
import { errorMessageHandler } from 'utils/errorMessage';
import { actions } from './slice';

const errorMessage = (err: Error) =>
	errorMessageHandler(err, 'Error occurred fetching organization.');

export function* handleError(err: Error) {
	const errMessage = errorMessage(err);
	yield call(toast.error, errorMessage);
	yield put(actions.setOrganizationError(errMessage));
}

export function* handleFetchOrganizations(
	action: PayloadAction<APIt.ListActionsQueryVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.ListOrganizationsQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(listOrganizations, {
				...action.payload,
				filter: {
					...action.payload.filter,
					disabled: { ne: true },
				},
			})
		);
		let organizationList = data?.listOrganizations?.items;
		let Cognitouser = yield select(selectCognitoUser);

		let currentOrganization =
			organizationList &&
			organizationList.filter(
				d =>
					d &&
					d.group ===
						convertOrganizationToGroup(Cognitouser?.['custom:organization'])
			);
		if (!!currentOrganization?.length) {
			yield put(actions.setPermissionDenied(false));
			yield put(actions.setFetchingData(false));
			yield put(
				actions.setOrganizationsSuccess(({
					organizationList: organizationList,
					currentOrg: currentOrganization && currentOrganization[0],
				} as unknown) as IOrganization[])
			);
		} else if (organizationList && !!organizationList.length) {
			yield put(actions.setPermissionDenied(false));
			yield put(actions.setFetchingData(false));
			yield put(
				actions.setOrganizationsSuccess(({
					organizationList: organizationList,
					currentOrg: organizationList[0],
				} as unknown) as IOrganization[])
			);
		} else {
			yield put(actions.setFetchingData(false));
			yield put(actions.setPermissionDenied(true));
		}
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleGetOrganization(
	action: PayloadAction<APIt.GetOrganizationQueryVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.GetOrganizationQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(getOrganization, action.payload)
		);
		yield put(
			actions.selectOrganizationSuccess(data?.getOrganization as IOrganization)
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleUpdateOrganization(
	action: PayloadAction<APIt.UpdateOrganizationMutationVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.UpdateOrganizationMutation> = yield call(
			[API, 'graphql'],
			graphqlOperation(updateOrganization, { input: action.payload })
		);
		yield put(
			actions.updateOrganizationSuccess(
				data?.updateOrganization as IOrganization
			)
		);
		yield call(toast.success, 'Organization Updated');
	} catch (err) {
		yield call(handleError, err);
	}
}

function* handleSetCopied(action): SagaIterator {
	yield call(toast.success, 'Copied Successfully');
	yield put(actions.setCopiedSuccess());
}

export function* organizationContainerSaga() {
	yield takeLatest(actions.fetchOrganizations.type, handleFetchOrganizations);
	yield takeLatest(actions.fetchOrganization.type, handleGetOrganization);
	yield takeLatest(actions.updateOrganization.type, handleUpdateOrganization);
	yield takeLatest(actions.setCopied.type, handleSetCopied);
}
