import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import { InputLabel, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import { listIntegrations } from 'app/containers/IntegrationsContainer/selectors';

export const GoalConfiguration = values => {
	const [t, i18n] = useTranslation();
	const integrationsList = useSelector(listIntegrations);
	//filter sourced integrations
	const sourcedIntegrations = integrationsList?.filter(item => item.source);

	return (
		<div>
			<InputLabel htmlFor="name">{t('goals_add_goal_general_name')}</InputLabel>
			<Field
				type="text"
				name="name"
				placeholder={t('goals_add_goal_general_name_placeholder')}
				component={TextField}
				InputLabelProps={{ shrink: false }}
				fullWidth
				variant="outlined"
			/>
			<InputLabel htmlFor="description">
				{t('goals_add_goal_general_description')}
			</InputLabel>
			<Field
				type="text"
				name="description"
				placeholder={t('goals_add_goal_general_description_placeholder')}
				component={TextField}
				InputLabelProps={{ shrink: false }}
				inputProps={{
					style: { padding: '0', margin: '0' },
				}}
				fullWidth
				variant="outlined"
				multiline
				rows={3}
			/>
			<InputLabel htmlFor="type">
				{t('goals_add_goal_general_source')}
			</InputLabel>
			<Field
				type="text"
				component={TextField}
				select={true}
				name="source"
				placeholder={t('goals_add_goal_general_source_placeholder')}
				fullWidth
				variant="outlined"
			>
				{sourcedIntegrations ? (
					sourcedIntegrations?.map(source => (
						<MenuItem key={source?.provider} value={source?.provider}>
							{source?.name}
						</MenuItem>
					))
				) : (
					<MenuItem value={''}>{'No source'}</MenuItem>
				)}
			</Field>
			<InputLabel htmlFor="defaultGoalValue">
				{t('goals_add_custom_goal_default_value')}
			</InputLabel>
			<Field
				type="text"
				name="defaultGoalValue"
				placeholder={t('goals_add_custom_goal_default_value_placeholder')}
				component={TextField}
				InputLabelProps={{ shrink: false }}
				fullWidth
				variant="outlined"
			/>
		</div>
	);
};
