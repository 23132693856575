import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { INotification, ContainerState } from './types';

export const initialState: ContainerState = {
	error: undefined,
	notifications: [],
	fetchingData: false,
};

const notificationsContainerSlice = createSlice({
	name: 'notificationsContainer',
	initialState,
	reducers: {
		setNotificationError(state, action: PayloadAction<string>) {
			state.fetchingData = false;
			state.error = action.payload;
		},
		fetchNotifications(state, _action) {
			state.fetchingData = true;
		},
		fetchNotificationsSuccess(state, action: PayloadAction<INotification[]>) {
			state.fetchingData = false;
			state.notifications = action.payload;
		},
		dismissNotification(_state, _action) {},
		dismissNotificationsSuccess(state, action: PayloadAction<string>) {
			state.notifications = state.notifications.filter(
				n => n.id !== action.payload
			);
		},
	},
});

export const { actions, reducer, name: sliceKey } = notificationsContainerSlice;
