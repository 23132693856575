import {
	Typography as MuiTypography,
	Button,
	TableRow,
	TableCell,
	TableBody,
	Table,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch, Switch, Route, useHistory } from 'react-router-dom';
import { selectFeatureFlags } from '../AuthContainer/selectors';
import { ModalContainer } from 'app/containers/ModalContainer';
import { S3Uploader } from './S3Uploader';
import { HPEUploader } from './HPEUploader';
import styles from './style.module.scss';

interface AppRecord {
	route: string;
	name: string;
	featureFlag: boolean;
	element: any;
}

function getAppRoute(app: AppRecord, match: any) {
	return `${match.url}/${app.route}`;
}
export function AppsContainer() {
	const [t, i18n] = useTranslation();
	const { feAppsS3Uploader, feAppsHpeS3Uploader } = useSelector(
		selectFeatureFlags
	);

	const apps: AppRecord[] = [
		{
			route: 's3uploader',
			name: t('fe_app_s3_uploader_title'),
			featureFlag: feAppsS3Uploader,
			element: S3Uploader,
		},
		{
			route: 'hpeuploader',
			name: t('fe_app_hpe_uploader_title'),
			featureFlag: feAppsHpeS3Uploader,
			element: HPEUploader,
		},
	];

	const match = useRouteMatch();
	const history = useHistory();
	return (
		<>
			<div className={styles.appsContainer}>
				<MuiTypography
					variant="h1"
					className={styles.header}
					style={{ padding: '0px 24px' }}
					data-translation-key="header_apps"
				>
					{t('header_apps')}
				</MuiTypography>
				<Table>
					<TableBody>
						{apps.map(app => (
							<AppLink key={app.name} app={app} />
						))}
					</TableBody>
				</Table>
			</div>
			<Switch>
				{apps.map(app => (
					<Route
						key={app.name}
						path={getAppRoute(app, match)}
						render={() => (
							<ModalContainer onCloseModel={() => history.push(match.url)}>
								<app.element />
							</ModalContainer>
						)}
					/>
				))}
			</Switch>
		</>
	);
}

function AppLink({ app }: { app: AppRecord }) {
	const match = useRouteMatch();
	const history = useHistory();
	const handleLaunch = () => history.push(getAppRoute(app, match));

	return app.featureFlag ? (
		<TableRow className={styles.appLink}>
			<TableCell>
				<MuiTypography variant="h3" className={styles.title}>
					{app.name}
				</MuiTypography>
			</TableCell>
			<TableCell>
				<Button onClick={handleLaunch}>Launch</Button>
			</TableCell>
		</TableRow>
	) : (
		<TableRow></TableRow>
	);
}
