import { Auth } from 'aws-amplify';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actions } from './slice';
import { AmplifyAuthStatus } from './types';
import { history } from 'utils/history';
import { errorMessageHandler } from 'utils/errorMessage';
import { toast } from 'react-toastify';
import { errorDelay } from 'utils/constant';

export const errorMessage = (err: Error | string) =>
	errorMessageHandler(err, 'Error occurred during auth.');

function handleRedirect(redirectData) {
	history.push(redirectData);
}

export function* handleLogout() {
	try {
		yield call([Auth, 'signOut']);
		yield put(actions.logoutSuccess());
		// yield call(handleRedirect, AmplifyAuthStatus.login);
		//todo: https://medium.com/@asher.cassetto.cohen/resetting-redux-state-with-a-root-reducer-bonus-how-to-reset-state-selectively-e2a008d0de61
		//currently throwing error

		//to clear previous state
		window.location.href = '/login';
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleError(err) {
	const errMessage = errorMessage(err);
	yield call(toast.error, errMessage, { autoClose: errorDelay });
	yield put(actions.setLoading(false));
	yield put(actions.setAuthError(errMessage));
}

export function* handleSignUp(action) {
	let { username, password, attributes } = action.payload;
	try {
		if (!password) {
			password = Math.random().toString(36).substr(2, 8);
		}
		yield call([Auth, 'signUp'], { username, password, attributes });
		yield put(actions.signUpSuccess());
		yield call(handleRedirect, {
			pathname: AmplifyAuthStatus.confirmSignUp,
			search: `user=${encodeURIComponent(username)}`,
		});
		yield call(toast.success, 'Sign Up Successfully');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleConfirmSignIn(action) {
	const { code } = action.payload;
	try {
		const CurrentAuthState = yield select();
		const cognitoUser = CurrentAuthState.authContainer.cognitoUser;
		yield call([Auth, 'confirmSignIn'], cognitoUser.username, code);
		yield call(handleRedirect, AmplifyAuthStatus.login);
		yield put(actions.signUpConfirmSuccess());
		yield call(toast.success, 'Thanks for confirming');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleSignUpConfirm(action) {
	const { code, username, password } = action.payload;
	try {
		yield call([Auth, 'confirmSignUp'], username, code, {
			clientMetadata: { password },
		});
		yield put(actions.signUpConfirmSuccess());
		yield call(toast.success, 'Thank you for confirming');
		yield put(actions.login({ username, password }));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleSignUpResendCode(action) {
	const username = action.payload;
	try {
		const CurrentAuthState = yield select();
		const cognitoUser = CurrentAuthState.authContainer.cognitoUser;
		const resendSignUp = yield call(
			[Auth, 'resendSignUp'],
			username || cognitoUser.username
		);
		yield put(actions.signUpResendCodeSucess(resendSignUp));
		yield call(toast.success, 'Code sent successfully');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleLogin(action) {
	const { username, password } = action.payload;
	try {
		const currentUser = yield call([Auth, 'signIn'], username, password);
		yield put(actions.setCognitoUser(currentUser.attributes));
		if (currentUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
			yield put(actions.setTempUser({ username, password }));
			yield call(handleRedirect, AmplifyAuthStatus.newPasswordRequired);
		} else if (currentUser.challengeName === 'SMS_MFA') {
			yield call(handleRedirect, AmplifyAuthStatus.confirmSignIn);
		} else {
			yield put(actions.loginSuccess());
			yield call(toast.success, 'You are logged into the dashboard');
		}
	} catch (err) {
		if (err?.code === 'UserNotConfirmedException') {
			yield call(handleRedirect, {
				pathname: AmplifyAuthStatus.confirmSignUp,
				search: `user=${encodeURIComponent(username)}`,
			});
		} else if (err?.code === 'PasswordResetRequiredException') {
			yield call(handleRedirect, AmplifyAuthStatus.forgotPassword);
		}
		yield call(handleError, err);
	}
}

/**
 * Touches the current authenticated user, if failed to retrieve the user info, user is not logged in.
 */
export function* handleFetchCognitoUser() {
	try {
		const currentUser = yield call([Auth, 'currentAuthenticatedUser']);
		yield put(actions.setCognitoUser(currentUser.attributes));
		yield put(actions.fetchCognitoUserSuccess());
	} catch (err) {
		yield put(actions.setUserError(errorMessage(err)));
	}
}

export function* handleCompleteNewPassword(action) {
	const { newPassword } = action.payload;
	try {
		const CurrentAuthState = yield select();
		const tempUser = CurrentAuthState.authContainer.tempUser;
		// get cognitoUser
		const cognitoUser = yield call(
			[Auth, 'signIn'],
			tempUser.username,
			tempUser.password
		);

		// update password and set default website
		yield call([Auth, 'completeNewPassword'], cognitoUser, newPassword, {});

		// reset temp user
		yield put(actions.setTempUser({}));
		const currentUser = yield call([Auth, 'currentAuthenticatedUser']);
		yield put(actions.setCognitoUser(currentUser.attributes));
		yield put(actions.loginSuccess());
		yield call(toast.success, 'Welcome to org!');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleSetNewPassword(action) {
	const { newPassword } = action.payload;
	try {
		const CurrentAuthState = yield select();
		const user = CurrentAuthState.authContainer.cognitoUser;
		yield call([Auth, 'completeNewPassword'], user, newPassword, {});
		const currentUser = yield call([Auth, 'currentUserInfo']);
		yield put(actions.setCognitoUser(currentUser.attributes));
		yield put(actions.loginSuccess());
		yield call(toast.success, 'Password changed!');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleForgotPassword(action) {
	const { username } = action.payload;
	try {
		yield call([Auth, 'forgotPassword'], username);
		yield call(handleRedirect, {
			pathname: AmplifyAuthStatus.forgotPasswordSubmit,
			search: `user=${encodeURIComponent(username)}`,
		});
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleResetPassword(action) {
	const { username, code, newPassword } = action.payload;
	try {
		yield call([Auth, 'forgotPasswordSubmit'], username, code, newPassword);
		yield call(toast.success, 'Password reset');
		yield put(actions.forgotPasswordSubmitSuccess());
		yield call(handleRedirect, AmplifyAuthStatus.login);
	} catch (err) {
		yield call(handleError, err);
	}
}
export function* handleChangePassword(action) {
	try {
		const user = yield call([Auth, 'currentAuthenticatedUser']);
		const { oldPassword, newPassword } = action.payload;
		yield call([Auth, 'changePassword'], user, oldPassword, newPassword);
		yield put(actions.changePasswordSuccess({}));
		yield call(toast.success, 'Password changed');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleUpdateUser(action) {
	try {
		const { name, email, phoneNumber, website } = action.payload;
		const user = yield call([Auth, 'currentAuthenticatedUser']);
		const attributes = {
			name,
			email,
			website,
			phone_number: phoneNumber,
		};
		//it includes + as well
		if (phoneNumber.length > 4) {
			//send all attributes
			yield call([Auth, 'updateUserAttributes'], user, attributes);
			yield put(actions.setCognitoUser({ ...user.attributes, ...attributes }));
		} else {
			//empty phone number if value less than 4 digits
			// to prevent from throwing error
			yield call([Auth, 'updateUserAttributes'], user, {
				...attributes,
				phone_number: '',
			});
			yield put(
				actions.setCognitoUser({
					...user.attributes,
					...attributes,
					phone_number: '',
				})
			);
		}
		yield put(actions.updateUserSuccess({}));
		yield call(toast.success, 'User info updated.');
	} catch (err) {
		yield put(actions.setUserError(errorMessage(err)));
	}
}

export function* authContainerSaga() {
	yield takeLatest(actions.login.type, handleLogin);
	yield takeLatest(actions.signUp.type, handleSignUp);
	yield takeLatest(actions.signUpConfirm.type, handleSignUpConfirm);
	yield takeLatest(actions.confirmSignIn.type, handleConfirmSignIn);
	yield takeLatest(actions.logout.type, handleLogout);
	yield takeLatest(actions.fetchCognitoUser.type, handleFetchCognitoUser);
	yield takeLatest(actions.setNewPassword.type, handleSetNewPassword);
	yield takeLatest(actions.forgotPassword.type, handleForgotPassword);
	yield takeLatest(actions.forgotPasswordSubmit.type, handleResetPassword);
	yield takeLatest(actions.changePassword.type, handleChangePassword);
	yield takeLatest(actions.completeNewPassword.type, handleCompleteNewPassword);
	yield takeLatest(actions.signUpResendCode.type, handleSignUpResendCode);
	yield takeLatest(actions.updateUser.type, handleUpdateUser);
}
