import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCognitoUser } from '../selectors';

interface Props {
	component: any;
	path?: string;
	exact?: boolean;
	userInfo?: object;
}

export const PublicRoute: React.StatelessComponent<Props> = props => {
	const { component: Component, userInfo, ...rest } = props;
	const cognitoUser: any = useSelector(selectCognitoUser);
	return (
		<Route
			{...rest}
			render={matchProps =>
				cognitoUser && !(cognitoUser && cognitoUser.userConfirmed === false) ? (
					<Redirect to="/dashboard" />
				) : (
					<Component {...matchProps} />
				)
			}
		/>
	);
};
