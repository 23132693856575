import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './style.module.scss';
import noDataImg from './notFound.png';
interface Props {
	heading: {
		key: string;
		text: string;
	};
	description: {
		key: string;
		text: string;
	};
	customStyle?: React.CSSProperties;
}

export const NoDataFound = (props: Props) => {
	const { heading, description, customStyle } = props;
	return (
		<div className={styles.noDataFound} style={{ ...customStyle }}>
			<div>
				<img src={noDataImg} alt="No Data img" />
				<span style={{ margin: '10px 0px' }} data-translation-key={heading.key}>
					{heading.text}
				</span>
				<Typography variant="body2" data-translation-key={description.text}>
					{description.text}
				</Typography>
			</div>
		</div>
	);
};
