import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

interface Props {
	startDate: Moment;
	setStartDate: Function;
	endDate: Moment;
	setEndDate: Function;
	startDateLimit: Moment;
}

export const DatePickerComp = ({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
}: Props) => {
	const [focusedInput, focusChange] = useState(null);

	return (
		<DateRangePicker
			startDateId="startDate"
			endDateId="endDate"
			startDate={startDate}
			endDate={endDate}
			onDatesChange={({ startDate, endDate }) => {
				startDate && setStartDate(startDate);
				endDate && setEndDate(endDate);
			}}
			focusedInput={focusedInput}
			onFocusChange={(focusedInput: any) => {
				focusChange(focusedInput);
			}}
			disableScroll={true}
			isOutsideRange={day => {
				// start date cannot be before the end date selected
				if (focusedInput === 'startDate' && day.isAfter(endDate)) {
					return true;
				}
				// end date cannot be before today
				if (focusedInput === 'endDate' && day.isAfter(new Date())) {
					return true;
				}
				return false;
			}}
		/>
	);
};
