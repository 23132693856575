import React from 'react';

export function ClearbitRevealIcon() {
	return (
		<svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1">
			<title>F2C28698-188D-4338-8BD5-4ECE1C8AB86A</title>
			<desc>Created with sketchtool.</desc>
			<g
				id="Design"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
			>
				<g
					id="Settings---Integrations---Data-sources"
					transform="translate(-688.000000, -462.000000)"
				>
					<g id="Clearbit-Reveal" transform="translate(688.000000, 462.000000)">
						<path
							d="M10.2555408,-4.14336123e-16 L53.7444592,4.14336123e-16 C57.3105342,-2.4074122e-16 58.6036791,0.371302445 59.9073828,1.06853082 C61.2110865,1.76575919 62.2342408,2.78891348 62.9314692,4.09261719 C63.6286976,5.39632089 64,6.68946584 64,10.2555408 L64,53.7444592 C64,57.3105342 63.6286976,58.6036791 62.9314692,59.9073828 C62.2342408,61.2110865 61.2110865,62.2342408 59.9073828,62.9314692 C58.6036791,63.6286976 57.3105342,64 53.7444592,64 L10.2555408,64 C6.68946584,64 5.39632089,63.6286976 4.09261719,62.9314692 C2.78891348,62.2342408 1.76575919,61.2110865 1.06853082,59.9073828 C0.371302445,58.6036791 1.60494146e-16,57.3105342 -2.76224082e-16,53.7444592 L2.76224082e-16,10.2555408 C-1.60494146e-16,6.68946584 0.371302445,5.39632089 1.06853082,4.09261719 C1.76575919,2.78891348 2.78891348,1.76575919 4.09261719,1.06853082 C5.39632089,0.371302445 6.68946584,2.4074122e-16 10.2555408,-4.14336123e-16 Z"
							id="Rectangle"
							fill="#4A9AFF"
						></path>
						<g
							id="clearbit-vector-logo"
							transform="translate(12.000000, 12.000000)"
							fillRule="nonzero"
						>
							<path
								d="M33.1807229,39.9518072 L6.86746988,39.9518072 C3.13253012,39.9518072 0.0963855422,36.8915663 0.0963855422,33.1807229 L0.0963855422,6.86746988 C0.0963855422,3.13253012 3.15662651,0.0963855422 6.86746988,0.0963855422 L33.1807229,0.0963855422 C36.9156627,0.0963855422 39.9519486,3.15662651 39.9519486,6.86746988 L39.9519486,33.1807229 C39.9759036,36.9156627 36.9156627,39.9518072 33.1807229,39.9518072"
								id="Path"
								fill="#FFFFFF"
							></path>
							<path
								d="M33.1807229,0.0722891566 L20.0240964,0.0722891566 L20.0240964,20 L39.9519486,20 L39.9519486,6.84337349 C39.9759036,3.13253012 36.9156627,0.0722891566 33.1807229,0.0722891566"
								id="Path"
								fill="#ECF4FF"
							></path>
							<path
								d="M20.0240964,39.9518072 L33.1807229,39.9518072 C36.9156627,39.9518072 39.9518072,36.8915663 39.9518072,33.1807229 L39.9518072,20.0240964 L20.0240964,20.0240964 L20.0240964,39.9518072 Z"
								id="Path"
								fill="#5FA7FF"
							></path>
						</g>
					</g>
				</g>
			</g>
		</svg>
	);
}
