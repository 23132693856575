import React from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteForever from '@material-ui/icons/DeleteForever';
import MailIcon from '@material-ui/icons/Mail';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getCognitoAttribute } from '../utility';
import AlertDialog from 'app/components/AlertDialog';
import styles from '../style.module.scss';

interface Props {
	removeMember(item: object): any;
	onAddMember(item: object): any;
	userInfo: any;
}
export const MemberEditor = (props: Props) => {
	const { removeMember, onAddMember, userInfo } = props;

	const [dialogState, setDialogOpen] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOptionClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleConfirmRemove = () => {
		setDialogOpen(true);
	};

	return (
		<>
			<span style={{ cursor: 'pointer' }} onClick={handleOptionClick}>
				<MoreHorizIcon />
			</span>

			<Menu
				id="member-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				className={styles.memberMenu}
			>
				<MenuItem
					onClick={() => {
						setAnchorEl(null);
						handleConfirmRemove();
					}}
				>
					<span>
						<DeleteForever style={{ fontSize: '18px', marginRight: '5px' }} />
					</span>
					Remove
				</MenuItem>

				{userInfo?.UserStatus !== 'CONFIRMED' && (
					<MenuItem
						onClick={() =>
							onAddMember({
								userName: getCognitoAttribute(userInfo, 'name') || '',
								userEmail: getCognitoAttribute(userInfo, 'email') || '',
							})
						}
					>
						<span>
							<MailIcon style={{ fontSize: '18px', marginRight: '5px' }} />
						</span>
						Resend Invite
					</MenuItem>
				)}
			</Menu>

			{dialogState && (
				<AlertDialog
					onCancel={() => setDialogOpen(false)}
					onAgree={() => {
						removeMember(userInfo);
						setDialogOpen(false);
					}}
					content={'Are you sure you want to remove member.'}
				/>
			)}
		</>
	);
};
