const validatedValues = values => {
	let obj = {
		numeric: false,
		lc: false,
		uppercase: false,
		special: false,
		length: false,
	};
	const { newPassword } = values;

	if (newPassword.match(/[0-9]/g)) {
		obj['numeric'] = true;
	}
	if (newPassword.match(/[a-z]/g)) {
		obj['lc'] = true;
	}
	if (newPassword.match(/[A-Z]/g)) {
		obj['uppercase'] = true;
	}
	if (newPassword.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
		obj['special'] = true;
	}
	if (newPassword.length >= 8) {
		obj['length'] = true;
	}

	return obj;
};

export default validatedValues;
