import React from 'react';
import GoogleOptimize from './google-optimize.svg';

export const GoogleOptimizeIcon = () => (
	<img
		style={{ width: '64px', height: '64px' }}
		src={GoogleOptimize}
		alt="GO"
	/>
);

export default GoogleOptimizeIcon;
