import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Providers, SalesforceCredsInput } from 'API';
import { integrationsContainerSaga } from '../../saga';
import { reducer, sliceKey, actions } from '../../slice';
import { AuthorizationForm } from './AuthorizationForm';
import { DataImportForm } from './DataImportForm';
import styles from './style.module.scss';
import commonIntStyles from 'app/containers/IntegrationsContainer/style.module.scss';
import {
	selectIntegrationByProvider,
	selectFetchingData,
	getSfOpportunity,
	getSelectedOpportunities,
	selectAllDeselected,
} from 'app/containers/IntegrationsContainer/selectors';
import { selectedOrganization } from 'app/containers/OrganizationsContainer/selectors';
import { actions as goalsActions } from 'app/containers/GoalContainer/slice';
import { useQuery } from 'utils/history';
import { Loader } from 'app/components/Loader';
import { Header } from '../../components/Header';
import { Divider, AppBar, Tabs, Tab } from '@material-ui/core';
import { SalesforceIcon } from './SalesforceIcon';
import { NotActivated } from '../../components/NotActivated';
import { ButtonSection } from '../../components/ButtonSection';
import { IntegrationProps as Props } from '../../types';

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	value: any;
	theme?: 'transparent';
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`salesforce-tabpanel-${index}`}
			aria-labelledby={`salesforce-tab-${index}`}
			{...other}
			className={styles.tabPanel}
		>
			{value === index && <>{children}</>}
		</div>
	);
};

function a11yProps(index: any, translationKey: string) {
	return {
		id: `salesforce-tab-${index}`,
		'aria-controls': `salesforce-tabpanel-${index}`,
		'data-translation-key': translationKey,
	};
}

const routesToTabValues = {
	authorization: 0,
	authorization_success: 1,
};

const tabValuesToRoutes = {
	0: 'authorization',
	1: 'authorization_success',
};

const integrationName = 'Salesforce';

const clearCredentials: SalesforceCredsInput = {
	access_token: '',
	refresh_token: '',
	signature: '',
	scope: '',
	id_token: '',
	instance_url: '',
	id: '',
	token_type: '',
	issued_at: '',
};

export function Salesforce({
	group,
	provider,
	organizationId,
	baseUrl,
	goToIntegration,
}: Props) {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: integrationsContainerSaga });
	const history = useHistory();
	const dispatch = useDispatch();
	const match = useRouteMatch();
	const query = useQuery();
	const { params } = match;
	const { tab } = params as any;
	const [t, i18n] = useTranslation();
	const loading = useSelector(selectFetchingData);
	const leads = useSelector(getSfOpportunity);
	const org = useSelector(selectedOrganization);
	const selectedOpportunities = useSelector(getSelectedOpportunities);
	const isAllDeselected = useSelector(selectAllDeselected);
	const salesforce = useSelector(
		selectIntegrationByProvider(Providers.salesforce)
	);

	//set tab values
	const [value, setValue] = React.useState<number | false>(0);

	//fetch Marketo Data
	useEffect(() => {
		salesforce?.id &&
			dispatch(actions.fetchIntegration({ id: salesforce?.id }));
	}, [dispatch, salesforce]);

	//innitial salesforce obj
	const initialIntegrationObj = {
		integrationId: salesforce?.id,
		integration: salesforce?.id
			? null
			: {
					id: salesforce?.id,
					name: integrationName,
					group,
					organizationId,
					source: true,
					provider,
			  },
		group,
		provider,
		attributes: undefined,
		lists: undefined,
		configuration: undefined,
		credentials: undefined,
	};

	//logged in sf user identity
	const fetchingIdentity = useCallback(() => {
		dispatch(
			actions.fetchSalesforceIdentity({
				oAuth: salesforce && salesforce?.credentials,
			})
		);
	}, [dispatch, salesforce]);

	const fetchOpportunities = useCallback(() => {
		(salesforce?.credentials as any)?.access_token?.length > 0 &&
			dispatch(
				actions.getOpportunity({
					oAuth: salesforce && salesforce?.credentials,
					sqlQuery:
						'SELECT ApiName,CreatedById,CreatedDate,DefaultProbability,Description,ForecastCategory,ForecastCategoryName,Id,IsActive,IsClosed,IsWon,LastModifiedById,LastModifiedDate,MasterLabel,SortOrder,SystemModstamp FROM OpportunityStage',
				})
			);
	}, [dispatch, salesforce]);

	const handleDisconnect = () => {
		dispatch(
			actions.modifyIntegration({
				...initialIntegrationObj,
				credentials: clearCredentials,
			})
		);
		dispatch(actions.removeSalesforceIdentity({}));
	};

	//default tabs
	const tabs = {
		authorization: {
			title: 'integrations_salesforce_tab_authorization_label',
			content: (
				<AuthorizationForm
					fetchingIdentity={fetchingIdentity}
					disconnectSf={handleDisconnect}
					isConnected={
						(salesforce?.credentials as any)?.access_token?.length > 0 ?? false
					}
				/>
			),
		},
		authorization_success: {
			title: 'integrations_salesforce_tab_import_label',
			content: (
				<DataImportForm
					credentials={{ ...salesforce?.credentials }}
					fetchOpportunities={fetchOpportunities}
					lists={salesforce?.lists?.items || []}
					leads={leads}
					selectedOpportunities={selectedOpportunities}
				/>
			),
		},
	};

	//setting tabs from url
	useEffect(() => {
		setValue(routesToTabValues[tab]);
	}, [tab]);

	//handle tab change
	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
		history.push(`${baseUrl}/${provider}/${tabValuesToRoutes[newValue]}`);
	};

	//handle Integration Active state
	const handleActiveState = active => {
		if (salesforce?.id) {
			//if salesforce is created only update active
			dispatch(
				actions.modifyIntegration({
					integrationId: salesforce?.id,
					provider,
					configuration: { ...salesforce?.configuration, active },
				})
			);
		} else {
			//if not, create integration and update active
			dispatch(
				actions.modifyIntegration({
					integrationId: salesforce?.id,
					integration: {
						id: salesforce?.id,
						name: integrationName,
						group,
						organizationId,
						source: true,
						provider,
					},
					group,
					provider,
					credentials: undefined,
					attributes: undefined,
					lists: undefined,
					configuration: { active },
				})
			);
		}
	};

	//save SF integration with all change data
	const saveSfIntegration = () => {
		try {
			//saved sf lists
			const lists = salesforce?.lists?.items ?? ([] as any[]);

			const goalsToBe = selectedOpportunities.map(item => ({
				group: org?.name?.replace(/ /gi, '-').toLowerCase(),
				organizationId: org?.id,
				name: item?.apiname,
				type: Providers.salesforce,
				typeState: Providers.salesforce,
				attributes: item,
			}));

			const listsToBe = selectedOpportunities.map(selectedLead => {
				const foundList = lists?.find(
					list => list.externalId === selectedLead.id
				);
				return {
					id: foundList?.id,
					externalId: selectedLead?.id,
					name: selectedLead?.apiname,
					provider: Providers.salesforce,
					smart: selectedLead?.smart,
					createdAt: foundList?.createdAt,
					updatedAt: foundList?.updatedAt,
				};
			});
			//create goals from leads
			goalsToBe.length > 0 &&
				dispatch(goalsActions.createMultiGoals(goalsToBe));

			//update sf integration
			dispatch(
				actions.modifyIntegration({
					...initialIntegrationObj,
					lists:
						isAllDeselected === true
							? []
							: listsToBe.length === 0
							? undefined
							: listsToBe,
				})
			);

			//close integration
			goToIntegration();
		} catch (err) {
			dispatch(actions.setIntegrationError(err));
		}
	};

	//handle what happen on if salesforce url changes
	useEffect(() => {
		if (tab === 'authorization') {
			setValue(routesToTabValues[tab]);
		} else if (tab === 'authorization_success') {
			const code = query.get('code');
			if (code && !(salesforce?.credentials as any)?.access_token?.length) {
				let args = {
					body: {
						group,
						organizationId,
						provider,
						code,
					},
				};
				if (window.opener) {
					window.opener.window.location.href =
						'authorization_success?code=' +
						encodeURIComponent(query.get('code') as any);
					window.close();
				}
				dispatch(actions.getSalesOauth(args));
			}
			setValue(routesToTabValues[tab]);
		}
	}, [dispatch, group, organizationId, provider, query, salesforce, tab]);

	//clearing sf states on unmount
	useEffect(() => {
		return () => {
			dispatch(actions.clearSalesforceStates());
		};
	}, [dispatch]);

	if (loading) {
		return (
			<div className={commonIntStyles.wrapper}>
				<Loader />
			</div>
		);
	}

	return (
		<div className={commonIntStyles.wrapper}>
			<Header
				label="integrations_salesforce_title"
				changehandler={handleActiveState}
				value={salesforce?.configuration?.active ?? false}
			/>
			<Divider />
			{salesforce?.configuration?.active ? (
				<>
					<AppBar position="static" className={styles.transparentTabBar}>
						<Tabs
							value={value}
							onChange={handleTabChange}
							aria-label="salesforce-tabs"
							className={styles.transparentTabs}
						>
							{Object.entries(tabs).map((tab, i) => {
								return (
									<Tab
										key={i}
										label={t(tab[1].title)}
										{...a11yProps(i, t(tab[1].title))}
									/>
								);
							})}
						</Tabs>
					</AppBar>
					{Object.entries(tabs).map((tab, i) => {
						return (
							<TabPanel value={value} index={i} key={i}>
								{tab[1].content}
							</TabPanel>
						);
					})}
				</>
			) : (
				<>
					<NotActivated name="Salesforce Source" Icon={<SalesforceIcon />} />
				</>
			)}

			<div className={styles.bottomSec}>
				<Divider />
				<ButtonSection
					disabled={!salesforce?.configuration?.active}
					onSave={saveSfIntegration}
					onCancel={goToIntegration}
				/>
			</div>
		</div>
	);
}
