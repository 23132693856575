import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { select, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { selectCognitoUser } from 'app/containers/AuthContainer/selectors';
import { errorMessageHandler } from 'utils/errorMessage';
import { notificationsByOrganization } from 'graphql/queries';
import { dismissNotification } from 'graphql/mutations';
import { actions } from './slice';
import { INotification } from './types';
import * as APIt from 'API';

const errorMessage = (err: Error) =>
	errorMessageHandler(err, 'Error occurred fetching notifications.');

function* handleError(err: Error) {
	const errMessage = errorMessage(err);
	yield call(toast.error, errMessage);
	yield put(actions.setNotificationError(errMessage));
}

function* handleFetchNotifications(
	action: PayloadAction<APIt.NotificationsByOrganizationQueryVariables>
): SagaIterator {
	try {
		const user = yield select(selectCognitoUser);
		const {
			data,
		}: GraphQLResult<APIt.NotificationsByOrganizationQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(notificationsByOrganization, {
				...action.payload,
				filter: {
					...action.payload.filter,
					dismissedBy: { ne: user.sub },
				},
			})
		);
		yield put(
			actions.fetchNotificationsSuccess(
				data?.notificationsByOrganization?.items as INotification[]
			)
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

function* handleDismissNotifications(
	action: PayloadAction<string>
): SagaIterator {
	try {
		yield call(
			[API, 'graphql'],
			graphqlOperation(dismissNotification, { id: action.payload })
		);
		yield put(actions.dismissNotificationsSuccess(action.payload));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* notificationsContainerSaga() {
	yield takeLatest(actions.fetchNotifications.type, handleFetchNotifications);
	yield takeLatest(
		actions.dismissNotification.type,
		handleDismissNotifications
	);
}
