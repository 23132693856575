import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
	IIntegration,
	IIntegrationField,
	IIntegrationList,
	ContainerState,
} from './types';
import { Providers } from 'API';

export const initialState: ContainerState = {
	error: undefined,
	integrations: [],
	nextToken: null,
	fetchingData: false,
	sfOpportunities: [],
	fetchingSfOpportunities: false,
	sfConnectedUser: null,
	hubSpotConnectedUser: null,
	reportData: [],
	marketoReportStats: [],
	gettingUrlLoader: false,
	marketoCredentials: undefined,
	marketoConfigurations: undefined,
	isMarketoCredsConnected: false,
	listOpen: false,
	marketoListsToBe: [],
	audienceToBeLists: [],
	marketoLeads: [],
	allDeselected: false,
	selectedOpportunities: [],
};
const integrationsContainerSlice = createSlice({
	name: 'integrationsContainer',
	initialState,
	reducers: {
		setIntegrationError(state, action: PayloadAction<string>) {
			state.fetchingData = false;
			state.error = action.payload;
		},
		fetchingIntegrations(state, _action) {
			state.fetchingData = true;
		},
		stopLoading(state, _action) {
			state.fetchingData = false;
		},
		fetchingIntegrationsSuccess(state, action: PayloadAction<IIntegration[]>) {
			state.fetchingData = false;
			state.error = undefined;
			state.integrations = action.payload || [];
		},
		setLoadingState(state, action) {
			state.fetchingData = action.payload;
		},
		fetchIntegration(state, _action) {
			state.fetchingData = true;
		},
		fetchIntegrationSuccess(state, action: PayloadAction<IIntegration>) {
			state.fetchingData = false;
			state.integrations = state.integrations?.map(i =>
				i.id !== action.payload.id ? i : action.payload
			);
		},
		fetchIntegrationLists(state, _action) {
			state.fetchingData = true;
		},
		fetchIntegrationListsSuccess(
			state,
			_action: PayloadAction<{
				integrationId: string;
				lists: IIntegrationList[];
			}>
		) {
			state.fetchingData = false;
			// @ts-ignore
			state.integrations = state.integrations?.map(i =>
				i.id !== _action.payload.integrationId
					? i
					: { ...i, lists: { ...i.lists, items: _action.payload.lists } }
			);
		},
		fetchMarketoReport(state, action) {
			state.fetchingData = true;
		},
		fetchMarketoReportDataSuccess(state, _action) {
			state.fetchingData = false;
			state.reportData = _action.payload;
		},
		fetchMarketoReportStats(state, _action) {
			state.fetchingData = true;
		},
		fetchMarketoReportStatsSuccess(state, _action) {
			state.marketoReportStats = _action.payload;
			state.fetchingData = false;
		},
		setNextToken(state, action: PayloadAction<string | null>) {
			state.nextToken = action.payload;
		},
		addCredential(_state, _action) {},
		addCredentialSuccess(state, action) {
			state.integrations = state.integrations?.map(i =>
				i.id !== action.payload.integrationId
					? i
					: { ...i, credentials: action.payload.credentials }
			);
		},
		addConfiguration(_state, _action) {},
		addConfigurationSuccess(_state, action) {
			_state.integrations = _state.integrations?.map(i =>
				i.id !== action.payload.integrationId
					? i
					: { ...i, configuration: action.payload.configuration }
			);
		},
		addIntegrationLists(_state, _action) {},
		addIntegrationListsSuccess(
			state,
			action: PayloadAction<{
				integrationId: string;
				lists: IIntegrationList[];
			}>
		) {
			// @ts-ignore
			state.integrations = state.integrations?.map(i =>
				i.id !== action.payload.integrationId
					? i
					: { ...i, lists: { ...i.lists, items: action.payload.lists } }
			);
		},
		addIntegrationFields(_state, _action) {},
		addIntegrationFieldsSuccess(
			state,
			action: PayloadAction<{
				integrationId: string;
				attributes: IIntegrationField[];
			}>
		) {
			// @ts-ignore
			state.integrations = state.integrations?.map(i =>
				i.id !== action.payload.integrationId
					? i
					: {
							...i,
							attributes: { ...i.attributes, items: action.payload.attributes },
					  }
			);
		},
		modifyIntegrations(_state, _action) {},
		modifyIntegration(_state, _action) {},
		modifyIntegrationSuccess(state, action: PayloadAction<IIntegration>) {
			state.fetchingData = false;
			if (state.integrations?.map(i => i.id).indexOf(action.payload.id) > -1) {
				state.integrations = state.integrations?.map(i =>
					i.id === action?.payload?.id ? action.payload : i
				);
			} else {
				state.integrations = [...state.integrations, action.payload];
			}
		},
		removeIntegration() {},
		removeIntegrationSuccess(state, action: PayloadAction<string>) {
			state.error = undefined;
			state.integrations = state.integrations.filter(
				i => i.id !== action.payload
			);
		},
		generateSalesforceUrl(state) {
			state.gettingUrlLoader = true;
		},
		generateHubSpotUrl(state) {
			state.gettingUrlLoader = true;
		},
		setUrlLoader(state) {
			state.gettingUrlLoader = false;
		},
		getHubSpotOauth(state, _action: PayloadAction<any>) {
			state.fetchingData = true;
		},
		getSalesOauth(state, _action: PayloadAction<any>) {
			state.fetchingData = true;
		},
		updateCredentials(state, action: PayloadAction<any>) {
			const integration = state.integrations.filter(
				d => d.id === action.payload.id
			);
			integration[0]['credentials'] = action.payload.credentials;
			state.integrations = [
				...state.integrations.filter(d => d.id !== action.payload.id),
				...integration,
			];
			state.fetchingData = false;
		},
		getOpportunity(state, _action: PayloadAction<any>) {
			state.fetchingSfOpportunities = true;
		},
		getOpportunitySuccess(state, action: PayloadAction<any>) {
			state.fetchingSfOpportunities = false;
			state.sfOpportunities = action.payload || [];
			state.selectedOpportunities = action.payload || [];
		},
		setSelectedOpportunities(state, action) {
			state.selectedOpportunities = action.payload;
		},
		fetchSalesforceIdentity(_state, _action: PayloadAction<any>) {},
		fetchSalesforceIdentitySuccess(state, action: PayloadAction<any>) {
			state.sfConnectedUser = action.payload;
		},
		refreshHubSpotToken(_state, _action) {},
		fetchHubSpotIdentity(state, _action: PayloadAction<any>) {
			state.gettingUrlLoader = true;
		},
		fetchHubSpotIdentitySuccess(state, action: PayloadAction<any>) {
			state.hubSpotConnectedUser = action.payload;
			state.gettingUrlLoader = false;
		},
		removeSalesforceIdentity(state, _action: PayloadAction<any>) {
			state.sfConnectedUser = null;
		},
		removeHubSpotIdentity(state, _action: PayloadAction<any>) {
			state.hubSpotConnectedUser = null;
		},
		setMarketoCredentials(state, _action) {
			state.marketoCredentials = _action.payload;
		},
		setIsMarketoCredsConnected(state, _action) {
			state.isMarketoCredsConnected = _action.payload;
		},
		setListOpen(state, _action) {
			state.listOpen = _action.payload;
		},
		setMarketoLeads(state, _action) {
			state.marketoLeads = _action.payload;
		},
		setMarketoListsToBe(state, _action) {
			const { leads, lists, values } = _action.payload;
			state.marketoListsToBe = Object.keys(values)
				.filter(key => !!values[key])
				.map(key => {
					const foundLead = leads.find(l => l.id.toString() === key);
					const foundList = lists.find(l => l.externalId === key);
					return {
						id: foundList?.id,
						externalId: key,
						name: foundLead?.name,
						provider: Providers.marketo,
						smart: foundLead?.smart,
						createdAt: foundList?.createdAt,
						updatedAt: foundList?.updatedAt,
					};
				});
		},
		setAudienceToBeLists(state, _action) {
			const { leads, values } = _action.payload;
			state.audienceToBeLists = Object.keys(values)
				.filter(item => !!values[item])
				.map(item => ({
					name: leads.find(l => l.id.toString() === item)?.name,
					audienceType: Providers.marketo,
					externalId: item,
				}));
		},
		clearMarketoState(state) {
			state.audienceToBeLists = [];
			state.marketoListsToBe = [];
			state.listOpen = false;
			state.allDeselected = false;
			state.marketoConfigurations = undefined;
		},
		setAllDeselected(state, _action) {
			state.allDeselected = _action.payload;
		},
		setMarketoConfiguration(state, _action) {
			state.marketoConfigurations = _action.payload;
		},
		clearSalesforceStates(state) {
			state.allDeselected = false;
			state.selectedOpportunities = [];
		},
	},
});
export const { actions, reducer, name: sliceKey } = integrationsContainerSlice;
