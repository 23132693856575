import palette from './palette';
const fontRoboto = 'Roboto';
export default {
	h1: {
		color: palette.text.primary,
		fontFamily: fontRoboto,
		fontSize: '28px',
		fontWeight: 400,
		letterSpacing: '0',
		lineHeight: '65px',
		marginBottom: '20px',
	},
	h2: {
		color: palette.text.primary,
		fontFamily: fontRoboto,
		fontSize: '26px',
		fontWeight: 400,
		letterSpacing: '0',
		lineHeight: '30px',
		marginBottom: '20px',
	},
	h3: {
		color: palette.text.primary,
		fontFamily: fontRoboto,
		fontSize: '22px',
		fontWeight: 400,
		letterSpacing: '0',
		lineHeight: '26px',
		marginBottom: '18px',
	},
	h4: {
		color: palette.text.primary,
		fontFamily: fontRoboto,
		fontSize: '18px',
		fontWeight: 400,
		letterSpacing: '0',
		lineHeight: '22px',
		marginBottom: '16px',
	},
	h5: {
		color: palette.text.primary,
		fontFamily: fontRoboto,
		fontSize: '16px',
		fontWeight: 400,
		letterSpacing: '0',
		lineHeight: '20px',
		marginBottom: '14px',
	},
	h6: {
		color: palette.text.primary,
		fontFamily: fontRoboto,
		fontSize: '14px',
		fontWeight: 400,
		letterSpacing: '0',
		lineHeight: '16px',
		marginBottom: '14px',
	},
	subtitle1: {
		color: palette.text.secondary,
		fontFamily: fontRoboto,
		fontSize: '16px',
		letterSpacing: '0px',
		lineHeight: '28px',
		marginBottom: '16px',
	},
	subtitle2: {
		color: palette.text.secondary,
		fontFamily: fontRoboto,
		fontWeight: 400,
		fontSize: '14px',
		letterSpacing: '0px',
		lineHeight: '21px',
		marginBottom: '16px',
	},
	body1: {
		color: palette.text.primary,
		fontFamily: fontRoboto,
		fontSize: '14px',
		letterSpacing: '0',
		lineHeight: '16px',
		// marginBottom: '16px',
	},
	body2: {
		color: palette.text.secondary,
		fontFamily: fontRoboto,
		fontSize: '12px',
		letterSpacing: '0',
		lineHeight: '20px',
		marginBottom: '16px',
	},
	button: {
		color: palette.white,
		fontSize: '14px',
		fontFamily: fontRoboto,
		lineHeight: '19px',
		letterSpacing: '0px',
		fontWeight: 400,
	},
	caption: {
		color: palette.text.link,
		fontFamily: fontRoboto,
		fontSize: '12px',
		letterSpacing: '0',
		lineHeight: '20px',
	},
};
