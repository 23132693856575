import React from 'react';
import { useDrop, DropTargetMonitor } from 'react-dnd';

import styles from './style.module.scss';

export const Bucket = ({ editable, handleDropWithTarget }: any) => {
	const [{ isOverCurrent }, drop] = useDrop(() => ({
		accept: ['conditions', 'leaves'],
		canDrop: _monitor => editable || false,
		drop: (item: unknown, monitor) => {
			// Non-greedy bucket (inner bucket doesn't trigger outter buckets)
			if (monitor.didDrop()) return;
			handleDropWithTarget(item);
			return undefined;
		},
		collect: (monitor: DropTargetMonitor) => ({
			isOverCurrent: monitor.isOver({ shallow: true }),
		}),
	}));
	if (!editable) return null;
	return (
		<div
			className={styles.bucket}
			ref={drop}
			style={{
				border:
					isOverCurrent && editable
						? '2px dashed #048cff'
						: '1px dashed #8891b380',
			}}
		>
			{isOverCurrent && editable ? (
				<span>Release to add conditions.</span>
			) : (
				<span>Drag additional conditions here.</span>
			)}
		</div>
	);
};

export default Bucket;
