import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, FieldArray } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import { Lead } from './fetchLeadsFromMarketo';
import { actions } from 'app/containers/IntegrationsContainer/slice';
import styles from './style.module.scss';
import { CheckedIcon } from './checkedIcon';
import cx from 'classnames';
import { NoMatchFound } from '../NoMatchFound';

interface Props {
	lists: any[];
	leads: Lead[];
	error?: Error;
	search: string;
	values: any;
	setValues: Function;
}

export function SelectedListFields({
	lists = [],
	leads,
	search,
	error: initalError,
	values,
	setValues,
}: Props) {
	const dispatch = useDispatch();
	const searchValue = search.toLowerCase();
	//update marketoList and audience to be created list on any change on list selection
	const handleToggleselection = externalId => {
		if (Object.keys(values).length === 1) {
			dispatch(actions.setAllDeselected(true));
		}
		dispatch(
			actions.setMarketoListsToBe({
				leads,
				lists,
				values: {
					...values,
					[externalId]: !values[externalId],
				},
			})
		);
		dispatch(
			actions.setAudienceToBeLists({
				leads,
				values: {
					...values,
					[externalId]: !values[externalId],
				},
			})
		);
	};

	if (
		lists.filter(a => a?.name?.toLowerCase().includes(searchValue)).length === 0
	) {
		return <NoMatchFound />;
	}

	return (
		<FieldArray
			name="marketoList"
			render={() => (
				<div className={styles.listWrapper}>
					{lists
						.filter(a => a?.name?.toLowerCase().includes(searchValue))
						.map(({ name, externalId }) => (
							<div key={externalId} className={styles.labelWrap}>
								<span className={styles.label}>{name}</span>
								<Field type="checkbox" name={externalId}>
									{() => (
										<div className={styles.checkWrapper}>
											<Checkbox
												checked={values[externalId] || false}
												onChange={e => {
													setValues({
														...values,
														[externalId]: e.target.checked,
													});
												}}
												disableRipple
												checkedIcon={<CheckedIcon />}
												className={cx({
													[styles.checkBox]: true,
													[styles.showSelected]: values[externalId] === true,
												})}
											/>
											<span className={styles.onHoverText}>
												{values[externalId] ? (
													<span
														style={{ color: '#FF7D5E' }}
														onClick={() => handleToggleselection(externalId)}
													>
														Deselect
													</span>
												) : (
													<span
														style={{ color: '#048CFF' }}
														onClick={() => handleToggleselection(externalId)}
													>
														Add
													</span>
												)}
											</span>
										</div>
									)}
								</Field>
							</div>
						))}
				</div>
			)}
		/>
	);
}
