export const checkConnection = async (
	apiHost: string,
	clientId: string,
	clientSecret: string
) => {
	try {
		const response = await fetch(
			`https://4dj4w5yix7.execute-api.us-west-2.amazonaws.com/backstage-production/marketo/credentials`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					endpoint: apiHost,
					clientId,
					clientSecret,
				}),
			}
		);
		const data = await response.json();
		//if api return authenticated false throw error
		if (!data.authenticated) throw new Error('Not Valid Credentials.');
		return data;
	} catch (err) {
		throw new Error(err);
	}
};
