import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectTriggerDomain = (state: RootState) =>
	state.triggersContainer || initialState;

export const selectError = createSelector(
	[selectTriggerDomain],
	state => state.error
);

export const selectLoading = createSelector(
	[selectTriggerDomain],
	state => state.loading
);

export const selectModalLoading = createSelector(
	[selectTriggerDomain],
	state => state.modalLoading
);

export const selectTriggers = createSelector(
	[selectTriggerDomain],
	state => state.triggers
);

export const selectTriggerByID = (id: string) =>
	createSelector([selectTriggerDomain], state => {
		return state.triggers.find(t => t.id === id);
	});

export const selectPage = createSelector([selectTriggerDomain], state =>
	state.triggers?.slice(state.indexRange[0], state.indexRange[1] + 1)
);

export const selectNextToken = createSelector(
	[selectTriggerDomain],
	state => state.nextToken
);

export const selectIndexRange = createSelector(
	[selectTriggerDomain],
	state => state.indexRange
);
