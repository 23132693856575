import React from 'react';

import { RetoolApp } from '../RetoolApp';

import styles from './style.module.scss';

export function S3Uploader() {
	return (
		<RetoolApp
			url="https://funnelenvy.retool.com/embedded/public/1797fac2-a7aa-46f0-8518-ac3fde7b4aa3"
			styles={styles}
		/>
	);
}
