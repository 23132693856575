import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Header } from '../../components/Header';
import { NotActivated } from '../../components/NotActivated';
import { ButtonSection } from '../../components/ButtonSection';
import { AccountLookupIcon } from '../AccountLookup/AccountLookupIcon';
import { actions } from '../../slice';
import { selectIntegrationByProvider } from 'app/containers/IntegrationsContainer/selectors';
import styles from 'app/containers/IntegrationsContainer/style.module.scss';
import RichTextConverter from 'app/components/RichTextConverter';
import { IntegrationProps as Props } from '../../types';
import { Providers } from 'API';

export const Custom = ({
	group,
	provider,
	organizationId,
	goToIntegration,
}: Props) => {
	const dispatch = useDispatch();
	const [t, i18n] = useTranslation();
	const custom = useSelector(selectIntegrationByProvider(Providers.custom));
	const inital = {
		integrationId: custom?.id,
		integration: custom?.id
			? null
			: {
					id: custom?.id,
					name: '',
					group,
					organizationId,
					source: true,
					provider,
			  },
		group,
		provider,
		attributes: undefined,
		lists: undefined,
		configuration: undefined,
	};
	const [name, setName] = useState(custom?.name ?? '');

	const handleActiveState = (active: boolean) => {
		dispatch(
			actions.modifyIntegration(
				custom?.id
					? {
							integrationId: custom?.id,
							provider,
							configuration: {
								...custom?.configuration,
								active,
							},
					  }
					: {
							...inital,
							configuration: { active },
					  }
			)
		);
	};

	const handleSave = () => {
		dispatch(
			actions.modifyIntegration({
				...inital,
				integration: {
					...inital.integration,
					name,
				},
				configuration: custom?.configuration,
			})
		);
		goToIntegration();
	};

	return (
		<div className={styles.wrapper}>
			<Header
				label="integrations_custom_title"
				changehandler={handleActiveState}
				value={custom?.configuration?.active ?? false}
			/>
			<Divider />
			{custom?.configuration?.active ? (
				<div className={`${styles.padding} ${styles.activeIntContentWrapper}`}>
					<Formik
						enableReinitialize
						initialValues={{
							name,
						}}
						onSubmit={() => handleSave()}
						validateSchema={Yup.object().shape({
							name: Yup.string().required('Required'),
						})}
					>
						{() => (
							<Form>
								<Typography
									variant="h6"
									data-translation-key={'integrations_custom_name'}
								>
									{t('integrations_custom_name')}
								</Typography>
								<Field
									type="text"
									name="name"
									placeholder={t('integrations_custom_name_placeholder')}
									component={TextField}
									InputLabelProps={{ shrink: false }}
									fullWidth
									variant="outlined"
									InputProps={{ onChange: e => setName(e.target.value) }}
								/>
								<Typography
									variant="body2"
									data-translation-key="integrations_custom_more"
								>
									{t('integrations_custom_more')}
									<RichTextConverter
										html={t('globals_knowledge_base')}
										translationKey={'globals_knowledge_base'}
									/>
								</Typography>
							</Form>
						)}
					</Formik>
				</div>
			) : (
				<NotActivated name="Custom source" Icon={<AccountLookupIcon />} />
			)}
			<div className={styles.bottomSec}>
				<Divider />
				<ButtonSection
					disabled={!custom?.configuration?.active || name.length <= 0}
					onSave={handleSave}
					onCancel={goToIntegration}
				/>
			</div>
		</div>
	);
};
