import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState, IAudience } from './types';
import { isEmpty } from 'lodash';

// The initial state of the AudiencesContainer container
export const initialState: ContainerState = {
	error: undefined,
	audiences: [],
	selectedAudience: {},
	fetchingData: false,
	modalFetchingData: false,
	nextToken: undefined,
	indexRange: [0, 0],
};

const audiencesContainerSlice = createSlice({
	name: 'audiencesContainer',
	initialState,
	reducers: {
		setAudienceError(state, action: PayloadAction<string>) {
			state.error = action.payload;
			state.fetchingData = false;
		},
		modifyAudiences(state, action) {
			state.fetchingData = true;
		},
		modifyAudience(state, action) {
			state.fetchingData = true;
		},
		modifyAudienceSuccess(state, action: PayloadAction<IAudience>) {
			state.fetchingData = false;
			if (state.audiences.map(i => i.id).indexOf(action.payload.id) > -1) {
				state.audiences = state.audiences.map(i =>
					i.id === action?.payload?.id ? action.payload : i
				);
			} else {
				state.audiences = [...state.audiences, action.payload];
			}
		},
		createAudience(state, action) {},
		createAudienceSuccess(state, action: PayloadAction<IAudience>) {
			state.error = undefined;
			state.audiences = action.payload
				? [...state.audiences, action.payload]
				: state.audiences;
			state.selectedAudience = action.payload;
		},
		addConditional(state, action) {},
		addConditionalSuccess(state, action) {
			const { audienceId, conditionals } = action.payload;
			state.fetchingData = false;
			state.audiences = state.audiences.map(audience =>
				audience.id !== audienceId
					? audience
					: { ...audience, conditions: conditionals }
			);
			//updat selected audience too with the latest conditions
			state.selectedAudience = state.audiences
				.map(audience =>
					audience.id !== audienceId
						? audience
						: { ...audience, conditions: conditionals }
				)
				.filter(item => item.id === audienceId)[0];
		},
		updateAudience(state, action) {
			state.fetchingData = true;
		},
		updateAudienceSuccess(state, action) {
			state.fetchingData = false;
			if (
				state.audiences.map(i => i.id).indexOf(action?.payload?.id || '') > -1
			) {
				state.audiences = state.audiences.map(i => {
					if (i.id !== action?.payload?.id) return i;
					return action.payload ? action.payload : i;
				});
			}
			state.selectedAudience = action.payload;
		},
		fetchAudiences(state, action) {
			state.fetchingData = true;
		},
		fetchAudiencesSuccess(state, action) {
			state.fetchingData = false;
			state.audiences = [...action.payload];
		},
		getAudience(state, action) {
			state.modalFetchingData = true;
		},
		getAudienceSuccess(state, action: PayloadAction<IAudience>) {
			state.selectedAudience = action.payload;
			state.modalFetchingData = false;
		},
		next(state, _action) {
			state.fetchingData = true;
		},
		previous(_state, _action) {},
		appendAudienceList(state, action) {
			state.audiences = [...state.audiences, ...action.payload];
			state.fetchingData = false;
		},
		setNextToken(state, action) {
			state.nextToken = action.payload;
		},
		setIndexRange(state, action) {
			state.indexRange = action.payload;
			state.fetchingData = false;
		},
		archiveAudience(state, _action) {
			state.fetchingData = true;
		},
		archiveAudienceSuccess(state, _action) {
			state.fetchingData = false;
			//filter out the archived audience from state
			state.audiences = state.audiences?.filter(i => i?.isArchived === false);
		},
	},
});

export const { actions, reducer, name: sliceKey } = audiencesContainerSlice;
