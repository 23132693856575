import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { call, put, all, takeLatest, select } from 'redux-saga/effects';
import * as _ from 'lodash';
import { actions } from './slice';
import * as APIt from 'API';
import { toast } from 'react-toastify';
import { IGoal, ModifyEventAndGoal } from './types';
import { getGoal, goalsByOrganization } from 'graphql/queries';
import { errorMessageHandler } from 'utils/errorMessage';
import { createGoal, updateGoal } from 'graphql/mutations';
import { history } from 'utils/history';
import { handleModifyEvent } from 'app/containers/EventContainer/saga';
import { selectIndexRange } from './selectors';

const errorMessage = (err: Error) =>
	errorMessageHandler(err, 'Error occurred fetching goals.');

export function* handleError(err: Error) {
	const errMessage = errorMessage(err);
	yield call(toast.error, errMessage);
	yield put(actions.setGoalError(errMessage));
}

export function* handleFetchGoals(
	action: PayloadAction<APIt.GoalsByOrganizationQueryVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.GoalsByOrganizationQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(goalsByOrganization, action.payload)
		);
		yield put(actions.fetchGoalsSuccess(data?.goalsByOrganization?.items));
		yield put(actions.setNextToken(data?.goalsByOrganization?.nextToken));
		yield put(
			actions.setIndexRange([
				0,
				parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10') - 1,
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleNext() {
	try {
		const indexRange = yield select(selectIndexRange);
		yield put(
			actions.setIndexRange([
				indexRange[0] + parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
				indexRange[1] + parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handlePrevious() {
	try {
		const indexRange = yield select(selectIndexRange);
		yield put(
			actions.setIndexRange([
				indexRange[0] - parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
				indexRange[1] - parseInt(process.env.REACT_APP_PAGE_LIMIT ?? '10'),
			])
		);
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleCreateGoal(
	action: PayloadAction<APIt.CreateGoalInput>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.CreateGoalMutation> = yield call(
			[API, 'graphql'],
			graphqlOperation(createGoal, { input: action.payload })
		);
		yield put(actions.createGoalSuccess(data?.createGoal as IGoal));
		if (data && data.createGoal) {
			history.push(`${data.createGoal.id}`);
		}
		yield call(toast.success, 'Goal created');
		return data?.createGoal?.id;
	} catch (err) {
		yield call(handleError, err);
	}
}
export function* handleModifyEventAndGoal(
	action: PayloadAction<ModifyEventAndGoal>
): SagaIterator {
	try {
		const { goalData, eventData, group, organizationId } = action.payload;
		let returnedEventData;
		if (eventData) {
			returnedEventData = yield call(handleModifyEvent, {
				payload: { ...eventData, group, organizationId },
				type: '',
			});
		}

		if (goalData) {
			yield call(goalData?.id ? handleUpdateGoal : handleCreateGoal, {
				payload: {
					...goalData,
					eventId: returnedEventData?.id, //pass event Id to goal
					group,
					organizationId,
				},
				type: '',
			});
		}
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleCreateMultiGoals(
	action: PayloadAction<any>
): SagaIterator {
	// TODO: varify organizationId is working
	console.log('action.payload:', action.payload);
	try {
		const query = new Array(...action.payload).map(d => {
			return {
				name: {
					eq: d['name'],
				},
			};
		});
		// search data
		const { data }: GraphQLResult<APIt.GoalsByOrganizationQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(goalsByOrganization, {
				organizationId: action.payload.organizationId,
				filter: { or: query },
			})
		);
		const currentlyAdded = (data?.goalsByOrganization?.items as any).map(
			d => d.name
		);

		//filterout already created goals
		const newGoals = action.payload.filter(
			d => !currentlyAdded.includes(d.name)
		);

		// add new salesforce goal
		yield all(
			(newGoals as any).map(x =>
				call([API, 'graphql'], graphqlOperation(createGoal, { input: x }))
			)
		);
		yield call(toast.success, 'Goals created');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleUpdateGoal(
	action: PayloadAction<APIt.UpdateGoalInput>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.UpdateGoalMutation> = yield call(
			[API, 'graphql'],
			graphqlOperation(updateGoal, { input: action.payload })
		);
		yield put(actions.updateGoalSuccess(data?.updateGoal as IGoal));
		yield call(toast.success, 'Goal Updated');
		return data?.updateGoal;
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleArchiveGoal(
	action: PayloadAction<APIt.UpdateGoalInput>
): SagaIterator {
	try {
		const data = yield call(handleUpdateGoal, {
			payload: action.payload,
			type: '',
		});
		yield put(actions.archiveGoalSuccess(data));
		yield call(toast.success, 'Goal Archived');
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* handleGetGoal(
	action: PayloadAction<APIt.GetGoalQueryVariables>
): SagaIterator {
	try {
		const { data }: GraphQLResult<APIt.GetGoalQuery> = yield call(
			[API, 'graphql'],
			graphqlOperation(getGoal, action.payload)
		);
		yield put(actions.getGoalSuccess(data?.getGoal as IGoal));
	} catch (err) {
		yield call(handleError, err);
	}
}

export function* goalContainerSaga() {
	yield takeLatest(actions.createGoal.type, handleCreateGoal);
	yield takeLatest(actions.modifyEventAndGoal.type, handleModifyEventAndGoal);
	yield takeLatest(actions.updateGoal.type, handleUpdateGoal);
	yield takeLatest(actions.archiveGoal.type, handleArchiveGoal);
	yield takeLatest(actions.getGoal.type, handleGetGoal);
	yield takeLatest(actions.createMultiGoals.type, handleCreateMultiGoals);
	yield takeLatest(actions.fetchGoals.type, handleFetchGoals);
	yield takeLatest(actions.next.type, handleNext);
	yield takeLatest(actions.previous.type, handlePrevious);
}
